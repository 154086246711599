import { CampaignSchedule } from '@/pages/Novi/types/Schedule';
import apiClient from '@/utils/apiClient';

const CREATE_SCHEDULE = '/api/novi/schedule';
const GET_SCHEDULE = '/api/novi/schedules/:campaign_id';
const GET_SCHEDULE_STATUS = '/api/novi/schedule/status';

const NOVI = 'NOVI';

export const createSchedule = async (payload: {
  campaign_id: string;
  schedule: {
    allowed_retries: number;
    time_between_retries: number;
    allowed_times_start: string;
    allowed_times_end: string;
    days: number[];
    timezone: string;
  };
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      clientType: NOVI,
      endPoint: CREATE_SCHEDULE,
      body: payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getSchedule = async (campaign_id: string): Promise<{ schedule: CampaignSchedule | null }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      clientType: NOVI,
      endPoint: GET_SCHEDULE.replace(':campaign_id', campaign_id),
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateScheduleStatus = async (payload: {
  campaign_id: string;
  status: string;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      clientType: NOVI,
      endPoint: GET_SCHEDULE.replace(':campaign_id', payload.campaign_id),
      body: { status: payload.status },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getScheduleStatus = async (campaign_id: string): Promise<{ status: string }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      clientType: NOVI,
      endPoint: `${GET_SCHEDULE_STATUS}?campaign_id=${campaign_id}`,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
