import { Chapter } from '@/interfaces/IResellerAcademy';
import { Theme } from '@/interfaces/IThemeInterface';
import { Features, Reseller } from '@/types/TReseller';
import apiClient from '@/utils/apiClient';

const RESELLER_LOGIN_API_PATH = '/api/reseller/login';
const RESELLER_FORGOT_PASSWORD = '/api/reseller/forgot-password';
const RESELLER_VALID_PASSWORD_LINK = '/api/reseller/reset-password-link-valid';
const RESELLER_RESET_PASSWORD = '/api/reseller/reset-password';
const GET_RESELLER_API_PATH = '/api/reseller/';
const RESELLER_DASHBOARD_LINK_API_PATH = '/get-dashboard-link';
const UPDATE_AUTO_SCALE_RECEPTIONIST_API_PATH = '/api/reseller/update-auto-scale-receptionist';
const UPDATE_RESELLER_BRANDING = '/update_reseller_business_name_and_logo';
const UPDATE_RESELLER_BRANDING_TOGGLE = '/api/reseller/branded/setting/update';
const Fetch_Reseller_Clients_API_PATH = '/fetch_reseller_clients';
const Fetch_Reseller_TEST_Clients_API_PATH = '/fetch_reseller_test_clients';
const DELETE_RECEPTIONIST_API_PATH = '/api/reseller/delete-receptionist';
const UPDATE_MANAGED_RECEPTIONIST_API_PATH = '/api/reseller/update-managed-receptionist';
const CLEAN_DATA_OF_MANAGED_RECEPTIONIST_API_PATH = '/api/reseller/clean-data-of-managed-receptionist';
const CREATE_CHECKOUT_SESSION_NEW_RESELLERS_API_PATH = '/create-checkout-session-new-resellers';
const BILLING_OVERVIEW_API_PATH = '/api/reseller/billing-overview';
const STRIPE_CANCEL_OR_UPDATE_API_PATH = '/stripeCancelOrUpdate';
const HANDLE_CUSTOMER_PORTAL_API_PATH = '/api/stripe/customerPortal/reseller';
const FETCH_PENDING_INVOICES_API_PATH = '/api/reseller/fetch-pending-invoices';
const IMPORTED_NUMBERS_API_PATH = '/api/reseller/twilio-imported-numbers';
const REMOVE_CLIENT_NUMBER_API_PATH = '/api/reseller/remove-client-number';
const ASSIGN_CLIENT_NUMBER_API_PATH = '/api/reseller/assign-client-number';
const CONVERT_TRIAL_RECEPTIONIST = '/api/reseller/convert_trial_receptionist';
const DECREMENT_UNRESERVED_RECEPTIONIST = '/api/reseller/remove-subscription';
const GENERATE_DASHBOARD_THEME_AI = '/api/reseller/generate-dashboard-theme-ai';
const SET_DASHBOARD_THEME = '/api/reseller/set-dashboard-theme';
const REMOVE_DASHBOARD_THEME = '/api/reseller/remove-dashboard-theme';
const REMOVE_DISCOUNT_API_PATH = '/api/v2/receptionist-dashboard/remove-discount';
const APPLY_COUPON_PATH = '/api/v2/receptionist-dashboard/apply-coupon';
const RESELLER_ACADEMY = '/api/reseller/academy';
const GET_RESOURCE_URL = (key: string) => `/api/reseller/academy/resource/${key}`;
export const UPDATE_RESELLER_LOGO = `${import.meta.env.VITE_APP_API_BASE_URL}/upload_reseller_logo`;

export const resellerLogin = async (payload: {
  emailAddress: string;
  password: string;
}): Promise<{ token: string; emailAddress: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: RESELLER_LOGIN_API_PATH,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const resellerUserExists = async (payload: {
  email_address: string;
}): Promise<{ resellerUserExists: boolean; message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: RESELLER_FORGOT_PASSWORD,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const isResellerResetPasswordLinkValid = async (payload: {
  email_address: string;
  token: string;
}): Promise<{ isValid: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: RESELLER_VALID_PASSWORD_LINK,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const resellerResetPassword = async (payload: {
  email_address: string;
  password: string;
}): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: RESELLER_RESET_PASSWORD,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getReseller = async (): Promise<Reseller> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_RESELLER_API_PATH,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const ResellerDashboardLink = async (payload: {
  email: string;
}): Promise<{ success?: boolean; link: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: RESELLER_DASHBOARD_LINK_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const UpdateAutoScaleReceptionist = async (payload: {
  reseller_email: string;
  auto_scale: boolean;
  customerId: string;
}): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_AUTO_SCALE_RECEPTIONIST_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const UpdateResellerBranding = async (payload: {
  emailAddress: string;
  privacyPolicyURL?: string;
  resellerName?: string;
  termsOfUseURL?: string;
  themeColor?: string;
  uploadedLogoS3Url?: string;
  purchaseRedirectLink?: string;
  logoDimensions?: { width: number; height: number };
  twilioAccessToken?: string;
  twilioAccountId?: string;
  featureVisibility?: Array<string>;
  featureGating?: Array<string>;
  featureTrialVisibility?: Array<string>;
  featureTrialGating?: Array<string>;
  features?: Features;
}): Promise<{ success?: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: UPDATE_RESELLER_BRANDING,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const UpdateResellerBrandingToggle = async (payload: {
  resellerEmail: string;
  branded: boolean;
}): Promise<{ success: boolean; branded: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_RESELLER_BRANDING_TOGGLE,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const fetchResellerClients = async (
  reseller_email: string,
  shouldFetchReservedNumbers: boolean
): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: Fetch_Reseller_Clients_API_PATH,
      body: { email_address: reseller_email, shouldFetchReservedNumbers },
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
export const fetchResellerTestClients = async (reseller_email: string): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: Fetch_Reseller_TEST_Clients_API_PATH,
      body: { email_address: reseller_email },
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const deleteReceptionist = async (payload: {
  receptionist_username: string;
  reseller_email: string;
}): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: DELETE_RECEPTIONIST_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateManagedReceptionist = async (payload: {
  receptionist_username: string;

  fields: Record<string, any>;
}): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_MANAGED_RECEPTIONIST_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const cleanDataOfManagedReceptionist = async (payload: {
  receptionist_username: string;
  tableNames: string[];
}): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CLEAN_DATA_OF_MANAGED_RECEPTIONIST_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const createCheckoutSessionNewResellers = async (payload: {
  resellerEmail: string;
  quantity: number;
}): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CREATE_CHECKOUT_SESSION_NEW_RESELLERS_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getBillingOverview = async (payload: {
  email_address: string;
}): Promise<{
  success: boolean;
  message?: string;
  data?: {
    basePlanStatus: string;
    voiceUsageCharge: number;
    subAccounts: { username: string; freeMinutesLeft: number; totalFreeMinutes: number; totalMinutesUsed: number }[];
    appliedCoupons?:
      | {
          id: string;
          name: string;
          amountOff: number | null;
          percentOff: number | null;
          duration: string;
          durationInMonths: number | null;
          validUntil: string | null;
        }[]
      | null;
  };
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: BILLING_OVERVIEW_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const stripeCancelOrUpdate = async (payload: {
  username: string;
  action: string;
  reseller: boolean;
}): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: STRIPE_CANCEL_OR_UPDATE_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const handleCustomerPortal = async (payload: { emailAddress: string }): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: HANDLE_CUSTOMER_PORTAL_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const fetchPendingInvoices = async (): Promise<{
  status: boolean;
  pendingInvoices?: any[];
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: FETCH_PENDING_INVOICES_API_PATH,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const convertTrialReceptionist = async (payload: {
  reseller_email: string;
  subAccountUsername: string;
}): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CONVERT_TRIAL_RECEPTIONIST,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
export const getImportedTwilioNumbers = async (): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: IMPORTED_NUMBERS_API_PATH,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const removeClientNumber = async (payload: { clientUsername: string; clientNumber: string }): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: REMOVE_CLIENT_NUMBER_API_PATH,
      body: { ...payload, wantsToRelease: false },
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const assignClientNumber = async (payload: { clientUsername: string; clientNumber: string }): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: ASSIGN_CLIENT_NUMBER_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const decrementUnreservedReceptionist = async (payload: { reseller_email: string }): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: DECREMENT_UNRESERVED_RECEPTIONIST,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const generateDashboardThemeAI = async (payload: {
  logoKey: string;
  currentTheme: object;
}): Promise<{
  success: boolean;
  data?: {
    theme: Theme;
  };
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: GENERATE_DASHBOARD_THEME_AI,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const setDashboardTheme = async (payload: {
  theme: object;
}): Promise<{
  success: boolean;
  data?: {
    dashboardTheme: Theme;
  };
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: SET_DASHBOARD_THEME,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const resetThemeToDefault = async (): Promise<{
  success: boolean;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: REMOVE_DASHBOARD_THEME,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const removeDiscount = async (payload: {
  appliedOn: string;
}): Promise<{
  success: boolean;
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: REMOVE_DISCOUNT_API_PATH,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const applyCouponCode = async (couponCode: string): Promise<{ success: boolean; message?: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: APPLY_COUPON_PATH,
      body: { couponCode },
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong with applyCouponCode', err);
    throw err;
  }
};

export const fetchResellerAcademy = async (): Promise<{
  success: boolean;
  data?: Chapter[];
  message?: string;
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: RESELLER_ACADEMY,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const updateResellerAcaemyHistiory = async (payload: {
  chapterId: number;
  videoSlug: string;
  isCompleted: boolean;
  watchedDuration: number;
}): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: RESELLER_ACADEMY,
      body: payload,
      clientType: 'RESELLER',
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getResourceURL = async (
  key: string
): Promise<{
  success: boolean;
  url?: string;
  message?: string;
}> => {
  try {
    const repsonse = apiClient.request({
      method: 'GET',
      endPoint: GET_RESOURCE_URL(key),
      clientType: 'RESELLER',
    });

    return repsonse;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
