import { useState, useEffect } from 'react';
import { Form, Input, Space, Button, Layout, notification, Tabs, Spin, Grid } from 'antd';
import {
  SettingOutlined,
  LayoutOutlined,
  BgColorsOutlined,
  FontColorsOutlined,
  BorderOutlined,
  RobotOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { CustomModal } from '@/components/CustomModal';
import { closeModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { PaletteOutlined, TagOutlined } from '@mui/icons-material';
import { ReceptionistDashboard } from '@/pages/ReceptionistDashboard';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchResellerClients, generateDashboardThemeAI, resetThemeToDefault, setDashboardTheme } from '@/api/reseller';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import { Theme } from '@/interfaces/IThemeInterface';
import './ThemeCustomizer.scss';
import { setAccount } from '@/store/account/slice';
import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { loginApi } from '@/api/user';
import { setAppTheme } from '@/store/app/slice';
import { getAppTheme } from '@/store/app/selector';

const { Content } = Layout;
const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const initialTheme: Theme = {
  themeBackgroundColorPrimary: '#ffffff',
  themeBackgroundColorTertiary: '#ededed',
  textColorPrimary: '#ffffff',
  textColorSecondary: '#37352f',
  textColorTertiary: '#acaba9',
  textColorDanger: '#ff4d4f',
  buttonSuccessColor: '#6e79d6',
  buttonSuccessColorHover: '#727cd5',
  buttonMenuItemHover: 'rgba(0, 0, 0, 0.06)',
  defaultBorderColor: '#c6bcbc',
  lightBorderColor: '#f0f0f0',
  badgeSuccessColor: '#b8ea8f',
  badgeWarningColor: '#ffe58f',
  badgeDisabledColor: '#e7e7e7',
  badgeInfoColor: '#91caff',
  badgeDangerColor: '#ffccc6',
  defaultHoverColor: '#0000000f',
};

const ThemeCustomizer = () => {
  const screens = useBreakpoint();
  const isDesktop = screens.xl;
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState('1');
  const [currentTheme, setCurrentTheme] = useState<Partial<Theme>>(initialTheme);
  const [savedTheme, setSavedTheme] = useState<Partial<Theme>>(initialTheme);
  const [showEditor, setShowEditor] = useState(false);
  const currentReseller = useAppSelector(getResellerData);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const dashboardTheme = useAppSelector(getAppTheme);

  const { data: resellerClients, error: resellerClientsError } = useQuery({
    queryKey: ['fetchResellerClients'],
    queryFn: () => {
      setLoadingPreview(true);
      return fetchResellerClients(currentReseller?.email_address, true);
    },
  });

  useEffect(() => {
    if (resellerClients?.items && resellerClients?.items[0]) {
      previewCurrentClientMutation.mutate({
        username: resellerClients.items[0].number,
        password: resellerClients.items[0].password,
      });
    }
  }, [resellerClients]);

  useEffect(() => {
    if (dashboardTheme) {
      // Set both current and saved theme to dashboard theme
      updateStates(dashboardTheme, true, true, true, false);
      return;
    }

    const completeTheme: Theme = {
      ...initialTheme,
      ...currentReseller?.dashboardTheme,
    };

    // Set both current and saved theme to complete theme
    updateStates(completeTheme, true, true, true, false);
  }, []);

  const previewCurrentClientMutation = useMutation({
    mutationKey: ['previewCurrentClient'],
    mutationFn: loginApi,
    onSuccess(data) {
      if (data.token) {
        dispatch(
          setAccount({
            isAuthenticated: true,
            user: data,
            authToken: data.token,
            currentPage: '',
            activeConfigureTab: '',
          })
        );

        LocalStorageUtility.setLocalData(LocalStorageKeys.AUTH_TOKEN, data.token);
      }
      setLoadingPreview(false);
    },
    onError() {
      notification.error({
        message: 'Error previewing client',
      });
      setLoadingPreview(false);
    },
  });

  const { mutate: generateTheme, isPending: isGenerating } = useMutation({
    mutationKey: ['generateAITheme'],
    mutationFn: generateDashboardThemeAI,
    onSuccess: resp => {
      if (resp.success && resp.data) {
        const completeTheme: Theme = {
          ...savedTheme,
          ...(resp.data.theme as Theme),
        };
        updateStates(completeTheme, false, true, true, false);
        notification.success({
          message: 'AI Theme Generated',
          description: 'New theme has been generated based on your brand!',
        });
      }
    },
    onError: err => {
      if (err.message === 'Rate limit exceeded. Maximum 5 theme generations per 24 hours.') {
        notification.error({
          message: 'Generation Failed',
          description: 'You have exceeded the maximum number of theme generations per 24 hours.',
        });
      } else {
        notification.error({
          message: 'Generation Failed',
          description: 'Failed to generate theme using AI.',
        });
      }
    },
  });

  const { mutate: saveThemeMutation, isPending: isSaving } = useMutation({
    mutationKey: ['saveTheme'],
    mutationFn: setDashboardTheme,
    onSuccess: data => {
      if (data.success && data.data) {
        updateStates(data.data?.dashboardTheme, true, true, true, true);
        notification.success({ message: 'Theme saved successfully' });
        dispatch(closeModal());
      }
    },
    onError: () => {
      notification.error({ message: 'Failed to save theme' });
    },
  });

  const { mutate: resetThemeMutation, isPending: isResetting } = useMutation({
    mutationKey: ['resetTheme'],
    mutationFn: resetThemeToDefault,
    onSuccess: () => {
      updateStates(initialTheme, true, true, true, false);
      // removing it from local as no need to keep it.
      localStorage.removeItem('dashboardTheme');
      notification.success({ message: 'Theme reset to default successfully' });
      dispatch(closeModal());
    },
    onError: () => {
      notification.error({ message: 'Failed to reset theme' });
    },
  });

  const applyTheme = (theme: Partial<Theme>) => {
    dispatch(setAppTheme(theme));
  };

  const updateStates = (
    theme: Partial<Theme>,
    saved: boolean,
    apply: boolean,
    setForm: boolean,
    setLocalStorage: boolean
  ) => {
    setCurrentTheme(theme);
    saved && setSavedTheme(theme);
    setForm && form.setFieldsValue(theme);
    setLocalStorage && localStorage.setItem('dashboardTheme', JSON.stringify(theme));
    apply && applyTheme(theme);
  };

  const handleModalClose = () => {
    // Reset to the last saved theme on close
    updateStates(savedTheme, false, true, true, false);
    dispatch(closeModal());
  };

  const handleSave = (values: Partial<Theme>) => {
    const completeTheme = {
      ...savedTheme, // Use saved theme as base instead of initial
      ...values, // Apply new values on top
    };
    saveThemeMutation({ theme: completeTheme });
  };

  const handleGenerateAI = () => {
    const completeCurrentTheme = {
      ...savedTheme,
      ...currentTheme,
    };
    generateTheme({
      logoKey: currentReseller?.logo_s3,
      currentTheme: completeCurrentTheme,
    });
  };

  const handleFormChange = (changedValues: Partial<Theme>) => {
    const newTheme = {
      ...currentTheme, // Keep existing theme values
      ...changedValues, // Apply only changed values
    };
    setCurrentTheme(newTheme);
    applyTheme(newTheme);
  };

  const ColorInput = ({ name, label }: { name: keyof Theme; label: string }) => (
    <Form.Item name={name} label={label}>
      <div className='theme-customizer-color-input'>
        <Input
          type='color'
          className='theme-customizer-color-input-picker'
          value={form.getFieldValue(name)}
          onChange={e => form.setFieldValue(name, e.target.value)}
        />
        <Input
          className='theme-customizer-color-input-text'
          value={form.getFieldValue(name)}
          onChange={e => form.setFieldValue(name, e.target.value)}
        />
      </div>
    </Form.Item>
  );

  const EditorContent = () => (
    <div className='theme-customizer-editor-form'>
      <Button
        className='mb-3'
        type='primary'
        icon={<RobotOutlined />}
        onClick={handleGenerateAI}
        loading={isGenerating}
        block
      >
        Generate Theme Using AI
      </Button>

      <Form
        form={form}
        layout='vertical'
        initialValues={currentTheme}
        onValuesChange={handleFormChange}
        onFinish={handleSave}
      >
        <Tabs activeKey={activeTab} onChange={setActiveTab} type='card'>
          <TabPane
            tab={
              <Space>
                <BgColorsOutlined />
                Background
              </Space>
            }
            key='1'
          >
            <ColorInput name='themeBackgroundColorPrimary' label='Primary' />
            <ColorInput name='themeBackgroundColorTertiary' label='Tertiary' />
          </TabPane>

          <TabPane
            tab={
              <Space>
                <FontColorsOutlined />
                Typography
              </Space>
            }
            key='2'
          >
            <ColorInput name='textColorPrimary' label='Primary' />
            <ColorInput name='textColorSecondary' label='Secondary' />
            <ColorInput name='textColorTertiary' label='Tertiary' />
            <ColorInput name='textColorDanger' label='Danger' />
          </TabPane>

          <TabPane
            tab={
              <Space>
                <LayoutOutlined />
                Buttons
              </Space>
            }
            key='3'
          >
            <ColorInput name='buttonSuccessColor' label='Success' />
            <ColorInput name='buttonSuccessColorHover' label='Success Hover' />
            <ColorInput name='buttonMenuItemHover' label='Menu Item Hover' />
          </TabPane>

          <TabPane
            tab={
              <Space>
                <BorderOutlined />
                Borders
              </Space>
            }
            key='4'
          >
            <ColorInput name='defaultBorderColor' label='Default' />
            <ColorInput name='lightBorderColor' label='Light' />
          </TabPane>

          <TabPane
            tab={
              <Space>
                <TagOutlined />
                Badges
              </Space>
            }
            key='5'
          >
            <ColorInput name='badgeSuccessColor' label='Success' />
            <ColorInput name='badgeWarningColor' label='Warning' />
            <ColorInput name='badgeDisabledColor' label='Disabled' />
            <ColorInput name='badgeInfoColor' label='Info' />
            <ColorInput name='badgeDangerColor' label='Danger' />
          </TabPane>

          <TabPane
            tab={
              <Space>
                <SettingOutlined />
                Other
              </Space>
            }
            key='6'
          >
            <ColorInput name='defaultHoverColor' label='Default Hover' />
          </TabPane>
        </Tabs>

        <div className='theme-customizer-actions'>
          <Button type='primary' htmlType='submit' size='large' block>
            Save Theme
          </Button>
          <Button type='primary' onClick={() => resetThemeMutation()} loading={isResetting} size='large' block>
            Reset to Default
          </Button>
        </div>
      </Form>
    </div>
  );

  return (
    <CustomModal
      width='100vw'
      className='theme-customizer-modal'
      title={
        <Space>
          <PaletteOutlined />
          Theme Customization
          <p
            className='
          text-sm font-bold text-red-600
          '
          >
            (BETA Version)
          </p>
        </Space>
      }
      modalId={MODAL_IDS.THEME_CUSTOMIZE_MODAL}
      onClose={handleModalClose}
      footer={null}
      cancelAction={handleModalClose}
    >
      <Layout className='theme-customizer-layout'>
        {isDesktop && (
          <div className='theme-customizer-sidebar'>
            <Spin spinning={isSaving}>
              <EditorContent />
            </Spin>
          </div>
        )}

        <Content className='theme-customizer-preview'>
          {currentReseller?.totalReceptionists <= 0 ? (
            <div className='h-full flex flex-col items-center justify-center'>
              <p className='text-xl font-bold'> You need to create a receptionist before you can preview the theme</p>
            </div>
          ) : loadingPreview && (!resellerClientsError || !previewCurrentClientMutation.error) ? (
            <div className='h-full flex items-center justify-center'>
              <Spin className='justify-center' size='large' />
            </div>
          ) : resellerClientsError || previewCurrentClientMutation.error ? (
            <div className='theme-customizer-preview-content'>
              <h2>Failed to load Preview</h2>
            </div>
          ) : (
            <div className='theme-customizer-preview-content'>
              <p className='font-bold text-base'>
                Previewing client: <span className='text-gray-500'>{previewCurrentClientMutation.data?.number}</span>
              </p>
              <ReceptionistDashboard debrand={true} />
            </div>
          )}
        </Content>

        {!isDesktop && (
          <>
            {showEditor ? (
              <div className='theme-customizer-editor-panel'>
                <Button
                  type='link'
                  className='theme-customizer-editor-panel-header'
                  icon={<LeftOutlined />}
                  onClick={() => setShowEditor(false)}
                >
                  Back to Preview
                </Button>

                <Spin spinning={isSaving}>
                  <EditorContent />
                </Spin>
              </div>
            ) : (
              <div className='theme-customizer-editor-toggle'>
                <Button type='primary' onClick={() => setShowEditor(true)} block>
                  Show Theme Editor
                </Button>
              </div>
            )}
          </>
        )}
      </Layout>
    </CustomModal>
  );
};

export default ThemeCustomizer;
