import { Button, message, Card } from 'antd';
import { IoChevronBack } from 'react-icons/io5';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';
import { parsePhoneNumber } from 'libphonenumber-js';
import { UploadCSVProps } from '@/pages/Novi/types/Leads';
import CSVValidator from './CSVValidator';
import { useMutation } from '@tanstack/react-query';
import { addLeadsViaCSV } from '@/api/novi/leads';

const UploadCSV: React.FC<UploadCSVProps> = ({ onClose, goBack, campaignId }) => {
  const selectedCampaign = useAppSelector(getSelectedCampaign);
  const requiredColumns = ['phone_number'];

  const variables = selectedCampaign?.variables ? JSON.parse(selectedCampaign.variables) : [];
  const requiredColumnsWithVariables = [...requiredColumns, ...Object.keys(variables)];

  const { mutate: uploadCsv } = useMutation({
    mutationKey: ['uploadCsv'],
    mutationFn: addLeadsViaCSV,
    onSuccess: () => {
      message.success('Upload successful');
      onClose();
    },
    onError: () => {
      message.error('Upload failed');
    },
  });

  const handleUpload = (file: File) => {
    if (file.type === 'text/csv') {
      if (campaignId) {
        uploadCsv({ campaign_id: campaignId, file });
      } else {
        throw new Error('Campaign ID is undefined');
      }
    } else {
      message.error('Please upload a valid CSV file.');
    }
  };

  const validateRow = (row: any) => {
    const errors = [];

    try {
      const parsedNumber = parsePhoneNumber(row.phone_number);

      if (!parsedNumber) {
        errors.push(`${row.phone_number} format is invalid`);
      }
    } catch (error) {
      errors.push(`Invalid phone number format: ${row.phone_number}`);
    }

    return {
      isValid: errors.length === 0,
      errors,
    };
  };

  return (
    <Card>
      <Button
        type='link'
        icon={<IoChevronBack />}
        onClick={goBack}
        style={{ marginBottom: '24px', color: '#1890ff', fontWeight: 500 }}
      >
        Choose another method
      </Button>
      <CSVValidator
        onClose={onClose}
        onSubmit={handleUpload}
        requiredColumns={requiredColumnsWithVariables}
        validateRow={validateRow}
      />
    </Card>
  );
};

export default UploadCSV;
