import { Form, notification, Spin } from 'antd';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { OverViewCardForm } from '../OverviewCardForm';
import React, { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { RefetchOptions, QueryObserverResult, UseMutationResult } from '@tanstack/react-query';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { User } from '@/types/TAccounts';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

export const AiPromptForm: React.FC<{
  ai_prompt?: string;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
}> = ({ ai_prompt, mutation, refetch }) => {
  const [form] = Form.useForm<{
    ai_prompt: string;
  }>();

  const dispatch = useAppDispatch();

  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);

  const handleSubmit = async (value: any) => {
    await mutation.mutateAsync({
      ai_prompt: value.ai_prompt,
    });

    refetch();
    notification.success({
      key: 'form-success',
      message: 'AI Prompt has been saved successfully!',
      duration: 3,
    });
    setIsFieldTouchReset(false);
    dispatch(closeModal());
  };

  const FormItems = () => (
    <Form.Item
      label={
        <LabelWithDescription
          customClassName='mt-2 '
          label={
            <div className='flex items-center gap-1'>
              AI Prompt
              <CopyableText text={ai_prompt || ''} hideText />
            </div>
          }
          description='A prompt for your AI receptionist. Generally, this default prompt is fine for most use cases.'
        />
      }
      name='ai_prompt'
      className='!mb-0'
    >
      <TextArea rows={8} placeholder='Please enter your Prompt' />
    </Form.Item>
  );

  return (
    <div>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={{
            ai_prompt,
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  );
};
