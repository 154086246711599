import { Card, Empty } from 'antd';

export const CallSummaryCard = ({
  callSummary,
  style,
}: {
  callSummary: string | undefined;
  style?: React.CSSProperties;
}) => {
  return (
    <Card title='Call Summary' style={style}>
      {callSummary ? <span>{callSummary}</span> : <Empty description='No Summary' />}
    </Card>
  );
};
