import { VideoContent } from '@/interfaces/IResellerAcademy';
import { ArrowLeftOutlined, CheckCircleFilled, ClockCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Typography, Progress, Spin, Empty } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ResellerDashboard from '../../ResellerDashboard/ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import ReactPlayer from 'react-player';
import './VideoPlayerStyles.scss';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerAcademy, getVideoBySlug } from '@/store/resellerAcademy/selector';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getResourceURL, updateResellerAcaemyHistiory } from '@/api/reseller';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { updateResellerAcademyVideoBySlug } from '@/store/resellerAcademy/slice';
import { AppRoutes } from 'src/enums/ERoutes';

const VideoPlayer = () => {
  const { Text, Title } = Typography;
  const { videoSlug, chapterId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading } = useAppSelector(getResellerAcademy);
  const playerRef = useRef<ReactPlayer>(null);
  const progressUpdateTimerRef = useRef<NodeJS.Timeout | null>(null);
  const lastUpdateTimeRef = useRef<number>(0);
  const hasInitializedRef = useRef(false);
  const lastApiCallTimeRef = useRef<number>(Date.now());

  const video = useAppSelector(getVideoBySlug(Number(chapterId), videoSlug as string));
  const [showCompletion, setShowCompletion] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasRecentlyUpdated, setHasRecentlyUpdated] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);

  const {
    data: videoURL,
    isLoading: videoURLLoading,
    refetch: refetchVideoURL,
  } = useQuery({
    queryKey: ['resource-url', video?.key],
    queryFn: () => {
      if (!video?.key) {
        return null;
      }
      return getResourceURL(video?.key as string);
    },
    staleTime: 55 * 60 * 1000, // 55 minutes
    // @ts-ignore
    cacheTime: 60 * 60 * 1000, // 1 hour
  });

  const { mutate: updateProgress } = useMutation({
    mutationKey: ['video-progress'],
    mutationFn: updateResellerAcaemyHistiory,
    onSuccess: _ => {
      lastUpdateTimeRef.current = Date.now();
      setHasRecentlyUpdated(true);

      setTimeout(() => {
        setHasRecentlyUpdated(false);
      }, 1000);
    },
    onError: err => {
      console.log('error: ', err);
    },
  });

  useEffect(() => {
    if (isPlaying) {
      const refreshInterval = setInterval(
        () => {
          refetchVideoURL();
        },
        55 * 60 * 1000
      ); // Refresh every 55 minutes while playing

      return () => clearInterval(refreshInterval);
    }
  }, [isPlaying, refetchVideoURL]);

  useEffect(() => {
    if (video?.isCompleted) {
      setShowCompletion(true);
    }

    return () => {
      if (progressUpdateTimerRef.current) {
        clearTimeout(progressUpdateTimerRef.current);
      }
    };
  }, [video]);

  useEffect(() => {
    if (video && !hasInitializedRef.current) {
      hasInitializedRef.current = true;

      // Update Redux store with initial video state
      dispatch(
        updateResellerAcademyVideoBySlug({
          slug: videoSlug as string,
          chapterId: Number(chapterId),
          video: {
            ...video,
            watchedDuration: video.watchedDuration || 0,
            isCompleted: video.isCompleted || false,
          },
        })
      );
    }
  }, [video, videoSlug, chapterId, dispatch]);

  const handleReady = () => {
    if (isReady) return;
    setIsReady(true);
    const duration = playerRef.current?.getDuration() || 0;

    dispatch(
      updateResellerAcademyVideoBySlug({
        slug: videoSlug as string,
        chapterId: Number(chapterId),
        video: {
          ...video,
          duration,
        } as VideoContent,
      })
    );

    if (video?.watchedDuration) {
      if (video?.watchedDuration > 0) {
        playerRef.current?.seekTo(video.watchedDuration, 'seconds');
      }
    }
  };

  const saveProgress = async (watchedDuration: number, _: boolean, forceSave: boolean = false) => {
    if (video?.isCompleted) {
      return;
    }

    const now = Date.now();
    const timeSinceLastUpdate = now - lastUpdateTimeRef.current;

    if (!forceSave && hasRecentlyUpdated) {
      return;
    }

    // Update Redux store for progress only
    dispatch(
      updateResellerAcademyVideoBySlug({
        slug: videoSlug as string,
        chapterId: Number(chapterId),
        video: {
          ...video,
          watchedDuration,
        } as VideoContent,
      })
    );

    // Send to server if conditions are met
    if ((forceSave || timeSinceLastUpdate >= 10000) && (isPlaying || forceSave)) {
      try {
        updateProgress({
          chapterId: Number(chapterId),
          videoSlug: video?.slug as string,
          isCompleted: false, // Never mark as complete through progress updates
          watchedDuration,
        });

        lastApiCallTimeRef.current = now;
      } catch (error) {
        console.error('Error saving progress:', error);
      }
    }
  };

  const handleBuffer = () => {
    setIsBuffering(true);
  };

  const handleBufferEnd = () => {
    setIsBuffering(false);
  };

  const handleVideoProgress = ({ playedSeconds }: { played: number; playedSeconds: number }) => {
    if (video?.isCompleted) {
      return;
    }

    // Update Redux store immediately for smooth local progress
    dispatch(
      updateResellerAcademyVideoBySlug({
        slug: videoSlug as string,
        chapterId: Number(chapterId),
        video: {
          ...video,
          watchedDuration: playedSeconds,
        } as VideoContent,
      })
    );

    // Check if it's time to make an API call
    const now = Date.now();
    const timeSinceLastApiCall = now - lastApiCallTimeRef.current;

    // Only update progress, don't check for completion here
    if (timeSinceLastApiCall >= 10000) {
      saveProgress(playedSeconds, false, false);
    }
  };

  const handleVideoEnd = () => {
    if (!video?.isCompleted) {
      setIsPlaying(false);
      const duration = playerRef.current?.getDuration() || video?.duration || 0;

      // First update Redux for immediate UI feedback
      dispatch(
        updateResellerAcademyVideoBySlug({
          slug: videoSlug as string,
          chapterId: Number(chapterId),
          video: {
            ...video,
            watchedDuration: duration,
            isCompleted: true,
          } as VideoContent,
        })
      );

      // Then make the API call with onSuccess handler
      updateProgress(
        {
          chapterId: Number(chapterId),
          videoSlug: video?.slug as string,
          isCompleted: true,
          watchedDuration: duration,
        },
        {
          onSuccess: () => {
            // Only show completion alert after successful API update
            setShowCompletion(true);
            lastApiCallTimeRef.current = Date.now();
            lastUpdateTimeRef.current = Date.now();
          },
          onError: () => {
            // Revert Redux state if API call fails
            dispatch(
              updateResellerAcademyVideoBySlug({
                slug: videoSlug as string,
                chapterId: Number(chapterId),
                video: {
                  ...video,
                  watchedDuration: duration,
                  isCompleted: false,
                } as VideoContent,
              })
            );
          },
        }
      );
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    if (!video?.isCompleted) {
      setIsPlaying(false);
      if (progressUpdateTimerRef.current) {
        clearTimeout(progressUpdateTimerRef.current);
      }
      saveProgress(video?.watchedDuration || 0, video?.isCompleted || false, true);
    }
  };

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.ACADEMY} showHeader={false}>
      <Spin spinning={loading || videoURLLoading}>
        <div className='video-player__container'>
          <div className='video-player__header'>
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(AppRoutes.RESELLER_ACADEMY_CHAPTER.replace(':chapterId', chapterId as string))}
              className='video-player__back-button'
            >
              Back to Chapter
            </Button>
            {!video && !loading && !videoURLLoading ? (
              <Empty description='Video not found' className='video-player__empty'>
                <Button
                  type='primary'
                  onClick={() =>
                    navigate(AppRoutes.RESELLER_ACADEMY_CHAPTER.replace(':chapterId', chapterId as string))
                  }
                >
                  Return to Chapter
                </Button>
              </Empty>
            ) : (
              <>
                <div className='video-player__title-section'>
                  <Title level={2} className='video-player__title'>
                    {video?.title}
                  </Title>
                  <div className='video-player__meta'>
                    <ClockCircleOutlined className='video-player__meta-icon' />
                    <Text>Duration: {formatDuration(video?.duration || 0)}</Text>
                  </div>
                </div>
                <div className='video-player__content'>
                  <div className='video-player__primary-content'>
                    <div className='video-player__video-wrapper'>
                      {showCompletion && (
                        <div className='video-player__completion-alert'>
                          <Alert
                            message='Lesson Completed!'
                            description="Great job! You've completed this lesson."
                            type='success'
                            showIcon
                            icon={<CheckCircleFilled />}
                            closable
                            onClose={() => setShowCompletion(false)}
                          />
                        </div>
                      )}

                      <div className='video-player__player-container'>
                        {isBuffering && (
                          <div className='video-player__buffering-overlay'>
                            <Spin size='large' tip='Buffering...' />
                          </div>
                        )}
                        <ReactPlayer
                          ref={playerRef}
                          url={videoURL?.url}
                          width='100%'
                          height='100%'
                          controls
                          onReady={handleReady}
                          onProgress={handleVideoProgress}
                          onEnded={handleVideoEnd}
                          onPlay={handlePlay}
                          onPause={handlePause}
                          onBuffer={handleBuffer}
                          onBufferEnd={handleBufferEnd}
                          config={{
                            file: {
                              forceVideo: true,
                              attributes: {
                                controlsList: 'nodownload',
                                onContextMenu: (e: React.MouseEvent) => e.preventDefault(),
                              },
                            },
                          }}
                        />
                      </div>

                      <div className='video-player__progress-section'>
                        <Progress
                          percent={
                            video?.isCompleted
                              ? 100
                              : Math.round(((video?.watchedDuration || 0) / (video?.duration || 1)) * 100)
                          }
                          status={video?.isCompleted ? 'success' : 'active'}
                          format={percent => `${percent}% completed`}
                          strokeColor={{
                            '0%': '#1890ff',
                            '100%': '#52c41a',
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='video-player__side-content'>
                    {video?.description && (
                      <Card className='video-player__content-card'>
                        <Title level={5}>Description</Title>
                        <Text>{video.description}</Text>
                      </Card>
                    )}

                    {video?.content && (
                      <Card className='video-player__content-card'>
                        <Title level={5}>Additional Content</Title>
                        <div className='prose max-w-none'>{video.content}</div>
                      </Card>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Spin>
    </ResellerDashboard>
  );
};

export default VideoPlayer;
