import React, { useRef, useState } from 'react';
import { Card, Typography, Button, Select, Table, Descriptions, Spin, notification, FormInstance } from 'antd';
import { CheckCircleOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import { getReseller } from '@/api/reseller';
import { useMutation, useQuery } from '@tanstack/react-query';
import ResellerDashboard from '../ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import { useGetResellerClients } from 'src/Hooks/resellerHooks';
import { CustomModal } from '@/components/CustomModal';
import {
  getNonUsNumberRequirement,
  getTwilioBundleStatus,
  provisionNumberRequest,
  resetTwilioBundle,
} from '@/api/reservedNumbers';
import GetBundleApproveForm from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetBundleApproveForm';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { updateReseller } from '@/store/reseller/slice';
import posthog from 'posthog-js';
import ConfettiExplosion from 'react-confetti-explosion';
import { GetStandAloneNumberForm } from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetStandAloneNumberForm';
import GetChooseNumberForm from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetChooseNumberForm';
import { AvailableNumber } from '@/interfaces/IReservedNumber';
import { PhoneOutlined } from '@mui/icons-material';
import Paragraph from 'antd/es/typography/Paragraph';

const { Title, Text } = Typography;
const { Option } = Select;

const ResellerPhoneNumbers: React.FC = () => {
  const formRef = useRef<FormInstance>(null);

  const [clients, setClients] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [isStandAloneNumberModalVisible, setIsStandAloneNumberModalVisible] = useState<boolean>(false);
  const [isBookNumberModalVisible, setIsBookNumberModalVisible] = useState<boolean>(false);
  const [ChooseNumberModalOpen, setChooseNumberModalOpen] = useState<boolean>(false);
  const [celeberate, setCeleberate] = useState(false);
  const [isNonUSDetailsModalOpen, setIsNonUSDetailsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [twilioDocsRequirements, setTwilioDocsRequirements] = useState<any>([]);

  const openStandAloneNumberModal = () => {
    posthog.capture('trying_to_get_standalone_number');
    setIsStandAloneNumberModalVisible(true);
  };

  const closeStandAloneNumberModal = () => {
    setIsStandAloneNumberModalVisible(false);
  };

  const openBookNumberModal = () => {
    setIsBookNumberModalVisible(true);
  };

  const closeBookNumberModal = () => {
    setIsBookNumberModalVisible(false);
  };

  const resellerData = useAppSelector(getResellerData);
  const dispatch = useAppDispatch();

  const { refetch } = useQuery({
    queryKey: ['reseller'],
    queryFn: getReseller,

    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { mutate: provisionNumberRequestMutation, isPending: isProvisionApiPending } = useMutation({
    mutationKey: ['provisionNumberRequest'],
    mutationFn: provisionNumberRequest,
    onSuccess: response => {
      const data = response;
      if (data.status && data.status === 'success' && data.provisionedNumber) {
        getClientsMutation.mutate(resellerData.email_address);
        setIsBookNumberModalVisible(false);
        setCeleberate(true);
        setClients(prevClients =>
          prevClients.map(client =>
            client.number === selectedClient.number ? { ...client, reservedNumber: data.provisionedNumber } : client
          )
        );
        setSelectedClient({ ...selectedClient, reservedNumber: data.provisionedNumber });
      }
    },
    onError: error => {
      console.log('error', error);

      notification.error({ message: error.message, description: 'Failed to provision number' });
    },
  });

  const getBundleIdMutation = useMutation({
    mutationKey: ['getBundleId'],
    mutationFn: (bundleId: string) => getTwilioBundleStatus(bundleId, true),
    onSuccess(data) {
      refetch();
      dispatch(updateReseller({ twilioBundleId: data.sid, twilioBundleStatus: data.status }));
      if (!resellerData.twilioBundleId) {
        dispatch(updateReseller({ twilioBundleId: data.sid, twilioBundleStatus: 'pending-review' }));
      }
    },
  });

  const { mutate: resetBundleMutation } = useMutation({
    mutationKey: ['resetBundle'],
    mutationFn: (email: string) => resetTwilioBundle(email, true),
    onSuccess: () => {
      dispatch(updateReseller({ twilioBundleId: null, twilioBundleStatus: null }));
      notification.success({ message: 'Bundle reset successfully' });
    },
    onError: () => {
      notification.error({ message: 'Failed to reset bundle' });
    },
  });

  const handleConfirmAction = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handleFormSubmit = (values: { country: string }) => {
    setSelectedCountry(values.country);
    closeStandAloneNumberModal();
    openBookNumberModal();
  };

  const getClientsMutation = useGetResellerClients({
    shouldFetchReservedNumbers: true,
    onSuccess: data => {
      setClients(data.items);
    },
  });

  const { mutate: getNonUsNumberRequirementMutation, isPending: isRequirementDispatchPending } = useMutation({
    mutationKey: ['getNonUsNumberRequirement'],
    mutationFn: getNonUsNumberRequirement,
    onSuccess: response => {
      setTwilioDocsRequirements(response);
      setIsNonUSDetailsModalOpen(true);
    },
  });

  // Main function to book number
  const bookNumberRequest = async (localChoosen: AvailableNumber | null) => {
    if (!selectedCountry) {
      return;
    }

    if (!resellerData?.twilioBundleId) {
      if (selectedCountry !== '( +1 ) - United States - US' && selectedCountry !== '( +1 ) - Canada - CA') {
        getNonUsNumberRequirementMutation({ countryCodeNumber: selectedCountry.split(' - ')[2] });
        setIsNonUSDetailsModalOpen(true);
        setIsBookNumberModalVisible(false);
        return;
      }
    }

    var countryCode = selectedCountry.split(' - ')[2]; // Something like "US"

    let payload = {
      username: selectedClient?.number,
      countryCode,
      localChoosen: null as string | null | AvailableNumber, // Update the type to allow both string, null, and AvailableNumber
      bundleId: null as string | null,
      addressId: null as string | null,
    };
    if (localChoosen !== null) {
      payload.localChoosen = localChoosen;
    }
    if (resellerData?.twilioBundleId && resellerData?.twilioAddressSids) {
      payload = {
        ...payload,
        bundleId: resellerData.twilioBundleId,
        addressId: resellerData.twilioAddressSids[0],
      };
    }

    provisionNumberRequestMutation(payload);
    setIsBookNumberModalVisible(false);
    closeStandAloneNumberModal();
  };
  // //////////////////////////// --------------------////////////////////////

  const handleClientSelect = (value: string) => {
    const selected = clients.find(client => client.number === value);
    setSelectedClient(selected);
  };

  const handleRefreshClients = () => {
    if (resellerData.email_address) {
      getClientsMutation.mutate(resellerData.email_address);
    }
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Email',
      dataIndex: 'email_address',
      key: 'email_address',
    },
    {
      title: 'Reserved Number',
      dataIndex: 'reservedNumber',
      key: 'reservedNumber',
    },
  ];

  React.useEffect(() => {
    if (resellerData.email_address) {
      getClientsMutation.mutate(resellerData.email_address);
    }
  }, [resellerData.email_address]);

  const { data } = useQuery({
    queryKey: ['getTwilioBundleStatus', resellerData?.twilioBundleId],
    queryFn: () => getTwilioBundleStatus(resellerData?.twilioBundleId as string, true),
    enabled: !!resellerData?.twilioBundleId,
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    retry: 3,
  });

  React.useEffect(() => {
    if (data) dispatch(updateReseller({ twilioBundleStatus: data.status }));
  }, [data]);

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.PHONE_NUMBERS}>
      {celeberate && (
        <ConfettiExplosion zIndex={1000} force={1} height='220vh' width={3000} particleCount={200} duration={5000} />
      )}
      <div className='h-full p-6'>
        <Title level={2}>Phone Numbers Management</Title>
        <Text>
          Manage your assigned phone numbers below. Imported phone numbers from your twilio will be available here
        </Text>

        <div className='max-w-5xl'>
          <Card title='Assign Phone Number' className='my-3'>
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <Text strong className='block mb-2'>
                  Sub-Account
                </Text>
                <div className='flex gap-2'>
                  {getClientsMutation.isPending || isRequirementDispatchPending ? (
                    <div className='flex justify-center flex-1'>
                      <Spin />
                    </div>
                  ) : (
                    <>
                      <Select
                        showSearch
                        className='flex-1'
                        placeholder='Select a sub-account'
                        optionFilterProp='children'
                        onChange={handleClientSelect}
                        value={selectedClient?.number}
                        loading={getClientsMutation.isPending}
                        filterOption={(input, option) =>
                          (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {clients.map(client => (
                          <Option key={client.number} value={client.number}>
                            {client.number}
                          </Option>
                        ))}
                      </Select>
                      <Button
                        icon={<ReloadOutlined />}
                        onClick={handleRefreshClients}
                        loading={getClientsMutation.isPending}
                        size='middle'
                      />
                    </>
                  )}
                </div>
                {selectedClient && (
                  <Descriptions column={1} size='small' className='mt-4'>
                    <Descriptions.Item label='Email'>{selectedClient.email_address}</Descriptions.Item>
                    {selectedClient.reservedNumber && (
                      <Descriptions.Item label='Current Number'>{selectedClient.reservedNumber}</Descriptions.Item>
                    )}
                  </Descriptions>
                )}
              </div>
              <div>
                <Text strong className='block mb-2'>
                  Action
                </Text>
                {selectedClient &&
                  (selectedClient.reservedNumber ? (
                    <Text type='success'>Number already assigned</Text>
                  ) : (
                    <>
                      {resellerData?.twilioBundleId && resellerData?.twilioBundleStatus == 'twilio-approved' ? (
                        <>
                          <Paragraph type='success'>
                            <CheckCircleOutlined /> Regulatory process successfully completed. You can now get your
                            standalone number.
                          </Paragraph>
                          <Button type='primary' onClick={openStandAloneNumberModal} icon={<PhoneOutlined />}>
                            Assign standalone number
                          </Button>
                        </>
                      ) : (resellerData?.twilioBundleId && resellerData?.twilioBundleStatus == 'in-review') ||
                        resellerData?.twilioBundleStatus == 'pending-review' ? (
                        <Paragraph>
                          <LoadingOutlined /> Your information is under review. It can take up to 2 to 3 business days
                          to get it approved.
                        </Paragraph>
                      ) : resellerData?.twilioBundleId && resellerData?.twilioBundleStatus == 'twilio-rejected' ? (
                        <>
                          <Paragraph>Your regulatory process failed due to the following reasons:</Paragraph>
                          <Paragraph type='danger'>{resellerData?.twilioBundleFailureReason}</Paragraph>
                          <Button onClick={() => resetBundleMutation(resellerData?.email_address)}>
                            Restart regulatory process
                          </Button>
                        </>
                      ) : (
                        <div className='flex gap-2'>
                          <>
                            <Button
                              type='primary'
                              onClick={openStandAloneNumberModal}
                              icon={<PhoneOutlined />}
                              loading={isProvisionApiPending}
                            >
                              Assign standalone number
                            </Button>
                          </>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </Card>

          <Card title='Sub Accounts' className='mt-6'>
            <Table
              dataSource={clients}
              columns={columns}
              rowKey='number'
              loading={getClientsMutation.isPending}
              pagination={{
                total: clients.length,
                pageSize: 10,
                showTotal: total => `Total ${total} items`,
              }}
            />
          </Card>
        </div>
      </div>
      <CustomModal
        title={null}
        isModalOpen={isNonUSDetailsModalOpen}
        confirmAction={() => setIsNonUSDetailsModalOpen(false)}
        cancelAction={() => setIsNonUSDetailsModalOpen(false)}
        width={700}
        footer={null}
        loading={isRequirementDispatchPending}
      >
        <GetBundleApproveForm
          countryCodeNumber={selectedCountry.split(' - ')[2]}
          twilioDocsRequirements={twilioDocsRequirements.requirements}
          regulationSid={twilioDocsRequirements.regulationSid}
          setClientDetails={(bundleId: string) => {
            getBundleIdMutation.mutate(bundleId);
          }}
          isReseller='true'
          resellerEmail={resellerData.email_address}
          onClose={() => setIsNonUSDetailsModalOpen(false)}
        />
      </CustomModal>
      <CustomModal
        title='Get your standalone number'
        isModalOpen={isStandAloneNumberModalVisible}
        confirmAction={handleConfirmAction}
        cancelAction={closeStandAloneNumberModal}
        okButtonProps={{ loading: isProvisionApiPending }}
        okText='Get Random Number'
      >
        <GetStandAloneNumberForm
          ref={formRef}
          debrand={true}
          setCountry={(country: string) => setSelectedCountry(country)}
          onSubmit={handleFormSubmit}
          setChooseNumberModalOpen={setChooseNumberModalOpen}
        />
      </CustomModal>
      {/* Favourite Number  Modal */}
      <CustomModal
        title='Get Your Favourite Area Code'
        isModalOpen={ChooseNumberModalOpen}
        confirmAction={() => {
          setChooseNumberModalOpen(false);
          setIsBookNumberModalVisible(false);
        }}
        cancelAction={() => setChooseNumberModalOpen(false)}
        footer={null}
      >
        <GetChooseNumberForm
          isOpen={ChooseNumberModalOpen}
          onClose={() => setChooseNumberModalOpen(false)}
          selectedCountryCode={selectedCountry.split(' - ')[2]}
          bookNumberRequest={props => bookNumberRequest(props ?? null)}
        />
      </CustomModal>
      <CustomModal
        title='Save your changes'
        isModalOpen={isBookNumberModalVisible}
        confirmAction={() => bookNumberRequest(null)}
        cancelAction={closeBookNumberModal}
      >
        <Title level={4}>Click Ok to confirm you want to buy number for {selectedCountry.split('-')[1]} </Title>
      </CustomModal>
    </ResellerDashboard>
  );
};

export default ResellerPhoneNumbers;
