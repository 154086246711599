import { useMutation } from '@tanstack/react-query';
import './UpgradeSubBanner.scss';
import { createClientPortalSession } from '@/api/user';
import { Button, notification } from 'antd';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';

export const UpgradeSubBanner = () => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const { mutate: updateSubscription, isPending } = useMutation({
    mutationKey: ['update-subscription'],
    mutationFn: createClientPortalSession,
    onSuccess: data => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error upgrading subscription' });
        return;
      } else {
        window.location.href = data.redirectPaymentURL as string;
      }
    },
    onError: () => {
      notification.error({ message: 'Error upgrading subscription' });
    },
  });

  const handleUpgradeToPRO = () => {
    updateSubscription({ username: loggedInUser?.number as string, action: 'subscription_update' });
  };
  // subscriptionType
  let subscriptionType = loggedInUser?.subscriptionType;
  let planDuraion = loggedInUser?.planDuration;

  return (
    <div className='banner text-sm flex flex-row items-center space-x-5'>
      <div>
        {planDuraion?.toLocaleLowerCase() === 'month' ? (
          <div>
            Switch to an annual plan to receive 3 free months <strong>(30+% off!)</strong>
          </div>
        ) : subscriptionType?.toLocaleLowerCase() === 'basic' && planDuraion?.toLocaleLowerCase() === 'year' ? (
          <div>Switch to annual pro to get more features</div>
        ) : (
          <div>Congrats you are on our premium plan!</div>
        )}
      </div>
      <Button loading={isPending} onClick={handleUpgradeToPRO} className='btn ml-2' type='primary'>
        Upgrade Now
      </Button>
    </div>
  );
};
