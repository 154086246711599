import boy7 from '../assets/images/man.png';
import midwestern from '../assets/images/midwestern.png';
import helpful from '../assets/images/helpful.png';
import reading from '../assets/images/reading.png';
import aus from '../assets/images/aus.png';
import commerce from '../assets/images/ken.png';
import british from '../assets/images/british.png';
import kentucky from '../assets/images/commerce.png';
import asmr from '../assets/images/asmr.png';

import ASMRLady from '../assets/sounds/ASMR-Lady.wav';
import AustralianWoman from '../assets/sounds/Australian-Woman.wav';
import BritishNarrationLady from '../assets/sounds/British-Narration-Lady.wav';
import CommercialLady from '../assets/sounds/Commercial-Lady.wav';
import CommercialMan from '../assets/sounds/Commercial-Man.wav';
import HelpfulWomen from '../assets/sounds/Helpful-Women.wav';
import KentuckyWoman from '../assets/sounds/Kentucky-Woman.wav';
import MidwesternWoman from '../assets/sounds/Midwestern-woman-en.wav';
import ReadingLady from '../assets/sounds/Reading-Lady.wav';

const AIModels = [
  {
    name: 'ASMR Lady',
    accent: 'English US',
    gender: 'Female',
    value: '03496517-369a-4db1-8236-3d3ae459ddf7',
    listen: 'ASMR-Lady.wav',
    provider: 'cartesia',
    icon: asmr,
    description: 'This voice is calming and soft, perfect for guided meditations and soothing content',
    background: '#FF4500',
  },
  {
    name: 'Australian Woman',
    accent: 'English Australian',
    gender: 'Female',
    value: '043cfc81-d69f-4bee-ae1e-7862cb358650',
    listen: 'Australian-Woman.wav',
    provider: 'cartesia',
    icon: aus,
    description:
      'This voice is deliberate and confident, with a slight Australian accent, perfect for inspiring characters in videos and storie',
    background: '#007BFF',
  },
  {
    name: 'British Narration Lady',
    accent: 'English UK',
    gender: 'Female',
    value: '4d2fd738-3b3d-4368-957a-bb4805275bd9',
    listen: 'British-Narration-Lady.wav',
    provider: 'cartesia',
    icon: british,
    description: 'This is a neutral voice with a British accent, perfect for narrations',
    background: '#FF0090',
  },
  {
    name: 'Commercial Lady',
    accent: 'English UK',
    gender: 'Female',
    value: 'c2ac25f9-ecc4-4f56-9095-651354df60c0',
    listen: 'Commercial-Lady.wav',
    provider: 'cartesia',
    icon: commerce,
    description: 'This voice is inviting, enthusiastic, and relatable, perfect for a commercial or advertisement',
    background: '#32CD32',
  },
  {
    name: 'Commercial Man',
    accent: 'English UK',
    gender: 'Male',
    value: '7360f116-6306-4e9a-b487-1235f35a0f21',
    listen: 'Commercial-Man.wav',
    provider: 'cartesia',
    icon: boy7,
    description: 'This voice is upbeat and enthusiastic, perfect for commercials and advertisements',
    background: '#FFC300',
  },
  {
    name: 'Kentucky Woman',
    accent: 'English US',
    gender: 'Female',
    value: '4f8651b0-bbbd-46ac-8b37-5168c5923303',
    listen: 'Kentucky-Woman.wav',
    provider: 'cartesia',
    icon: kentucky,
    description: 'This voice is energetic and upbeat, with a slight Kentucky accent, perfect for speeches and rallies',
    background: '#800080',
  },
  {
    name: 'Helpful Women',
    accent: 'English UK',
    gender: 'Female',
    value: '156fb8d2-335b-4950-9cb3-a2d33befec77',
    listen: 'Helpful-Women.wav',
    provider: 'cartesia',
    icon: helpful,
    description:
      'This voice is friendly and conversational, designed for customer support agents and casual conversations',
    background: '#FF69B4',
  },
  {
    name: 'Reading Lady',
    accent: 'English US',
    gender: 'Female',
    value: '15a9cd88-84b0-4a8b-95f2-5d583b54c72e',
    listen: 'Reading-Lady.wav',
    provider: 'cartesia',
    icon: reading,
    description: 'This voice is monotone and deliberate, perfect for a slower-paced and more serious reading voice',
    background: '#00CED1',
  },
  {
    name: 'Midwestern Woman',
    accent: 'English US',
    gender: 'Female',
    value: '11af83e2-23eb-452f-956e-7fee218ccb5c',
    listen: 'Midwestern-woman-en.wav',
    provider: 'cartesia',
    icon: midwestern,
    background: '#FF6F61',
  },
];

export const soundMapping = {
  '03496517-369a-4db1-8236-3d3ae459ddf7': ASMRLady,
  '043cfc81-d69f-4bee-ae1e-7862cb358650': AustralianWoman,
  '4d2fd738-3b3d-4368-957a-bb4805275bd9': BritishNarrationLady,
  'c2ac25f9-ecc4-4f56-9095-651354df60c0': CommercialLady,
  '7360f116-6306-4e9a-b487-1235f35a0f21': CommercialMan,
  '4f8651b0-bbbd-46ac-8b37-5168c5923303': KentuckyWoman,
  '156fb8d2-335b-4950-9cb3-a2d33befec77': HelpfulWomen,
  '15a9cd88-84b0-4a8b-95f2-5d583b54c72e': ReadingLady,
  '11af83e2-23eb-452f-956e-7fee218ccb5c': MidwesternWoman,
};

export default AIModels;
