import { Form, Input, notification, Spin, Switch } from 'antd';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingOutlined } from '@ant-design/icons';
import { OverViewCardForm } from '../../OverviewCardForm';
import { useState } from 'react';
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query';
import { updateUser } from '@/api/user';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { User } from '@/types/TAccounts';

const MaxUsageLimitForm: React.FC<{
  usage_notification_emails: any[];
  max_minutes: number;
  minutesLimitEnabled: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}> = ({ usage_notification_emails, max_minutes, minutesLimitEnabled, refetch }) => {
  const [form] = Form.useForm();
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<string>('');
  const dispatch = useAppDispatch();

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
    },
  });

  const handleSubmit = async (values: {
    usage_notification_emails: string[];
    max_minutes: number;
    minutesLimitEnabled: boolean;
  }) => {
    const validEmails = values.usage_notification_emails.filter(email => email && email.trim() !== '');
    if (validEmails.length === 0) {
      setEmailError('At least 1 email is required!');
      return;
    }

    const updatedValues = { ...values, usage_notification_emails: validEmails };

    await mutation.mutateAsync(updatedValues);
    dispatch(closeModal());
  };
  const FormItems = () => (
    <>
      <div className='flex items-center justify-between'>
        <span>Max Usage Limit Status</span>
        <Form.Item className='!mb-0' name='minutesLimitEnabled'>
          <Switch size='small' />
        </Form.Item>
      </div>
      <div className='flex items-center bg-slate-200 p-2 rounded mb-2 mt-2'>
        <InfoOutlinedIcon />
        You can provide upto 3 email addresses to receive notifications.
      </div>
      <Form.Item className='h-48 !mb-1' label='Email Addresses'>
        {['0', '1', '2'].map(index => (
          <Form.Item
            key={index}
            name={['usage_notification_emails', parseInt(index)]}
            rules={[{ type: 'email', message: 'Please enter a valid email address' }]}
          >
            <Input placeholder='Enter email' />
          </Form.Item>
        ))}
      </Form.Item>
      {emailError && emailError}

      <div className='flex items-center bg-slate-200 p-2 rounded mb-2'>
        <InfoOutlinedIcon />
        This is in minutes.
      </div>
      <Form.Item
        label='Maximum Usgae Limit'
        name='max_minutes'
        rules={[{ required: true, message: 'Please enter a limit' }]}
      >
        <Input placeholder='Enter limit' />
      </Form.Item>
    </>
  );

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={{ usage_notification_emails, max_minutes, minutesLimitEnabled }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </>
  );
};

export default MaxUsageLimitForm;
