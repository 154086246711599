import { Form, Input } from 'antd';

export const ManagePasswordForm = () => {
  return (
    <div>
      <Form.Item
        label='Old Password'
        name='oldPassword'
        rules={[{ required: true, message: 'Please input your old password!' }]}
      >
        <Input.Password placeholder='Please enter your old password' />
      </Form.Item>
      <Form.Item
        label='New Password'
        name='newPassword'
        rules={[{ required: true, message: 'Please input your new password!' }]}
      >
        <Input.Password placeholder='Please enter your new password' />
      </Form.Item>
      <Form.Item
        label='Confirm Password'
        name='confirmPassword'
        dependencies={['newPassword']}
        rules={[
          { required: true, message: 'Please confirm your password!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Password you entered is not matching with New Password!'));
            },
          }),
        ]}
      >
        <Input.Password placeholder='Please confirm your password' />
      </Form.Item>
    </div>
  );
};
