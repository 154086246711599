import { generateOAuthIntegrationTokens } from '@/api/user';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const OAuthCallBackPage = () => {
  const { provider } = useParams();
  const code = new URLSearchParams(window.location.search).get('code');

  if (!code) {
    return <>invalid parameters</>;
  }

  useEffect(() => {
    if (code && provider) {
      (async () => {
        const tokens = await generateOAuthIntegrationTokens(code, provider);
        if (tokens.accessToken) {
          alert('Connected successfully, you can find My AI Front Desk under external integrations.');
          (window as Window).location = '/';
        }
      })();
    }
  }, [code, provider]);
  return <div></div>;
};

export default OAuthCallBackPage;
