import { useEffect, useMemo, useState } from 'react';
import { Spin, Tabs } from 'antd';
import {
  CalendarMonthOutlined,
  OfflineBoltOutlined,
  PermPhoneMsgOutlined,
  QuestionAnswerOutlined,
  WbIncandescentOutlined,
} from '@mui/icons-material';
import NotificationCard from './components';
import ScenarioBasedNotification from './ScenarioBased';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUser, updateUser } from '@/api/user';
import CrmBasedNotification from './CRMNotifications';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { NotifKey } from 'src/enums/ENotifKey';
import { InfoType, InfoTypeCall, InfoTypeCrm, InfoTypeScenario } from '@/interfaces/INotifications';
import { fetchGroups } from '@/api/fetchUserData';
import { TUTORIALS } from 'src/enums/ETutorials';

export const ManageNotifications = () => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const currentUser = useAppSelector(getLoggedInUser);
  const username = loggedInUser?.number || '';

  const initialCallNotif = {
    email: false,
    sms: false,
    voicemail: false,
    emails: [],
    phones: [],
    showTranscript: true,
    showCallSummary: true,
    showIntakeForms: true,
    showWorkflow: true,
    showCallSummaryText: true,
    showCallInfoLinkText: true,
  };

  const initialNotif = {
    email: false,
    sms: false,
    voicemail: false,
    emails: [],
    phones: [],
  };

  const [callNotif, setCallNotif] = useState<InfoTypeCall>(initialCallNotif);
  const [textNotif, setTextNotif] = useState<InfoType>(initialNotif);
  const [calendarNotif, setCalendarNotif] = useState<InfoType>(initialNotif);
  const [scenarioNotif, setScenarioNotif] = useState<InfoTypeScenario[]>([]);
  const [voicemailNotif, setVoicemailNotif] = useState<InfoType>(initialNotif);
  const [robocallNotif, setRobocallNotif] = useState<InfoType>(initialNotif);
  const [crmNotif, setCrmNotif] = useState<InfoTypeCrm[]>([]);

  const { data, isPending, refetch } = useQuery({
    queryKey: ['receptionist'],
    queryFn: getUser,
  });

  const { data: groups, isPending: isPendingCrm } = useQuery({
    queryKey: ['groups', username],
    queryFn: () => fetchGroups(username),
  });

  const { mutate: updateUserFn, isPending: isPendingUpdate } = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
    },
  });

  const availableGroups = useMemo(() => {
    let data: {
      group_id: string;
      group_name: string;
    }[] = [];
    if (groups?.groups && groups.groups.length > 0) {
      data = groups.groups.map(item => {
        return {
          group_id: item.group_id,
          group_name: item.group_name,
        };
      });
    }
    return data;
  }, [groups]);

  useEffect(() => {
    refetch();
  }, [currentUser.number]);

  useEffect(() => {
    if (data) {
      getNotificationSettings();
    }
  }, [data, availableGroups]);

  const getNotificationSettings = () => {
    if (availableGroups.length > 0) {
      const settingsParsed = data?.crm_notif_settings ? JSON.parse(data.crm_notif_settings) : [];

      const settings = availableGroups.map(group => {
        return (
          settingsParsed.find((setting: any) => setting.group_id === group.group_id) || {
            group_id: group.group_id,
            group_name: group.group_name,
            email: false,
            sms: false,
            voicemail: false,
            emails: [],
            phones: [],
          }
        );
      });

      setCrmNotif(settings);
    }

    // call notif
    if (data?.call_notif_settings) {
      const settingsParsed = JSON.parse(data.call_notif_settings);
      setCallNotif({
        email: settingsParsed.call_enabled || settingsParsed.email,
        sms: settingsParsed.text_enabled || settingsParsed.sms,
        voicemail: settingsParsed.voicemail_enabled || settingsParsed.voicemail,
        emails: settingsParsed.email_recipients || settingsParsed.emails || [],
        phones: settingsParsed.text_recipients || settingsParsed.phones || [],
        showTranscript: settingsParsed.showTranscript,
        showCallSummary: settingsParsed.showCallSummary,
        showIntakeForms: settingsParsed.showIntakeForms,
        showWorkflow: settingsParsed.showWorkflow,
        showCallSummaryText: settingsParsed.showCallSummaryText,
        showCallInfoLinkText: settingsParsed.showCallInfoLinkText,
      });
    } else {
      setCallNotif(initialCallNotif);
    }

    // Texting plateform notif
    if (data?.texting_platform_notif_settings) {
      const settingsParsed = JSON.parse(data.texting_platform_notif_settings);
      setTextNotif({
        email: settingsParsed.enabled || settingsParsed.email,
        sms: settingsParsed.text_enabled || settingsParsed.sms,
        voicemail: settingsParsed.voicemail_enabled || settingsParsed.voicemail,
        emails: settingsParsed.email_recipients || settingsParsed.emails || [],
        phones: settingsParsed.text_recipients || settingsParsed.phones || [],
      });
    } else {
      setTextNotif(initialNotif);
    }

    // scenario workflow notif
    if (data?.workflow_notif_settings) {
      let settingsParsed = JSON.parse(data.workflow_notif_settings);
      const settingsParsedM: InfoTypeScenario[] = settingsParsed?.map((el: any) => {
        let email = false;
        if (el.email === undefined) {
          email = true;
        } else {
          email = el.email;
        }

        let sms = false;
        if (el.sms === undefined) {
          sms = true;
        } else {
          sms = el.sms;
        }

        let voicemail = false;
        if (el.voicemail === undefined) {
          voicemail = true;
        } else {
          voicemail = el.voicemail;
        }

        return {
          ...el,
          email,
          sms,
          voicemail,
          emails: el.email_recipients || el.emails || [],
          phones: el.text_recipients || el.phones || [],
        };
      });
      setScenarioNotif(settingsParsedM);
    } else {
      setScenarioNotif([]);
    }

    // voicemail notif
    if (data?.voicemail_notif_settings) {
      let settingsParsed = JSON.parse(data.voicemail_notif_settings);
      setVoicemailNotif(settingsParsed);
    } else {
      setVoicemailNotif(initialNotif);
    }

    // Calendar notif
    if (data?.calendar_notif_settings) {
      let settingsParsed = JSON.parse(data.calendar_notif_settings);
      setCalendarNotif(settingsParsed);
    } else {
      setCalendarNotif(initialNotif);
    }

    // Robocall notif
    if (data?.robocall_notif_settings) {
      let settingsParsed = JSON.parse(data.robocall_notif_settings);
      setRobocallNotif(settingsParsed);
    } else {
      setRobocallNotif(initialNotif);
    }
  };

  const CallInteractionTab = () => {
    return (
      <div className='flex flex-col gap-8 mb-16'>
        <NotificationCard
          heading='Call Interaction Settings'
          label=''
          description='Select where we should notify you'
          cardDescription='Automatically send detailed call transcripts via text or email after your receptionist answers a new call.'
          email={callNotif.email}
          sms={callNotif.sms}
          voicemail={callNotif.voicemail}
          emails={callNotif.emails}
          phones={callNotif.phones}
          notifKey={NotifKey.DirectCall}
          saveEmails={(val: string[]) => {
            callNotif.emails = val;
            updateUserFn({
              call_notif_settings: JSON.stringify(callNotif),
            });
          }}
          savePhones={(val: string[]) => {
            callNotif.phones = val;
            updateUserFn({
              call_notif_settings: JSON.stringify(callNotif),
            });
          }}
          saveCheck={method => {
            const props = { ...callNotif, [method]: !callNotif[method] };
            updateUserFn({
              call_notif_settings: JSON.stringify(props),
            });
          }}
          show={{
            transcript: callNotif.showTranscript,
            callSummary: callNotif.showCallSummary,
            intakeForms: callNotif.showIntakeForms,
            workflow: callNotif.showWorkflow,
            callSummaryText: callNotif.showCallSummaryText,
            callInformationLink: callNotif.showCallInfoLinkText,
          }}
          onShowChange={val => {
            const props = {
              ...callNotif,
              showTranscript: val.transcript,
              showCallSummary: val.callSummary,
              showIntakeForms: val.intakeForms,
              showWorkflow: val.workflow,
              showCallSummaryText: val.callSummaryText,
              showCallInfoLinkText: val.callInformationLink,
            };
            updateUserFn({
              call_notif_settings: JSON.stringify(props),
            });
          }}
          tutorialId={TUTORIALS.NOTIFICATION_SYSTEM}
        />
        <NotificationCard
          heading='Voicemail Settings'
          label=''
          description='Select where we should notify you'
          cardDescription='Receive email and phone notifications when your receptionist have a voicemail.'
          email={voicemailNotif.email}
          sms={voicemailNotif.sms}
          voicemail={voicemailNotif.voicemail}
          emails={voicemailNotif.emails}
          phones={voicemailNotif.phones}
          notifKey={NotifKey.Voicemail}
          saveEmails={(val: string[]) => {
            voicemailNotif.emails = val;
            updateUserFn({
              voicemail_notif_settings: JSON.stringify(voicemailNotif),
            });
          }}
          savePhones={(val: string[]) => {
            voicemailNotif.phones = val;
            updateUserFn({
              voicemail_notif_settings: JSON.stringify(voicemailNotif),
            });
          }}
          saveCheck={method => {
            const props = { ...voicemailNotif, [method]: !voicemailNotif[method] };
            updateUserFn({
              voicemail_notif_settings: JSON.stringify(props),
            });
          }}
          show={null}
          tutorialId={TUTORIALS.NOTIFICATION_SYSTEM}
        />
        <NotificationCard
          heading='Robocall Settings'
          label=''
          description='Select where we should notify you'
          cardDescription='Receive email and phone notifications when a robocall is detected and listed under the robocall blocklist.'
          email={robocallNotif.email}
          sms={robocallNotif.sms}
          voicemail={robocallNotif.voicemail}
          emails={robocallNotif.emails}
          phones={robocallNotif.phones}
          notifKey={NotifKey.Robocall}
          saveEmails={(val: string[]) => {
            robocallNotif.emails = val;
            updateUserFn({
              robocall_notif_settings: JSON.stringify(robocallNotif),
            });
          }}
          savePhones={(val: string[]) => {
            robocallNotif.phones = val;
            updateUserFn({
              robocall_notif_settings: JSON.stringify(robocallNotif),
            });
          }}
          saveCheck={method => {
            const props = { ...robocallNotif, [method]: !robocallNotif[method] };
            updateUserFn({
              robocall_notif_settings: JSON.stringify(props),
            });
          }}
          show={null}
          tutorialId={TUTORIALS.NOTIFICATION_SYSTEM}
        />
      </div>
    );
  };

  const DirectSMSInteractionTab = () => {
    return (
      <div>
        <NotificationCard
          heading='Direct SMS Interaction Settings (Texting Plateform)'
          label=''
          description='Select where we should notify you'
          cardDescription='Receive email and phone notifications when someone texts your AI receptionist number'
          email={textNotif.email}
          sms={textNotif.sms}
          voicemail={textNotif.voicemail}
          emails={textNotif.emails}
          phones={textNotif.phones}
          notifKey={NotifKey.DirectSMS}
          saveEmails={(val: string[]) => {
            textNotif.emails = val;
            updateUserFn({
              texting_platform_notif_settings: JSON.stringify(textNotif),
            });
          }}
          savePhones={(val: string[]) => {
            textNotif.phones = val;
            updateUserFn({
              texting_platform_notif_settings: JSON.stringify(textNotif),
            });
          }}
          saveCheck={method => {
            const props = { ...textNotif, [method]: !textNotif[method] };
            updateUserFn({
              texting_platform_notif_settings: JSON.stringify(props),
            });
          }}
          show={null}
          tutorialId={TUTORIALS.NOTIFICATION_SYSTEM}
        />
      </div>
    );
  };

  const CalendarInteractionTab = () => {
    return (
      <div>
        <NotificationCard
          heading='Scheduled Calendar Appointment Notification'
          label=''
          description='Select where we should notify you'
          cardDescription='Receive email and phone notifications when caller schedules a Calendar Meet'
          email={calendarNotif.email}
          sms={calendarNotif.sms}
          voicemail={calendarNotif.voicemail}
          emails={calendarNotif.emails}
          phones={calendarNotif.phones}
          notifKey={NotifKey.Calendar}
          saveEmails={(val: string[]) => {
            calendarNotif.emails = val;
            updateUserFn({
              calendar_notif_settings: JSON.stringify(calendarNotif),
            });
          }}
          savePhones={(val: string[]) => {
            calendarNotif.phones = val;
            updateUserFn({
              calendar_notif_settings: JSON.stringify(calendarNotif),
            });
          }}
          saveCheck={method => {
            const props = { ...calendarNotif, [method]: !calendarNotif[method] };
            updateUserFn({
              calendar_notif_settings: JSON.stringify(props),
            });
          }}
          show={null}
          tutorialId={TUTORIALS.NOTIFICATION_SYSTEM}
        />
      </div>
    );
  };

  return (
    <Spin spinning={isPending || isPendingUpdate}>
      <Tabs
        defaultActiveKey='Call'
        items={[
          {
            key: 'Call',
            label: `Call interaction`,
            children: <CallInteractionTab />,
            icon: <PermPhoneMsgOutlined />,
          },
          {
            key: 'Scenario Based',
            label: `Scenario Based Notification`,
            children: (
              <div>
                <ScenarioBasedNotification
                  scenarioNotif={scenarioNotif}
                  updateUser={(val: string) => {
                    updateUserFn({
                      workflow_notif_settings: val,
                    });
                  }}
                />
              </div>
            ),
            icon: <WbIncandescentOutlined />,
          },
          {
            key: 'Direct SMS',
            label: `Direct SMS interaction`,
            children: <DirectSMSInteractionTab />,
            icon: <QuestionAnswerOutlined />,
          },
          {
            key: 'Intake CRM',
            label: `CRM Notification`,
            children: (
              <Spin spinning={isPendingCrm}>
                <CrmBasedNotification
                  crmNotif={crmNotif}
                  updateUser={(val: string) => {
                    updateUserFn({
                      crm_notif_settings: val,
                    });
                  }}
                />
              </Spin>
            ),
            icon: <OfflineBoltOutlined />,
          },
          {
            key: 'Calendar Notifications',
            label: `Calendar Notifications`,
            children: <CalendarInteractionTab />,
            icon: <CalendarMonthOutlined />,
          },
        ]}
      />
    </Spin>
  );
};
