import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  Tooltip,
  Alert,
} from 'antd';
import PlayForWorkOutlinedIcon from '@mui/icons-material/PlayForWorkOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useCallback, useEffect, useState } from 'react';
import './CallLogs.scss';
import { CustomModal } from '@/components/CustomModal';
import { CallDetails } from './CallDetails';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deleteCallLog,
  downloadCallLogs,
  getCallLogs,
  getCallRecording,
  updateUserContact,
  updateUserTimeZone,
  getCallLogByCallSid,
  updateCallLog,
} from '@/api/logs';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { useDispatch } from 'react-redux';
import { setUserContacts, setUserTimeZone } from '@/store/account/slice';
import {
  DeleteOutlined,
  FacebookOutlined,
  LinkOutlined,
  MailOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  RollbackOutlined,
  ShareAltOutlined,
  UserAddOutlined,
  WhatsAppOutlined,
  XOutlined,
} from '@ant-design/icons';
import { CallLog } from '@/interfaces/ILogs';
import { renderPSTDate } from '@/utils/helper';
import { CallIntakeResponse } from './CallIntakeResponse';
import { IntakeForm } from '@/types/TCallLogs';
import { getProvisionedNumber } from '@/api/reservedNumbers';
import { useLocalTimezone } from 'src/common/hooks/useLocalTimezone';
import EllipsisText from '@/components/LabelWithdescription/EllipsisText';
import LearnMore from '@/components/Buttons/LearnMore';
import { TUTORIALS } from 'src/enums/ETutorials';
import AlgoliaSearch from '@/components/AlgoliaSearch/AlgoliaSearch';
import CopyableText from '@/components/LabelWithdescription/CopyableText';
import { smallScreen } from 'src/enums/EScreenSize';
import '../../../../components/CustomModal/CustomModal.scss';
import { CheckOutlined } from '@mui/icons-material';
import { TableRowSelection } from 'antd/es/table/interface';

const timeZoneList = momentTimeZone.tz.names();

const CallLogs: React.FC<{ debrand: boolean; isNoviUser: boolean; campaignId?: string }> = ({
  debrand,
  isNoviUser,
}) => {
  const dispatch = useDispatch();
  const loggedInUser = useAppSelector(getLoggedInUser);
  const { RangePicker } = DatePicker;
  const [callLogs, setCallLogs] = useState<CallLog[]>([]);
  const [showFiltered, setShowFiltered] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [callLog, setCallLog] = useState<CallLog | null>(null);
  const [startDate, setStartDate] = useState<number | null>(null);
  const [endDate, setEndDate] = useState<number | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [selectedLog, setSelectedLog] = useState<{ key: string; callSid: string; date: string } | null>(null);
  const [saveContactModal, setSaveContactModal] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<string | null>(null);
  const [selectedContactName, setSelectedContactName] = useState<string>('');
  const [contacts, setContacts] = useState<{ [key: string]: string } | null>(
    typeof loggedInUser.contacts === 'string' ? JSON.parse(loggedInUser.contacts) : loggedInUser.contacts
  );
  const { timeZone, setTimeZone } = useLocalTimezone(loggedInUser?.time_zone);
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [shareableLink, setShareableLink] = useState<string | null>(null);
  const [intakeResponses, setIntakeResponses] = useState<IntakeForm[]>([]);
  const [intakeModal, setIntakeModal] = useState<boolean>(false);
  const [loadingRecordings, setLoadingRecordings] = useState<{ [key: string]: boolean }>({});
  const [isSearchedLog, setIsSearchedLog] = useState(false);
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // Checkbox for row selection
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<CallLog> = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: record => ({
      disabled: record.isRead, // Disable selection for already read items
    }),
  };

  const hasSelected = selectedRowKeys.length > 0;

  const hideEmptyCalls = (e: any) => {
    setShowFiltered(e.target.checked);
  };

  const { data, refetch, isPending, isRefetching } = useQuery({
    queryKey: ['getCallLogs'],
    queryFn: () => {
      // checking if isSearchedLog is true, then make it false
      if (isSearchedLog) {
        setIsSearchedLog(false);
        setCallLogs([]);
      }

      if (startDate && endDate) {
        return getCallLogs({ limit: 50, startDate, endDate });
      } else {
        return getCallLogs({ limit: 50 });
      }
    },
  });

  const { data: provisionedNumbers, isLoading: isLoadingProvisionedNumber } = useQuery({
    queryKey: ['getProvisionedNumbers'],
    queryFn: () => getProvisionedNumber(),
  });

  const { mutate: callRecordingMutation } = useMutation({
    mutationKey: ['getCallRecording'],
    mutationFn: getCallRecording,
    onMutate: callSid => {
      setLoadingRecordings(prev => ({ ...prev, [callSid]: true }));
    },
    onSuccess(data) {
      const updatedCallLogs = callLogs.map((log: CallLog) => {
        if (log.callSid === data.callSid) {
          return { ...log, audio: data.url };
        }
        return log;
      });
      setCallLogs(updatedCallLogs);
      setLoadingRecordings(prev => ({ ...prev, [data.callSid]: false }));
    },
  });

  const { mutate: deleteCallLogMutation, status: deleteCallLogStatus } = useMutation({
    mutationKey: ['deleteCallLog'],
    mutationFn: deleteCallLog,
    onSuccess() {
      const updatedCallLogs = callLogs.filter((log: CallLog) => log.key !== selectedLog?.key);
      setConfirmDelete(false);
      setSelectedLog(null);
      setCallLogs(updatedCallLogs);
      notification.success({ message: 'Call log deleted successfully' });
    },
  });

  const { mutate: updateCallLogMutation } = useMutation({
    mutationKey: ['updateCallLog'],
    mutationFn: updateCallLog,
    onSuccess({ success, data }) {
      if (success && data) {
        const updatedLogs = callLogs.map((log: CallLog) => {
          if (log.callSid === data.callSid) {
            return data;
          } else {
            return log;
          }
        });
        setCallLog(data);
        setCallLogs(updatedLogs);
        notification.success({ message: 'Thanks for rating!' });
      }
    },
  });

  const { mutate: updateCallLogReadMutation } = useMutation({
    mutationKey: ['updateCallLog'],
    mutationFn: updateCallLog,
    onSuccess({ success, data }) {
      if (success && data) {
        const updatedLogs = callLogs.map((log: CallLog) => {
          if (log.callSid === data.callSid) {
            return data;
          } else {
            return log;
          }
        });
        setCallLog(data);
        setCallLogs(updatedLogs);
      }
    },
  });

  const updateUserContactMutation = useMutation({
    mutationKey: ['updateUserContact'],
    mutationFn: updateUserContact,
    onSuccess(data) {
      setContacts(data.contacts);
      dispatch(setUserContacts({ userContacts: data.contacts }));
      setSaveContactModal(false);
    },
  });

  const updateTimeZoneMutation = useMutation({
    mutationKey: ['updateTimeZone'],
    mutationFn: updateUserTimeZone,
    onSuccess(_, values) {
      dispatch(setUserTimeZone({ timeZone: values.timeZone }));
    },
  });

  const { mutate: downloadCallLogsMutation, status: downloadCallLogsStatus } = useMutation({
    mutationKey: ['downloadCallLogs'],
    mutationFn: downloadCallLogs,
  });

  const { mutate: getCallLogByCallSidMutation, isPending: callLogbySidPending } = useMutation({
    mutationKey: ['getCallLogByCallSid'],
    mutationFn: getCallLogByCallSid,
    onSuccess: data => {
      if (data.success && data.data) {
        setIsSearchedLog(true);
        setCallLogs([data.data]);
      }
    },
    onError: () => {
      notification.error({ message: 'Failed to fetch call log' });
    },
  });

  const handleLogSelect = useCallback(
    async (callSid: string) => {
      getCallLogByCallSidMutation(callSid);
    },
    [loggedInUser.number]
  );

  // Add a reset function to restore normal view
  const handleResetSearch = useCallback(() => {
    setIsSearchedLog(false);
    refetch(); // This will reload the original call logs
  }, [refetch]);

  const handleClick = (callLog: CallLog) => {
    setShowModal(true);
    setCallLog(callLog);
  };

  const handleConfirm = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirmDeleteModal = (key: string, callSid: string, date: string) => {
    setSelectedLog({ key, callSid, date });
    setConfirmDelete(true);
  };

  const handleCancelDeleteModal = () => {
    setSelectedLog(null);
    setConfirmDelete(false);
  };

  const handleTimeZoneChange = (value: string) => {
    setTimeZone(value);
    updateTimeZoneMutation.mutate({ timeZone: value });
  };

  const handleDateRangeChange = (_: any, dateStrings: [string, string]) => {
    const [start, end] = dateStrings;
    setStartDate(start ? moment(start).valueOf() : null);
    setEndDate(end ? moment(end).valueOf() : null);
  };

  const handleGetCallRecording = (callSid: string) => {
    callRecordingMutation(callSid);
  };

  const handleDeleteCallLog = () => {
    if (selectedLog !== null) {
      deleteCallLogMutation(selectedLog);
    }
  };

  const handleShareModel = (shareableLink: string) => {
    setShareModal(true);
    setShareableLink(shareableLink);
  };

  const handleSaveContact = (from: string) => {
    setSaveContactModal(true);
    setSelectedContact(from);
  };

  const handleCancelSaveContact = () => {
    setSelectedContact(null);
    setSelectedContactName('');
    setSaveContactModal(false);
  };

  const handleSaveUserContact = () => {
    if (selectedContact !== null && selectedContactName.length) {
      updateUserContactMutation.mutate({ contactNumber: selectedContact, contactName: selectedContactName });
    }
  };

  const handleShareLink = (platform: string) => {
    if (!shareableLink) return;

    let baseUrl = import.meta.env.VITE_APP_BASE_URL;

    if (debrand) {
      baseUrl = import.meta.env.VITE_APP_WIHTELABEL_URL;
    }

    const url = `${baseUrl}/call-information/${shareableLink}`;

    if (platform === 'Facebook') {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
    } else if (platform === 'Copy') {
      navigator.clipboard.writeText(url).then(() => {
        notification.success({ message: 'Link copied to clipboard' });
      });
    } else if (platform === 'Email') {
      window.open(
        `mailto:?subject=Check out this call recording&body=Hey, I thought you might be interested in this call recording: ${url}`,
        '_blank'
      );
    } else if (platform === 'X') {
      window.open(`https://twitter.com/intent/tweet?text=Check out this call recording: ${url}`, '_blank');
    } else if (platform === 'Whatsapp') {
      window.open(`https://api.whatsapp.com/send?text=Check out this call recording: ${url}`, '_blank');
    }

    setShareModal(false);
  };

  const handleCallLogsDownload = () => {
    if (startDate && endDate) {
      downloadCallLogsMutation({ startDate, endDate, isNoviUser });
    } else {
      downloadCallLogsMutation({ isNoviUser });
    }
  };

  const parseIntakeResponse = (intakeResponse: string) => {
    try {
      if (!intakeResponse) {
        return [];
      }
      const parsedResponse = JSON.parse(intakeResponse);
      if (Array.isArray(parsedResponse)) {
        const transformedResponse = parsedResponse
          .map(obj => {
            return Object.entries(obj)
              .map(([key, value]) => [
                { key: 'Question', value: key },
                { key: 'Answer', value: value },
              ])
              .flat();
          })
          .flat() as IntakeForm[];
        setIntakeResponses(transformedResponse);
        setIntakeModal(true);
      } else if (typeof parsedResponse === 'object' && parsedResponse !== null) {
        const transformedResponse = Object.entries(parsedResponse)
          .map(([key, value]) => [
            { key: 'Question', value: key },
            { key: 'Answer', value: value },
          ])
          .flat() as IntakeForm[];

        setIntakeResponses(transformedResponse);
        setIntakeModal(true);
      }
    } catch (error) {
      notification.error({ message: 'Error parsing intake response' });
      return [];
    }
  };

  const handleCancelIntakeModal = () => {
    setIntakeModal(false);
  };

  const handleMarkAsRead = () => {
    if (selectedRowKeys.length > 0) {
      selectedRowKeys.forEach(callSid => {
        const logToUpdate = callLogs.find(log => log.key === callSid);
        if (logToUpdate) {
          updateCallLogReadMutation({
            callSid: logToUpdate.callSid,
            date: logToUpdate.date || '', // Ensuring date comes from the correct log
            data: { isRead: true },
          });
        }
      });
      setSelectedRowKeys([]); // Clear selection after marking as read
    } else {
      notification.info({ message: 'Please select at least one row to mark as read.' });
    }
  };

  const handleRowInteraction = (record: CallLog) => {
    if (!record.isRead) {
      updateCallLogReadMutation({
        callSid: record.callSid,
        date: record.date || '',
        data: { isRead: true },
      });

      setCallLogs(prevLogs => prevLogs.map(log => (log.callSid === record.callSid ? { ...log, isRead: true } : log)));
    }
  };

  const ToORFROM = isNoviUser
    ? {
        title: 'To',
        dataIndex: 'to',
        key: 'to',
        render: (to: string) => {
          return (
            <Space direction='vertical'>
              <div>
                <CopyableText text={contacts?.[to] || to} />
                <Tooltip title='Save Contact'>
                  <Button type='text' icon={<UserAddOutlined />} onClick={() => handleSaveContact(to)} size='small' />
                </Tooltip>
              </div>
            </Space>
          );
        },
      }
    : {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
        render: (from: string) => (
          <div>
            <CopyableText text={contacts?.[from] || from} />
            <Tooltip title='Save Contact'>
              <Button type='text' icon={<UserAddOutlined />} onClick={() => handleSaveContact(from)} size='small' />
            </Tooltip>
          </div>
        ),
      };

  const columns: TableProps<CallLog>['columns'] = [
    {
      key: 'Date',
      title: 'Dates',
      render: (_, record) => <CopyableText text={renderPSTDate(record.date, timeZone)} />,
    },
    ToORFROM,
    {
      title: 'Recording',
      key: 'recording',
      render: (_, record) =>
        record.isRecorded === 'true' ? (
          record.audio ? (
            <audio controls className='h-[30px]'>
              <source src={record.audio} type='audio/mpeg' />
            </audio>
          ) : (
            <Button
              icon={<PlayCircleOutlined />}
              onClick={() => handleGetCallRecording(record.callSid)}
              loading={loadingRecordings[record.callSid]}
            >
              Listen
            </Button>
          )
        ) : (
          <Tag color='red'>No Recording</Tag>
        ),
    },
    {
      title: 'Call Summary',
      dataIndex: 'Call Summary',
      key: 'Call Summary',
      render: (_, { callSummary, audio }) => (
        <Space>
          {callSummary ? (
            <div className='flex items-center gap-1'>
              <EllipsisText text={callSummary} length={audio ? 35 : 48} />
              <CopyableText text={callSummary} hideText />
            </div>
          ) : (
            'No User Interaction'
          )}
        </Space>
      ),
    },
    {
      title: 'Call Transcript',
      dataIndex: 'Call Transcript',
      key: 'Call Transcript',
      render: (_, record) => <Button onClick={() => handleClick(record)}>Transcript</Button>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size='small'>
          <Tooltip title='Share'>
            <Button type='text' icon={<ShareAltOutlined />} onClick={() => handleShareModel(record.shareableLink)} />
          </Tooltip>
          <Dropdown
            menu={{
              items: [
                {
                  key: 'intake',
                  label: 'View Intake Response',
                  onClick: () => parseIntakeResponse(record.intakeResponses as string),
                  disabled: !(() => {
                    try {
                      const parsedIntakeResponses = JSON.parse(record.intakeResponses || '[]');

                      // Check if parsed data is a non-empty array or object
                      if (Array.isArray(parsedIntakeResponses)) {
                        return parsedIntakeResponses.length > 0 && Object.keys(parsedIntakeResponses[0]).length > 0;
                      } else if (typeof parsedIntakeResponses === 'object' && parsedIntakeResponses !== null) {
                        return Object.keys(parsedIntakeResponses).length > 0;
                      }
                      return false;
                    } catch (e) {
                      return false;
                    }
                  })(),
                },
              ],
            }}
          >
            <Button type='text' icon={<MoreOutlined />} />
          </Dropdown>
          <Tooltip title='Delete'>
            <Button
              type='text'
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleConfirmDeleteModal(record.key, record.callSid, record.date)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const shareOptions = [
    { key: 'Copy', icon: <LinkOutlined />, color: '#0000EE', label: 'Copy Link' },
    { key: 'Email', icon: <MailOutlined />, color: '#D44638', label: 'Email' },
    { key: 'Facebook', icon: <FacebookOutlined />, color: '#3b5998', label: 'Facebook' },
    { key: 'Twitter', icon: <XOutlined />, color: '#000000', label: 'X' },
    { key: 'WhatsApp', icon: <WhatsAppOutlined />, color: '#25D366', label: 'WhatsApp' },
  ];

  useEffect(() => {
    if (data) {
      setCallLogs(data?.callLogs);
    }
  }, [data?.callLogs]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  const formattedIntakeString = intakeResponses.map(response => `${response.key}: ${response.value}`).join('\n\n');

  const FeedbackButton = ({ rating, onClick }: { rating: string; onClick: () => void }) => (
    <button
      onClick={onClick}
      onMouseEnter={() => setHoveredButton(rating)}
      onMouseLeave={() => setHoveredButton(null)}
      className={`
        px-3 py-1 rounded-full mr-2
        transition-all duration-300 ease-in-out
        hover:scale-110 hover:shadow-lg
        ${hoveredButton === rating ? 'bg-blue-100' : 'bg-gray-100'}
      `}
    >
      <span
        className={`
        transform transition-transform duration-300
        ${hoveredButton === rating ? 'scale-125' : 'scale-100'}
      `}
      >
        {rating === 'good' ? '👍' : '👎'}
      </span>
    </button>
  );

  const getFooterContent = () => {
    return (
      <div className='flex items-center justify-between'>
        {callLog?.rating ? (
          <span>You rated this call log {callLog?.rating === 'good' ? '👍' : '👎'}</span>
        ) : (
          <div className='flex-1 flex items-center'>
            <FeedbackButton
              rating='good'
              onClick={() => {
                updateCallLogMutation({
                  callSid: callLog?.callSid || '',
                  date: callLog?.date || '',
                  data: {
                    rating: 'good',
                  },
                });
              }}
            />
            <FeedbackButton
              rating='poor'
              onClick={() => {
                updateCallLogMutation({
                  callSid: callLog?.callSid || '',
                  date: callLog?.date || '',
                  data: {
                    rating: 'poor',
                  },
                });
              }}
            />
          </div>
        )}
        <div>
          <button onClick={handleConfirm} className='px-4 py-2 mr-2 border rounded hover:bg-gray-100'>
            Cancel
          </button>
          <button onClick={handleConfirm} className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'>
            OK
          </button>
        </div>
      </div>
    );
  };

  const filteredData = showFiltered
    ? callLogs.filter(log => log.callSummary !== undefined && log.callSummary !== 'NA' && log.callSummary !== '')
    : callLogs;

  const baseClass = 'call-logs';
  return (
    <div className={baseClass}>
      <div className={`${baseClass}_header`}>
        <h1>Call Logs</h1>
        {isSearchedLog && (
          <Button type='default' onClick={handleResetSearch} icon={<RollbackOutlined />}>
            Back to All Logs
          </Button>
        )}
        {!isSearchedLog && (
          <Button
            type='primary'
            icon={<PlayForWorkOutlinedIcon />}
            onClick={() => handleCallLogsDownload()}
            disabled={
              isLoadingProvisionedNumber ||
              downloadCallLogsStatus === 'pending' ||
              !provisionedNumbers?.provisioned_number
            }
          >
            {!smallScreen && 'Download Logs'}
          </Button>
        )}
        <Button
          type='primary'
          icon={<RefreshOutlinedIcon />}
          onClick={() => {
            refetch();
          }}
          loading={isPending || isRefetching}
        >
          {!smallScreen && 'Refresh Logs'}
        </Button>
      </div>
      {!isSearchedLog && (
        <div className={`${baseClass}_input-container responsive-input-container`}>
          <RangePicker placement='bottomRight' onChange={handleDateRangeChange} />
          <Select
            value={timeZone}
            onChange={handleTimeZoneChange}
            showSearch
            placeholder='Select a time zone'
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={timeZoneList.map(zone => ({ label: zone, value: zone }))}
            className='tz-filter'
          />
          <Button type='primary' icon={<CheckOutlined />} disabled={!hasSelected} onClick={handleMarkAsRead}>
            Mark As Read
          </Button>
          <Checkbox onChange={hideEmptyCalls}>Hide Empty Calls</Checkbox>
        </div>
      )}
      {loggedInUser?.algoliaSearchToken && (
        <div className='mb-3'>
          <AlgoliaSearch
            userNumber={loggedInUser.number as string}
            algoliaSearchToken={loggedInUser.algoliaSearchToken as string}
            onLogSelect={handleLogSelect}
            onReset={handleResetSearch}
            isLoading={callLogbySidPending}
          />
        </div>
      )}
      <Alert className='overview-info' closable description='Access and review your Call Logs in minutes! 👇' />
      <Spin spinning={isPending}>
        <div className={`${baseClass}_table-container`}>
          <Table
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            columns={columns}
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
            }}
            onRow={record => ({
              onClick: event => {
                const targetElement = event.target as HTMLElement;
                if (targetElement.tagName === 'INPUT' && (targetElement as HTMLInputElement).type === 'checkbox') {
                  return;
                }
                handleRowInteraction(record);
              },
            })}
            scroll={{ x: 'max-content' }}
            rowClassName={record => (record.isRead ? 'read-row' : 'unread-row')}
          />
        </div>
      </Spin>

      <div className='custom-modal'>
        <Modal
          onCancel={handleCancel}
          title={<strong>Call Log Details</strong>}
          open={showModal}
          onOk={handleConfirm}
          footer={getFooterContent()}
        >
          {<CallDetails callLog={callLog} />}
        </Modal>
      </div>
      <CustomModal
        title={
          <div>
            <strong>Call Intake Form</strong>
            <CopyableText text={formattedIntakeString} hideText />
          </div>
        }
        children={<CallIntakeResponse intakeResponse={intakeResponses} />}
        isModalOpen={intakeModal}
        confirmAction={handleCancelIntakeModal}
        cancelAction={handleCancelIntakeModal}
      />
      <CustomModal
        title={<>Are you sure you want to delete this call log?</>}
        children={<>This action cannot be undone.</>}
        isModalOpen={confirmDelete}
        confirmAction={handleDeleteCallLog}
        cancelAction={handleCancelDeleteModal}
        footer={[
          <Button key='cancel' onClick={handleCancelDeleteModal}>
            Cancel
          </Button>,
          <Button
            key='confirm'
            type='primary'
            onClick={handleDeleteCallLog}
            danger
            loading={deleteCallLogStatus === 'pending'}
          >
            Delete
          </Button>,
        ]}
      />
      <CustomModal
        title={<>Save contact</>}
        isModalOpen={saveContactModal}
        confirmAction={handleSaveUserContact}
        cancelAction={handleCancelSaveContact}
      >
        <div>
          <p className='my-2'>
            How would you like to save this <strong>{selectedContact}</strong>?
          </p>
          <Input
            placeholder='Enter name'
            onChange={e => setSelectedContactName(e.target.value)}
            value={selectedContactName}
          />
        </div>
      </CustomModal>
      <CustomModal
        title={
          <div className='flex items-center gap-4'>
            <>Share Call Log</>
            <LearnMore tutorialId={TUTORIALS.SHARABLE_CALL_LINK} />
          </div>
        }
        isModalOpen={shareModal}
        confirmAction={() => setShareModal(false)}
        cancelAction={() => setShareModal(false)}
        centered
      >
        <div className='flex flex-col items-center space-y-6'>
          <div className='flex justify-center space-x-4'>
            {shareOptions.map(option => (
              <Tooltip key={option.key} title={option.label}>
                <Button
                  type='default'
                  shape='circle'
                  size='large'
                  icon={option.icon}
                  style={{
                    color: option.color,
                    borderColor: option.color,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '48px',
                    height: '48px',
                  }}
                  onClick={() => handleShareLink(option.key)}
                />
              </Tooltip>
            ))}
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default CallLogs;
