import { IntakeForm } from '@/types/TCallLogs';
import { List } from 'antd';
import './CallDetails.scss';

export const CallIntakeResponse = ({ intakeResponse }: { intakeResponse: IntakeForm[] }) => {
  return (
    <div className='max-h-[400px] overflow-y-auto'>
      <List
        dataSource={intakeResponse}
        renderItem={item =>
          item && (
            <List.Item
              className={`flex mx-4 ${item.key === 'Question' ? 'justify-start text-left' : 'justify-end text-right'}`}
            >
              <List.Item.Meta title={<strong>{item.key}</strong>} description={item.value} />
            </List.Item>
          )
        }
      />
    </div>
  );
};
