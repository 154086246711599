import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';
import { NoviUser, NoviUserState } from '@/pages/Novi/types/NoviUser';
import LocalStorageUtility from '@/utils/localStorage';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';

const initialState: NoviUserState = {
  user: {
    email_address: '',
    business_name: '',
    logo: '',
    twilio_access_token: '',
    twilio_account_id: '',
    is_terms_accepted: false,
  },
  currentPage: ENoviDashboardSider.MY_CAMPAIGNS,
  selectedCampaignId: null,
  selectedCampaign: null,
};

export const slice: Slice<
  NoviUserState,
  {
    setNoviUser: (state: NoviUserState, action: PayloadAction<NoviUser>) => void;
    setNoviCurrentPage: (state: NoviUserState, action: PayloadAction<ENoviDashboardSider>) => void;
    setNoviSelectedCampaignId: (state: NoviUserState, action: PayloadAction<string | null>) => void;
    setNoviSelectedCampaign: (state: NoviUserState, action: PayloadAction<any | null>) => void;
  },
  'noviUserAccount'
> = createSlice({
  name: 'noviUserAccount',
  initialState,
  reducers: {
    setNoviUser: (state: NoviUserState, action: PayloadAction<NoviUser>) => {
      state.user = {
        email_address: action.payload.email_address,
        business_name: action.payload.business_name,
        logo: action.payload.logo,
        twilio_access_token: action.payload.twilio_access_token,
        twilio_account_id: action.payload.twilio_account_id,
        is_terms_accepted: action.payload.is_terms_accepted,
      };
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_USER, action.payload);
    },
    setNoviCurrentPage: (state: NoviUserState, action: PayloadAction<ENoviDashboardSider>) => {
      state.currentPage = action.payload;
    },
    setNoviSelectedCampaignId: (state: NoviUserState, action: PayloadAction<string | null>) => {
      state.selectedCampaignId = action.payload;
    },
    setNoviSelectedCampaign: (state: NoviUserState, action: PayloadAction<any | null>) => {
      state.selectedCampaign = action.payload;
    },
  },
});

export const { setNoviUser, setNoviCurrentPage, setNoviSelectedCampaignId, setNoviSelectedCampaign } = slice.actions;

export default slice.reducer;
