import apiClient from '@/utils/apiClient';
import axios from 'axios';

const calComApiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_CAL_COM_API_URL,
  timeout: 10000,
});

const CAL_COM_API_ENDPOINTS = {
  getAllUsersEventTypesPath: () => `/event-types`,
  getAllApiKeysPath: () => `/calcom`,
};

const CAL_COM_SERVER_API_ENDPOINTS = {
  getAllApiKeysPath: (username: string | undefined) => `/api/calendars/cal-com/api-keys/${username}`,
  addCalComCalendarPath: () => `/api/calendars/cal-com/`,
};

export const getAllUserEventTypes = async ({ apiKey }: { apiKey: string }) => {
  try {
    const response = await calComApiClient.get(CAL_COM_API_ENDPOINTS.getAllUsersEventTypesPath(), {
      params: {
        apiKey: apiKey,
      },
    });
    return response.data?.event_types || [];
  } catch (error) {
    console.error('Error fetching user-selected calendars:', error);
    throw error;
  }
};

export const getAllCalComApiKeys = async (username: string | undefined) => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: CAL_COM_SERVER_API_ENDPOINTS.getAllApiKeysPath(username),
    });
    return response.data || [];
  } catch (error) {
    console.error('Error fetching API keys:', error);
    throw error;
  }
};

export const addCalComCalendar = async (data: any) => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CAL_COM_SERVER_API_ENDPOINTS.addCalComCalendarPath(),
      body: data,
    });
    return response.data || [];
  } catch (error) {
    console.error('Error addCalComCalendar:', error);
    throw error;
  }
};
