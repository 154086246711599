import { useState } from 'react';
import { Card, Typography, Badge } from 'antd';
import { InfoCircleOutlined, ThunderboltOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaignId } from '@/store/novi/user/selector';
import UploadCSV from './UploadCSV/UploadCsv';
import { AddNewLeadProps } from '@/pages/Novi/types/Leads';
import ManualInsertLead from './SingleLead/ManualInsertLead';
import ZapierGuide from './AddViaZapier/AddLeadViaZapier';

const { Text } = Typography;

interface ImportOptionProps {
  icon: typeof ThunderboltOutlined | typeof UploadOutlined | typeof UserOutlined;
  title: string;
  description: string;
  badge?: boolean;
  onClick: () => void;
}

const AddNewLeads: React.FC<AddNewLeadProps> = ({ closeModal, refetchLeads }) => {
  const [activeComponent, setActiveComponent] = useState<
    'options' | 'uploadCSV' | 'manualPhones' | 'zapierIntegration'
  >('options');
  const campaign_id = useAppSelector(getSelectedCampaignId) as string;

  const handleOnClose = () => {
    setActiveComponent('options');
    refetchLeads('NEW');
    closeModal();
  };

  const goBack = () => {
    setActiveComponent('options');
  };

  const ImportOption: React.FC<ImportOptionProps> = ({ icon: Icon, title, description, badge, onClick }) => (
    <Card hoverable onClick={onClick} style={{ marginBottom: 12, border: '1px solid #f0f0f0' }}>
      <div style={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
        <div
          style={{
            width: 48,
            height: 48,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 8,
            backgroundColor: Icon === ThunderboltOutlined ? '#f3e8ff' : Icon === UploadOutlined ? '#dcfce7' : '#dbeafe',
            flexShrink: 0,
          }}
        >
          <Icon
            style={{
              fontSize: 24,
              color: Icon === ThunderboltOutlined ? '#9333ea' : Icon === UploadOutlined ? '#16a34a' : '#2563eb',
            }}
          />
        </div>
        <div style={{ flex: 1, minWidth: 0 }}>
          <div style={{ marginBottom: 4 }}>
            <Text strong style={{ fontSize: 16, marginRight: 8 }}>
              {title}
            </Text>
            {badge && (
              <Badge
                count='Recommended'
                style={{
                  backgroundColor: '#dbeafe',
                  color: '#2563eb',
                  border: 'none',
                  fontSize: 12,
                  padding: '0 8px',
                  height: 20,
                  borderRadius: 10,
                  display: 'inline-block',
                }}
              />
            )}
          </div>
          <Text type='secondary' style={{ fontSize: 14, lineHeight: '20px' }}>
            {description}
          </Text>
        </div>
      </div>
    </Card>
  );

  if (activeComponent === 'uploadCSV') {
    return <UploadCSV onClose={handleOnClose} goBack={goBack} campaignId={campaign_id} />;
  }

  if (activeComponent === 'manualPhones') {
    return <ManualInsertLead onClose={handleOnClose} goBack={goBack} />;
  }

  if (activeComponent === 'zapierIntegration') {
    return <ZapierGuide goBack={goBack} />;
  }

  return (
    <div style={{ marginTop: 16 }}>
      <ImportOption
        icon={ThunderboltOutlined}
        title='Trigger Via Event'
        description='Automatically import leads from other apps using Zapier integrations'
        badge={true}
        onClick={() => setActiveComponent('zapierIntegration')}
      />

      <ImportOption
        icon={UploadOutlined}
        title='Upload CSV'
        description='Import leads from a spreadsheet (.csv format)'
        onClick={() => setActiveComponent('uploadCSV')}
      />

      <ImportOption
        icon={UserOutlined}
        title='Enter Manually'
        description='Add individual lead information manually'
        onClick={() => setActiveComponent('manualPhones')}
      />

      <Card style={{ backgroundColor: '#f0f5ff', borderColor: '#bfdbfe' }} styles={{ body: { padding: 16 } }}>
        <div style={{ display: 'flex', gap: 8 }}>
          <InfoCircleOutlined style={{ color: '#2563eb', fontSize: 14, marginTop: 3 }} />
          <div>
            <Text strong style={{ color: '#1e3a8a', display: 'block', fontSize: 14 }}>
              Need help choosing?
            </Text>
            <Text style={{ color: '#3b82f6', fontSize: 12 }}>
              Use Trigger Via Event for continuous lead import from your existing tools. CSV upload is great for bulk
              imports, while manual entry works best for individual leads.
            </Text>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AddNewLeads;
