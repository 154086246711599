import { Lead } from '@/pages/Novi/types/Leads';
import apiClient from '@/utils/apiClient';

const NOVI_LEAD = '/api/novi/leads';
const ADD_LEAD_VIA_CSV = '/api/novi/leads/csv';
const UPDATE_LEAD_STATUS = '/api/novi/leads/:lead_id/status';
const UPDATE_LEAD_DETAILS = '/api/novi/leads/:lead_id';
const DELETE_LEAD = '/api/novi/leads/:lead_id';
const CALL_A_LEAD = '/api/novi/call';

const NOVI = 'NOVI';

export const addNewLead = async (payload: {
  campaign_id: string;
  lead: {
    phone_number: string;
    variables: Record<string, string>;
  };
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: NOVI_LEAD,
      clientType: NOVI,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error adding new lead:', error);
    throw error;
  }
};

export const addLeadsViaCSV = async (payload: { campaign_id: string; file: File }): Promise<{ message: string }> => {
  const formData = new FormData();
  formData.append('csv', payload.file);

  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `${ADD_LEAD_VIA_CSV}?campaign_id=${payload.campaign_id}`,
      clientType: NOVI,
      body: formData,
    });

    return response;
  } catch (error) {
    console.error('Error adding leads via CSV:', error);
    throw error;
  }
};

export const getLeads = async (
  campaign_id: string,
  query: {
    status: string;
    nextKey: string;
    limit: number;
  }
): Promise<{
  leads: Lead[];
  pagination: {
    nextKey: string | null;
    hasMore: boolean;
  };
}> => {
  const params = new URLSearchParams({
    campaignId: campaign_id,
    leadStatus: query.status,
    limit: query.limit.toString(),
  });

  if (query.nextKey) {
    params.append('nextKey', query.nextKey);
  }

  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: NOVI_LEAD + '?' + params.toString(),
      clientType: NOVI,
    });

    return {
      ...response,
      pagination: {
        ...response.pagination,
        nextKey: response.pagination.nextKey || null,
      },
    };
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
};

export const getPollingLeads = async (
  campaign_id: string,
  lastUpdatedAt: number,
  query: {
    status: string;
    nextKey: string;
    limit: number;
  }
): Promise<{
  leads: Lead[];
  pagination: {
    nextKey: string | null;
    hasMore: boolean;
  };
}> => {
  const params = new URLSearchParams({
    campaignId: campaign_id,
    leadStatus: query.status,
    limit: query.limit.toString(),
    lastUpdatedAt: lastUpdatedAt.toString(),
  });

  if (query.nextKey) {
    params.append('nextKey', query.nextKey);
  }

  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: NOVI_LEAD + '/polling?' + params.toString(),
      clientType: NOVI,
    });

    return {
      ...response,
      pagination: {
        ...response.pagination,
        nextKey: response.pagination.nextKey || null,
      },
    };
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
};

export const updateLeadStatus = async (payload: {
  campaign_id: string;
  lead_id: string;
  status: string;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: UPDATE_LEAD_STATUS.replace(':lead_id', payload.lead_id),
      clientType: NOVI,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error updating lead status:', error);
    throw error;
  }
};

export const updateLeadDetails = async (payload: {
  campaign_id: string;
  lead_id: string;
  leadDetails: {
    variables: Record<string, string>;
    status: string;
  };
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: UPDATE_LEAD_DETAILS.replace(':lead_id', payload.lead_id),
      clientType: NOVI,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error updating lead details:', error);
    throw error;
  }
};

export const deleteLead = async (payload: { campaign_id: string; lead_id: string }): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: DELETE_LEAD.replace(':lead_id', payload.lead_id),
      clientType: NOVI,
      body: { campaign_id: payload.campaign_id },
    });

    return response;
  } catch (error) {
    console.error('Error deleting lead:', error);
    throw error;
  }
};

export const makeACall = async (payload: { campaign_id: string; lead_id: string }): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CALL_A_LEAD,
      clientType: NOVI,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error making a call:', error);
    throw error;
  }
};
