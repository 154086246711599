import CommonSider from '@/components/Sider/Sider';
import { MenuProps } from 'antd';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import {
  Groups2Outlined,
  LinkOutlined,
  SellOutlined,
  DescriptionOutlined,
  SubscriptionsOutlined,
  ImportExport,
  LogoutOutlined,
  PhoneOutlined,
} from '@mui/icons-material';
import MobileLogo from '@/assets/images/mobile-log.png';
import DesktopLogo from '@/assets/images/desktop-logo.png';
import LocalStorageUtility from '@/utils/localStorage';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';
import { useEffect, useRef } from 'react';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import ResellerTour from './ResellerTour/ResellerTour';
import { BookOutlined } from '@ant-design/icons';

const ResellerSider: React.FC<{
  onMenuSelect: (key: ResellerSiderItem) => void;
  activeMenuItem: ResellerSiderItem;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ onMenuSelect, activeMenuItem, setLoading }) => {
  const { isMobile } = useMobileDeviceCheck();
  const currentReseller = useAppSelector(getResellerData);
  const allReceptionistRef = useRef(null);
  const brandingRef = useRef(null);
  const tutorialRef = useRef(null);

  const items: MenuProps['items'] = [
    {
      key: ResellerSiderItem.MY_RECEPTIONISTS,
      icon: <Groups2Outlined />,
      label: <div ref={allReceptionistRef}>{ResellerSiderItem.MY_RECEPTIONISTS}</div>,
    },
    {
      key: ResellerSiderItem.TRIAL_RECEPTIONIST,
      icon: <SubscriptionsOutlined />,
      label: ResellerSiderItem.TRIAL_RECEPTIONIST,
    },
    {
      key: ResellerSiderItem.EMBED,
      icon: <LinkOutlined className='rotate-45' />,
      label: ResellerSiderItem.EMBED,
    },
    {
      key: ResellerSiderItem.BRANDING,
      icon: <SellOutlined />,
      label: <div ref={brandingRef}>{ResellerSiderItem.BRANDING}</div>,
    },
    ...(currentReseller?.permanently_unbranded || currentReseller?.branded
      ? []
      : [
          {
            key: ResellerSiderItem.TUTORIALS,
            icon: <DescriptionOutlined />,
            label: <div ref={tutorialRef}>{ResellerSiderItem.TUTORIALS}</div>,
          },
          {
            key: ResellerSiderItem.SUBSCRIPTION,
            icon: <SubscriptionsOutlined />,
            label: ResellerSiderItem.SUBSCRIPTION,
          },
          {
            key: ResellerSiderItem.ACADEMY,
            icon: <BookOutlined />,
            label: ResellerSiderItem.ACADEMY,
          },
        ]),

    {
      key: ResellerSiderItem.PHONE_NUMBERS,
      icon: <PhoneOutlined />,
      label: ResellerSiderItem.PHONE_NUMBERS,
    },
    {
      key: ResellerSiderItem.IMPORT_NUMBER,
      icon: (
        <ImportExport
          style={{
            fontSize: '1.5rem',
            marginLeft: '-0.4rem',
            marginRight: '-0.2rem',
          }}
        />
      ),
      label: ResellerSiderItem.IMPORT_NUMBER,
    },

    {
      key: 'Logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
      danger: true,
    },
  ];

  const handleClick: MenuProps['onClick'] = e => {
    if (e.key === 'Logout') {
      LocalStorageUtility.clearLocalData();
      window.location.reload();
    } else {
      onMenuSelect(e.key as ResellerSiderItem);
    }
  };

  const AppLogo = (
    <div className='px-4 py-2'>
      {isMobile ? <img src={MobileLogo} alt='logo' /> : <img src={DesktopLogo} alt='logo' />}
    </div>
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <CommonSider
        items={items}
        logo={currentReseller?.permanently_unbranded || currentReseller.branded ? '' : AppLogo}
        activeMenuItem={activeMenuItem}
        handleClick={handleClick}
        openedOnboarding={true}
        tourOpen={true}
        setOpenedOnboarding={() => {}}
        resellerSider
      />
      {(activeMenuItem === ResellerSiderItem.MY_RECEPTIONISTS ||
        activeMenuItem === ResellerSiderItem.TUTORIALS ||
        activeMenuItem === ResellerSiderItem.BRANDING) && (
        <ResellerTour allReceptionistRef={allReceptionistRef} brandingRef={brandingRef} tutorialRef={tutorialRef} />
      )}
    </>
  );
};

export default ResellerSider;
