import { Theme } from '@/interfaces/IThemeInterface';
import { AppState } from '@/types/TApp';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

const initialState: AppState = {
  isLoading: false,
};

export const slice: Slice<
  AppState,
  {
    setAppLoading: (state: AppState, action: PayloadAction<boolean>) => void;
    setAppTheme: (state: AppState, action: PayloadAction<Partial<Theme>>) => void;
  },
  'app'
> = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAppTheme: (state: AppState, action: PayloadAction<Partial<Theme>>) => {
      state.dashboardTheme = action.payload;
      const root = document.documentElement;
      Object.entries(action.payload).forEach(([key, value]) => {
        const cssKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();
        root.style.setProperty(`--${cssKey}`, value as string);
      });
    },
  },
});

export const { setAppLoading, setAppTheme } = slice.actions;
export default slice.reducer;
