import React, { useState } from 'react';
import EllipsisText from '@/components/LabelWithdescription/EllipsisText';
import { TVoiceModel } from '@/types/TVoiceModel';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { Card, Popconfirm, Modal, Select, notification } from 'antd';
import userPng from '../../../assets/images/user.png';
import { AddBox, CheckBox, DeleteOutlineRounded, ModeOutlined, Settings } from '@mui/icons-material';
import { User } from '@/types/TAccounts';
import AudioPlayer from './AudioPlayer';
import CopyableText from '@/components/LabelWithdescription/CopyableText';
import { updateVoiceConfig } from '@/api/voice';

interface VoiceLibraryCardProps {
  index: number;
  data: TVoiceModel;
  isSelected: boolean;
  onApplyClick: (value: string) => void;
  currentlyPlayingIndex: number | null;
  onPlayClick: (index: number | null) => void;
  user: User | undefined;
  deleteVoiceMutation: UseMutateFunction<
    {
      success: boolean;
      message?: string;
    },
    Error,
    {
      voiceId: string;
      fileName: string;
    },
    unknown
  >;
}

const VoiceLibraryCard: React.FC<VoiceLibraryCardProps> = ({
  index,
  data,
  isSelected,
  onApplyClick,
  currentlyPlayingIndex,
  user,
  deleteVoiceMutation,
}) => {
  const [open, setOpen] = useState(false);
  const [speed, setSpeed] = useState(data?.voiceConfig?.speed || 'normal');
  const [emotion, setEmotion] = useState(data?.voiceConfig?.emotion || 'positivity');
  const [level, setLevel] = useState(data?.voiceConfig?.level || '');

  const { mutate: updateVoiceConfigMutation } = useMutation({
    mutationKey: ['updateVoiceConfig'],
    mutationFn: updateVoiceConfig,
    onSuccess() {
      notification.success({ message: 'Voice configuration applied successfully!' });
    },
    onError() {
      notification.error({ message: 'Some error occured during voice model updation' });
    },
  });

  const showModal = () => {
    setOpen(true);
  };

  const handleApplyClick = () => {
    if (!isSelected) {
      onApplyClick(data.voiceId);
    }
  };

  const handleSpeed = (value: string) => {
    setSpeed(value);
  };

  const handleEmotion = (value: string) => {
    setEmotion(value);
  };

  const handleLevel = (value: string) => {
    setLevel(value);
  };

  const handleOk = async () => {
    updateVoiceConfigMutation({
      voiceId: data.voiceId,
      voiceConfig: {
        speed: speed,
        emotion: emotion,
        level: level,
      },
      addedBy: data.addedBy,
    });
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        className={`flex flex-col w-70 border-[2px] rounded-xl gap-4 p-0 relative group my-3 ${
          currentlyPlayingIndex !== null && currentlyPlayingIndex !== index ? 'opacity-50 cursor-not-allowed' : ''
        } ${isSelected ? 'border-blue-500' : 'border-gray-300'}`}
        actions={[
          <button
            className=' text-red-500 cursor-pointer group-hover:opacity-100 transition-opacity duration-300 rounded-full p-1.5 bg-green-50 hover:bg-green-100'
            onClick={handleApplyClick}
          >
            {isSelected ? <CheckBox /> : <AddBox />}
          </button>,
          data.provider === 'cartesia' && (
            <button
              className=' text-red-500 cursor-pointer group-hover:opacity-100 transition-opacity duration-300 rounded-full p-1.5 bg-green-50 hover:bg-green-100'
              onClick={showModal}
            >
              <Settings />
            </button>
          ),
          user?.isSuperAdmin && (
            <Popconfirm
              title='Are you sure to delete this voice?'
              onConfirm={() => deleteVoiceMutation({ voiceId: data.voiceId, fileName: data.fileName })}
              okText='Yes'
              cancelText='No'
            >
              <DeleteOutlineRounded
                fontSize='large'
                className=' text-red-500 cursor-pointer group-hover:opacity-100 transition-opacity duration-300 rounded-full p-1.5 bg-green-50 hover:bg-green-100'
              />
            </Popconfirm>
          ),
          user?.isSuperAdmin && !data.isCloned && (
            <ModeOutlined
              fontSize='large'
              className='text-red-500 cursor-pointer group-hover:opacity-100 transition-opacity duration-300 rounded-full p-1.5 bg-green-50 hover:bg-green-100'
            />
          ),
        ]}
      >
        <>
          <div className='flex gap-2 w-full'>
            <div className='flex flex-col gap-1 w-3/4'>
              <div className='flex gap-1'>
                <h1 className='font-semibold'>{data?.name}</h1>
                <CopyableText text={data?.name} hideText />
              </div>
              {data?.gender && data?.accent && (
                <span className='text-sm'>
                  {data?.gender} | {data.accent}
                </span>
              )}
              <EllipsisText text={data.description || ''} length={35} extraClass='text-xs text-gray-500' />
            </div>
            <div className='flex flex-col items-center justify-center gap-2 w-1/4'>
              <img className='w-[4rem]' src={data.avatarUrl || userPng} alt={data.name} />
            </div>
          </div>
          <div className='mt-4'>
            <AudioPlayer
              fileName={data.fileName}
              onPlay={() => console.log('Audio started playing')}
              onPause={() => console.log('Audio paused')}
            />
          </div>
        </>
      </Card>

      <Modal title='Voice Configuration' open={open} onCancel={handleCancel} onOk={handleOk}>
        <div className='mb-3'>
          <label htmlFor='speed' className='mb-1 block font-medium'>
            Speed
          </label>
          <Select
            value={speed}
            id='speed'
            style={{ width: '100%' }}
            onChange={handleSpeed}
            options={[
              { value: 'slowest', label: 'Slowest' },
              { value: 'slow', label: 'Slow' },
              { value: 'normal', label: 'Normal' },
              { value: 'fast', label: 'Fast' },
              { value: 'fastest', label: 'Fastest' },
            ]}
          />
        </div>

        <div className='mb-3'>
          <label htmlFor='emotion' className='mb-1 block font-medium'>
            Emotion Name
          </label>
          <Select
            value={emotion}
            style={{ width: '100%' }}
            onChange={handleEmotion}
            options={[
              { value: 'anger', label: 'Anger' },
              { value: 'positivity', label: 'Positivity' },
              { value: 'surprise', label: 'Surprise' },
              { value: 'sadness', label: 'Sadness' },
              { value: 'curiosity', label: 'Curiosity' },
            ]}
          />
        </div>

        <div className='mb-4'>
          <label htmlFor='level' className='mb-1 block font-medium'>
            Emotion Level
          </label>
          <Select
            value={level}
            style={{ width: '100%' }}
            onChange={handleLevel}
            options={[
              { value: 'lowest', label: 'Lowest' },
              { value: 'low', label: 'Low' },
              { value: '', label: 'Balanced' },
              { value: 'high', label: 'High' },
              { value: 'highest', label: 'Highest' },
            ]}
          />
        </div>
      </Modal>
    </>
  );
};

export default VoiceLibraryCard;
