import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import classes from './../RegisterPageV3.module.css';
import debrandResellLogo from './../shake-hand.png';
import logo from './../Blue_Logo.png';
import { prod } from '../config';
import Header from '../components/registration/Header';
import WelcomeScreen from '../components/registration/WelcomeScreen';
import BusinessNameScreen from '../components/registration/BusinessNameScreen';
import UploadLogo from '../components/registration/UploadLogo';
import EmailRegisterForm from '../components/registration/EmailRegisterForm';
import PasswordRegisterForm from '../components/registration/PasswordRegisterForm';
import ThemeColorSelectionScreen from '../components/registration/ThemeColorSelectionScreen';
import axios from 'axios';
import ModalWrapper from '../components/modals/ModalWrapper';
import Loader from '../components/dashboard/Loader';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ResellerRegistration = ({ debrand = false }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const path = window.location.pathname;

  let encodedData = null;
  let name = null;
  let customOffer = false;

  if (path.startsWith('/custom-offer-reseller')) {
    // Extract encryptedData from the path for /custom-offer-reseller route
    const pathSegments = path.split('/');
    encodedData = pathSegments[pathSegments.length - 1];
    // Assuming name is still in the query string
    name = urlParams.get('name');
    customOffer = true;
  }

  const [resellerName, setResellerName] = useState('');
  const [themeColor, setThemeColor] = useState('#007bff');
  const [resellerType, setresellerType] = useState('');
  const [uploadedLogo, setUploadedLogo] = useState('');
  const [uploadedLogoS3Url, setUploadedLogoS3Url] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [stepState, setStepState] = useState(0);
  const [customOfferLoading, setCustomOfferLoading] = useState(false);
  const [customOfferLoadingMsg, setCustomOfferLoadingMsg] = useState('Getting things ready...');

  const [, setIsReferralUser] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!debrand) {
      // Include Zendesk Widget script
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=a1895b38-5ada-4a38-b4b1-580c900fcc1e';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        // Cleanup when component unmounts
        document.body.removeChild(script);
      };
    }
  }, []);

  useEffect(() => {
    window.gtag('event', 'ResellerRegistrationPageView', {
      event_category: 'Pageview',
    });
  }, []);

  // use effect for step state changing to fire GTag analytics
  useEffect(() => {
    // just record what step number we are on
    window.gtag('event', 'ResellerRegistrationStep', {
      event_category: 'ResellerRegistration.js',
      event_label: 'Step # ' + stepState,
    });
    console.log('Step State: ' + stepState);
  }, [stepState]);

  var serverURL = prod ? 'https://myaifrontdeskadmin.com' : 'http://localhost:8080';
  if (prod) {
    console.log = function () {};
    console.error = function () {};
  }
  useEffect(() => {
    (function (w, r) {
      w._rwq = r;
      w[r] =
        w[r] ||
        function () {
          (w[r].q = w[r].q || []).push(arguments);
        };
    })(window, 'rewardful');
    let s = document.createElement('script');
    s.async = true;
    s.src = 'https://r.wdfl.co/rw.js';
    s.setAttribute('data-rewardful', '30c5c6');
    s.setAttribute('data-domains', 'myaifrontdesk.com, myaifrontdeskdashboard.com');
    document.head.appendChild(s);

    window.rewardful('ready', function () {
      console.log(window.Rewardful);
      if (window.Rewardful.referral) {
        console.log('Current referral ID: ', window.Rewardful.referral);
      } else {
        console.log('No referral present.');
      }
    });
  }, []);

  useEffect(() => {
    // Create a URLSearchParams object to access the query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Get the 'referralCode' parameter
    const referralCode = queryParams.get('referralCode');

    if (referralCode) {
      // Do something with the referralCode
      console.log('Referral Code:', referralCode);

      if (referralCode) {
        // The user has a referral code, so set the state to true
        setIsReferralUser(true);
        setReferralCode(referralCode);
      }
    } else {
      // Handle the case when referralCode is not present in the URL
      console.log('Referral Code is not present in the URL');
    }
  }, []);

  const handleCustomOfferResellerSubmit = async () => {
    setCustomOfferLoading(true);
    // Make server call using fetch to /createClient
    try {
      const response = await axios.post(`${serverURL}/api/reseller/create_reseller_customOffer`, {
        emailAddress,
        resellerName,
        uploadedLogoS3Url,
        referralCode,
        resellerType,
        themeColor,
        encodedData,
        firstName,
        lastName,
        phoneNumber: !phoneNumber.startsWith('+') ? `+${phoneNumber}` : phoneNumber,
      });
      if (!response.status === 200) {
        window.gtag('event', 'couldnt_create_reseller_custom_offer', {
          event_category: 'ResellerRegistration.js',
          event_label: 'error',
        });
        throw new Error('Network response was not ok');
      }
      const data = response.data;
      const status = data.success;
      if (status) {
        window.gtag('event', 'create_reseller_custom_offer', {
          event_category: 'ResellerRegistration.js',
          event_label: 'success',
        });
        if (data.checkoutURL) {
          setCustomOfferLoadingMsg('Redirecting to checkout...');
          window.location.href = data.checkoutURL;
          setCustomOfferLoading(false);
        }
      }
    } catch (error) {
      window.gtag('event', 'couldnt_create_reseller', {
        event_category: 'ResellerRegistration.js',
        event_label: 'error',
      });
      console.error(error);
      toast.error('Failed to complete the onboarding process');
    } finally {
      setCustomOfferLoading(false);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      return;
    }

    // Make server call using fetch to /createClient
    try {
      const response = await fetch(`${serverURL}/create_reseller`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailAddress,
          resellerName,
          uploadedLogoS3Url,
          password,
          referralCode,
          resellerType,
          themeColor,
          firstName,
          lastName,
          phoneNumber,
        }),
      });
      if (!response.ok) {
        window.gtag('event', 'couldnt_create_reseller', {
          event_category: 'ResellerRegistration.js',
          event_label: 'error',
        });
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const status = data.success;
      if (status) {
        window.gtag('event', 'create_reseller', {
          event_category: 'ResellerRegistration.js',
          event_label: 'success',
        });
        if (!debrand) {
          navigate('/reseller_portal', {
            state: { emailAddress: emailAddress, password: password },
          });
        } else {
          navigate('/reseller_portal_unbranded', {
            state: { emailAddress: emailAddress, password: password },
          });
        }
      }
    } catch (error) {
      window.gtag('event', 'couldnt_create_reseller', {
        event_category: 'ResellerRegistration.js',
        event_label: 'error',
      });
      console.error(error);
    }
  };

  const header = (
    <div
      className={classes.header}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 0',
        fontSize: '18px', // Increased font size for better readability
        background: '#f8f8f8', // Light background color
        color: 'rgb(119, 129, 145)',
      }}
    >
      <button
        className={classes.navItem}
        onClick={() => window.open('https://www.myaifrontdesk.com', '_blank')}
        style={{
          padding: '8px 12px', // Added padding for better button appearance
          border: 'none',
          background: 'transparent', // Transparent background
          color: 'inherit',
        }}
      >
        Product
      </button>
      <button
        className={classes.navItem}
        onClick={() => (window.location.href = '/reseller_portal')}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          border: 'none',
          padding: '8px 12px',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Reseller Login
      </button>
      <button
        className={classes.navItem}
        onClick={() => window.open('https://www.myaifrontdesk.com/white-label', '_blank')}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          padding: '8px 12px',
          border: 'none',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Book Consultation
      </button>
      <button
        className={classes.navItem}
        onClick={() =>
          (window.location.href =
            'mailto:contact@myaifrontdesk.com?subject=Contact Us&body=Please let us know how we can assist you.')
        }
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          padding: '8px 12px',
          border: 'none',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Contact
      </button>
    </div>
  );

  return (
    <>
      {/* <Toaster position="top-center" reverseOrder={false} /> */}
      <div
        className={classes.rootPage}
        style={{
          height: '100%',
          overflow: 'auto',
          // fontFamily: "Roboto, sans-serif",
          overflowX: 'hidden',
          transition: '.4s ease',
        }}
      >
        {!debrand && <Header />}

        <script src='https://www.google.com/recaptcha/api.js' async defer></script>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            maxwidth: '100%',
            padding: '1rem 1rem 3rem',
            borderRadius: '10px',
            // fontFamily: "Quicksand, sans-serif",
            margin: '1rem auto', // Centering the card on the page
            minHeight: 'calc(100vh - 163px)',
            position: 'relative',
          }}
        >
          <div
            style={{
              color: 'rgb(40, 42, 48)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1rem',
              // overflow: "auto",
              '@media screen and (max-width: 480px)': {
                padding: '0.5rem',
              },
              '@media screen and (max-width: 375px)': {
                padding: '0.25rem',
              },
            }}
          >
            <div
              style={{
                width: '100%',
              }}
            >
              {stepState === 0 && (
                <WelcomeScreen
                  debrand={debrand}
                  debrandResellLogo={debrandResellLogo}
                  logo={logo}
                  altText={'AI Receptionist'}
                  mainHeading={
                    name
                      ? `Exclusive Reseller Invite for ${name}`
                      : `
            Welcome to ${debrand ? 'the' : "My AI Front Desk's"} Reseller Program
                  
                  `
                  }
                  classes={classes}
                  setStepState={setStepState}
                  subHeading={'⚡️ Build whitelabeled receptionists in minutes.'}
                  buttonText={'Build Now'}
                />
              )}

              {stepState === 1 && (
                <div
                  className={classes.box}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Reseller Type
                    </h3>
                    <select
                      className={classes.input}
                      value={resellerType}
                      onChange={e => setresellerType(e.target.value)}
                    >
                      <option value=''>Select Type</option>
                      <option value='Digital Marketing Agency'>Digital Marketing Agency</option>
                      <option value='Individual'>Individual</option>
                      <option value='Consultant'>Consultant</option>
                      <option value='VOIP business'>VOIP business</option>
                      <option value='Other'>Other</option>
                    </select>

                    <button
                      type='button'
                      onClick={() => {
                        if (resellerType) setStepState(2);
                        else toast.error('Please select a type');
                      }}
                      className={classes.button}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 2 && (
                <BusinessNameScreen
                  businessName='Business Name'
                  classes={classes}
                  infoText={` The name of your business. We will slap this branding on all your
                    receptionists. You can always change this later.`}
                  resellerName={resellerName}
                  setResellerName={setResellerName}
                  toast={toast}
                  placeholderText={"e.g. John's Digital Marketing & AI Agency"}
                  setStepState={setStepState}
                  buttonText={'Continue'}
                />
              )}

              {stepState === 3 && (
                <UploadLogo
                  uploadHeading={'Upload Logo'}
                  infoText={`
           Your business logo. We will slap this branding on all your
           receptionists. You can always change this later.
           `}
                  uploadedLogo={uploadedLogo}
                  classes={classes}
                  onNext={() => {
                    setStepState(4);
                  }}
                  onSkip={() => {
                    setStepState(4);
                  }}
                  serverURL={serverURL}
                  toast={toast}
                  setUploadedLogoS3Url={setUploadedLogoS3Url}
                  setStepState={setStepState}
                  onChangeFunc={event => {
                    setUploadedLogo(event.target.files.length > 0 ? event.target.files[0] : null);
                  }}
                />
              )}

              {stepState === 4 && (
                <ThemeColorSelectionScreen
                  classes={classes}
                  heading='Theme color'
                  onNext={() => {
                    setStepState(5);
                  }}
                  onSkip={() => {
                    setStepState(5);
                  }}
                  themeColor={themeColor}
                  buttonText={'Continue'}
                  setStepState={setStepState}
                  onChange={event => {
                    setThemeColor(event.target.value);
                  }}
                />
              )}

              {stepState === 5 && (
                // <div className={classes.box}>
                //   <Fade duration={1000} bottom>
                //     <h3
                //       className={classes.heading}
                //       style={{
                //         textAlign: "center",
                //       }}
                //     >
                //       Email Address
                //     </h3>
                //     <div
                //       className={classes.subtitle}
                //       style={{
                //         textAlign: "center",
                //       }}
                //     >
                //       Your email address
                //     </div>

                //     <input
                //       type="email"
                //       value={emailAddress}
                //       className={classes.input + " " + classes.centered}
                //       required
                //       onChange={(event) => {
                //         let value = event.target.value;
                //         setEmailAddress(value);
                //       }}
                //       placeholder="e.g. john@ai_dma.com"
                //     />

                //     <button
                //       type="button"
                //       onClick={() => {
                //         if (emailAddress) setStepState(6);
                //         else toast.error("Please enter your email address");
                //       }}
                //       className={classes.button + " " + classes.centered}
                //       style={{
                //         marginTop: "2rem",
                //       }}
                //     >
                //       Continue
                //     </button>
                //   </Fade>
                // </div>

                <EmailRegisterForm
                  classes={classes}
                  onChangeEmail={event => {
                    let value = event.target.value;
                    setEmailAddress(value);
                  }}
                  setStepState={setStepState}
                  toast={toast}
                  emailAddress={emailAddress}
                  setEmailAddress={setEmailAddress}
                />
              )}

              {stepState === 6 && (
                <div className={classes.box}>
                  <Fade duration={1000} bottom>
                    <h3 className={classes.heading} style={{ textAlign: 'center' }}>
                      First Name
                    </h3>
                    <div className={classes.subtitle} style={{ textAlign: 'center' }}>
                      Please enter your first name
                    </div>

                    <input
                      type='text'
                      value={firstName}
                      className={`${classes.input} ${classes.centered}`}
                      required
                      onChange={event => setFirstName(event.target.value)}
                      placeholder='e.g. John'
                    />

                    <button
                      type='button'
                      onClick={() => {
                        if (firstName.trim()) setStepState(7);
                        else toast.error('Please enter your first name');
                      }}
                      className={`${classes.button} ${classes.centered}`}
                      style={{ marginTop: '2rem' }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 7 && (
                <div className={classes.box}>
                  <Fade duration={1000} bottom>
                    <h3 className={classes.heading} style={{ textAlign: 'center' }}>
                      Last Name
                    </h3>
                    <div className={classes.subtitle} style={{ textAlign: 'center' }}>
                      Please enter your last name
                    </div>

                    <input
                      type='text'
                      value={lastName}
                      className={`${classes.input} ${classes.centered}`}
                      required
                      onChange={event => setLastName(event.target.value)}
                      placeholder='e.g. Smith'
                    />

                    <button
                      type='button'
                      onClick={() => {
                        if (lastName.trim()) setStepState(8);
                        else toast.error('Please enter your last name');
                      }}
                      className={`${classes.button} ${classes.centered}`}
                      style={{ marginTop: '2rem' }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 8 && (
                <div className={classes.box}>
                  <Fade duration={1000} bottom>
                    <h3 className={classes.heading} style={{ textAlign: 'center' }}>
                      Phone Number
                    </h3>
                    <div className={classes.subtitle} style={{ textAlign: 'center' }}>
                      Please enter your phone number
                    </div>

                    <div
                      style={{ width: '100%', maxWidth: '400px', margin: '0 auto', position: 'relative', zIndex: '10' }}
                    >
                      <PhoneInput
                        country={'us'}
                        value={phoneNumber}
                        onChange={phone => {
                          console.log('Phine', phone);
                          setPhoneNumber(phone);
                        }}
                        inputStyle={{
                          width: '100%',
                          height: '40px',
                          fontSize: '16px',
                          padding: '8px 45px',
                        }}
                        containerStyle={{
                          marginTop: '1rem',
                        }}
                        buttonStyle={{
                          background: 'white',
                          border: '1px solid #ddd',
                          borderRight: 'none',
                        }}
                      />
                    </div>

                    <button
                      type='button'
                      onClick={() => {
                        console.log('Phone Number', phoneNumber);
                        if (phoneNumber.length >= 10) {
                          customOffer ? handleCustomOfferResellerSubmit() : setStepState(9);
                        } else {
                          toast.error('Please enter a valid phone number');
                        }
                      }}
                      className={`${classes.button} ${classes.centered}`}
                      style={{ marginTop: '2rem' }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 9 &&
                // <div className={classes.box}>
                //   <Fade duration={1000} bottom>
                //     <h3
                //       className={classes.heading}
                //       style={{
                //         textAlign: "center",
                //       }}
                //     >
                //       Create Password
                //     </h3>
                //     <div
                //       className={classes.subtitle}
                //       style={{
                //         textAlign: "center",
                //       }}
                //     >
                //       You will use this password to log in to your dashboard
                //     </div>

                //     <input
                //       type="password"
                //       value={password}
                //       className={classes.input + " " + classes.centered}
                //       required
                //       onChange={(event) => setPassword(event.target.value)}
                //     />

                //     {password.length > 0 ? (
                //       <label
                //         style={{
                //           textAlign: "center",
                //           marginTop: "1.5rem",
                //           display: "block",
                //         }}
                //       >
                //         <p
                //           className={classes.heading}
                //           style={{ textAlign: "center" }}
                //         >
                //           *Confirm Password
                //         </p>
                //         <p className={classes.subtitle}>
                //           Please enter your password once again{" "}
                //         </p>
                //         <input
                //           className={classes.input + " " + classes.centered}
                //           type="password"
                //           value={confirmPassword}
                //           required
                //           onChange={(event) => {
                //             setConfirmPassword(event.target.value);
                //             if (password !== event.target.value) {
                //               setPasswordMatchError(true);
                //               return;
                //             } else {
                //               setPasswordMatchError(false);
                //             }
                //           }}
                //         />
                //       </label>
                //     ) : (
                //       <></>
                //     )}
                //     <div
                //       style={{
                //         height: "26px",
                //         width: "100%",
                //         marginTop: ".5rem",
                //       }}
                //     >
                //       {passwordMatchError ? (
                //         <p
                //           className={classes.label}
                //           style={{
                //             color: "red",
                //             textAlign: "center",
                //             margin: 0,
                //           }}
                //         >
                //           Passwords do not match.
                //         </p>
                //       ) : (
                //         <></>
                //       )}
                //     </div>

                //     <div style={{ textAlign: "center" }}>
                //       {password && confirmPassword && (
                //         <div>
                //           <input
                //             type="checkbox"
                //             name="termsAndConditions"
                //             value={termsAndConditions}
                //             onChange={(e) =>
                //               setTermsAndConditions(e.target.checked)
                //             }
                //           />
                //           <label htmlFor="termsAndConditions">
                //             I agree to the{" "}
                //             <a
                //               href="https://assets-global.website-files.com/646676436cb9dc8974098e06/65a60030580c5ed45b310736_Terms%20and%20Conditions.pdf"
                //               target="_blank"
                //               rel="noopener noreferrer"
                //             >
                //               terms and conditions
                //             </a>
                //           </label>
                //         </div>
                //       )}
                //     </div>

                //     <button
                //       disabled={
                //         password === null ||
                //         password === undefined ||
                //         password.trim().length === 0 ||
                //         password !== confirmPassword ||
                //         !termsAndConditions
                //       }
                //       className={classes.button + " " + classes.centered}
                //       style={{
                //         marginTop: "2rem",
                //       }}
                //       onClick={(e) => {
                //         if (!password || !confirmPassword) {
                //           e.preventDefault();
                //           toast.error("Please enter a password");
                //         }

                //         handleSubmit(e);
                //       }}
                //     >
                //       Create My Reseller Account!
                //     </button>
                //   </Fade>
                // </div>

                !customOffer && (
                  <PasswordRegisterForm
                    classes={classes}
                    onChangePassword={event => setPassword(event.target.value)}
                    setStepState={setStepState}
                    toast={toast}
                    setConfirmPassword={setConfirmPassword}
                    confirmPassword={confirmPassword}
                    password={password}
                    setPasswordMatchError={setPasswordMatchError}
                    termsAndConditions={termsAndConditions}
                    setTermsAndConditions={setTermsAndConditions}
                    handleSubmit={handleSubmit}
                  />
                )}

              <div
                style={{
                  display: 'flex',
                  marginTop: '2rem',
                  justifyContent: 'center',
                  gap: '13px',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  bottom: '1rem',
                }}
              >
                {new Array(customOffer ? 9 : 10).fill(0).map((el, idx) => {
                  return (
                    <div
                      key={'dot-indicator-' + idx}
                      className={classes.dot + ' ' + (idx === stepState ? classes.active : '')}
                      style={{}}
                      onClick={() => {
                        if (idx < stepState) setStepState(idx);
                      }}
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {customOfferLoading && (
          <ModalWrapper closeBtn={false} className={'rounded-lg'}>
            <div className='flex flex-col items-center justify-center'>
              <Loader />
              <p>{customOfferLoadingMsg}</p>
            </div>
          </ModalWrapper>
        )}
      </div>
    </>
  );
};
export default ResellerRegistration;
