import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Card, Button } from 'antd';
import PublicLayout from '@/components/Layout/PublicLayout';
import {
  APP_LANDING_PAGE,
  AppRoutes,
  BOOK_CONSULTATION_LANDING_PAGE,
  PRIVACY_POLICY_LANDING_PAGE,
  TERMS_OF_USE_LANDING_PAGE,
} from 'src/enums/ERoutes';
import './blog.css';
import ctaImage from '@/assets/images/cta-image.png';
import { useQuery } from '@tanstack/react-query';
import { getBlogs } from '@/api/articles';
import { BlogPost } from '@/interfaces/IBlogs';
import { Loading } from '@/components/Loading';

const { Title, Paragraph } = Typography;

const BlogPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data: blog, isLoading: loading } = useQuery<BlogPost, Error>({
    queryKey: ['blog', slug],
    queryFn: () => getBlogs({ type: 'blog', slug: slug }),
    enabled: !!slug,
  });

  const items = [
    { key: 1, target: '_blank', url: APP_LANDING_PAGE, label: 'Product' },
    { key: 2, target: '_self', url: AppRoutes.RECEPTIONIST_LOGIN, label: 'Login' },
    { key: 3, target: '_self', url: AppRoutes.REGISTER, label: 'Register' },
    { key: 4, target: '_blank', url: BOOK_CONSULTATION_LANDING_PAGE, label: 'Book Consultation' },
    { key: 5, target: '_blank', url: APP_LANDING_PAGE, label: 'Contact' },
    { key: 6, target: '_blank', url: PRIVACY_POLICY_LANDING_PAGE, label: 'Privacy Policy' },
    { key: 7, target: '_blank', url: TERMS_OF_USE_LANDING_PAGE, label: 'Terms of Use' },
  ];

  const updateBody = (body: string) => {
    // Check if the body is a stringified HTML and if it contains URLs
    // If it contains URLs, then check if it has our domains or not
    // If those are not our domains, then add rel="noopener noreferrer nofollow" to the anchor tags
    // Also add nofollow to the anchor tags so that the crawlers don't follow the links
    const parser = new DOMParser();
    const doc = parser.parseFromString(body, 'text/html');

    // Update anchor tags
    const links = doc.querySelectorAll('a');
    links.forEach(link => {
      if (link.href && !(link.href.includes('app.myaifrontdesk.com') || link.href.includes('myaifrontdesk.com'))) {
        link.setAttribute('rel', 'noopener noreferrer nofollow');
      }
    });

    // Update iframe tags
    const iframes = doc.querySelectorAll('iframe');
    iframes.forEach(iframe => {
      if (iframe.src && !(iframe.src.includes('app.myaifrontdesk.com') || iframe.src.includes('myaifrontdesk.com'))) {
        iframe.setAttribute('rel', 'noopener noreferrer nofollow');
      }
    });

    // Now return the updated body as a string like before
    return doc.body.innerHTML;
  };

  const getProductContent = (productType: string | undefined) => {
    const productContent = {
      receptionist: {
        title: 'Try Our AI Receptionist Today',
        description: 'Start your free trial for My AI Front Desk today, it takes minutes to setup!',
        cta_1_text: 'TRY FOR FREE',
        cta_1_link: 'https://app.myaifrontdesk.com/register',
        cta_2_text: 'BECOME A RESELLER',
        cta_2_link: 'https://www.myaifrontdesk.com/white-label',
      },
      reseller: {
        title: 'Become a Reseller of My AI Front Desk',
        description: 'Explore our white label solutions and grow your business.',
        cta_1_text: 'BECOME A RESELLER',
        cta_1_link: 'https://www.myaifrontdesk.com/white-label',
        cta_2_text: 'TRY OUR RECEPTIONIST',
        cta_2_link: 'https://app.myaifrontdesk.com/register',
      },
      affiliate: {
        title: 'Join Our Affiliate Program Today',
        description: 'Partner with My AI Front Desk and earn 40% recurring commission right now!',
        cta_1_text: 'AFFILIATE PROGRAM',
        cta_1_link: 'https://www.myaifrontdesk.com/affiliate',
        cta_2_text: 'TRY OUR RECEPTIONIST',
        cta_2_link: 'https://app.myaifrontdesk.com/register',
      },
      novi: {
        title: 'Try Our AI Outbound Dialers Today',
        description: 'Start your free trial for My AI Front Desk today, it takes minutes to setup!',
        cta_1_text: 'TRY FOR FREE',
        cta_1_link: 'https://www.myaifrontdesk.com/novi',
        cta_2_text: 'BECOME A RESELLER',
        cta_2_link: 'https://www.myaifrontdesk.com/white-label',
      },
    };
    return productContent[productType as keyof typeof productContent] || productContent.receptionist;
  };

  return (
    <PublicLayout debrand={false} items={items}>
      <div className='container mx-auto px-4 mt-20'>
        <div className='grid grid-cols-1 md:grid-cols-12 gap-6'>
          <div className='md:col-span-8'>
            {loading ? (
              <div className='flex justify-center items-center h-screen'>
                <Loading />
              </div>
            ) : blog ? (
              <div>
                <Title level={2} className='mb-4'>
                  {blog.name}
                </Title>
                {blog['main-image'] && (
                  <div className='mb-6'>
                    <img src={blog['main-image']} alt={blog.name} className='max-w-full h-auto' />
                  </div>
                )}
                {blog['post-body'] && (
                  <div
                    className='blog-content'
                    dangerouslySetInnerHTML={{
                      __html: updateBody(blog['post-body'].replace(/^'|'$/g, '')),
                    }}
                  />
                )}
              </div>
            ) : (
              <Title level={5}>Blog post not found</Title>
            )}
          </div>
          {!loading && blog && (
            <div className='md:col-span-4'>
              <Card className='bg-gradient-to-br from-blue-100 to-purple-100 h-[600px] flex flex-col justify-between sticky top-5 w-11/12 mx-auto'>
                <div className='flex-grow flex flex-col justify-between p-6'>
                  <div>
                    <Title level={4} className='mb-4'>
                      {getProductContent(blog.product_type).title}
                    </Title>
                    <Paragraph>{getProductContent(blog.product_type).description}</Paragraph>
                  </div>
                  <div>
                    <Button
                      type='primary'
                      block
                      className='mb-4'
                      href={getProductContent(blog.product_type).cta_1_link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {getProductContent(blog.product_type).cta_1_text}
                    </Button>
                    <Button
                      block
                      href={getProductContent(blog.product_type).cta_2_link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {getProductContent(blog.product_type).cta_2_text}
                    </Button>
                  </div>
                </div>
                <div className='p-4 mt-auto'>
                  <img src={ctaImage} alt='CTA' className='max-w-full h-auto' />
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
    </PublicLayout>
  );
};

export default BlogPage;
