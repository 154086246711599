import { Alert, Form, FormInstance } from 'antd';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import './ChangeTestingNumberForm.scss';
import { forwardRef, useState } from 'react';
import PhoneNumberInput from '@/components/Inputs/PhoneNumberInput';
import { handlePrefixPlusOnPhone } from '@/utils/helper';

type ChangeTestingNumberFormProps = {
  onSubmit: (values: { testing_number: string }) => void;
};

export const ChangeTestingNumberForm = forwardRef<FormInstance, ChangeTestingNumberFormProps>(({ onSubmit }, ref) => {
  const [form] = Form.useForm<{
    testing_number: string;
  }>();

  const [isTouched, setIsTouched] = useState<boolean>(form.isFieldsTouched());

  const loggedInUser = useAppSelector(getLoggedInUser);

  const handleFinish = (values: { testing_number: string }) => {
    onSubmit({ testing_number: handlePrefixPlusOnPhone(values.testing_number) });
  };

  const SaveButton = () => {
    return <span>You have some unsaved changes </span>;
  };

  const baseClass = 'change-testing-number-form';
  return (
    <div className={`${baseClass}`}>
      <p className='text-sm mt-1 mb-2'>
        <span>This will allow you to test call your receptionist from a different number.</span>
        <br />
        <span>
          This is useful if you don't have access to <strong>{loggedInUser.testing_number}</strong> to test your
          receptionist
        </span>
        <br />
        <span>
          Once you start your free trial, you can reserve a stand alone number and call your receptionist from any
          number.
        </span>
      </p>
      <Form
        form={form}
        layout='vertical'
        name='change-testing-number-form'
        className={baseClass}
        onFinish={handleFinish}
        ref={ref}
      >
        <Form.Item
          label={
            <LabelWithDescription
              label='Testing Number'
              description='The phone number you want to use for testing'
              required={true}
            />
          }
          className={`${baseClass}__input-item`}
        >
          <PhoneNumberInput name='testing_number' />
        </Form.Item>
        <Form.Item
          shouldUpdate={() => {
            const isFormChanged = form.isFieldsTouched() !== isTouched;
            if (isFormChanged) {
              setIsTouched(form.isFieldsTouched());
            }

            return isFormChanged;
          }}
          className={`${baseClass}__footer`}
        >
          {() => form.isFieldsTouched() && isTouched && <Alert type='warning' message={<SaveButton />} />}
        </Form.Item>
      </Form>
    </div>
  );
});
