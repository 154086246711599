import { IntakeForm } from '@/types/TCallLogs';
import { Card, List } from 'antd';

export const CallInakeFormCard = ({ intakeForm, style }: { intakeForm: IntakeForm[]; style?: React.CSSProperties }) => {
  return (
    <Card title='Intake Form' style={style}>
      <List
        dataSource={intakeForm}
        renderItem={item =>
          item && (
            <List.Item
              style={{
                display: 'flex',
                justifyContent: item.key === 'Question' ? 'flex-start' : 'flex-end',
                textAlign: item.key === 'Question' ? 'left' : 'right',
              }}
            >
              <List.Item.Meta title={<strong>{item.key}</strong>} description={item.value} />
            </List.Item>
          )
        }
      />
    </Card>
  );
};
