import { TAllCallLogs, TCallDuration, THourlyCallLogs, TTextLogs, TWeeklyCallLogs } from '@/types/TAnalytics';
import apiClient from '@/utils/apiClient';

const GET_ANALYTICS_API_PATH = '/api/v2/receptionist-dashboard/analytics/weekly-usage';
const GET_YEARLY_ANALYTICS_API_PATH = '/api/v2/receptionist-dashboard/analytics/yearly-usage';
const GET_TEXT_LOGS_ANALYTICS_API_PATH = '/api/v2/receptionist-dashboard/analytics/text-logs';

export const getWeeklyUsageData = async (query: {
  days?: number;
  startDate?: number;
  endDate?: number;
}): Promise<{
  weeklyCallLogs: TWeeklyCallLogs[];
  hourlyCallLogs: THourlyCallLogs[];
  callDuration: TCallDuration[];
}> => {
  try {
    let queryParamString = '?';

    if (query.days) {
      queryParamString += `days=${query.days}`;
    }

    if (query.startDate) {
      queryParamString += `&startDate=${query.startDate}`;
    }

    if (query.endDate) {
      queryParamString += `&endDate=${query.endDate}`;
    }

    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${GET_ANALYTICS_API_PATH}${queryParamString}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getYearlyUsageData = async (): Promise<TAllCallLogs> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${GET_YEARLY_ANALYTICS_API_PATH}`,
    });
    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getTextLogsAnalytics = async (): Promise<{ allTextLogs: TTextLogs[] }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${GET_TEXT_LOGS_ANALYTICS_API_PATH}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
