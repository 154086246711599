import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import './PreviewButton.scss';

interface props {
  children: React.ReactNode;
  title: string;
  setPreviewEmail: (val: boolean) => void;
}

const PreviewButton: React.FC<props> = ({ title, setPreviewEmail, children }) => {
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prevTime => {
        if (prevTime >= 6) {
          clearInterval(interval); // Stop the interval
          return prevTime; // Prevent updating beyond 6
        }
        return prevTime + 1;
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <Tooltip title={title}>
      <div
        className={`px-2 py-1 hover:bg-slate-200 cursor-pointer rounded-lg ${time < 6 ? 'preview-button' : ''} `}
        onClick={() => setPreviewEmail(true)}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default PreviewButton;
