import React from 'react';
import { Form, InputNumber, Select, Button, Typography, Row, Col, Card, Alert, Tooltip, Space, theme } from 'antd';
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import momentTimeZone from 'moment-timezone';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaignId } from '@/store/novi/user/selector';
import { notification } from 'antd';
import { CampaignSchedule } from '@/pages/Novi/types/Schedule';
import { useMutation } from '@tanstack/react-query';
import { createSchedule } from '@/api/novi/schedule';

const { Text } = Typography;
const { useToken } = theme;
const timeZoneList = momentTimeZone.tz.names();

const daysOptions = [
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thu' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' },
];

interface ScheduleInfoFormProps {
  existingSettings?: CampaignSchedule | null;
  onCancel: () => void;
  onSuccess: () => void;
}

const ScheduleInfoForm: React.FC<ScheduleInfoFormProps> = ({ existingSettings, onCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const campaignID = useAppSelector(getSelectedCampaignId);
  const { token } = useToken();

  const { mutate: createNewSchedule, isPending } = useMutation({
    mutationKey: ['create-schedule'],
    mutationFn: createSchedule,
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Schedule settings updated',
      });
      onSuccess();
    },
    onError: error =>
      notification.error({
        message: 'Error',
        description: error.message,
      }),
  });

  const timeOptions = Array.from({ length: 24 }, (_, idx) => {
    const hour = idx.toString().padStart(2, '0');
    return { value: `${hour}:00`, label: `${hour}:00` };
  });

  const onFinish = (values: CampaignSchedule) => {
    const payload = {
      schedule: {
        ...values,
        days: values.days.map((day: string) => parseInt(day, 10)),
      },
      campaign_id: campaignID as string,
    };
    createNewSchedule(payload);
  };

  const cardStyle = {
    borderRadius: token.borderRadiusLG,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.03)',
  };

  const innerCardStyle = {
    ...cardStyle,
    background: '#fafafa',
  };

  return (
    <Card
      style={cardStyle}
      styles={{
        body: {
          padding: token.padding,
        },
      }}
    >
      <Space direction='vertical' style={{ width: '100%' }} size='middle'>
        <Row justify='space-between' align='middle'>
          <Text strong style={{ fontSize: 16 }}>
            {existingSettings ? 'Edit Schedule Settings' : 'Create New Schedule'}
          </Text>
          <Space>
            {existingSettings && (
              <Button
                size='small'
                style={{
                  borderRadius: '6px',
                  padding: '0 12px',
                  height: '28px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
                onClick={onCancel}
                icon={<CloseOutlined style={{ fontSize: 12 }} />}
              >
                Cancel Edit
              </Button>
            )}
            <Button
              type='primary'
              size='small'
              style={{
                borderRadius: '6px',
                padding: '0 12px',
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
              onClick={() => form.submit()}
              loading={isPending}
              icon={<SaveOutlined style={{ fontSize: 12 }} />}
            >
              {existingSettings ? 'Save Changes' : 'Create Schedule'}
            </Button>
          </Space>
        </Row>

        <Alert
          type='info'
          message={
            <Space size={1} direction='vertical' style={{ fontSize: 12 }}>
              <Text strong>Quick Tips:</Text>
              <ul style={{ paddingLeft: 16, margin: '4px 0' }}>
                <li>Choose optimal audience availability times</li>
                <li>Account for international time zones</li>
                <li>Balance retry frequency with experience</li>
                <li>Follow local calling regulations</li>
              </ul>
            </Space>
          }
          style={{ borderRadius: token.borderRadiusLG }}
        />

        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
          initialValues={existingSettings || {}}
          size='small'
          style={{ width: '100%' }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Card
                size='small'
                title={<Text style={{ fontSize: 14 }}>Retry Configuration</Text>}
                style={innerCardStyle}
                styles={{
                  body: { padding: '12px 16px' },
                  header: { background: '#f0f0f0' },
                }}
              >
                <Form.Item
                  label={
                    <Space size={4}>
                      <Text style={{ fontSize: 12 }}>Maximum Retries</Text>
                      <Tooltip title='Number of times to attempt reaching a contact'>
                        <QuestionCircleOutlined style={{ fontSize: 12, color: token.colorTextSecondary }} />
                      </Tooltip>
                    </Space>
                  }
                  name='allowed_retries'
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <InputNumber min={1} max={5} style={{ width: '100%' }} size='small' />
                </Form.Item>

                <Form.Item
                  label={
                    <Space size={4}>
                      <Text style={{ fontSize: 12 }}>Hours Between Retries</Text>
                      <Tooltip title='Minimum waiting period between attempts'>
                        <QuestionCircleOutlined style={{ fontSize: 12, color: token.colorTextSecondary }} />
                      </Tooltip>
                    </Space>
                  }
                  name='time_between_retries'
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <InputNumber min={0.5} max={24} step={0.5} size='small' style={{ width: '100%' }} />
                </Form.Item>
              </Card>
            </Col>

            <Col xs={24} md={12}>
              <Card
                size='small'
                title={<Text style={{ fontSize: 14 }}>Time Zone</Text>}
                style={innerCardStyle}
                styles={{
                  body: { padding: '12px 16px' },
                  header: { background: '#f0f0f0' },
                }}
              >
                <Form.Item
                  label={
                    <Space size={4}>
                      <Text style={{ fontSize: 12 }}>Campaign Time Zone</Text>
                      <Tooltip title='Primary time zone for operations'>
                        <QuestionCircleOutlined style={{ fontSize: 12, color: token.colorTextSecondary }} />
                      </Tooltip>
                    </Space>
                  }
                  name='timezone'
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Select
                    showSearch
                    size='small'
                    options={timeZoneList.map(zone => ({ label: zone, value: zone }))}
                    filterOption={(input, option) =>
                      (option?.label as string).toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <Card
            size='small'
            title={<Text style={{ fontSize: 14 }}>Operating Hours</Text>}
            style={{ ...innerCardStyle, marginTop: 16 }}
            styles={{
              body: { padding: '12px 16px' },
              header: { background: '#f0f0f0' },
            }}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={
                    <Space size={4}>
                      <Text style={{ fontSize: 12 }}>Start Time</Text>
                      <Tooltip title='Daily call start time'>
                        <QuestionCircleOutlined style={{ fontSize: 12, color: token.colorTextSecondary }} />
                      </Tooltip>
                    </Space>
                  }
                  name='allowed_times_start'
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Select size='small' options={timeOptions} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={
                    <Space size={4}>
                      <Text style={{ fontSize: 12 }}>End Time</Text>
                      <Tooltip title='Daily call end time'>
                        <QuestionCircleOutlined style={{ fontSize: 12, color: token.colorTextSecondary }} />
                      </Tooltip>
                    </Space>
                  }
                  name='allowed_times_end'
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Select size='small' options={timeOptions} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label={
                <Space size={4}>
                  <Text style={{ fontSize: 12 }}>Active Days</Text>
                  <Tooltip title='Days when calls are allowed'>
                    <QuestionCircleOutlined style={{ fontSize: 12, color: token.colorTextSecondary }} />
                  </Tooltip>
                </Space>
              }
              name='days'
              rules={[{ required: true, message: 'Select at least one day' }]}
            >
              <Select mode='multiple' size='small' options={daysOptions} style={{ width: '100%' }} />
            </Form.Item>
          </Card>
        </Form>
      </Space>
    </Card>
  );
};

export default ScheduleInfoForm;
