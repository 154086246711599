import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import useAuth from '@/routes/hooks/useAuth';
import { Loading } from '@/components/Loading';
import { AppRoutes, UNBRANDED_DOMAINS } from 'src/enums/ERoutes';
import { useEffect } from 'react';
import { Page404 } from '@/pages/Page404';

const PrivateReceptionistRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();
  const domain = window.location.hostname;
  const [searchParams] = useSearchParams();

  const isUnbrandedPath =
    location.pathname === AppRoutes.UNBRANDED_RECEPTIONIST ||
    location.pathname === AppRoutes.UNBRANDED_DASHBOARD ||
    UNBRANDED_DOMAINS.some(unbrandedDomain => domain.includes(unbrandedDomain));

  const resellerCode = searchParams.get('reseller_code') || searchParams.get('code');
  const resellerCodeWL = searchParams.get('wlcode');

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      (location.pathname === AppRoutes.UNBRANDED_RECEPTIONIST || location.pathname === AppRoutes.UNBRANDED_DASHBOARD)
    ) {
      try {
        document.title = `Portal Login`;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `Dashboard/Portal login`);
        }
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
          pageTitle.textContent = `Portal Login`;
        }
      } catch (err) {
        console.error('Error updating page metadata:', err);
      }
    }
  }, [isUnbrandedPath]);

  useEffect(() => {
    if (domain === 'myaifrontdeskdashboard.com' && location.pathname === '/unbranded_receptionist') {
      const newUrl = new URL('https://www.aiphonereceptionist.com/dashboard');
      if (resellerCode) {
        newUrl.searchParams.set('code', resellerCode);
      }
      window.location.href = newUrl.toString();
    }
  }, [domain, location.pathname, resellerCode]);

  if (isLoading) {
    return <Loading />;
  }
  if (!isAuthenticated) {
    if (isUnbrandedPath && location.pathname === '/') {
      return <Page404 />;
    }
    if (resellerCodeWL) {
      return (
        <Navigate
          to={AppRoutes.RESELLER_PORTAL}
          state={{ from: location.pathname, debrand: isUnbrandedPath, resellerCode: resellerCodeWL }}
          replace
        />
      );
    }
    return <Navigate to='/login' state={{ from: location.pathname, debrand: isUnbrandedPath, resellerCode }} replace />;
  }

  return <Outlet context={{ debrand: isUnbrandedPath }} />;
};

export default PrivateReceptionistRoute;
