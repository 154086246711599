import { useState } from 'react';
import { Copy, Check, ChevronDown, ChevronRight } from 'lucide-react';

const CodeBlock = ({ code, title = 'Generated Script' }: { code: string; title: string }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(code);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className='w-full rounded-lg border border-gray-200 bg-white shadow-sm'>
      <div
        className='flex items-center justify-between px-4 py-2 border-b border-gray-200 cursor-pointer'
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className='flex items-center space-x-2'>
          {isExpanded ? (
            <ChevronDown className='w-4 h-4 text-gray-500' />
          ) : (
            <ChevronRight className='w-4 h-4 text-gray-500' />
          )}
          <span className='font-medium text-sm text-gray-700'>{title}</span>
        </div>
        <button
          onClick={e => {
            e.stopPropagation();
            handleCopy();
          }}
          className='flex items-center space-x-1 px-2 py-1 text-xs text-gray-500 hover:text-gray-700 hover:bg-gray-50 rounded transition-colors'
        >
          {isCopied ? <Check className='w-4 h-4' /> : <Copy className='w-4 h-4' />}
          <span>{isCopied ? 'Copied!' : 'Copy'}</span>
        </button>
      </div>
      {isExpanded && (
        <div className='p-4 bg-gray-50 overflow-x-auto'>
          <pre className='text-sm'>
            <code>{code}</code>
          </pre>
        </div>
      )}
    </div>
  );
};

export default CodeBlock;
