import { CheckCircleOutlined, DeleteOutlined, InfoCircleOutlined, TagOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Card, Empty, notification, Popconfirm, Tag, Tooltip, Typography } from 'antd';
import './RenderCouponInfo.scss';
import { useState } from 'react';

interface Coupon {
  id: string;
  name?: string;
  percentOff?: number;
  amountOff?: number;
  duration: 'repeating' | 'forever' | 'once';
  durationInMonths?: number;
  validUntil?: string;
  startedOn?: string;
  appliedOn?: string;
}

interface CouponInfoProps {
  appliedCoupons: Coupon[];
  onUpdate: () => void;
  removeDiscount: (coupon: { appliedOn: string }) => Promise<{ success: boolean; message?: string }>;
}

const { Title, Text } = Typography;

const RenderCouponInfo = ({ appliedCoupons, onUpdate, removeDiscount }: CouponInfoProps) => {
  const [loadingCouponId, setLoadingCouponId] = useState<string | null>(null);

  const removeCouponMutation = useMutation({
    mutationKey: ['remove-coupon'],
    mutationFn: removeDiscount,
    onSuccess: () => {
      notification.success({
        message: 'Coupon removed successfully',
      });
      setLoadingCouponId(null);
      onUpdate();
    },
    onError: () => {
      notification.error({
        message: 'Failed to remove coupon',
        description: 'Please try again or contact support',
      });
      setLoadingCouponId(null);
    },
  });

  const handleRemoveCoupon = (coupon: Coupon) => {
    setLoadingCouponId(coupon.id);
    removeCouponMutation.mutate({ appliedOn: coupon.appliedOn as string });
  };

  if (!appliedCoupons || appliedCoupons.length === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description='No active coupons'
        className='coupon-info__empty-state'
      />
    );
  }

  return (
    <div className='coupon-info'>
      {/* Header Section */}
      <div className='coupon-info__header-section'>
        <div className='coupon-info__header'>
          <Title level={4} className='coupon-info__header-title'>
            <TagOutlined />
            Active Discounts
            <Tag>{appliedCoupons.length} active</Tag>
          </Title>
        </div>
        <Text type='secondary' className='coupon-info__info-note'>
          <InfoCircleOutlined className='coupon-info__info-note-icon' />
          Note: Coupons will not apply to any invoice after the coupon's expiry.
        </Text>
      </div>
      {/* Coupons Grid */}
      <div className='coupon-info__grid'>
        {appliedCoupons.map((coupon: Coupon, index: number) => (
          <Card key={coupon.id || index} className='coupon-info__card' size='small'>
            <div className='coupon-info__card-content'>
              {/* Coupon Content */}
              <div>
                {/* Coupon Header */}
                <div className='coupon-info__card-header'>
                  <Text strong className='coupon-info__card-header-title'>
                    {coupon.name || coupon.id}
                  </Text>
                  <Tag color='success' icon={<CheckCircleOutlined />}>
                    Active
                  </Tag>
                </div>

                {/* Coupon Details Grid */}
                <div className='coupon-info__card-details'>
                  {/* Discount Info */}
                  <div className='coupon-info__card-detail-item'>
                    <Text type='secondary' className='coupon-info__card-detail-item-label'>
                      Discount
                    </Text>
                    <div className='coupon-info__card-detail-item-value'>
                      {coupon.percentOff
                        ? `${coupon.percentOff}% off`
                        : coupon.amountOff
                          ? `$${coupon.amountOff} off`
                          : 'N/A'}
                    </div>
                  </div>

                  {/* Duration Info */}
                  <div className='coupon-info__card-detail-item'>
                    <Text type='secondary' className='coupon-info__card-detail-item-label'>
                      Duration
                    </Text>
                    <div className='coupon-info__card-detail-item-value'>
                      {coupon.duration === 'repeating'
                        ? `${coupon.durationInMonths} months`
                        : coupon.duration === 'forever'
                          ? 'Forever'
                          : 'One time'}
                    </div>
                  </div>

                  {/* Started On Info */}
                  {coupon.startedOn && (
                    <div className='coupon-info__card-detail-item'>
                      <Text type='secondary' className='coupon-info__card-detail-item-label'>
                        Started on
                      </Text>
                      <div className='coupon-info__card-detail-item-value'>
                        {new Date(coupon.startedOn).toLocaleDateString()}
                      </div>
                    </div>
                  )}

                  {/* Valid Until Info */}
                  {coupon.validUntil && (
                    <div className='coupon-info__card-detail-item'>
                      <Text type='secondary' className='coupon-info__card-detail-item-label'>
                        Valid until
                      </Text>
                      <div className='coupon-info__card-detail-item-value'>
                        {new Date(coupon.validUntil).toLocaleDateString()}
                      </div>
                    </div>
                  )}

                  {/* Applied On Info */}
                  {coupon.appliedOn && (
                    <div className='coupon-info__card-detail-item'>
                      <Tooltip title='Indicates which subscription the coupon applies to: Voice Usage Subscription, Base Subscription, or both (if "Customer"). Customer-level coupons are ignored for subscriptions with an existing coupon.'>
                        <div className='coupon-info__card-detail-item-header'>
                          <Text type='secondary' className='coupon-info__card-detail-item-header-label'>
                            Applied on
                          </Text>
                          <InfoCircleOutlined className='coupon-info__card-detail-item-header-info-icon' />
                        </div>
                        <div className='coupon-info__card-detail-item-value'>{coupon.appliedOn}</div>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>

              {/* Remove Button */}
              <div className='coupon-info__card-actions'>
                <Popconfirm
                  title='Remove Coupon'
                  description='Are you sure you want to remove this coupon?'
                  onConfirm={() => handleRemoveCoupon(coupon)}
                  okText='Yes'
                  cancelText='No'
                  placement='left'
                >
                  <Button type='text' danger icon={<DeleteOutlined />} loading={loadingCouponId === coupon.id}>
                    <span>Remove</span>
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default RenderCouponInfo;
