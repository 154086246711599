import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  Button,
  message,
  Select,
  Table,
  Descriptions,
  Spin,
  Space,
  Carousel,
  Modal,
  Form,
  Input,
  Tooltip,
  Divider,
  notification,
} from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import {
  getImportedTwilioNumbers,
  removeClientNumber,
  assignClientNumber,
  UpdateResellerBranding,
} from '@/api/reseller';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ResellerDashboard from '../ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import { useGetResellerClients } from 'src/Hooks/resellerHooks';
import { CustomModal } from '@/components/CustomModal';
import { PlayCircleOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/enums/ERoutes';

const { Title, Text } = Typography;
const { Option } = Select;

interface TwilioNumber {
  phoneNumber: string;
  friendlyName: string;
  sid: string;
  dateCreated: string;
  capabilities: {
    fax: boolean;
    voice: boolean;
    sms: boolean;
    mms: boolean;
  };
}

const ImportPhoneNumbers: React.FC = () => {
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedNumber, setSelectedNumber] = useState<string | null>(null);
  const [isConfirmationModalActive, setIsConfirmationModalActive] = useState<boolean>(false);
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const [showTwilioAccountId, setShowTwilioAccountId] = useState<boolean>(false);
  const [showTwilioAccessToken, setShowTwilioAccessToken] = useState<boolean>(false);
  const [addTwilioCredentials, setAddTwilioCredentials] = useState<boolean>(false);

  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const mutation = useMutation({
    mutationKey: ['updateResellerBranding'],
    mutationFn: UpdateResellerBranding,
    onError(data) {
      notification.error({
        message: data.message,
      });
    },
    onSuccess(data) {
      if (data.success) {
        queryClient.invalidateQueries({ queryKey: ['reseller'] });
        notification.success({
          key: 'update-reseller',
          message: 'Updated successfully',
          duration: 3,
        });
      }
    },
  });

  const handleSubmit = async (values: { twilioAccessToken: string; twilioAccountId: string }) => {
    await mutation.mutate({
      emailAddress: resellerData.email_address,
      twilioAccessToken: values.twilioAccessToken,
      twilioAccountId: values.twilioAccountId,
    });
  };

  const resellerData = useAppSelector(getResellerData);

  // set twiio keys if they exist
  useEffect(() => {
    if (resellerData) {
      form.setFieldsValue({
        twilioAccountId: resellerData.twilio_account_id,
        twilioAccessToken: resellerData.twilio_access_token,
      });
      if (!resellerData.twilio_access_token || !resellerData.twilio_account_id) {
        setAddTwilioCredentials(true);
      } else {
        setAddTwilioCredentials(false);
      }
    }
  }, [resellerData, form, resellerData.twilio_access_token, resellerData.twilio_account_id]);

  const getClientsMutation = useGetResellerClients({
    shouldFetchReservedNumbers: true,
    onSuccess: data => {
      setClients(data.items);
    },
  });

  const {
    data: twilioNumberData,
    isFetching: isLoadingTwilioNumbers,
    refetch: refetchTwilioNumbers,
  } = useQuery<{ numbers: TwilioNumber[] }>({
    queryKey: ['twilioNumbers', resellerData?.email_address],
    queryFn: getImportedTwilioNumbers,
    enabled: !!resellerData?.email_address,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const updateLocalClients = (clientUsername: string, newReservedNumber: string | null) => {
    setClients(prevClients =>
      prevClients.map(client =>
        client.number === clientUsername ? { ...client, reservedNumber: newReservedNumber } : client
      )
    );
  };

  const resetForm = () => {
    setSelectedClient(null);
    setSelectedNumber(null);
  };

  const removeClientNumberMutation = useMutation({
    mutationFn: removeClientNumber,
    onSuccess: (_, variables) => {
      updateLocalClients(variables.clientUsername, null);
      message.success('Number removed successfully');
      setIsConfirmationModalActive(false);
      resetForm();
    },
    onError: () => {
      message.error('Error removing the phone number');
    },
  });

  const assignClientNumberMutation = useMutation({
    mutationFn: assignClientNumber,
    onSuccess: (_, variables) => {
      updateLocalClients(variables.clientUsername, variables.clientNumber);
      message.success('Number assigned successfully');
      resetForm();
    },
    onError: () => {
      message.error('Error assigning the phone number');
    },
  });

  const handleClientSelect = (value: string) => {
    const selected = clients.find(client => client.number === value);
    setSelectedClient(selected);
    setSelectedNumber(null);
  };

  const handleNumberSelect = (value: string) => {
    setSelectedNumber(value);
  };

  const handleApply = () => {
    if (!selectedNumber || !selectedClient?.number) {
      message.error('Please select a sub-account and a number');
      return;
    }
    assignClientNumberMutation.mutate({
      clientUsername: selectedClient.number,
      clientNumber: selectedNumber,
    });
  };

  const handleRemoveNumber = () => {
    if (!selectedClient?.number || !selectedClient?.reservedNumber) {
      message.error('Invalid operation');
      return;
    }
    removeClientNumberMutation.mutate({
      clientUsername: selectedClient.number,
      clientNumber: selectedClient.reservedNumber,
    });
  };

  const handleRefreshClients = () => {
    if (resellerData.email_address) {
      getClientsMutation.mutate(resellerData.email_address);
    }
  };
  const handleVideoModal = () => {
    setShowVideoModal(!showVideoModal);
  };

  const handleRefreshNumbers = () => {
    refetchTwilioNumbers();
  };

  const handlePhoneNumbersNavigation = () => {
    navigate(AppRoutes.RESELLER_PHONE_NUMBERS);
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Email',
      dataIndex: 'email_address',
      key: 'email_address',
    },
    {
      title: 'Reserved Number',
      dataIndex: 'reservedNumber',
      key: 'reservedNumber',
    },
  ];

  React.useEffect(() => {
    if (resellerData.email_address) {
      getClientsMutation.mutate(resellerData.email_address);
    }
  }, [resellerData.email_address]);

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.IMPORT_NUMBER}>
      <Space direction='vertical' className='p-6 pt-0'>
        <Title level={2} className='text-center mb-12 text-4xl font-bold text-blue-600'>
          Twilio Setup
        </Title>
        <Title level={3}>Why Twilio Integration?</Title>
        <Text className='!text-red-600'>
          Note : This section of your admin dashboard is optional and is only required if you want to import your own
          phone numbers from your own Twilio account. If you do not have a Twilio account, you can skip this section.
          And head over to by clicking on {'->'}
          <Button type='link' onClick={handlePhoneNumbersNavigation} className=' text-blue-600 hover:text-blue-800'>
            (Phone Numbers)
          </Button>
        </Text>
        <Text className='text-gray-700'>
          Our platform leverages Twilio, a leading communication platform, to enable features like SMS messaging, voice
          calls, and more. By integrating your own Twilio credentials, you can unlock these powerful capabilities within
          our application.
        </Text>
        <a
          className='text-blue-600'
          href='https://help.twilio.com/articles/14726256820123-What-is-a-Twilio-Account-SID-and-where-can-I-find-it-'
          target='_blank'
          rel='noreferrer'
        >
          <span className='text-blue-600 font-bold'>official twilio docs</span>
        </a>
        <Button
          type='link'
          icon={<PlayCircleOutlined className='text-blue-600' />}
          onClick={handleVideoModal}
          className='mt-4 text-blue-600 hover:text-blue-800'
        >
          Watch Video Overview
        </Button>
        <Divider className='border-gray-300' />

        {addTwilioCredentials ? (
          <Space direction='vertical'>
            <Title level={3} className='text-blue-600'>
              Twilio Account Details
            </Title>
            <Text className='text-gray-700'>
              To use our Twilio integration, you'll need to provide your Twilio Account ID and Twilio Access Token.
              These credentials allow our platform to securely connect to your Twilio account and utilize its features.
              <Tooltip title='Twilio is a communication platform that allows you to send SMS messages, make phone calls, and more. By providing your own Twilio credentials, you can leverage these features within our application.'>
                <QuestionCircleOutlined className='ml-2 text-gray-500 cursor-pointer' />
              </Tooltip>
            </Text>
            <Space direction='vertical'>
              <Form form={form} onFinish={handleSubmit} layout='vertical' className='space-y-6'>
                <Text className='text-lg font-semibold text-gray-800'>Twilio Account ID</Text>
                <Form.Item
                  label='Twilio account SID'
                  name='twilioAccountId'
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value && /\s/.test(value)) {
                          return Promise.reject('Spaces are not allowed');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.Password
                    size='large'
                    placeholder='Enter your Twilio Account ID '
                    iconRender={visible =>
                      visible ? (
                        <EyeOutlined className='text-gray-500' />
                      ) : (
                        <EyeInvisibleOutlined className='text-gray-500' />
                      )
                    }
                    visibilityToggle={{
                      visible: showTwilioAccountId,
                      onVisibleChange: setShowTwilioAccountId,
                    }}
                    className='rounded-lg'
                  />
                </Form.Item>
                <Text className='text-lg font-semibold text-gray-800'>Twilio Access Token</Text>
                <Form.Item
                  label='Twilio auth token'
                  name='twilioAccessToken'
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value && /\s/.test(value)) {
                          return Promise.reject('Spaces are not allowed');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.Password
                    size='large'
                    placeholder='Enter your Twilio Access Token'
                    iconRender={visible =>
                      visible ? (
                        <EyeOutlined className='text-gray-500' />
                      ) : (
                        <EyeInvisibleOutlined className='text-gray-500' />
                      )
                    }
                    visibilityToggle={{
                      visible: showTwilioAccessToken,
                      onVisibleChange: setShowTwilioAccessToken,
                    }}
                    className='rounded-lg'
                  />
                </Form.Item>
                <Space direction='horizontal'>
                  <Button type='default' size='large' onClick={() => setAddTwilioCredentials(false)}>
                    Cancel
                  </Button>
                  <Button
                    type='primary'
                    size='large'
                    loading={mutation.isPending}
                    onClick={() => handleSubmit(form.getFieldsValue())}
                  >
                    Save Changes
                  </Button>
                </Space>
              </Form>
            </Space>
          </Space>
        ) : (
          <div className='max-w-5xl'>
            <Button
              type='link'
              onClick={() => setAddTwilioCredentials(true)}
              className=' text-blue-600 hover:text-blue-800'
            >
              Change twilio credentials?
            </Button>
            <Card title='Assign Phone Number' className='my-3'>
              <div className='grid grid-cols-3 gap-4'>
                <div>
                  <Text strong className='block mb-2'>
                    Sub-Account
                  </Text>
                  <div className='flex gap-2 cursor-pointer'>
                    {getClientsMutation.isPending ? (
                      <div className='flex justify-center flex-1'>
                        <Spin />
                      </div>
                    ) : (
                      <>
                        <Select
                          showSearch
                          className='flex-1'
                          placeholder='Select a sub-account'
                          optionFilterProp='children'
                          onChange={handleClientSelect}
                          value={selectedClient?.number}
                          loading={getClientsMutation.isPending}
                          filterOption={(input, option) =>
                            (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                          }
                        >
                          {clients.map(client => (
                            <Option key={client.number} value={client.number}>
                              {client.number}
                            </Option>
                          ))}
                        </Select>
                        <Button
                          icon={<ReloadOutlined />}
                          onClick={handleRefreshClients}
                          loading={getClientsMutation.isPending}
                          size='middle'
                        />
                      </>
                    )}
                  </div>
                  {selectedClient && (
                    <Descriptions column={1} size='small' className='mt-4'>
                      <Descriptions.Item label='Email'>{selectedClient.email_address}</Descriptions.Item>
                      {selectedClient.reservedNumber && (
                        <Descriptions.Item label='Current Number'>{selectedClient.reservedNumber}</Descriptions.Item>
                      )}
                    </Descriptions>
                  )}
                </div>
                <div>
                  <Text strong className='block mb-2'>
                    Action
                  </Text>
                  {selectedClient &&
                    (selectedClient.reservedNumber ? (
                      <Button danger onClick={() => setIsConfirmationModalActive(true)}>
                        Remove Number
                      </Button>
                    ) : (
                      <>
                        <div className='flex gap-2'>
                          {isLoadingTwilioNumbers ? (
                            <div className='flex justify-center flex-1'>
                              <Spin />
                            </div>
                          ) : (
                            <>
                              <Select
                                showSearch
                                className='flex-1'
                                placeholder='Select a phone number'
                                optionFilterProp='children'
                                onChange={handleNumberSelect}
                                value={selectedNumber}
                                filterOption={(input, option) =>
                                  (option?.children as unknown as string)
                                    .toLowerCase()
                                    .includes(input.replace(/\s|-|\(|\)/g, '').toLowerCase())
                                }
                              >
                                {twilioNumberData?.numbers?.map(number => (
                                  <Option key={number.sid} value={number.phoneNumber}>
                                    {number.phoneNumber}
                                  </Option>
                                ))}
                              </Select>
                              <Button
                                icon={<ReloadOutlined />}
                                onClick={handleRefreshNumbers}
                                loading={isLoadingTwilioNumbers}
                                size='middle'
                              />
                            </>
                          )}
                        </div>

                        {selectedNumber && (
                          <Button
                            type='primary'
                            onClick={handleApply}
                            className='mt-3'
                            loading={assignClientNumberMutation.isPending}
                          >
                            Apply Changes
                          </Button>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </Card>

            <Card title='Sub Accounts' className='mt-6'>
              <Table
                dataSource={clients}
                columns={columns}
                rowKey='number'
                loading={getClientsMutation.isPending}
                pagination={{
                  total: clients.length,
                  pageSize: 10,
                  showTotal: total => `Total ${total} items`,
                }}
              />
            </Card>
          </div>
        )}
      </Space>

      <Modal open={showVideoModal} onCancel={handleVideoModal} footer={null} width={800} className='video-modal'>
        <Title level={3} className='text-center text-blue-600'>
          Twilio Integration Overview
        </Title>
        <Carousel autoplay>
          <iframe
            width='100%'
            height='400'
            src='https://www.loom.com/share/de9348ff74c94de49e75551a4543d81a'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </Carousel>
      </Modal>

      {/* <CustomModal
        title='Twilio information required'
        isModalOpen={isTwilioDetailsModalActive}
        confirmAction={() => navigate(AppRoutes.RESELLER_BRANDING)}
        cancelAction={() => {}} // Empty function to prevent closing on cancel
        cancelButtonProps={{ style: { display: 'none' } }} // Hide the cancel button
        closable={false} // Prevent closing by clicking outside or pressing ESC
        maskClosable={false}
      >
        <div className=''>
          <p>
            Please head over to the{' '}
            <Link to={AppRoutes.RESELLER_BRANDING}>
              <span className='text-blue-600 font-bold'>"Branding"</span>
            </Link>{' '}
            tab to add your twilio config
          </p>
          <Title level={4}>Where to find Twilio Account SID and Auth Token?</Title>
          <p className=''>
            For the instructions, see the{' '}
            <a
              className='text-blue-600'
              href='https://help.twilio.com/articles/14726256820123-What-is-a-Twilio-Account-SID-and-where-can-I-find-it-'
              target='_blank'
              rel='noreferrer'
            >
              <span className='text-blue-600 font-bold'>official twilio docs</span>
            </a>
          </p>
          {resellerData?.permanently_unbranded || resellerData?.branded ? (
            ''
          ) : (
            <>
              <p className='font-bold text-center'>OR</p>
              <p>
                Follow this{' '}
                <a
                  className='text-blue-600'
                  href='https://www.loom.com/share/de9348ff74c94de49e75551a4543d81a'
                  target='_blank'
                  rel='noreferrer'
                >
                  video
                </a>
              </p>
            </>
          )}
        </div>
      </CustomModal> */}
      <CustomModal
        title='Are you sure?'
        visible={isConfirmationModalActive}
        cancelAction={() => {
          setIsConfirmationModalActive(false);
          setSelectedNumber(null);
        }}
        footer={[
          <Button
            key='back'
            onClick={() => {
              setIsConfirmationModalActive(false);
              setSelectedNumber(null);
            }}
          >
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            danger
            onClick={handleRemoveNumber}
            loading={removeClientNumberMutation.isPending}
          >
            Remove
          </Button>,
        ]}
      >
        <p className=''>
          Are you sure you want to remove the phone number? This will disassociate the phone number from the account
          until to assign it back
        </p>
      </CustomModal>
    </ResellerDashboard>
  );
};

export default ImportPhoneNumbers;
