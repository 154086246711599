import apiClient from '@/utils/apiClient';

const FETCH_CAMPAIGNS = '/api/novi/campaigns';
const FETCH_RECEPTIONIST_TOKEN = '/api/novi/campaigns/:campaign_id/token';
const UPDATE_CAMPAIGN_NAME = '/api/novi/campaigns/name';
const DUPLICATE_CAMPAIGN = '/api/novi/campaigns/duplicate';

const NOVI = 'NOVI';

export const getNoviCampaigns = async (): Promise<{ campaigns: any[] }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      clientType: NOVI,
      endPoint: FETCH_CAMPAIGNS,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const getCampaignToken = async (campaign_id: string): Promise<{ success: boolean; auth_token: string }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      clientType: NOVI,
      endPoint: FETCH_RECEPTIONIST_TOKEN.replace(':campaign_id', campaign_id),
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const updateCampaignName = async (payload: {
  campaign_id: string;
  business_name: string;
}): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      clientType: NOVI,
      endPoint: UPDATE_CAMPAIGN_NAME,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const duplicateACampaign = async (payload: {
  campaign_id: string;
  business_name: string;
}): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      clientType: NOVI,
      endPoint: DUPLICATE_CAMPAIGN,
      body: payload,
    });

    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};
