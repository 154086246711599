import React, { useState, useEffect, useRef } from 'react';
import { Device, Call } from '@twilio/voice-sdk';
import { Mic, ArrowDown, Phone } from 'lucide-react';
import { Modal, Button } from 'antd';
import { getLoggedInUser } from '@/store/account/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { RECEPTIONIST_NUMBER, RECEPTIONIST_NUMBER_US_RESELLER } from 'src/constants';

interface TokenResponse {
  token: string;
}

const KeyPadButton: React.FC<{
  value: string;
  subText?: string;
  onClick: (value: string) => void;
}> = ({ value, subText, onClick }) => (
  <button
    className='w-20 h-20 rounded-full bg-gradient-to-br from-white to-blue-50 
               border border-blue-100 shadow-md hover:shadow-lg transition-all
               flex flex-col items-center justify-center group'
    onClick={() => onClick(value)}
  >
    <span className='text-2xl font-semibold text-blue-600'>{value}</span>
    {subText && <span className='text-xs text-blue-400 group-hover:text-blue-500'>{subText}</span>}
  </button>
);

const CallComponent: React.FC<{ handleStopAudio: () => void }> = ({ handleStopAudio }) => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const device = useRef<Device | null>(null);
  const call = useRef<Call | null>(null);
  const [status, setStatus] = useState<string>('');
  const [isStarted, setIsStarted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isKeypadOpen, setIsKeypadOpen] = useState(false);

  useEffect(() => {
    const setupDevice = async () => {
      try {
        setStatus('Please wait while we setup your device...');
        const response = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/playground/token`);
        if (!response.ok) {
          throw new Error(`Failed to fetch token: ${response.statusText}`);
        }
        const data: TokenResponse = await response.json();

        if (!data.token) {
          throw new Error('No token received from server');
        }

        const newDevice = new Device(data.token, {
          edge: ['ashburn', 'dublin', 'sydney'],
        });

        device.current = newDevice;

        // Set up event listeners
        newDevice.on('ready', () => {
          setStatus('Device ready');
          setError(null);
        });

        newDevice.on('error', (error: Error) => {
          console.error('Device error:', error);
          setStatus('Error occurred');
          setError(error.message || 'An unknown error occurred');
          setIsStarted(false);
        });

        newDevice.on('connect', (callInstance: Call) => {
          setStatus('Call connected');
          call.current = callInstance;
          setError(null);

          callInstance.on('warning', (warning: string) => {
            console.warn('Call warning:', warning);
          });

          callInstance.on('disconnect', () => {
            setStatus('Call ended');
            call.current = null;
            setIsStarted(false);
          });

          callInstance.on('cancel', () => {
            setStatus('Call ended');
            call.current = null;
            setIsStarted(false);
          });

          callInstance.on('error', () => {
            setStatus('Call errored Out');
            call.current = null;
            setIsStarted(false);
          });

          callInstance.on('reject', () => {
            setStatus('Call rejected');
            call.current = null;
            setIsStarted(false);
          });
        });

        await newDevice.register();
        if (!isStarted) setStatus('Click the mic to start the call');
      } catch (error) {
        console.error('Error setting up device:', error);
        setStatus('Setup failed');
        setError(error instanceof Error ? error.message : 'Failed to set up device');
        setIsStarted(false);
      }
    };

    setupDevice();
  }, []);

  useEffect(() => {
    return () => {
      if (device.current) {
        device.current.destroy();
      }
      if (call.current) {
        call.current.disconnect();
      }
    };
  }, []);

  const handleStartAudio = async () => {
    if (isStarted) {
      if (call.current) call.current.disconnect();
      else handleStopAudio();
      setIsStarted(false);
      return;
    }

    setIsStarted(true);
    setError(null);

    try {
      if (!device.current) {
        throw new Error('Device not initialized');
      }

      setStatus('Initiating call...');
      let params;
      if (loggedInUser.provisionedNumber)
        params = {
          To: loggedInUser.provisionedNumber,
          Called: loggedInUser.provisionedNumber,
          From: loggedInUser.provisionedNumber,
          Direction: 'outbound',
        };
      else if (loggedInUser.testing_number)
        if (loggedInUser.reseller_email)
          params = {
            To: RECEPTIONIST_NUMBER_US_RESELLER,
            Called: RECEPTIONIST_NUMBER_US_RESELLER,
            From: loggedInUser.testing_number,
            Direction: 'outbound',
          };
        else
          params = {
            To: RECEPTIONIST_NUMBER,
            Called: RECEPTIONIST_NUMBER,
            From: loggedInUser.testing_number,
            Direction: 'outbound',
          };

      // @ts-ignore
      params.websiteCalling = true;

      const callInstance = await device.current.connect({ params });

      if (!callInstance) {
        throw new Error('Failed to create call instance');
      }

      call.current = callInstance;
    } catch (error) {
      console.error('Error initiating call:', error);
      setStatus('Call failed');
      setError(error instanceof Error ? error.message : 'Failed to initiate call');
      setIsStarted(false);
    }
  };

  const handleKeypadPress = (digit: string) => {
    if (call?.current) {
      call.current.sendDigits(digit);
    }
  };

  const keypadButtons = [
    { value: '1' },
    { value: '2', subText: 'ABC' },
    { value: '3', subText: 'DEF' },
    { value: '4', subText: 'GHI' },
    { value: '5', subText: 'JKL' },
    { value: '6', subText: 'MNO' },
    { value: '7', subText: 'PQRS' },
    { value: '8', subText: 'TUV' },
    { value: '9', subText: 'WXYZ' },
    { value: '*' },
    { value: '0', subText: '+' },
    { value: '#' },
  ];

  return (
    <div className='space-y-6 min-h-[50vh] flex flex-col justify-center items-center'>
      <div className='text-center space-y-2 mb-14'>
        <div className='text-lg font-medium'>{status}</div>
        {error && <div className='text-red-500 text-sm'>Error: {error}</div>}
      </div>

      <div
        className={`flex flex-col items-center gap-4 ${
          status === 'Please wait while we setup your device...' ? 'grayscale pointer-events-none' : ''
        }`}
      >
        <div
          className='relative cursor-pointer'
          onClick={handleStartAudio}
          role='button'
          tabIndex={0}
          aria-label={isStarted ? 'Stop call' : 'Start call'}
        >
          {/* Multiple pulsing rings */}
          <div
            className={`absolute -inset-4 h-40 w-40 rounded-full bg-gradient-to-r from-blue-400/20 to-purple-500/20 ${isStarted && 'animate-[pulse_2s_ease-in-out_infinite]'}`}
          />
          <div
            className={`absolute -inset-2 h-36 w-36 rounded-full bg-gradient-to-r from-blue-500/30 to-purple-600/30 ${isStarted && 'animate-[pulse_2s_ease-in-out_infinite]'}`}
          />

          {/* Glowing effect behind the mic */}
          <div className='absolute inset-0 h-32 w-32 rounded-full bg-gradient-to-r from-blue-400 to-purple-500 blur-xl opacity-20' />

          {/* Main mic circle with gradient border */}
          <div
            className='h-32 w-32 rounded-full bg-gradient-to-br from-white to-blue-50 flex items-center justify-center relative 
                         shadow-lg border border-white backdrop-blur-sm
                         before:absolute before:inset-0 before:rounded-full before:bg-gradient-to-br before:from-blue-400/20 before:to-purple-500/20'
          >
            <Mic size={48} className='text-blue-600 drop-shadow-md' />
          </div>

          {/* Decorative dots */}
          <div
            className={`absolute -top-2 -right-2 h-4 w-4 rounded-full bg-purple-400 blur-[1px] ${isStarted && 'animate-pulse'}`}
          />
          <div
            className={`absolute top-32 -left-1 h-3 w-3 rounded-full bg-blue-400 blur-[1px] ${isStarted && 'animate-pulse'} delay-150`}
          />

          {/* Arrow and text with enhanced styling */}
          <div className='mt-14 flex flex-col items-center'>
            <div className='relative'>
              <ArrowDown className='animate-bounce mb-2 text-blue-600 drop-shadow-md' />
              <div className='absolute inset-0 text-purple-500 animate-bounce mb-2 blur-sm opacity-50' />
            </div>
            <span
              className='text-sm font-medium whitespace-nowrap bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent
                           drop-shadow-sm'
            >
              Click to {isStarted ? 'stop' : 'start'}
            </span>
          </div>
        </div>

        {/* Keypad Button */}
        {isStarted && (
          <Button
            type='dashed'
            icon={<Phone className='w-4 h-4 mr-2' />}
            onClick={() => setIsKeypadOpen(true)}
            className='mt-4'
          >
            Open Keypad
          </Button>
        )}
      </div>

      {/* Ant Design Modal for Keypad */}
      <Modal
        title='Keypad'
        open={isKeypadOpen}
        onCancel={() => setIsKeypadOpen(false)}
        footer={null}
        centered
        width={400}
      >
        <div className='grid grid-cols-3 gap-4 p-4'>
          {keypadButtons.map(button => (
            <KeyPadButton
              key={button.value}
              value={button.value}
              subText={button.subText}
              onClick={handleKeypadPress}
            />
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default CallComponent;
