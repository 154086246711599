import React, { useMemo } from 'react';
import { Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getResellerByCode, getResellerLogo } from '@/api/unbrandedReceptionist';

interface props {
  debrand: boolean;
  resellerCode: string;
}

const ResellerImage: React.FC<props> = ({ debrand, resellerCode }) => {
  const { data } = useQuery({
    queryKey: ['reseller-code', resellerCode],
    queryFn: () => getResellerByCode({ resellerCode: resellerCode || '' }),
    enabled: !!(debrand && resellerCode),
  });

  const { data: resellerLogo, isLoading } = useQuery({
    queryKey: ['reseller-logo'],
    queryFn: () => getResellerLogo({ key: data.reseller.logo_s3 }),
    enabled: !!(debrand && data?.reseller?.logo_s3),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const resellerLogoUrl = useMemo(() => {
    if (!resellerLogo) return;

    return URL.createObjectURL(resellerLogo);
  }, [resellerLogo]);

  return (
    <div className=' flex justify-center items-center'>
      {isLoading ? (
        <Spin className='mt-20' />
      ) : resellerLogoUrl ? (
        <img
          style={{
            width: `${data?.reseller?.logoDimensions?.width?.N || 64}px`,
            height: `${data?.reseller?.logoDimensions?.height?.N || 64}px`,
            objectFit: 'contain',
          }}
          src={resellerLogoUrl}
          alt='logo'
        />
      ) : (
        <h1> {data?.reseller && data?.reseller?.reseller_name} </h1>
      )}
    </div>
  );
};

export default ResellerImage;
