import { Typography, Card, Space, Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './stepCard.scss';

// Reusable Step Card Component
interface StepCardProps {
  integrationType: string;
  title: string;
  description: string;
  product: string;
  integration: string;
  productLogo?: string;
  integrationLogo?: string;
  showTrigger?: boolean;
  showAction?: boolean;
  showWorkflow?: boolean;
}

interface IntegrationStepsProps {
  integrationType: string;
  product: string;
  integration: string;
  productLogo: string;
  integrationLogo: string;
  step1: string;
  step2: string;
  step3: string;
}

const { Title, Text } = Typography;

const StepCard = ({
  integrationType,
  title,
  description,
  product,
  integration,
  productLogo,
  integrationLogo,
  showTrigger,
  showAction,
  showWorkflow,
}: StepCardProps) => (
  <Row gutter={[24, 24]} align='middle' className='step-row'>
    <Col xs={24} md={12}>
      <div className='visual-container'>
        {showTrigger && (
          <Card className='step-card'>
            <Space size={16}>
              {productLogo ? (
                <img src={productLogo} alt={product} className='step-logo' />
              ) : (
                <div className='logo-placeholder primary' />
              )}
              <div>
                <Text type='secondary' className='step-label'>
                  Step 1
                </Text>
                <Text>
                  {integrationType === 'inbound' ? `${product} answers the phone` : `${product} detects a trigger`}
                </Text>
              </div>
            </Space>
          </Card>
        )}

        {showAction && (
          <>
            <DownOutlined className='step-arrow' />
            <Card className='step-card'>
              <Space size={16}>
                {integrationLogo ? (
                  <img src={integrationLogo} alt={integration} className='step-logo' />
                ) : (
                  <div className='logo-placeholder secondary' />
                )}
                <div>
                  <Text type='secondary' className='step-label'>
                    Step 2
                  </Text>
                  <Text>Sends data to {integration}</Text>
                </div>
              </Space>
            </Card>
          </>
        )}

        {showWorkflow && (
          <>
            <DownOutlined className='step-arrow' />
            <div className='workflow-badge'>
              <Text strong className='workflow-text'>
                Automated workflow
              </Text>
            </div>
          </>
        )}
      </div>
    </Col>

    <Col xs={24} md={12}>
      <div className='content-container'>
        <Title level={2} className='step-title'>
          {title}
        </Title>
        <Text type='secondary'>{description}</Text>
      </div>
    </Col>
  </Row>
);

const IntegrationSteps = ({
  integrationType,
  product,
  integration,
  productLogo,
  integrationLogo,
  step1,
  step2,
  step3,
}: IntegrationStepsProps) => {
  return (
    <div className='integration-steps'>
      <div className='steps-container'>
        <StepCard
          integrationType={integrationType}
          title={`Select a trigger from ${product}`}
          description={`A trigger is an event that initiates the automation. ${step1}`}
          product={product}
          integration={integration}
          productLogo={productLogo}
          integrationLogo={integrationLogo}
          showTrigger
        />

        <StepCard
          integrationType={integrationType}
          title={`Setup an action from ${integration}`}
          description={`An action is what takes place after the automation is triggered. ${step2}`}
          product={product}
          integration={integration}
          productLogo={productLogo}
          integrationLogo={integrationLogo}
          showTrigger
          showAction
        />

        <StepCard
          integrationType={integrationType}
          title={`That's it! You just connected ${product} to ${integration}`}
          description={`${step3}`}
          product={product}
          integration={integration}
          productLogo={productLogo}
          integrationLogo={integrationLogo}
          showTrigger
          showAction
          showWorkflow
        />
      </div>
    </div>
  );
};

export default IntegrationSteps;
