import { Avatar, Button, Col, Dropdown, Layout, Row, Space, Typography, theme } from 'antd';
import SiderMenu from './Sidebar/Sidebar';
import NoviContent from './Content/Content';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { setNoviCurrentPage, setNoviSelectedCampaignId } from '@/store/novi/user/slice';
import { ENoviDashboardSider } from '../../enums/ENoviDashboardSider';
import { getLoggedInNoviUser, getNoviCurrentPage, getSelectedCampaignId } from '@/store/novi/user/selector';
import { useEffect } from 'react';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';
import { Header } from 'antd/es/layout/layout';
import { ArrowLeftOutlined, LogoutOutlined } from '@mui/icons-material';
import { UserOutlined } from '@ant-design/icons';
import LocalStorageUtility from '@/utils/localStorage';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/enums/ERoutes';

const { Content } = Layout;
const { useToken } = theme;
const { Title } = Typography;

export const NoviDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPage = useAppSelector(getNoviCurrentPage);
  const selectedCampaignId = useAppSelector(getSelectedCampaignId);
  const noviUser = useAppSelector(getLoggedInNoviUser);
  const { isMobile } = useMobileDeviceCheck();
  const { token } = useToken();

  const handleMenuSelect = (key: ENoviDashboardSider) => {
    dispatch(setNoviCurrentPage(key));
  };

  const handleBack = () => {
    dispatch(setNoviSelectedCampaignId(null));
  };

  const handleLogout = () => {
    LocalStorageUtility.clearLocalData();
    dispatch(setNoviSelectedCampaignId(null));
    navigate(AppRoutes.NOVI_PORTAL_LOGIN);
    return;
  };

  useEffect(() => {
    if (selectedCampaignId) {
      dispatch(setNoviCurrentPage(ENoviDashboardSider.LEADS));
    } else {
      dispatch(setNoviCurrentPage(ENoviDashboardSider.MY_CAMPAIGNS));
    }
  }, [selectedCampaignId]);

  return (
    <Layout>
      <SiderMenu
        currentPage={currentPage}
        handleMenuSelect={handleMenuSelect}
        selectedCampaignId={selectedCampaignId}
        noviUser={noviUser}
        isMobile={isMobile}
      />
      <Layout>
        <Header>
          <Row justify='space-between' align='middle' style={{ width: '100%', height: '100%' }}>
            <Col>
              <Space align='center'>
                {selectedCampaignId && (
                  <Button
                    onClick={handleBack}
                    type='default'
                    style={{
                      border: 'none',
                      background: token.colorBgTextHover,
                      borderRadius: token.borderRadiusLG,
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 16px',
                    }}
                  >
                    <Space>
                      <ArrowLeftOutlined style={{ fontSize: '14px' }} />
                      <Title
                        level={5}
                        style={{
                          margin: 0,
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        My Campaigns
                      </Title>
                    </Space>
                  </Button>
                )}
              </Space>
            </Col>

            <Col>
              <Space align='center' size={24}>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'logout',
                        label: 'Logout',
                        icon: <LogoutOutlined style={{ fontSize: '14px' }} />,
                        onClick: handleLogout,
                      },
                    ],
                  }}
                  placement='bottomRight'
                  trigger={['click']}
                >
                  <Avatar
                    size={40}
                    style={{
                      backgroundColor: '#ffffff',
                    }}
                    icon={noviUser.logo ? <img src={noviUser.logo} alt='logo' /> : <UserOutlined />}
                  />
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Header>

        <Content>
          <NoviContent currentPage={currentPage} />
        </Content>
      </Layout>
    </Layout>
  );
};
