import React, { useState } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { List, Tooltip } from 'antd';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import './OnboardingStepsList.scss';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';

const stepLabels = [
  'Register 📝',
  'Test Receptionist ⚙️',
  'Take Tour 🔎',
  'Explore Dashboard 🧭',
  '(Optional) Book a Free Onboarding Call 🗺️',
  'Start Free Trial 😎',
  'Get AI Number ☎️',
];

interface Step {
  title: string;
  completed: boolean;
}

interface StepsListProps {
  steps: Step[];
  currentStep: number;
  setOpenedOnboarding?: any;
}

const StepsList: React.FC<StepsListProps> = ({ steps, currentStep, setOpenedOnboarding }) => {
  const [tooltipPlacement, setTooltipPlacement] = useState<'top' | 'right'>('right');
  const { isMobile } = useMobileDeviceCheck();
  const handleMouseEnter = () => {
    setTooltipPlacement(tooltipPlacement === 'top' ? 'right' : 'top');
  };
  return (
    <div className='p-4 bg-button-success/10 rounded-lg shadow-lg w-72 text-xs'>
      {/* Progress Indicator */}
      <div className='flex items-center justify-between mb-4 sm:flex-row flex-col'>
        <h3 className='font-semibold text-sm flex items-center space-x-1'>
          {!isMobile && (
            <span className='relative flex h-3 w-3 mr-1'>
              <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75'></span>
              <span className='relative inline-flex rounded-full h-3 w-3 bg-sky-500'></span>
            </span>
          )}
          <span className='sm:text-lg text-sm font-bold'>Get Started</span>
        </h3>
        <span>{`${steps.filter(step => step.completed).length}/${steps.length}`}</span>
      </div>

      {/* Progress Bar */}
      {!isMobile && (
        <div className='w-full bg-gray-200 rounded-full h-2 mb-4'>
          <div
            className='h-2 bg-blue-500 rounded-full'
            style={{
              width: `${(steps.filter(step => step.completed).length / steps.length) * 100}%`,
            }}
          ></div>
        </div>
      )}

      {/* Steps List */}
      <List
        dataSource={steps}
        renderItem={(item, index) => (
          <List.Item
            className='flex items-center justify-between cursor-pointer'
            onClick={() => setOpenedOnboarding(true)}
          >
            {/* Step Title with Tooltip for Current Step */}
            {currentStep === index ? (
              <Tooltip
                title={<div onMouseEnter={handleMouseEnter}>Complete to activate your receptionist. 🤖</div>}
                placement={tooltipPlacement}
                open
                overlayClassName='text-xs font-semibold steps-tooltip'
                overlayInnerStyle={{
                  background: 'red',
                }}
              >
                <div className={`${item.completed ? 'line-through text-gray-400' : ''} sm:text-sm text-xs font-bold`}>
                  {item.title}
                </div>
              </Tooltip>
            ) : (
              <div className={`${item.completed ? 'line-through text-gray-400' : ''} sm:text-sm text-xs font-bold`}>
                {item.title}
              </div>
            )}

            {/* Checkmark or Empty Circle */}
            {!isMobile && (
              <>
                {' '}
                {item.completed ? (
                  <CheckCircleFilled className='text-blue-500' />
                ) : (
                  <div
                    className={`w-4 h-4 rounded-full border ${
                      currentStep === index ? 'border-blue-500' : 'border-gray-300'
                    }`}
                  ></div>
                )}
              </>
            )}
          </List.Item>
        )}
      />
    </div>
  );
};
type OnboardingStepsListProps = {
  setOpenedOnboarding?: (key: boolean) => void;
};
const OnboardingStepsList: React.FC<OnboardingStepsListProps> = ({ setOpenedOnboarding }) => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const stepsDone = loggedInUser.instruction_steps || [];

  if (stepsDone.length >= 7) {
    return <></>;
  }
  const steps = stepLabels.map((title, index) => {
    let completed = false;

    switch (stepsDone.length) {
      case 0:
        completed = index === 0; // Only 'Register' should be marked completed
        break;
      case 3:
        completed = index <= 1; // 'Test Receptionist' should be completed
        break;
      case 4:
        completed = index <= 3; // 'Take Tour' and 'Explore Dashboard' should be completed
        break;
      case 5:
        completed = index <= 4; // 'Start Free Trial' should be completed
        break;
      case 6:
        completed = index <= 5; // Mark all steps up to 'Start Free Trial' as completed
        break;
      case 7:
        completed = index <= 5; // All steps except 'Get AI Number' should be completed
        break;
      default:
        completed = index === 0; // Default to only 'Register' being completed
        break;
    }

    return {
      title,
      completed,
    };
  });

  const currentStep = stepsDone.length;

  const baseClass = 'onboarding-steps-list-container';

  return (
    <div className={`flex justify-center items-center text-xs border shadow-xl ${baseClass}`}>
      <StepsList steps={steps} currentStep={currentStep} setOpenedOnboarding={setOpenedOnboarding} />
    </div>
  );
};

export default OnboardingStepsList;
