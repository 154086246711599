import { OverViewCardInfo } from '../OverviewCardInfo';
import { useMutation, useQuery } from '@tanstack/react-query';
import { MODAL_IDS } from 'src/enums/EModal';
import { CalendarWorkflowForm } from './CalendarWorkflowForm';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert, Button } from 'antd';
import { ECalendar } from 'src/enums/ECalendar';
import { configureCalendar, getCalendars } from '@/api/calendar';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal, openModal } from '@/store/modal/slice';
import { TCalendar } from '@/types/TCalendar';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import Spinner from '@/components/Spinner/Spinner';
import { getCalendarConflictingScenariosNotice } from './helper';
import { TUTORIALS } from 'src/enums/ETutorials';
import { CustomModal } from '@/components/CustomModal';
import ChooseCalendarType from './sub-components/ChooseCalendarType';
import { ConnectCalComWorkflowForm } from './sub-components/calcom/ConnectCalComForm';

export const CalendarWorkflowInfo: React.FC<{ clientObject: any }> = ({ clientObject }) => {
  const [searchParams] = useSearchParams();

  const [googleCalendar, setGoogleCalendar] = useState<TCalendar | null>(null);
  const [calComCalendars, setCalComCalendars] = useState<TCalendar[]>([]);
  const [notice, setNotice] = useState<any>([]);

  const dispatch = useAppDispatch();

  const { data, refetch } = useQuery({
    queryKey: ['get-calendar'],
    queryFn: () => getCalendars(),
  });

  const { mutate: configureCalendarFn, isPending } = useMutation({
    mutationKey: ['update-calendar'],
    mutationFn: configureCalendar,
    onSuccess: () => {
      refetch();
      dispatch(closeModal());
    },
  });

  useEffect(() => {
    if (clientObject) {
      const formattedNotice = getCalendarConflictingScenariosNotice(clientObject);
      if (formattedNotice) {
        setNotice(formattedNotice);
      }
    }
  }, [clientObject]);

  useEffect(() => {
    const encAcc = searchParams.get('encAcc');
    const encRefr = searchParams.get('encRefr');
    if (encAcc && encRefr) {
      configureCalendarFn({ encAcc, encRefr });
    }
  }, [searchParams]);

  useEffect(() => {
    if (data?.data?.calendars) {
      const googleCal = data.data.calendars.find(
        (calendar: TCalendar) => calendar.nameOfCalendar === ECalendar.GOOGLE_CALENDAR
      );
      const calComCals = data.data.calendars.filter(
        (calendar: TCalendar) => calendar.nameOfCalendar === ECalendar.CAL_COM
      );

      setGoogleCalendar(googleCal || null);
      setCalComCalendars(calComCals);
    }
  }, [data]);

  const handleConnectCalendar = () => {
    dispatch(
      openModal({
        modalId: MODAL_IDS.CHOOSE_CALENDAR_TYPE_MODAL,
      })
    );
  };

  const renderGoogleCalendarDetails = (calendar: TCalendar) => (
    <div className='flex flex-col md:flex-row justify-between gap-8'>
      <div className='w-1/2 flex flex-col gap-4'>
        <LabelWithDescription
          customClassName='w-full mb-1'
          label={<span className='text-sm font-semibold'>Calendar Name</span>}
        />
        {calendar.nameOfCalendar}
        <LabelWithDescription
          customClassName='w-full mb-1'
          label={<span className='text-sm font-semibold'>Duration of the meeting (in minutes)</span>}
        />
        {calendar.lengthOfAppointment}
      </div>
      <div className='w-1/2 flex flex-col gap-4'>
        <LabelWithDescription
          customClassName='w-full mb-1'
          label={<span className='text-sm font-semibold'>Number of concurrent events allowed</span>}
        />
        {calendar.noOfConcurrentEventsAllowed}
        <LabelWithDescription
          customClassName='w-full mb-1'
          label={<span className='text-sm font-semibold'>Gap between appointments (in minutes)</span>}
        />
        {calendar.gapBetweenAppointments}
      </div>
    </div>
  );

  const renderCalComCalendarDetails = (calendar: TCalendar, idx: number) => (
    <div key={idx} className='flex flex-col md:flex-row justify-between gap-8 my-4 bg-gray-50 p-2 rounded-lg'>
      <div className='w-1/2 flex flex-col gap-4'>
        <LabelWithDescription
          customClassName='w-full mb-1'
          label={<span className='text-sm font-semibold'>Event Type ID</span>}
        />
        {calendar.eventTypeId}
        <LabelWithDescription
          customClassName='w-full mb-1'
          label={<span className='text-sm font-semibold'>Scenario</span>}
        />
        {calendar.scenario}
      </div>
      <div className='w-1/2 flex flex-col gap-4'>
        <LabelWithDescription
          customClassName='w-full mb-1'
          label={<span className='text-sm font-semibold'>Number of concurrent events allowed</span>}
        />
        {calendar.noOfConcurrentEventsAllowed}
        <LabelWithDescription
          customClassName='w-full mb-1'
          label={<span className='text-sm font-semibold'>Days Offering Dates</span>}
        />
        {calendar.daysForOfferingDates}
      </div>
    </div>
  );

  const InfoComponent = () => (
    <Spinner spinning={isPending}>
      <div className='pl-4 w-full mt-1 pt-2 pb-4'>
        {googleCalendar || calComCalendars.length > 0 ? (
          <>
            {notice.length > 0 && (
              <Alert
                className='mb-4 w-full'
                type='info'
                closable={false}
                message={
                  <span>
                    <span className='mb-2'>
                      ⚠️ Heads up! A few of your scenarios are conflicting. The AI may get confused when trying to
                      schedule an appointment in the following configurations:
                    </span>
                    {notice.map((item: any, index: number) => (
                      <div key={index}>
                        {index + 1}. <strong>{item.key}:</strong> {item.message}
                      </div>
                    ))}
                  </span>
                }
              />
            )}
            {googleCalendar && <div className='mb-8'>{renderGoogleCalendarDetails(googleCalendar)}</div>}
            {calComCalendars.map((calendar, idx) => renderCalComCalendarDetails(calendar, idx))}
          </>
        ) : (
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-1'>
              No calendar attached. Please attach a calendar by clicking
              <span className='font-semibold'>Attach Calendar.</span>
            </div>
            <Button type='primary' onClick={handleConnectCalendar}>
              Attach Calendar
            </Button>
          </div>
        )}
      </div>
    </Spinner>
  );
  return (
    <>
      <OverViewCardInfo
        info={<InfoComponent />}
        formActionType='Edit'
        displayFormActionBtn={!!googleCalendar || (calComCalendars && !(calComCalendars.length === 0))}
        form={
          googleCalendar ? (
            <CalendarWorkflowForm
              refetch={refetch}
              onClose={() => {}}
              calendar={googleCalendar}
              askOtherDetails={googleCalendar.askOtherDetails}
            />
          ) : (
            <ConnectCalComWorkflowForm existingCalendars={calComCalendars} refetch={refetch} />
          )
        }
        width={'50%'}
        footer={null}
        modalId={MODAL_IDS.CALENDAR_FORM_MODAL}
        tutorialId={TUTORIALS.GOOGLE_CALENDAR}
        displayTutorialActionBtn={true}
      />
      <CustomModal
        width={'50%'}
        title={<h1 className='text-2xl'>Choose which calendar you want to connect 📅</h1>}
        children={<ChooseCalendarType refetch={refetch} />}
        footer={null}
        modalId={MODAL_IDS.CHOOSE_CALENDAR_TYPE_MODAL}
      />
    </>
  );
};
