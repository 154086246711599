import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

export const resellerAcademyState = (state: RootState) => state.resellerAcademy;

export const getResellerAcademy = createSelector([resellerAcademyState], resellerAcademy => resellerAcademy);

export const getChapterById = (chapterId: Number) =>
  createSelector([getResellerAcademy], ({ chapters = [] }) => chapters.find(chapter => chapter.id === chapterId));

export const getVideoBySlug = (chapterId: Number, videoSlug: string) =>
  createSelector([getChapterById(chapterId)], chapter => chapter?.videos.find(video => video.slug === videoSlug));

export const getPdfBySlug = (chapterId: Number, pdfSlug: string) =>
  createSelector([getChapterById(chapterId)], chapter => chapter?.pdfs?.find(pdf => pdf.slug === pdfSlug));
