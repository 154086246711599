import { LabelWithDescription } from '@/components/LabelWithdescription';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { DeleteOutline } from '@mui/icons-material';
import { Button, Card, Flex, Form, Input, notification, Spin, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { OverViewCardForm } from '../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import './CallTransferingWorkflowForm.scss';
import { CallWorkflow, User } from '@/types/TAccounts';
import PhoneNumberInput from '@/components/Inputs/PhoneNumberInput';
import { handlePrefixPlusOnPhone } from '@/utils/helper';

interface CallTransferingWorkflowFormProps {
  callTransferingWorkflowData: CallWorkflow[];
  showAddFieldButton?: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
  scenarioIndex?: number;
}

const CallTransferingWorkflowFormAdd: React.FC<CallTransferingWorkflowFormProps> = ({
  callTransferingWorkflowData = [],
  showAddFieldButton = true,
  refetch,
  mutation,
  scenarioIndex,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const [showDisplayedPhone, setShowDisplayedPhone] = useState<boolean>(false);
  const [showHotTransferOptions, setShowHotTransferOptions] = useState<boolean>(false);

  const handleWhisperOptions = (e: any) => {
    const selectedValue = e.target.value;
    setShowHotTransferOptions(selectedValue === 'Hot Transfer (With Summary)');
  };

  const handleRadioChange = (e: any) => {
    const selectedValue = e.target.value;
    setShowDisplayedPhone(selectedValue === 'Yes');
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [callTransferingWorkflowData, form]);

  const handleSubmit = async (values: { callWorkflows: CallWorkflow[] }) => {
    try {
      const newWf = values.callWorkflows || [];
      let cf = callTransferingWorkflowData ? newWf.concat(callTransferingWorkflowData) : newWf;

      cf = cf.map(item => {
        return {
          ...item,
          workflowForwardingNumber: handlePrefixPlusOnPhone(item.workflowForwardingNumber),
        };
      });

      await mutation.mutateAsync({ callWorkflows: cf });
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Call Transfering workflow has been created successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
      dispatch(closeModal());
    } catch (err) {
      console.log(err);
    }
  };

  const FormItems = () => (
    <Form.List name='callWorkflows' initialValue={[{}]}>
      {(fields, { add, remove }) => (
        <div className='card-container'>
          {fields.map(({ key, name, ...restField }, index) => (
            <div key={key} className='mb-2'>
              <Card
                className='flex-1'
                title={
                  <div className='flex justify-between'>
                    <span className='font-bold text-base'>Scenario {(scenarioIndex ?? 0) + index + 1}</span>
                    {fields.length > 1 && showAddFieldButton && (
                      <Button type='primary' danger icon={<DeleteOutline />} onClick={() => remove(name)}>
                        Delete
                      </Button>
                    )}
                  </div>
                }
              >
                <div className='border-b'>
                  <LabelWithDescription
                    customClassName='mt-2'
                    label='Scenario Description'
                    description='Describe the scenario in which you would like to transfer the call.'
                  />
                  <Form.Item
                    className=' mt-2 pt-2 '
                    {...restField}
                    name={[name, 'description']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value || value.trim() === '') {
                            return Promise.reject("Description can't be blank");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder='e.g. Transfer the caller to the billing department. Execute whenever caller asks for a refund or receipt.' />
                  </Form.Item>
                </div>

                <div>
                  <LabelWithDescription
                    customClassName='mt-2'
                    label='Phone Number'
                    description='Specify the number you want your AI receptionist to forward the call to when the scenario is triggered.'
                  />
                  <div className='input-container mt-2'>
                    <Flex gap={8}>
                      <Form.Item className='flex-auto !mb-2' label='Number:'>
                        <PhoneNumberInput name={[name, 'workflowForwardingNumber']} />
                      </Form.Item>
                      <div className='mt-10'>#</div>
                      <Form.Item
                        name={[name, 'workflowForwardingDID']}
                        className='flex-auto !mb-2'
                        label='Extension (Optional):'
                      >
                        <Input
                          placeholder='e.g: 302'
                          onKeyPress={event => {
                            const keyCode = event.keyCode || event.which;
                            const keyValue = String.fromCharCode(keyCode);
                            if (!/^\d+$/.test(keyValue)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Flex>
                  </div>
                </div>

                <div className='border-b '>
                  <LabelWithDescription
                    customClassName='mt-2 mb-2'
                    label='Voice Response'
                    description='Specify what you want your AI receptionist to say right before the call is transferred.'
                  />
                  <Form.Item
                    className=' mt-2 pt-2 '
                    {...restField}
                    name={[name, 'voiceResponse']}
                    rules={[{ required: true, message: "Voice Response can't be blank" }]}
                  >
                    <Input placeholder='e.g. Sounds good, I am transferring you to the billing department right now. Please hold.' />
                  </Form.Item>
                </div>
                <div className='border-b'>
                  <LabelWithDescription
                    customClassName='mt-2'
                    label='Call Whisper Settings'
                    description='Customize your settings for call whisper here.'
                  />
                  <Form.Item className='mt-2 pt-2' label='Type' {...restField} name={[name, 'whisperType']}>
                    <Radio.Group
                      name='whisperType'
                      {...restField}
                      options={[
                        { label: 'Cold Transfer (Without Summary)', value: 'Cold Transfer (Without Summary)' },
                        { label: 'Hot Transfer (With Summary)', value: 'Hot Transfer (With Summary)' },
                      ]}
                      onChange={handleWhisperOptions}
                    />
                  </Form.Item>
                  {showHotTransferOptions && (
                    <>
                      <Form.Item
                        className=' mt-2 pt-2 '
                        label='Handoff Message'
                        {...restField}
                        name={[name, 'handoffMessageType']}
                      >
                        <Radio.Group
                          name='handoffMessageType'
                          {...restField}
                          options={[
                            { label: 'Prompt', value: 'Prompt' },
                            { label: 'Static Word', value: 'Static Word' },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        className=' mt-2 pt-2'
                        {...restField}
                        name={[name, 'handoffMessage']}
                        rules={[{ required: true, message: "Handsoff Message can't be blank" }]}
                      >
                        <Input placeholder='e.g. Summarize the above conversation' />
                      </Form.Item>
                    </>
                  )}
                </div>
                <div className='border-b'>
                  <LabelWithDescription
                    customClassName='mt-2'
                    label="Call Transferee's Settings"
                    description="Customize your settings for call transferee's numbers."
                  />
                  <Form.Item
                    className='mt-2 pt-2'
                    label='Show Transferee as Caller'
                    {...restField}
                    name={[name, 'callTransferee']}
                  >
                    <Radio.Group
                      name='callTransferee'
                      {...restField}
                      options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                      ]}
                      onChange={handleRadioChange}
                    />
                  </Form.Item>
                  {showDisplayedPhone && (
                    <Form.Item
                      className=' mt-2 pt-2 '
                      label='Displayed Phone Number'
                      {...restField}
                      name={[name, 'displayedNumber']}
                    >
                      <Radio.Group
                        name='displayedNumber'
                        {...restField}
                        options={[
                          { label: "Retail Agent's Number", value: "Retail Agent's Number" },
                          { label: "Transferee's Number", value: "Transferee's Number" },
                        ]}
                      />
                    </Form.Item>
                  )}
                </div>
              </Card>
            </div>
          ))}
          {showAddFieldButton && (
            <Form.Item>
              <Button
                type='dashed'
                onClick={() => {
                  add();
                }}
                block
                icon={<PlusOutlined />}
              >
                Add Call Transfering Workflow
              </Button>
            </Form.Item>
          )}
        </div>
      )}
    </Form.List>
  );

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <div className='max-h-[60vh] overflow-auto'>
          <OverViewCardForm
            form={form}
            initialValues={undefined}
            formItems={<FormItems />}
            handleSubmit={handleSubmit}
            isFieldTouchReset={isFieldTouchReset}
          />
        </div>
      </Spin>
    </>
  );
};

export default CallTransferingWorkflowFormAdd;
