import { List } from 'antd';
import './VoiceMailLogsDetails.scss';
import { VoiceMailLog } from '@/interfaces/ILogs';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

export const VoiceMailLogDetails = ({ voicemailLog }: { voicemailLog: VoiceMailLog }) => {
  const data = [
    { label: 'Username', value: voicemailLog.forwardedFrom },
    { label: 'Date', value: voicemailLog.date },
    { label: 'from', value: voicemailLog.from },
    { label: 'Transcript', value: voicemailLog.voicemail_transcript },
  ];

  return (
    <List
      size='small'
      dataSource={data}
      className='voicemail-logs-details '
      renderItem={item => (
        <List.Item>
          <div className='flex items-center gap-1'>
            <strong>{item.label}:</strong>
            <CopyableText text={item.value} hideText />
          </div>
          <span> {item.value}</span>
        </List.Item>
      )}
    />
  );
};
