import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

const accountState = (state: RootState) => state.account;

export const getAuthenticationStatus = createSelector([accountState], accountState => accountState.isAuthenticated);

export const getLoggedInUser = createSelector([accountState], accountState => accountState.user);

export const getUserInstructionSteps = createSelector(
  [accountState],
  accountState => accountState.user.instruction_steps
);

export const getUserFeatures = createSelector([accountState], accountState => accountState?.user?.features);

export const getcurrentPage = createSelector([accountState], accountState => accountState?.currentPage);

export const getActiveConfigureTab = createSelector([accountState], accountState => accountState?.activeConfigureTab);

export const getResellerUserFeaturesVisibility = createSelector(
  [accountState],
  accountState => accountState?.user?.featuresSubAccount?.visibility?.featureVisibilitySidebar
);

export const getResellerUserFeaturesGating = createSelector(
  [accountState],
  accountState => accountState?.user?.featuresSubAccount?.gating?.featureGatingSidebar
);
export const getResellerUserConfigureVisibility = createSelector(
  [accountState],
  accountState => accountState?.user?.featuresSubAccount?.visibility?.configureVisibility
);
export const getResellerUserConfigureGating = createSelector(
  [accountState],
  accountState => accountState?.user?.featuresSubAccount?.gating?.configureGating
);

export const getResellerUserAdvanceFeaturesVisibility = createSelector(
  [accountState],
  accountState => accountState?.user?.featuresSubAccount?.visibility?.advanceFeaturesVisibility
);
export const getResellerUserAdvanceFeaturesGating = createSelector(
  [accountState],
  accountState => accountState?.user?.featuresSubAccount?.gating?.advanceFeaturesGating
);
