import './ContactSupport.scss';
import { useEffect } from 'react';
import { show } from '@intercom/messenger-js-sdk';
import { Alert } from 'antd';

export const ContactSupport = () => {
  useEffect(() => {
    show();
  }, []);

  const baseClass = 'contact-support';
  return (
    <>
      <Alert className='overview-info' closable description='Get in touch with Support in just minutes! 👇' />
      <div className={baseClass}>
        <iframe
          src='https://calendly.com/d/cph3-yqw-sfb/invite-only-ai-front-desk-product-consultation'
          title='Calendly'
          className={`${baseClass}__content`}
        />
      </div>
    </>
  );
};
