import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Spin, Typography, Card } from 'antd';
import './sitemap.scss';

const { Text } = Typography;

const fetchSitemap = async () => {
  const response = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/v2/receptionist-dashboard/sitemap.xml`, {
    headers: { 'content-type': 'application/xml' },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch sitemap: ${response.statusText}`);
  }

  const xmlText = await response.text();

  // Format XML with proper indentation
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlText, 'text/xml');
  const serializer = new XMLSerializer();
  const formattedXml = serializer
    .serializeToString(xmlDoc)
    .replace(/></g, '>\n<') // Add newlines between tags
    .replace(/\n<([^/])/g, '\n  <$1'); // Add indentation

  return formattedXml;
};

const Sitemap: React.FC = () => {
  const {
    data: xmlContent,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['sitemap'],
    queryFn: fetchSitemap,
    retry: 2,
    staleTime: 1000 * 60 * 60,
  });

  if (isLoading) {
    return (
      <div className='loading-spinner-wrapper'>
        <Spin size='large' />
      </div>
    );
  }

  if (error) {
    return (
      <div className='error-container'>
        <h2>Error Loading Sitemap</h2>
        <p>{error instanceof Error ? error.message : 'An unknown error occurred'}</p>
      </div>
    );
  }

  return (
    <div className='sitemap-container'>
      <Card className='xml-card'>
        <div className='xml-header'>
          <Text strong>sitemap.xml</Text>
        </div>
        <pre className='xml-content'>
          <code>{xmlContent}</code>
        </pre>
      </Card>
    </div>
  );
};

export default Sitemap;
