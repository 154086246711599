import React, { useRef, useState } from 'react';
import { MessageSquare, PhoneIncoming, X, Mic, Lock, Send } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactNode } from 'react';
import { Button, Card, FormInstance, Input, notification, Typography } from 'antd';
import MakeCall from './MakeCall';
import { useMutation } from '@tanstack/react-query';
import { makeTestCall, updateTestingNumber } from '@/api/reservedNumbers';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import CustomOnboardingButton from '@/components/Onboarding/CustomButton';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setTestingNumber } from '@/store/account/slice';
import { CustomModal } from '@/components/CustomModal';
import { ChangeTestingNumberForm } from '../TestItOut/Steps/ChangeTestingNumberForm';

type CommunicationOption = 'make-call' | 'receive-call' | 'chat' | null;
type CommunicationOptionNonNull = Exclude<CommunicationOption, null>;

const ComingSoonOverlay = ({ show }: { show: boolean }) => {
  if (!show) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className='absolute inset-0 backdrop-blur-[2px] bg-white/60 z-10 rounded-lg'
    >
      <div className='absolute inset-0 flex items-start pt-20 justify-center'>
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className='text-center space-y-3'
        >
          <div className='bg-white/80 p-3 rounded-full shadow-xl inline-block mb-2'>
            <Lock className='w-6 h-6 text-gray-500' />
          </div>
          <p className='font-semibold text-gray-800'>Coming Soon</p>
          {/* <p className='text-sm text-gray-500 max-w-[200px]'>This feature is currently in development</p> */}
        </motion.div>
      </div>
    </motion.div>
  );
};

const Playground = ({}: { debrand: boolean }) => {
  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = useState<CommunicationOption>(null);
  const [isHovered, setIsHovered] = useState<CommunicationOption>(null);
  const loggedInUser = useAppSelector(getLoggedInUser);
  const [changeTestingModalVisible, setIsChangeTestingModalVisible] = useState(false);
  const testingNumberFormRef = useRef<FormInstance>(null);

  const makeVoidTestCall = useMutation({
    mutationKey: ['testCall'],
    mutationFn: makeTestCall,
    onSuccess() {},
    onError() {},
  });

  const { mutate: changeTestingNumberMutation, status: changingTestingNumberStatus } = useMutation({
    mutationKey: ['changeTestingNumber'],
    mutationFn: updateTestingNumber,
    onSuccess(_, variables) {
      setIsChangeTestingModalVisible(false);
      dispatch(setTestingNumber({ testing_number: variables.testing_number }));
      notification.success({ message: 'Testing number changed successfully' });
    },
  });

  const handleChangeTestingNumberFormSubmit = (values: { testing_number: string }) => {
    changeTestingNumberMutation(values);
  };

  const handleChangeTestingNumberConfirmAction = () => {
    if (testingNumberFormRef.current) {
      testingNumberFormRef.current.submit();
    }
  };

  const handleStopAudio = () => {
    setSelectedOption(null);
  };

  const cardVariants = {
    initial: { y: 20, opacity: 0 },
    animate: (i: number) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    }),
    hover: {
      y: -8,
      transition: {
        duration: 0.3,
        ease: 'easeOut',
      },
    },
  };

  const renderContent = () => {
    if (!selectedOption) {
      return (
        <AnimatePresence mode='wait'>
          <motion.div
            key='main'
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className='p-8 space-y-12'
          >
            <div className='text-center space-y-4'>
              <motion.h1
                className='text-4xl font-bold bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 bg-clip-text text-transparent'
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                How would you like to connect?
              </motion.h1>
              <motion.p
                className='text-gray-500'
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                Choose your preferred way to test
              </motion.p>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
              {[
                {
                  id: 'make-call',
                  icon: Mic,
                  title: 'Join Audio',
                  description: 'Start a voice call within the browser',
                  gradient: 'from-blue-500 to-cyan-500',
                  bgGradient: 'from-blue-50 to-cyan-50',
                  iconColor: 'text-blue-600',
                  comingSoon: false,
                },
                {
                  id: 'receive-call',
                  icon: PhoneIncoming,
                  title: 'Receive Call',
                  description: 'Receive phone call on your phone',
                  gradient: 'from-green-500 to-emerald-500',
                  bgGradient: 'from-green-50 to-emerald-50',
                  iconColor: 'text-green-600',
                  comingSoon: false,
                  onlyForPremium: true,
                  clickFn: () => makeVoidTestCall.mutate(),
                },
                {
                  id: 'chat',
                  icon: MessageSquare,
                  title: 'Start Chat',
                  description: 'Begin a text conversation',
                  gradient: 'from-purple-500 to-pink-500',
                  bgGradient: 'from-purple-50 to-pink-50',
                  iconColor: 'text-purple-600',
                  comingSoon: true,
                },
              ].map((option, i) =>
                option.onlyForPremium && loggedInUser.demo && !loggedInUser.reseller_email ? (
                  <React.Fragment key={option.id} />
                ) : (
                  <motion.div
                    key={option.id}
                    custom={i}
                    variants={cardVariants}
                    initial='initial'
                    animate='animate'
                    whileHover={!option.comingSoon ? 'hover' : undefined}
                    onClick={() => {
                      !option.comingSoon && setSelectedOption(option.id as CommunicationOption);
                      if (option.clickFn) option.clickFn();
                    }}
                    onMouseEnter={() => !option.comingSoon && setIsHovered(option.id as CommunicationOption)}
                    onMouseLeave={() => !option.comingSoon && setIsHovered(null)}
                    className={`relative group ${option.comingSoon ? 'cursor-default' : 'cursor-pointer'}`}
                  >
                    <Card className='relative h-full bg-white/90 backdrop-blur-sm border-0 shadow-md transition-all duration-300'>
                      <div className='p-8 flex flex-col items-center space-y-6'>
                        <motion.div
                          className={`h-24 w-24 rounded-full bg-gradient-to-br ${option.bgGradient} flex items-center justify-center
                          relative overflow-hidden`}
                          animate={{
                            scale: isHovered === option.id ? 1.1 : 1,
                            opacity: option.comingSoon ? 0.7 : 1,
                          }}
                          transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                        >
                          <option.icon size={48} className={`${option.iconColor} drop-shadow-lg`} />
                          <div className='absolute inset-0 bg-gradient-to-r from-white/0 via-white/50 to-white/0 rotate-45 transform translate-x-full group-hover:translate-x-[-200%] transition-transform duration-1000' />
                        </motion.div>

                        <div className='text-center space-y-2'>
                          <h3
                            className={`text-xl font-semibold bg-gradient-to-r ${option.gradient} bg-clip-text text-transparent`}
                          >
                            {option.title}
                          </h3>
                          <p className='text-gray-500'>{option.description}</p>
                        </div>
                      </div>
                      <ComingSoonOverlay show={option.comingSoon} />
                    </Card>
                  </motion.div>
                )
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      );
    }

    // Rest of the component remains the same...
    const content: Record<CommunicationOptionNonNull, ReactNode> = {
      'make-call': (
        <div className='p-8 space-y-6'>
          <motion.div
            className='flex items-center justify-between mb-8'
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <h2 className='text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent'>
              Join Audio
            </h2>
            <Button className='h-10 px-2' type='default' onClick={() => setSelectedOption(null)}>
              <X size={24} />
            </Button>
          </motion.div>
          <MakeCall handleStopAudio={handleStopAudio} />
        </div>
      ),
      'receive-call': (
        <div className='p-8 space-y-6'>
          <motion.div
            className='flex items-center justify-between mb-8'
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <h2 className='text-2xl font-bold bg-gradient-to-r from-green-600 to-emerald-600 bg-clip-text text-transparent'>
              Receive Call
            </h2>
            <Button className='h-10 px-2' type='default' onClick={() => setSelectedOption(null)}>
              <X size={24} />
            </Button>
          </motion.div>

          <motion.div
            className='text-center space-y-8'
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: 'spring', stiffness: 200, damping: 20 }}
          >
            <div className='relative mx-auto w-24 h-24'>
              <motion.div
                className='absolute inset-0 rounded-full bg-gradient-to-r from-green-400 to-emerald-500 opacity-20'
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.2, 0.1, 0.2],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                }}
              />
              <motion.div
                className='relative h-full w-full rounded-full bg-gradient-to-br from-green-500 to-emerald-600 p-0.5'
                transition={{
                  duration: 8,
                  repeat: Infinity,
                  ease: 'linear',
                }}
              >
                <div className='h-full w-full rounded-full bg-white flex items-center justify-center'>
                  <PhoneIncoming size={32} className='text-green-600' />
                </div>
              </motion.div>
            </div>
            <div className='space-y-4'>
              <h3 className='text-xl font-semibold bg-gradient-to-r from-green-600 to-emerald-600 bg-clip-text text-transparent'>
                Calling {loggedInUser.testing_number}...
              </h3>
              <p className='text-gray-500'>This is the phone number you've set as your testing number</p>
            </div>
            <Button
              type='default'
              className='rounded-full mx-2 h-12 text-lg border-2 border-gray-200 hover:border-red-500 hover:text-red-500 transition-colors duration-300'
              onClick={() => setSelectedOption(null)}
            >
              Cancel
            </Button>{' '}
            <CustomOnboardingButton
              onClick={() => {
                setIsChangeTestingModalVisible(true);
              }}
              className='bg-red-400 max-w-[300px] max-h-11 !text-white hover:!text-stone-950'
            >
              <Typography.Text className='!text-white hover:!text-stone-950 min-w-[300px] min-h-[40px] !text-center !flex !justify-center !items-center'>
                I don't have {loggedInUser.testing_number || '--'} with me.
              </Typography.Text>
            </CustomOnboardingButton>
          </motion.div>
        </div>
      ),
      chat: (
        <div className='p-8 space-y-6'>
          <motion.div
            className='flex items-center justify-between mb-8'
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <h2 className='text-2xl font-bold bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent'>
              Text Chat
            </h2>
            <Button className='h-10 px-2' type='default' onClick={() => setSelectedOption(null)}>
              <X size={24} />
            </Button>
          </motion.div>

          <motion.div
            className='bg-gradient-to-br from-purple-50 to-pink-50 rounded-xl p-6 h-96 flex flex-col relative overflow-hidden'
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: 'spring', stiffness: 200, damping: 20 }}
          >
            <motion.div
              className='absolute inset-0 bg-gradient-to-r from-purple-400/10 blur-3xl via-transparent to-pink-400/10'
              animate={{
                x: ['-100%', '100%'],
              }}
              transition={{
                duration: 10,
                repeat: Infinity,
                ease: 'linear',
              }}
            />

            <div className='flex-1 flex items-center justify-center relative'>
              <motion.div
                className='text-center space-y-4'
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <motion.div whileHover={{ scale: 1.1, rotate: [0, -10, 10, 0] }} transition={{ duration: 0.5 }}>
                  <MessageSquare size={48} className='text-purple-600 mx-auto drop-shadow-lg' />
                </motion.div>
                <p className='text-gray-600 font-medium'>No messages yet</p>
                <p className='text-sm text-gray-400'>Start the conversation below</p>
              </motion.div>
            </div>

            <div className='mt-4 flex gap-4 relative'>
              <Input
                type='text'
                placeholder='Type your message...'
                className='flex-1 h-12 bg-white/80 backdrop-blur-sm border-2 border-purple-100 focus:border-purple-500 rounded-xl shadow-sm'
              />
              <Button className='h-12 w-12 bg-gradient-to-r from-purple-500 to-pink-500 rounded-xl text-white shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-105'>
                <Send size={20} />
              </Button>
            </div>
          </motion.div>
        </div>
      ),
    };

    return (
      <AnimatePresence mode='wait'>
        <motion.div
          key={selectedOption}
          initial={{ opacity: 0, scale: 0.95, y: 20 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.9, y: -20 }}
          transition={{
            type: 'spring',
            stiffness: 200,
            damping: 20,
          }}
          className='w-full'
        >
          {selectedOption && content[selectedOption]}
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <div className='min-h-screen bg-gradient-to-br from-gray-50 via-white to-gray-100 flex justify-center p-4'>
      <div className='pointer-events-none absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]' />
      <div className='pointer-events-none absolute inset-0 bg-[radial-gradient(circle_800px_at_100%_200px,rgba(110,121,214,0.1),transparent)]' />
      <CustomModal
        title='Change Testing Number'
        isModalOpen={changeTestingModalVisible}
        confirmAction={handleChangeTestingNumberConfirmAction}
        cancelAction={() => setIsChangeTestingModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsChangeTestingModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={handleChangeTestingNumberConfirmAction}
            loading={changingTestingNumberStatus === 'pending'}
          >
            Change
          </Button>,
        ]}
      >
        <ChangeTestingNumberForm ref={testingNumberFormRef} onSubmit={handleChangeTestingNumberFormSubmit} />
      </CustomModal>
      <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className='relative w-full max-w-5xl'>
        <Card className='w-full border-2 bg-white/80 backdrop-blur-sm rounded-2xl overflow-hidden'>
          {renderContent()}
        </Card>
      </motion.div>
    </div>
  );
};

export default Playground;
