import React, { useState } from 'react';
import { Button, Table, Switch, Modal, Input, Upload, Select, notification, Tag, Tooltip } from 'antd';
import {
  PlusOutlined,
  LinkOutlined,
  UploadOutlined,
  ReloadOutlined,
  SyncOutlined,
  EditOutlined,
  EyeOutlined,
  CopyOutlined,
  DeleteFilled,
} from '@ant-design/icons';
import type { UploadProps } from 'antd/es/upload';
import { useMutation } from '@tanstack/react-query';
import {
  deleteKnowledgeBase,
  generateKnowledgeBase,
  manualSyncKnowledgeBase,
  updateKnowledgeBaseActivation,
  updateKnowledgeBaseContent,
} from '@/api/user';

const { Option } = Select;
import { Typography } from 'antd';
import { InfoOutlined } from '@mui/icons-material';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';
// @ts-ignore
import TextEditor from '../../../../../v1/components/TextEditor/TextEditor';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';
import { updateCampaignVariables } from '@/api/novi/CamapaignPortalApis';
import DOMPurify from 'dompurify';

const { Text } = Typography;

export const KnowledgeBaseRevamped: React.FC<{
  data: any;
  refetch: () => void;
  isNoviUser: boolean;
}> = ({ data, refetch, isNoviUser }) => {
  const { isMobile } = useMobileDeviceCheck();

  const MAX_TEXT_CHARACTERS = 25000;
  const [isTextModalVisible, setIsTextModalVisible] = useState(false);
  const [isUrlModalVisible, setIsUrlModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [textInput, setTextInput] = useState('');
  const [urlInput, setUrlInput] = useState('');
  const [syncType, setSyncType] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [editContentIndex, setEditContentIndex] = useState<number | null>(null);
  const [editTextInput, setEditTextInput] = useState('');
  const [selectedText, setSelectedText] = useState('');
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);

  let selectedCampaign: any;
  if (isNoviUser) {
    selectedCampaign = useAppSelector(getSelectedCampaign);
  }

  const [variables, setVariables] = useState<any[]>(
    selectedCampaign?.variables.length ? JSON.parse(selectedCampaign?.variables) : []
  );

  const { mutate: updateVariables } = useMutation({
    mutationKey: ['update-variables'],
    mutationFn: () => updateCampaignVariables(selectedCampaign?.number, variables),
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Variables have been saved successfully!',
        duration: 3,
      });
    },
  });

  const handleVariableChange = (variables: any) => {
    console.log(variables);
    setVariables(variables);
    updateVariables();
  };

  const GenerateKnowledgeBase = useMutation({
    mutationKey: ['generate-knowledge-base'],
    mutationFn: (formData: FormData) => generateKnowledgeBase(formData),
    onSuccess: () => {
      refetch();
      notification.success({
        message: 'Knowledge base entry created successfully',
      });
    },
    onError: error => {
      notification.error({
        message: `Failed to create knowledge base entry: ${error}`,
      });
    },
  });

  const ManualSyncKnowledgeBase = useMutation({
    mutationKey: ['manual-sync-knowledge-base'],
    mutationFn: (payload: { number: string; entryIndex: number; entry: any }) => manualSyncKnowledgeBase(payload),
    onSuccess: () => {
      refetch();
      setLoading(false);
      notification.success({
        message: 'Manual sync successful',
      });
    },
    onError: () => {
      setLoading(false);
      notification.error({
        message: 'Failed to sync',
      });
    },
  });

  const UpdateKnowledgeBaseActivation = useMutation({
    mutationKey: ['update-knowledge-base-activation'],
    mutationFn: (payload: { number: string; entryIndex: number; isActive: boolean }) =>
      updateKnowledgeBaseActivation(payload),
    onSuccess: () => {
      refetch();
      setLoading(false);
      notification.success({
        message: 'Status updated successfully',
      });
    },
    onError: () => {
      setLoading(false);
      notification.error({
        message: 'Failed to update status',
      });
    },
  });

  const UpdateKnowledgeBaseContent = useMutation({
    mutationKey: ['update-knowledge-base-content'],
    mutationFn: (payload: { number: string; entryIndex: number; content: string }) =>
      updateKnowledgeBaseContent(payload),
    onSuccess: () => {
      refetch();
      notification.success({
        message: 'Content updated successfully',
      });
    },
    onError: () => {
      notification.error({
        message: 'Failed to update content',
      });
    },
  });

  const DeleteKnowledgeBase = useMutation({
    mutationKey: ['delete-knowledge-base'],
    mutationFn: (payload: { number: string; entryIndex: number }) => deleteKnowledgeBase(payload),
    onSuccess: () => {
      refetch();
      setLoading(false);
      notification.success({
        message: 'Entry deleted successfully',
      });
    },
    onError: () => {
      setLoading(false);
      notification.error({
        message: 'Failed to delete entry',
      });
    },
  });

  const openViewModal = (text: string) => {
    setSelectedText(text);
    setIsViewModalVisible(true);
  };

  const columns = [
    {
      title: (
        <Tooltip
          title='Type of knowledge base: Simple text (Text), URL (Link) or Document (File).'
          className='flex items-center space-x-2'
        >
          <span>Type</span>
          <InfoOutlined className='text-sm cursor-pointer' />
        </Tooltip>
      ),
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>,
    },
    {
      title: (
        <Tooltip
          title='The generated/provided content which the AI will use to communicate on call.'
          className='flex items-center space-x-2'
        >
          <span>Text</span>
          <InfoOutlined className='text-sm cursor-pointer' />
        </Tooltip>
      ),
      dataIndex: 'content',
      key: 'content',
      render: (text: string, record: any, index: number) => (
        <div className='flex items-center space-x-2'>
          <Text className='truncate max-w-xs inline-block'>
            {text ? (
              DOMPurify.sanitize(text)
                .replace(/<[^>]*>?/gm, '')
                .substring(0, 100) + '...'
            ) : (
              <Tooltip
                title="The AI is working on generating relevant data from your given resource, it can take upto 3-5 minutes based on the amount of original data, once it's done you'll be able to see it here, you can refresh to check the status using the button on top right of the table."
                className='flex items-center space-x-2'
              >
                <Tag color='yellow'>Processing</Tag>
                <InfoOutlined className='text-sm cursor-pointer' />
              </Tooltip>
            )}
          </Text>
          {text && (
            <>
              <Tooltip title='View' className='flex items-center space-x-2'>
                <EyeOutlined onClick={() => openViewModal(text)} className='cursor-pointer text-blue-500' />
              </Tooltip>

              {record.type === 'text' && (
                <Tooltip title='Edit Content' className='flex items-center space-x-2'>
                  <EditOutlined onClick={() => openEditModal(index, text)} className='cursor-pointer text-blue-500' />
                </Tooltip>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: (
        <Tooltip
          title="If you don't want to remove the knowledge base but temporarily enable/disable it, you can do it from here."
          className='flex items-center space-x-2'
        >
          <span>Active</span>
          <InfoOutlined className='text-sm cursor-pointer' />
        </Tooltip>
      ),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean, _: any, index: number) => (
        <div className='flex items-center space-x-2'>
          <Switch
            checked={isActive}
            onChange={checked => {
              setLoading(true);
              UpdateKnowledgeBaseActivation.mutate({
                number: data.number,
                entryIndex: index,
                isActive: checked,
              });
            }}
          />
          <Tag color={isActive ? 'green' : 'red'}>{isActive ? 'Active' : 'Deactivated'}</Tag>
        </div>
      ),
    },
    {
      title: (
        <Tooltip
          title='This specifies how the data for the knowledge base will be refresh, updated or synced. Only URL type knowledge bases support automatic updates.'
          className='flex items-center space-x-2'
        >
          <span>Data Update Type</span>
          <InfoOutlined className='text-sm cursor-pointer' />
        </Tooltip>
      ),
      dataIndex: 'syncType',
      key: 'syncType',
      render: (syncType: string) => (
        <div>
          {syncType ? (
            <>
              <b>Auto Sync:</b> {syncType.charAt(0).toUpperCase() + syncType.slice(1)}
            </>
          ) : (
            'Manual'
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_: any, record: any, index: number) => (
        <div className='flex space-x-2'>
          {record.type === 'link' && record.content && (
            <>
              <Tooltip
                title='If you want to sync the data from the URL immediately, use this option!'
                className='flex items-center space-x-2'
              >
                <Button
                  type='primary'
                  icon={<SyncOutlined />}
                  onClick={() => {
                    setLoading(true);
                    ManualSyncKnowledgeBase.mutate({
                      number: data.number,
                      entryIndex: index,
                      entry: JSON.stringify(record),
                    });
                  }}
                >
                  Manual Sync
                </Button>
                <InfoOutlined className='text-sm cursor-pointer' />
              </Tooltip>
            </>
          )}
          <Button
            type='primary'
            danger
            icon={<DeleteFilled />}
            onClick={() => {
              setLoading(true);
              DeleteKnowledgeBase.mutate({
                number: data.number,
                entryIndex: index,
              });
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const openEditModal = (index: number, content: string) => {
    setEditContentIndex(index);
    setEditTextInput(content);
    setIsEditModalVisible(true);
  };

  const handleEditSave = () => {
    if (editContentIndex === null) return;
    UpdateKnowledgeBaseContent.mutate(
      {
        number: data.number,
        entryIndex: editContentIndex,
        content: editTextInput,
      },
      {
        onSettled: () => {
          setIsEditModalVisible(false);
          setEditTextInput('');
          setEditContentIndex(null);
        },
      }
    );
  };

  const handleAddText = () => {
    if (!textInput) return;
    setLoading(true);

    const formData = new FormData();
    formData.append('number', data.number);
    formData.append('textInput', textInput);

    GenerateKnowledgeBase.mutate(formData, {
      onSettled: () => {
        setLoading(false);
        setIsTextModalVisible(false);
        setTextInput('');
      },
    });
  };

  const handleFetchUrl = () => {
    if (!urlInput || !syncType) return;
    setLoading(true);

    const formData = new FormData();
    formData.append('number', data.number);
    formData.append('url', urlInput);
    formData.append('syncType', syncType);

    GenerateKnowledgeBase.mutate(formData, {
      onSettled: () => {
        setLoading(false);
        setIsUrlModalVisible(false);
        setUrlInput('');
        setSyncType('');
      },
    });
  };
  const uploadProps: UploadProps = {
    beforeUpload: file => {
      const isAllowedType =
        file.type === 'application/pdf' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'text/plain';

      if (!isAllowedType) {
        notification.error({
          message: 'You can only upload PDF, DOCX, or TXT files.',
        });
        return Upload.LIST_IGNORE;
      }

      setSelectedFile(file);
      setIsUploadModalVisible(true);
      return false;
    },
  };

  const handleUploadFile = () => {
    if (!selectedFile) return;
    setLoading(true);

    const formData = new FormData();
    formData.append('number', data.number);
    formData.append('files', selectedFile);

    GenerateKnowledgeBase.mutate(formData, {
      onSettled: () => {
        setLoading(false);
        setIsUploadModalVisible(false);
        setSelectedFile(null);
      },
    });
  };

  return (
    <div className='p-6 bg-white rounded-lg shadow-md'>
      <h2 className='text-2xl font-bold mb-4'>AI Knowledge Base 🧠</h2>
      <p>
        Here you can provide your business information or any other essential information for your AI agent. You can
        provide the information through <b>normal text</b>, by giving a <b>(URL / Address / Link)</b> to your website
        pages or by uploading <b>documents</b>. Your AI can also periodically refresh data from your provided website
        links.
      </p>
      <div className='flex flex-wrap gap-2 pb-2'>
        <Tooltip
          title='Give your AI information through simple text, you can update, deactivate or delete this text anytime! Maximum three text knowledge bases are allowed. Keep them short and descriptive for better results.'
          className='flex items-center space-x-2'
        >
          <Button type='primary' icon={<PlusOutlined />} onClick={() => setIsTextModalVisible(true)}>
            Add Text Knowledge Base
          </Button>
          <InfoOutlined className='text-sm cursor-pointer' />
        </Tooltip>
        <Tooltip
          title='Tired of typing information? Just refer your AI to one of your information pages on your website and let AI do its work! AI can fetch data from there automatically, also can refresh data periodically as specified. Maximum 3 URL knowledge base are allowed.'
          className='flex items-center space-x-2'
        >
          <Button type='primary' icon={<LinkOutlined />} onClick={() => setIsUrlModalVisible(true)}>
            {isMobile ? 'Fetch Knowledge Base f...' : 'Fetch Knowledge Base From URL'}
          </Button>
          <InfoOutlined className='text-sm cursor-pointer' />
        </Tooltip>
        <Tooltip
          title="Got information in documents or PDF's? No worries. You can upload here and AI will extract essential information from there! Maximum 4 documents are allowed (docs, pdfs) with a limit of 10,000 words."
          className='flex items-center space-x-2'
        >
          <Upload {...uploadProps} showUploadList={false}>
            <Button type='primary' icon={<UploadOutlined />}>
              {isMobile ? 'Extract Knowledge Base...' : 'Extract Knowledge Base From Document'}
            </Button>
          </Upload>
          <InfoOutlined className='text-sm cursor-pointer' />
        </Tooltip>
        <Tooltip
          title='Refresh your knowledge base to check on updates on running processes.'
          className='flex items-center space-x-2'
        >
          <Button type='default' icon={<ReloadOutlined />} onClick={refetch}>
            Refresh
          </Button>
        </Tooltip>
      </div>

      <div className='overflow-x-auto'>
        <Table
          columns={columns}
          dataSource={data?.knowledgeBase}
          rowKey={(_, index: any) => index.toString()}
          pagination={false}
          loading={loading}
        />
      </div>

      {/* Modals for Add Text, Fetch URL, Confirm Upload, and Edit Text */}
      {/* Add Text Modal */}
      <Modal
        title='Add Text'
        open={isTextModalVisible}
        onOk={handleAddText}
        onCancel={() => setIsTextModalVisible(false)}
        confirmLoading={loading}
        okText='Add'
      >
        {isNoviUser ? (
          <TextEditor
            placeholder='Please enter your business information'
            value={textInput}
            vvariables={variables}
            // @ts-ignore
            onChange={(text, variables) => {
              if (text) {
                setTextInput(text);
              }
              if (variables) {
                handleVariableChange(variables);
              }
            }}
          />
        ) : (
          <Input.TextArea
            style={{ margin: '1rem 0' }}
            autoSize={{ minRows: 8 }}
            maxLength={MAX_TEXT_CHARACTERS}
            value={textInput}
            onChange={e => setTextInput(e.target.value)}
            placeholder='Please enter your business information'
            showCount
          />
        )}
      </Modal>

      {/* Fetch URL Modal */}
      <Modal
        title='Fetch Knowledge Base From URL'
        open={isUrlModalVisible}
        onOk={handleFetchUrl}
        onCancel={() => setIsUrlModalVisible(false)}
        confirmLoading={loading}
        okText='Add'
      >
        <LabelWithDescription label='URL / Address / Link to the webpage:' />
        <Input
          value={urlInput}
          onChange={e => setUrlInput(e.target.value)}
          placeholder='https://yourwebsite.com/pricing'
          className='my-2'
          type='url'
          required
        />
        <LabelWithDescription label='Auto Update Data Duration' />
        <Select
          placeholder='Select Auto Update Duration'
          className='my-2 w-full'
          onChange={value => setSyncType(value)}
        >
          <Option value='none'>None</Option>
          <Option value='daily'>Daily</Option>
          <Option value='weekly'>Weekly</Option>
          <Option value='every 10 days'>Every 10 Days</Option>
          <Option value='every 2 weeks'>Every 2 Weeks</Option>
        </Select>
      </Modal>

      {/* Edit Text Modal */}
      <Modal
        width={'80%'}
        title='Edit Text'
        open={isEditModalVisible}
        onOk={handleEditSave}
        onCancel={() => setIsEditModalVisible(false)}
        okText='Save'
      >
        {isNoviUser ? (
          <TextEditor
            placeholder='Please enter your business information'
            value={editTextInput}
            vvariables={variables}
            // @ts-ignore
            onChange={(text, variables) => {
              if (text) {
                setEditTextInput(text);
              }
              if (variables) {
                handleVariableChange(variables);
              }
            }}
          />
        ) : (
          <Input.TextArea
            autoSize={{ minRows: 8 }}
            style={{ margin: '1rem 0' }}
            maxLength={MAX_TEXT_CHARACTERS}
            value={editTextInput}
            onChange={e => setEditTextInput(e.target.value)}
            placeholder='Please enter your business information'
            showCount
          />
        )}
      </Modal>

      <Modal
        title='Confirm Upload'
        open={isUploadModalVisible}
        onOk={handleUploadFile}
        onCancel={() => {
          setIsUploadModalVisible(false);
          setSelectedFile(null);
        }}
        confirmLoading={loading}
        okText='Upload'
      >
        <p>Are you sure you want to upload?</p>
      </Modal>

      <Modal
        title='View Text'
        open={isViewModalVisible}
        onCancel={() => setIsViewModalVisible(false)}
        footer={
          <Button icon={<CopyOutlined />} onClick={() => navigator.clipboard.writeText(selectedText)}>
            Copy Text
          </Button>
        }
      >
        <Typography.Paragraph>{selectedText}</Typography.Paragraph>
      </Modal>
    </div>
  );
};
