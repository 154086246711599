import { getZapierKey } from '@/api/user';
import { LoadingOutlined } from '@ant-design/icons';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useQuery } from '@tanstack/react-query';
import { Flex, Input, notification, Spin } from 'antd';
import Title from 'antd/es/typography/Title';
import ZapierIntegrationModal from '../ZapierIntegrationInstruction/ZapierIntegrationModal/ZapierIntegrationModal';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { MODAL_IDS } from 'src/enums/EModal';
import { openModal } from '@/store/modal/slice';

const ZapierIntegrationForm: React.FC<{ debrand: boolean }> = ({ debrand }) => {
  const dispatch = useAppDispatch();
  const open = () => {
    dispatch(openModal({ modalId: MODAL_IDS.RESELLER_ZAPIER_INSTRUCTION_MODAL }));
  };

  const { data, isPending } = useQuery({
    queryKey: ['zapier-api-key'],
    queryFn: () => getZapierKey({ isDebrand: !!debrand }),
  });

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notification.success({
          key: 'copy-url-success',
          message: 'Copied to clipboard',
          duration: 3,
        });
      })
      .catch(() => {
        notification.error({
          key: 'copy-url-failed',
          message: 'Failed to copy',
          duration: 3,
        });
      });
  };

  const renderInput = (value: string | undefined, title: string) => (
    <>
      <Title level={5}>{title}</Title>
      <Input
        readOnly
        value={value}
        placeholder='Loading...'
        addonAfter={
          <ContentCopyOutlinedIcon className='cursor-pointer' onClick={() => value && copyToClipboard(value)} />
        }
      />
    </>
  );

  return (
    <div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} spinning={isPending}>
        <span>Please copy your API key and username to integrate with Zapier.</span>
        <Flex vertical gap={8}>
          {renderInput(data?.inviteUrl, 'Zapier Invite Link')}
          {renderInput(data?.username, 'Username')}
          {renderInput(data?.apiKey, 'API Key')}

          {debrand ? (
            <>
              <span onClick={open} className='text-blue-500 underline text-base mt-4 cursor-pointer'>
                Click Here for Instructions
              </span>

              {<ZapierIntegrationModal />}
            </>
          ) : (
            <a
              href='https://www.myaifrontdesk.com/tutorials/how-to-integrate-ai-front-desk-with-any-app-using-zapier'
              target='_blank'
              rel='noopener noreferrer'
              className='text-base mt-4'
            >
              <span className='text-blue-500 underline'>Click Here for Instructions</span>
            </a>
          )}
        </Flex>
      </Spin>
    </div>
  );
};

export default ZapierIntegrationForm;
