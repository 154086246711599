import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Tag,
  Space,
  Input,
  Typography,
  Layout,
  Card,
  Row,
  Col,
  notification,
  Checkbox,
  Badge,
  Avatar,
  theme,
  FormInstance,
  Spin,
  Tooltip,
  Form,
  Modal,
} from 'antd';
import {
  PlusOutlined,
  ArrowLeftOutlined,
  BuildOutlined,
  PhoneOutlined,
  RightOutlined,
  ClockCircleOutlined,
  EditOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInNoviUser } from '@/store/novi/user/selector';
import { Content, Header } from 'antd/es/layout/layout';
import { useDispatch } from 'react-redux';
import { setNoviSelectedCampaign, setNoviSelectedCampaignId, setNoviUser } from '@/store/novi/user/slice';
import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { CustomModal } from '@/components/CustomModal/CustomModal.js';
import { GetStandAloneNumberForm } from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetStandAloneNumberForm.js';
import { provisionNumberRequest } from '@/api/reservedNumbers.js';
import GetChooseNumberForm from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetChooseNumberForm.js';
import { useMutation, useQuery } from '@tanstack/react-query';
import GetBundleApproveForm from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetBundleApproveForm.js';
import { APP_LANDING_PAGE } from 'src/enums/ERoutes.js';
import { acceptTermsAndConditions } from '@/api/novi/user.js';
import { duplicateACampaign, getCampaignToken, getNoviCampaigns, updateCampaignName } from '@/api/novi/campaigns.js';
import { getScheduleStatus } from '@/api/novi/schedule';

// @ts-ignore
import RegisterV3 from '../../../../../v1/RegisterPageV3';

const { Title, Text } = Typography;
const { useToken } = theme;

const NoviCampaigns = () => {
  const dispatch = useDispatch();
  const noviUser = useAppSelector(getLoggedInNoviUser);
  const formRef = useRef<FormInstance>(null);
  const { token } = useToken();

  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isStandAloneNumberModalVisible, setIsStandAloneNumberModalVisible] = useState<boolean>(false);
  const [isBYOTInstructionsModalVisible, setIsBYOTInstructionsModalVisible] = useState<boolean>(false);
  const [isBookNumberModalVisible, setIsBookNumberModalVisible] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [ChooseNumberModalOpen, setChooseNumberModalOpen] = useState<boolean>(false);
  const [isNonUSDetailsModalOpen, setIsNonUSDetailsModalOpen] = useState(false);
  const [twilioDocsRequirements] = useState<any>([]);
  const [currentClient, setCurrentClient] = useState('');
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [editCampaignModal, setEditCampaignModal] = useState<string | null>(null);
  const [duplicateCampaignModal, setDuplicateCampaignModal] = useState<string | null>(null);

  const [updateCampaignNameForm] = Form.useForm();
  const [duplicateCampaignForm] = Form.useForm();

  const headerStyle = {
    background: '#fff',
    padding: '12px 16px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.03)',
    height: 'auto',
    lineHeight: 'normal',
  };

  const cardStyle = {
    borderRadius: '8px',
    background: '#fafafa',
    marginBottom: '16px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
  };

  const {
    data: clients,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['campaignClients'],
    queryFn: () => getNoviCampaigns(),
    staleTime: 0,
  });

  const { data: scheduleStatus, isLoading: isScheduleLoading } = useQuery({
    queryKey: ['campaign-schedule', clients?.campaigns],
    queryFn: async () => {
      if (!clients?.campaigns) return [];
      const statusPromises = clients.campaigns.map(campaign => getScheduleStatus(campaign.number));
      return Promise.all(statusPromises);
    },
    enabled: !!clients?.campaigns,
  });

  const { mutate: provisionNumberRequestMutation, isPending: isProvisionApiPending } = useMutation({
    mutationKey: ['provisionNumberRequest'],
    mutationFn: provisionNumberRequest,
    onSuccess: () => {
      notification.success({ message: 'Provisioned a number successfully' });
      refetch();
    },
    onError: error => {
      let errorMsg;
      if (error?.message.includes('Authenticate')) errorMsg = 'Invalid Twilio credentials';
      notification.error({ message: errorMsg || 'Failed to provision number' });
    },
  });

  const { mutate: saveTermsAcceptanceMutation, isPending: isSavingTermsAcceptance } = useMutation({
    mutationKey: ['saveTermsAcceptance'],
    mutationFn: () => acceptTermsAndConditions(),
    onSuccess: async () => {
      notification.success({ message: 'Terms acceptance saved successfully' });
      setIsTermsModalVisible(false);
      dispatch(
        setNoviUser({
          ...noviUser,
          is_terms_accepted: true,
        })
      );
    },
    onError: () => {
      notification.error({ message: 'Failed to save terms acceptance' });
    },
  });

  const { mutate: updateCampaignMutation, isPending: isUpdatingnName } = useMutation({
    mutationKey: ['updateCampaign'],
    mutationFn: updateCampaignName,
    onSuccess: () => {
      notification.success({ message: 'Campaign updated successfully' });
      refetch();
      handleCancelEditCampaign();
    },
    onError: () => {
      notification.error({ message: 'Failed to update campaign' });
    },
  });

  const { mutate: duplicateCampaignMutation, isPending: isDuplicatingCampaign } = useMutation({
    mutationKey: ['duplicateCampaign'],
    mutationFn: duplicateACampaign,
    onSuccess: () => {
      notification.success({ message: 'Campaign duplicated successfully' });
      refetch();
      handleCancelDuplicateCampaign();
    },
    onError: () => {
      notification.error({ message: 'Failed to duplicate campaign' });
    },
  });

  const handleUpdateCampaignName = () => {
    updateCampaignNameForm.validateFields().then(values => {
      updateCampaignMutation({
        campaign_id: editCampaignModal as string,
        business_name: values.campaignName,
      });
    });
  };

  const duplicateCampaign = () => {
    duplicateCampaignForm.validateFields().then(values => {
      duplicateCampaignMutation({
        campaign_id: duplicateCampaignModal as string,
        business_name: values.duplicateCampaignName,
      });
    });
  };

  const getToken = async (campaign_id: string) => {
    try {
      const response = await getCampaignToken(campaign_id);
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN, response.auth_token);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  const handleOpenCampaign = async (number: string) => {
    await getToken(number);
    dispatch(setNoviSelectedCampaignId(number));
    dispatch(setNoviSelectedCampaign(clients?.campaigns.find(item => item.number === number)));
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const openBYOTInstructionsModal = () => {
    setIsBYOTInstructionsModalVisible(true);
  };

  const closeBYOTInstructionsModal = () => {
    setIsBYOTInstructionsModalVisible(false);
  };

  const openStandAloneNumberModal = () => {
    setIsStandAloneNumberModalVisible(true);
  };

  const closeStandAloneNumberModal = () => {
    setIsStandAloneNumberModalVisible(false);
  };

  const openBookNumberModal = () => {
    setIsBookNumberModalVisible(true);
  };

  const closeBookNumberModal = () => {
    setIsBookNumberModalVisible(false);
  };

  const handleFormSubmit = (values: { country: string }) => {
    setSelectedCountry(values.country);
    closeStandAloneNumberModal();
    openBookNumberModal();
  };

  const handleConfirmAction = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handleBack = () => {
    dispatch(setNoviSelectedCampaignId(null));
    dispatch(setNoviSelectedCampaign(null));
    setShowCreateCampaign(false);
  };

  const handleCreateCampaign = () => {
    setShowCreateCampaign(true);
  };

  const handleEditCampaign = (campaignID: string) => {
    setEditCampaignModal(campaignID);
  };

  const handleCancelEditCampaign = () => {
    setEditCampaignModal(null);
    updateCampaignNameForm.resetFields();
  };

  const handleDuplicateCampaign = (campaignID: string) => {
    setDuplicateCampaignModal(campaignID);
  };

  const handleCancelDuplicateCampaign = () => {
    setDuplicateCampaignModal(null);
    duplicateCampaignForm.resetFields();
  };

  const bookNumberRequest = async () => {
    if (!selectedCountry) {
      return;
    }

    var countryCode = selectedCountry.split(' - ')[2];

    let payload = {
      username: currentClient,
      countryCode,
      isNovi: true,
    };

    provisionNumberRequestMutation(payload);
    setIsBookNumberModalVisible(false);
    closeStandAloneNumberModal();
  };

  const handleTermsSubmit = () => {
    if (termsAccepted) {
      saveTermsAcceptanceMutation();
    } else {
      notification.error({
        message: 'Terms and Conditions',
        description: 'You must accept the Terms and Conditions to continue.',
      });
    }
  };

  const filteredData = clients?.campaigns.filter((item: any) =>
    item.business_name.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    if (!noviUser.is_terms_accepted) setIsTermsModalVisible(true);
  }, [noviUser.is_terms_accepted]);

  const renderCampaignCard = (campaign: any, index: number) => (
    <Card
      size='small'
      style={cardStyle}
      styles={{
        body: {
          padding: '16px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <div className='flex flex-col h-full gap-3'>
        <div className='flex items-start justify-between'>
          <Space>
            <Avatar icon={<BuildOutlined />} style={{ backgroundColor: token.colorPrimary }} size='small' />
            <div className='flex flex-col'>
              <Text strong style={{ fontSize: '14px' }}>
                {campaign.business_name}
              </Text>
              <Space size={4} className='mt-1'>
                <Tag color='blue' style={{ fontSize: '12px', padding: '0 8px' }}>
                  {campaign.businessType || 'No Business Type'}
                </Tag>
                <Tag color={campaign.isActive ? 'success' : 'default'} style={{ fontSize: '12px', padding: '0 8px' }}>
                  {campaign.isActive ? 'Active' : 'Inactive'}
                </Tag>
              </Space>
            </div>
          </Space>
          <Space>
            <Tooltip title='Edit Campaign'>
              <Button
                type='text'
                icon={<EditOutlined />}
                size='small'
                onClick={() => handleEditCampaign(campaign.number)}
              />
            </Tooltip>
            <Tooltip title='Duplicate Campaign'>
              <Button
                type='text'
                icon={<CopyOutlined />}
                size='small'
                onClick={() => handleDuplicateCampaign(campaign.number)}
              />
            </Tooltip>
          </Space>
        </div>

        <div className='flex-1'>
          <div className='grid gap-2'>
            <div className='flex items-center gap-2'>
              <PhoneOutlined style={{ color: token.colorTextSecondary, fontSize: '14px' }} />
              {campaign.reservedNumber ? (
                <Badge status='success' text={<Text style={{ fontSize: '12px' }}>{campaign.reservedNumber}</Text>} />
              ) : (
                <Text style={{ fontSize: '12px' }}>No number assigned</Text>
              )}
            </div>

            <div className='grid gap-1'>
              <div className='flex items-center gap-2'>
                <ClockCircleOutlined style={{ color: token.colorTextSecondary, fontSize: '14px' }} />
                {scheduleStatus?.[index]?.status === 'ACTIVE' ? (
                  <Badge
                    status='success'
                    text={
                      <Text style={{ fontSize: '12px' }}>
                        Schedule <Tag color='success'>Active</Tag>
                      </Text>
                    }
                  />
                ) : scheduleStatus?.[index]?.status === 'INACTIVE' ? (
                  <Badge
                    status='warning'
                    text={
                      <Text style={{ fontSize: '12px' }}>
                        Schedule <Tag color='warning'>In Active</Tag>
                      </Text>
                    }
                  />
                ) : (
                  <Badge status='default' text={<Text style={{ fontSize: '12px' }}>No Schedule</Text>} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-wrap gap-2 mt-auto'>
          {!campaign.reservedNumber && (
            <Button
              type='default'
              size='small'
              icon={<PhoneOutlined />}
              onClick={() => {
                if (noviUser.twilio_account_id && noviUser.twilio_access_token) {
                  setCurrentClient(campaign.number);
                  openStandAloneNumberModal();
                } else {
                  openBYOTInstructionsModal();
                }
              }}
              loading={isProvisionApiPending}
              disabled={isProvisionApiPending}
              style={{ fontSize: '12px' }}
            >
              Get standalone number
            </Button>
          )}
          <Button type='primary' size='small' onClick={() => handleOpenCampaign(campaign.number)} className='ml-auto'>
            Open Campaign <RightOutlined style={{ marginLeft: '4px' }} />
          </Button>
        </div>
      </div>
    </Card>
  );

  if (showCreateCampaign) {
    return (
      <Layout>
        <Header style={headerStyle}>
          <Button icon={<ArrowLeftOutlined />} onClick={handleBack} size='small' style={{ marginRight: '12px' }}>
            Back to Campaigns
          </Button>
        </Header>
        <Content style={{ padding: '16px' }}>
          <RegisterV3
            debrand
            campaign_user_email={noviUser.email_address}
            campaign_user_name={noviUser.business_name}
            isCampaign
          />
        </Content>
      </Layout>
    );
  }

  return (
    <Spin spinning={isLoading || isScheduleLoading}>
      <Layout style={{ background: 'transparent' }}>
        <div style={{ padding: '16px' }}>
          <Space direction='vertical' size='middle' style={{ width: '100%' }}>
            <div className='flex flex-wrap justify-between items-center gap-4'>
              <Title level={4} style={{ margin: 0, fontSize: '16px' }}>
                My Campaigns
              </Title>
              <Space wrap>
                <Input.Search
                  placeholder='Search campaigns'
                  onSearch={handleSearch}
                  onChange={e => handleSearch(e.target.value)}
                  style={{ width: '240px' }}
                  size='small'
                  allowClear
                />
                <Button type='primary' icon={<PlusOutlined />} onClick={handleCreateCampaign} size='small'>
                  Create Campaign
                </Button>
              </Space>
            </div>

            <Row gutter={[16, 16]}>
              {filteredData?.map((campaign: any, index: number) => (
                <Col xs={24} sm={12} md={12} lg={8} xl={8} key={campaign.number}>
                  {renderCampaignCard(campaign, index)}
                </Col>
              ))}
            </Row>
          </Space>
        </div>

        <CustomModal
          title='Bring Your Own Twilio'
          isModalOpen={isBYOTInstructionsModalVisible}
          confirmAction={closeBYOTInstructionsModal}
          cancelButtonProps={{ style: { display: 'none' } }}
          className=''
        >
          <div className='text-center'>
            <Text style={{ fontSize: '14px' }}>
              Please head over to the <span className='font-bold'>"Branding"</span> tab to add your twilio config
            </Text>
            <p className='font-bold'>OR</p>
            <p>
              Follow this{' '}
              <a
                className='text-blue-600'
                href='https://www.loom.com/share/ae3b7257107243e39bc1792b5e2c83c0'
                target='_blank'
                rel='noreferrer'
              >
                video
              </a>
            </p>
          </div>
        </CustomModal>

        <CustomModal
          title='Terms and Conditions'
          isModalOpen={isTermsModalVisible}
          confirmAction={handleTermsSubmit}
          cancelAction={() => {}}
          cancelButtonProps={{ style: { display: 'none' } }}
          closable={false}
          maskClosable={false}
          okButtonProps={{ loading: isSavingTermsAcceptance, size: 'small' }}
        >
          <Space direction='vertical' size='small'>
            <Text style={{ fontSize: '14px' }}>
              Please read and accept our Terms and Conditions before creating a campaign.
            </Text>
            <Checkbox checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)}>
              <Text style={{ fontSize: '12px' }}>
                I have read and agree to the{' '}
                <a
                  href={`${APP_LANDING_PAGE}/terms-of-service`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-600 hover:text-black'
                >
                  Terms and Conditions
                </a>
              </Text>
            </Checkbox>
          </Space>
        </CustomModal>

        <CustomModal
          title='Get your standalone number'
          isModalOpen={isStandAloneNumberModalVisible}
          confirmAction={handleConfirmAction}
          cancelAction={closeStandAloneNumberModal}
          okButtonProps={{ loading: isProvisionApiPending }}
          okText='Get Random Number'
        >
          <GetStandAloneNumberForm
            debrand
            isNovi
            ref={formRef}
            setCountry={(country: string) => setSelectedCountry(country)}
            onSubmit={handleFormSubmit}
            setChooseNumberModalOpen={setChooseNumberModalOpen}
          />
        </CustomModal>
        <CustomModal
          title='Save your changes'
          isModalOpen={isBookNumberModalVisible}
          confirmAction={() => bookNumberRequest()}
          cancelAction={closeBookNumberModal}
        >
          <Title level={4}>Click Ok to confirm you want to buy number for {selectedCountry.split('-')[1]} </Title>
        </CustomModal>
        <CustomModal
          title='Get Your Favourite Area Code'
          isModalOpen={ChooseNumberModalOpen}
          confirmAction={() => {
            setChooseNumberModalOpen(false);
            setIsBookNumberModalVisible(false);
          }}
          cancelAction={() => setChooseNumberModalOpen(false)}
          footer={null}
        >
          <GetChooseNumberForm
            isOpen={ChooseNumberModalOpen}
            onClose={() => setChooseNumberModalOpen(false)}
            selectedCountryCode={selectedCountry.split(' - ')[2]}
            bookNumberRequest={() => bookNumberRequest()}
          />
        </CustomModal>
        <CustomModal
          title={null}
          isModalOpen={isNonUSDetailsModalOpen}
          confirmAction={() => setIsNonUSDetailsModalOpen(false)}
          cancelAction={() => setIsNonUSDetailsModalOpen(false)}
          width={700}
          footer={null}
        >
          <GetBundleApproveForm
            countryCodeNumber={selectedCountry.split(' - ')[2]}
            twilioDocsRequirements={twilioDocsRequirements.requirements}
            regulationSid={twilioDocsRequirements.regulationSid}
            setClientDetails={() => {}}
            onClose={() => setIsNonUSDetailsModalOpen(false)}
          />
        </CustomModal>
        <Modal
          title='Edit Campaign'
          open={!!editCampaignModal}
          onOk={handleUpdateCampaignName}
          onCancel={handleCancelEditCampaign}
          width={600}
          centered
          footer={[
            <Button key='cancel' onClick={handleCancelEditCampaign}>
              Cancel
            </Button>,
            <Button key='save' type='primary' onClick={handleUpdateCampaignName} loading={isUpdatingnName}>
              Save
            </Button>,
          ]}
        >
          <Form form={updateCampaignNameForm} layout='vertical'>
            <Form.Item
              name='campaignName'
              label='Campaign Name'
              rules={[{ required: true, message: 'Please enter a campaign name' }]}
            >
              <Input placeholder='Enter campaign name' />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title='Duplicate Campaign'
          open={!!duplicateCampaignModal}
          onCancel={handleCancelDuplicateCampaign}
          footer={[
            <Button key='cancel' onClick={handleCancelDuplicateCampaign}>
              Cancel
            </Button>,
            <Button key='duplicate' type='primary' onClick={duplicateCampaign} loading={isDuplicatingCampaign}>
              Duplicate
            </Button>,
          ]}
        >
          <Form form={duplicateCampaignForm} layout='vertical'>
            <Form.Item
              name='duplicateCampaignName'
              label='New Campaign Name'
              rules={[
                {
                  required: true,
                  message: 'Please enter a name for the new campaign',
                },
              ]}
            >
              <Input placeholder='Enter new campaign name' />
            </Form.Item>
            <Text type='secondary'>The new campaign will be created with the same settings as the original.</Text>
          </Form>
        </Modal>
      </Layout>
    </Spin>
  );
};

export default NoviCampaigns;
