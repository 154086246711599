import { Card, Typography, Button, Steps } from 'antd';
import { ThunderboltOutlined, LinkOutlined, AppstoreOutlined } from '@ant-design/icons';
import { IoChevronBack } from 'react-icons/io5';

const { Title, Text } = Typography;

const ZapierGuide: React.FC<{ goBack: () => void }> = ({ goBack }) => {
  const zapierUrl = 'https://zapier.com/developer/public-invite/215190/ea1db180e895095ac1ccd99e09f12050/';

  return (
    <Card className='max-w-2xl p-6'>
      <Button type='link' icon={<IoChevronBack />} onClick={goBack} style={{ marginBottom: '20px' }}>
        Choose another method
      </Button>
      <div className='mb-8'>
        <Title level={4} className='flex items-center gap-2 mb-4'>
          <ThunderboltOutlined className='text-blue-500' />
          Connect Your Apps with Zapier
        </Title>
        <Text className='text-gray-600'>
          Automatically schedule calls when you receive information from any app - no coding needed!
        </Text>
      </div>

      <Steps
        direction='vertical'
        current={-1}
        items={[
          {
            title: 'Connect to Zapier',
            description: (
              <div className='mt-2'>
                <Button
                  type='primary'
                  icon={<LinkOutlined />}
                  onClick={() => window.open(zapierUrl, '_blank')}
                  className='mb-2'
                >
                  Go to Zapier
                </Button>
                <Text className='block text-gray-500 text-sm'>Sign up for free if you haven't already</Text>
              </div>
            ),
          },
          {
            title: 'Choose Your Trigger',
            description: (
              <div className='mt-2'>
                <div className='flex items-center gap-2 mb-2'>
                  <AppstoreOutlined />
                  <Text>Connect any of 6000+ apps as your trigger</Text>
                </div>
                <Text className='text-gray-500 text-sm'>Examples: CRM, Google Sheets, Email, etc.</Text>
              </div>
            ),
          },
          {
            title: 'Set Up AI Receptionist',
            description: (
              <Text className='mt-2 block text-gray-600'>
                Search for "AI Receptionist" and select "Schedule Call" action
              </Text>
            ),
          },
          {
            title: 'Map Fields',
            description: (
              <Text className='mt-2 block text-gray-600'>Match fields from your app to AI Receptionist</Text>
            ),
          },
          {
            title: 'Test & Finish',
            description: <Text className='mt-2 block text-gray-600'>Click "Test" to confirm everything works</Text>,
          },
        ]}
      />
    </Card>
  );
};

export default ZapierGuide;
