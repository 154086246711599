export enum ENoviDashboardSider {
  MY_CAMPAIGNS = 'My Campaigns',
  BRANDING = 'Branding',
  BYOT = 'BYOT',
  OUTBOUND_ANALYTICS = 'Outbound Analytics',
  LEADS = 'Leads',
  CONFIGURE = 'Configure',
  ANALYTICS = 'Analytics',
  VOICE_LIBRARY = 'Voice Library',
  TEXTING_PLATFORM = 'Texting Platform',
  CALL_LOGS = 'Call Logs',
  LOGS = 'Logs',
  INTAKE_FORMS = 'Intake Forms',
  TEXT_LOGS = 'Text Logs',
  VOICE_MAIL_LOGS = 'Voicemail Logs',
  REFERRAL_PROGRAM = 'Referral Program',
  TWO_WAY_TEXTING = '2-Way Texting',
  CRM = 'CRM',
  SCHEDULE = 'Schedule',
}
