import React, { useState } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { CallSentiment } from '@/interfaces/ILogs';

interface SentimentData {
  label: string;
  value: number;
  color: string;
}

interface SentimentsPanelProps {
  sentiments: CallSentiment;
}

const SentimentsPanel: React.FC<SentimentsPanelProps> = ({
  sentiments = { neutral: 51, positive: 43, negative: 6 },
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const sentimentData: SentimentData[] = [
    {
      label: 'Neutral',
      value: sentiments.neutral,
      color: 'bg-purple-200',
    },
    {
      label: 'Positive',
      value: sentiments.positive,
      color: 'bg-blue-200',
    },
    {
      label: 'Negative',
      value: sentiments.negative,
      color: 'bg-orange-200',
    },
  ];

  return (
    <div className='w-full max-w-md bg-white rounded-lg shadow-sm p-4'>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className='w-full flex items-center justify-between mb-4 text-gray-600'
      >
        <span className='text-sm font-medium'>SENTIMENTS</span>
        {isExpanded ? <ChevronUp className='w-5 h-5' /> : <ChevronDown className='w-5 h-5' />}
      </button>

      {isExpanded && (
        <div className='space-y-3'>
          {sentimentData.map(sentiment => (
            <div key={sentiment.label} className='flex items-center'>
              <div className={`w-2 h-2 rounded-full ${sentiment.color} mr-3`} />
              <div className='flex-1'>
                <div className='flex justify-between items-center mb-1'>
                  <span className='text-sm text-gray-700'>{sentiment.label}</span>
                  <span className='text-sm text-gray-500'>{sentiment.value}%</span>
                </div>
                <div className='w-full bg-gray-100 rounded-full h-1.5'>
                  <div className={`h-full rounded-full ${sentiment.color}`} style={{ width: `${sentiment.value}%` }} />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SentimentsPanel;
