import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import PublicLayout from '@/components/Layout/PublicLayout';
import {
  APP_LANDING_PAGE,
  AppRoutes,
  BOOK_CONSULTATION_LANDING_PAGE,
  PRIVACY_POLICY_LANDING_PAGE,
  TERMS_OF_USE_LANDING_PAGE,
} from 'src/enums/ERoutes';
import apiClient from '@/utils/apiClient';
import { useNavigate } from 'react-router-dom';
import './integrations.scss';

const { Meta } = Card;
const { Title } = Typography;

interface Capability {
  capability: string;
  description: string;
}

interface Integration {
  product: string;
  workflow: {
    step1: string;
    step2: string;
    step3: string;
  };
  datetime?: string;
  productDescription?: string;
  integrationLogo?: string;
  productLogo?: string;
  name: string;
  integration: string;
  capabilities: Capability[];
  integrationDescription?: string;
}

interface IntegrationsResponse {
  integration: {
    success: boolean;
    data?: Integration[];
    lastEvaluatedKey?: string | null;
    message?: string;
    error?: string;
  };
}

const formatTitle = (text: string): string => {
  return text
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const IntegrationsPage: React.FC = () => {
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [lastKey, setLastKey] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const navigationItems = [
    { key: 1, target: '_blank', url: APP_LANDING_PAGE, label: 'Product' },
    { key: 2, target: '_self', url: AppRoutes.RECEPTIONIST_LOGIN, label: 'Login' },
    { key: 3, target: '_self', url: AppRoutes.REGISTER, label: 'Register' },
    { key: 4, target: '_blank', url: BOOK_CONSULTATION_LANDING_PAGE, label: 'Book Consultation' },
    { key: 5, target: '_blank', url: APP_LANDING_PAGE, label: 'Contact' },
    { key: 6, target: '_blank', url: PRIVACY_POLICY_LANDING_PAGE, label: 'Privacy Policy' },
    { key: 7, target: '_blank', url: TERMS_OF_USE_LANDING_PAGE, label: 'Terms of Use' },
  ];

  const getIntegrations = async (key: string | null): Promise<IntegrationsResponse> => {
    try {
      let config: { params: { type: string; lastEvaluatedKey?: string } } = {
        params: {
          type: 'pagination',
        },
      };
      if (key) {
        config.params.lastEvaluatedKey = key;
      }

      const response = await apiClient.request({
        method: 'GET',
        endPoint: `/integrations`,
        ...config,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

  const loadMoreData = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await getIntegrations(lastKey);
      if (response?.integration?.success) {
        if (!response?.integration?.data || response.integration.data.length === 0) {
          setHasMore(false);
        } else {
          const parsedData = response.integration.data?.map(integration => ({
            ...integration,
            product: formatTitle(integration.product),
            integration: formatTitle(integration.integration),
            workflow:
              typeof integration.workflow === 'string' ? JSON.parse(integration.workflow) : integration.workflow,
            capabilities:
              typeof integration.capabilities === 'string'
                ? JSON.parse(integration.capabilities)
                : integration.capabilities,
          }));
          setIntegrations(prev => [...prev, ...parsedData]);
          setLastKey(response.integration.lastEvaluatedKey ?? null);
          if (!response.integration.lastEvaluatedKey) {
            setHasMore(false);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching integrations:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialLoad = async () => {
      try {
        const response = await getIntegrations(null);
        if (response.integration.success) {
          const parsedData = response.integration.data?.map(integration => ({
            ...integration,
            product: formatTitle(integration.product),
            integration: formatTitle(integration.integration),
            workflow:
              typeof integration.workflow === 'string' ? JSON.parse(integration.workflow) : integration.workflow,
            capabilities:
              typeof integration.capabilities === 'string'
                ? JSON.parse(integration.capabilities)
                : integration.capabilities,
          }));
          setIntegrations(parsedData as any);
          setLastKey(response.integration.lastEvaluatedKey ?? null);
          setHasMore(!!response.integration.lastEvaluatedKey);
        }
      } catch (error) {
        console.error('Error in initial load:', error);
        setHasMore(false);
      } finally {
        setInitialLoading(false);
      }
    };

    initialLoad();
  }, []);

  const handleCardClick = (name: string) => {
    navigate(`/integration/${name}`);
  };

  if (initialLoading) {
    return (
      <PublicLayout debrand={false} items={navigationItems}>
        <div className='loading-spinner-wrapper'>
          <Spin size='large' />
        </div>
      </PublicLayout>
    );
  }

  return (
    <PublicLayout debrand={false} items={navigationItems}>
      <div className='integrations-page'>
        <div className='scroll-container'>
          <div id='scrollableDiv'>
            <InfiniteScroll
              dataLength={integrations.length}
              next={loadMoreData}
              hasMore={hasMore}
              loader={
                loading && (
                  <div className='loading-spinner'>
                    <Spin size='large' />
                  </div>
                )
              }
              endMessage={
                integrations.length > 0 ? (
                  <div className='end-message'>
                    <Title level={5} className='end-message-text'>
                      No more integrations to load
                    </Title>
                  </div>
                ) : null
              }
              scrollableTarget='scrollableDiv'
              className='overflow-visible'
            >
              <div className='integrations-grid'>
                <Row gutter={[24, 24]} className='m-0 w-full'>
                  {integrations.map(item => (
                    <Col xs={24} sm={12} md={8} key={item.name} className='integration-card'>
                      <Card
                        hoverable
                        cover={
                          <div className='card-cover'>
                            <div className='logos-container'>
                              <img
                                alt={item.product}
                                src={item.productLogo || '/placeholder-logo.png'}
                                className='integration-logo'
                              />
                              <span className='plus-symbol'>+</span>
                              <img
                                alt={item.integration}
                                src={item.integrationLogo || '/placeholder-logo.png'}
                                className='integration-logo'
                              />
                            </div>
                          </div>
                        }
                        onClick={() => handleCardClick(item.name)}
                      >
                        <Meta
                          title={`${item.product} + ${item.integration}`}
                          description={
                            <div className='card-description'>
                              <p className='description-text'>{item.productDescription}</p>
                              {item.datetime && (
                                <p className='date-text'>
                                  {new Date(item.datetime).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  })}
                                </p>
                              )}
                            </div>
                          }
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default IntegrationsPage;
