import { Button, Input, Select, Form, Space, notification } from 'antd';
import { LeadsInfoFormProps } from '@/pages/Novi/types/Leads';
import { useMutation } from '@tanstack/react-query';
import { updateLeadDetails } from '@/api/novi/leads';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaignId } from '@/store/novi/user/selector';
const { Option } = Select;

const LeadsInfoForm: React.FC<LeadsInfoFormProps> = ({ lead, refetchLeads, onClose }) => {
  const campaign_id = useAppSelector(getSelectedCampaignId) as string;
  const [form] = Form.useForm();

  const options = [
    { label: 'Contacted', value: 'CONTACTED' },
    { label: 'New', value: 'NEW' },
  ];

  const { mutate: updateLeads, isPending } = useMutation({
    mutationKey: ['updateLeads'],
    mutationFn: updateLeadDetails,
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Lead data updated successfully',
      });
      refetchLeads();
      onClose();
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: error?.message || 'Something went wrong',
      });
    },
  });

  const handleSave = (values: any) => {
    const { phone_number, status, ...rest } = values;
    const variables = { ...rest };
    updateLeads({
      lead_id: lead.lead_id,
      campaign_id,
      leadDetails: {
        status,
        variables,
      },
    });
  };

  return (
    <Space direction='vertical' className='w-full'>
      <Form layout='vertical' form={form} onFinish={handleSave}>
        <Form.Item label='Phone Number' name='phone_number' initialValue={lead.phone_number}>
          <Input disabled />
        </Form.Item>
        <Form.Item label='Status' name='status' initialValue={lead.status}>
          <Select>
            {options.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {Object.keys(lead.variables).map(key => {
          return (
            <Form.Item key={key} label={key} name={key} initialValue={lead.variables[key]}>
              <Input />
            </Form.Item>
          );
        })}
        <Form.Item>
          <Space className='flex justify-end'>
            <Button type='primary' htmlType='submit' loading={isPending}>
              Save Changes
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Space>
  );
};

export default LeadsInfoForm;
