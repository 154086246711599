import React from 'react';
import { Card, Typography, Tooltip, notification, Button } from 'antd';
import {
  DeleteFilled,
  CopyOutlined,
  EyeOutlined,
  LockOutlined,
  FieldTimeOutlined,
  ClearOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { openModal } from '@/store/modal/slice';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import { MODAL_IDS } from 'src/enums/EModal';
import { ResellerSubAccountFeatures } from '@/types/TAccounts';

const { Title, Text } = Typography;

interface ReceptionistCardProps {
  business_name: string;
  number: string;
  password: string;
  minutesLimitEnabled?: boolean;
  max_usage_limit_logs?: Array<{
    month: string;
    usage: number;
  }>;
  onDelete: (number: string) => void;
  onPreview: (credentials: { username: string; password: string }) => void;
  onChangePassword: (number: string) => void;
  onClearLogs: (number: string) => void;
  onSetMinutesLimit: (number: string) => void;
  onConvert?: (number: string) => void;
  loading?: boolean;
  currentClient?: string | null;
  setCurrentClient: (client: string | null) => void;
  trial?: boolean;
  reservedNumber?: string;
  featureVisibilitySubClient?: Array<string>;
  featureGatingSubClient?: Array<string>;
  featuresSubAccount?: ResellerSubAccountFeatures;
}

const ReceptionistCard: React.FC<ReceptionistCardProps> = ({
  business_name,
  number,
  password,
  minutesLimitEnabled,
  max_usage_limit_logs,
  onDelete,
  onPreview,
  onChangePassword,
  onClearLogs,
  onSetMinutesLimit,
  onConvert,
  currentClient,
  setCurrentClient,
  loading,
  trial,
  reservedNumber,
  featuresSubAccount = {
    visibility: {
      featureVisibilitySidebar: [],
    },
  },
}) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    notification.success({
      message: 'Copied to clipboard',
      duration: 2,
    });
  };

  const dispatch = useAppDispatch();

  const getCurrentMonthUsage = () => {
    const currentMonth = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;
    return Math.floor(max_usage_limit_logs?.find(log => log.month === currentMonth)?.usage || 0);
  };

  if (loading && currentClient === number) {
    return <Card loading />;
  }

  const actionButtons = [
    <Tooltip title='Open SubAccount' key='preview'>
      <EyeOutlined
        className='text-blue-500 text-lg hover:text-blue-700'
        onClick={() => {
          setCurrentClient(null);
          setCurrentClient(number);
          onPreview({ username: number, password });
        }}
      />
    </Tooltip>,
    <Tooltip title='Change Password' key='password'>
      <LockOutlined className='text-gray-500 text-lg hover:text-gray-700' onClick={() => onChangePassword(number)} />
    </Tooltip>,
    <Tooltip title='Clear Logs' key='clear'>
      <ClearOutlined className='text-orange-500 text-lg hover:text-orange-700' onClick={() => onClearLogs(number)} />
    </Tooltip>,
    <Tooltip title='Minutes Limitation' key='minutes'>
      <FieldTimeOutlined
        className='text-green-500 text-lg hover:text-green-700'
        onClick={() => onSetMinutesLimit(number)}
      />
    </Tooltip>,
  ];

  // Add Delete button as the last action
  actionButtons.push(
    <Tooltip title='Delete Receptionist' key='delete'>
      <DeleteFilled className='text-red-500 text-lg hover:text-red-700' onClick={() => onDelete(number)} />
    </Tooltip>
  );

  return (
    <Card
      className='hover:shadow-lg transition-shadow duration-300'
      actions={actionButtons}
      extra={
        <div
          className='flex flex-col items-end'
          onClick={() => {
            dispatch(
              openModal({
                modalId: MODAL_IDS.FEATURE_GATING_CUSTOMIZE_MODAL,
                data: {
                  isTrialForm: trial,

                  number: number,
                  featuresSubAccount: featuresSubAccount,
                },
              })
            );
          }}
        >
          <span className='text-xs font-medium bg-blue-100 text-blue-800 px-2 mb-[-1px] rounded-full '>BETA</span>
          <Button type='link' className='border border-blue-500 text-blue-500 hover:!bg-blue-500 hover:!text-white'>
            <FeaturedPlayListOutlinedIcon />
            Feature Management
          </Button>
        </div>
      }
    >
      <div className='space-y-4'>
        <Title level={4} className='!mb-1 text-center flex flex-row justify-between'>
          <span>{business_name}</span>

          {
            // If the receptionist is a trial receptionist, display a trial badge
            trial && (
              <Tooltip title='Convert' key='convert'>
                <SyncOutlined
                  className='text-purple-500 text-lg hover:text-purple-700'
                  onClick={() => {
                    setCurrentClient(null);
                    setCurrentClient(number);
                    onConvert?.(number);
                  }}
                />
              </Tooltip>
            )
          }
        </Title>

        <div className='space-y-2'>
          <div className='flex items-center justify-between bg-gray-50 p-2 rounded'>
            <div>
              <Text type='secondary'>Username</Text>
              <div className='font-medium'>{number}</div>
            </div>
            <Tooltip title='Copy Username'>
              <CopyOutlined
                className='text-gray-500 cursor-pointer hover:text-blue-500'
                onClick={() => copyToClipboard(number)}
              />
            </Tooltip>
          </div>

          <div className='flex items-center justify-between bg-gray-50 p-2 rounded'>
            <div>
              <Text type='secondary'>Password</Text>
              <div className='font-medium'>{password}</div>
            </div>
            <Tooltip title='Copy Password'>
              <CopyOutlined
                className='text-gray-500 cursor-pointer hover:text-blue-500'
                onClick={() => copyToClipboard(password)}
              />
            </Tooltip>
          </div>

          {reservedNumber && (
            <div className='flex items-center justify-between bg-gray-50 p-2 rounded'>
              <div>
                <Text type='secondary'>Reserved Number</Text>
                <div className='font-medium'>{reservedNumber}</div>
              </div>
              <Tooltip title='Copy Username'>
                <CopyOutlined
                  className='text-gray-500 cursor-pointer hover:text-blue-500'
                  onClick={() => copyToClipboard(reservedNumber)}
                />
              </Tooltip>
            </div>
          )}
        </div>

        {minutesLimitEnabled && (
          <div className='mt-4 bg-blue-50 p-2 rounded'>
            <Text type='secondary'>Monthly Usage (minutes)</Text>
            <div className='font-medium'>{getCurrentMonthUsage()}</div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default ReceptionistCard;
