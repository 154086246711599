import LocalStorageUtility from '@/utils/localStorage';
import { Navigate, Outlet } from 'react-router-dom';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { AppRoutes } from 'src/enums/ERoutes';
import { NoviUser } from '../types/NoviUser';
import { jwtDecode } from 'jwt-decode';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setNoviUser } from '@/store/novi/user/slice';

interface DecodedToken {
  exp: number;
  [key: string]: any;
}

const isTokenExpired = (token: string): boolean => {
  try {
    const cleanToken = typeof token === 'string' ? token.replace('Bearer ', '').trim() : JSON.stringify(token);
    const { exp } = jwtDecode<DecodedToken>(cleanToken);
    return exp < Math.floor(Date.now() / 1000);
  } catch {
    return true;
  }
};

const isUserDataValid = (user: NoviUser | null): boolean => {
  if (!user) return false;

  const requiredKeys: (keyof NoviUser)[] = ['email_address', 'business_name', 'is_terms_accepted', 'logo'];
  return requiredKeys.every(key => user[key] !== undefined && user[key] !== null);
};

const NoviPrivateRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  let isAuthenticated = false;

  try {
    const authToken = LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_AUTH_TOKEN);
    const noviUser = LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_USER);

    const isTokenValid = !!authToken && !isTokenExpired(authToken as string);
    const isUserValid = isUserDataValid(noviUser as NoviUser);

    isAuthenticated = isTokenValid && isUserValid;

    if (!isAuthenticated) {
      LocalStorageUtility.clearLocalData();
    }

    dispatch(setNoviUser(noviUser as NoviUser));
  } catch {
    LocalStorageUtility.clearLocalData();
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={AppRoutes.NOVI_PORTAL_LOGIN} />;
};

export default NoviPrivateRoute;
