import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import ResellerDashboard from '../../ResellerDashboard/ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getPdfBySlug, getResellerAcademy } from '@/store/resellerAcademy/selector';
import { getResourceURL } from '@/api/reseller';
import { AppRoutes } from 'src/enums/ERoutes';
import './PdfViewer.scss';

const PdfViewer = () => {
  const { Title } = Typography;
  const { pdfSlug, chapterId } = useParams();
  const navigate = useNavigate();
  const { loading } = useAppSelector(getResellerAcademy);
  const pdf = useAppSelector(getPdfBySlug(Number(chapterId), pdfSlug as string));
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

  const {
    data: pdfURL,
    isLoading: pdfURLLoading,
    refetch: refetchPdfURL,
  } = useQuery({
    queryKey: ['resource-url', pdf?.key],
    queryFn: () => {
      if (!pdf?.key) {
        return null;
      }
      return getResourceURL(pdf?.key as string);
    },
    staleTime: 55 * 60 * 1000,
    //@ts-ignore
    cacheTime: 60 * 60 * 1000,
  });

  // Refresh URL periodically
  useEffect(() => {
    const refreshInterval = setInterval(
      () => {
        refetchPdfURL();
      },
      55 * 60 * 1000
    );

    return () => clearInterval(refreshInterval);
  }, [refetchPdfURL]);

  const getSecureUrl = (url: string) => {
    // Ensure URL has necessary parameters to prevent download
    return `${url}#toolbar=0&navpanes=0&view=FitH`;
  };

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.ACADEMY} showHeader={false}>
      <Spin spinning={loading || pdfURLLoading || !isDocumentLoaded}>
        <div className='pdf-viewer__container'>
          <div className='pdf-viewer__header'>
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(AppRoutes.RESELLER_ACADEMY_CHAPTER.replace(':chapterId', chapterId as string))}
              className='pdf-viewer__back-button'
            >
              Back to Chapter
            </Button>

            <div className='pdf-viewer__title-section'>
              <Title level={2} className='pdf-viewer__title'>
                {pdf?.title}
              </Title>
            </div>
          </div>

          <div className='pdf-viewer__content'>
            <div className='pdf-viewer__primary-content'>
              <div className='pdf-viewer__wrapper'>
                {pdfURL?.url && (
                  <object
                    data={getSecureUrl(pdfURL.url)}
                    type='application/pdf'
                    className='pdf-viewer__document'
                    onLoad={() => setIsDocumentLoaded(true)}
                  >
                    <p>Unable to display PDF file. Please install a PDF reader or try a different browser.</p>
                  </object>
                )}
              </div>
            </div>

            {/* <div className='pdf-viewer__side-content'>
              {pdf?.description && (
                <Card className='pdf-viewer__content-card'>
                  <Title level={5}>Description</Title>
                  <Text>{pdf.description}</Text>
                </Card>
              )}
            </div> */}
          </div>
        </div>
      </Spin>
    </ResellerDashboard>
  );
};

export default PdfViewer;
