import { combineReducers, configureStore } from '@reduxjs/toolkit';
import accountReducer from './account/slice';
import appReducer from './app/slice';
import modalReducer from './modal/slice';
import resellerReducer from './reseller/slice';
import resellerTourReducer from './resellerTour/slice';
import noviUserAccountReducer from './novi/user/slice';
import resellerAcademyReducer from './resellerAcademy/slice';

const rootReducer = combineReducers({
  app: appReducer,
  account: accountReducer,
  modal: modalReducer,
  reseller: resellerReducer,
  resellerTour: resellerTourReducer,
  noviUserAccount: noviUserAccountReducer,
  resellerAcademy: resellerAcademyReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: {
    serialize: true,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
