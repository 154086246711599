import * as React from 'react';
import ResellerDashboard from '../ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import {
  cleanDataOfManagedReceptionist,
  convertTrialReceptionist,
  deleteReceptionist,
  fetchResellerTestClients,
  ResellerDashboardLink,
  updateManagedReceptionist,
} from '@/api/reseller';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Col, Input, InputNumber, notification, Row, Space, Switch, Typography } from 'antd';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import { Loading } from '@/components/Loading';
import ReceptionistCard from '@/Reseller_Common_Components/ReceptionistCard.tsx';
import LocalStorageUtility from '@/utils/localStorage';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { loginApi } from '@/api/user';
import { setAccount } from '@/store/account/slice';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { Add, CopyAll } from '@mui/icons-material';
// @ts-ignore
import RegisterPageV3 from '../../../v1/RegisterPageV3.jsx';
import classes from '../../../v1/Whitelabel/ResellerPortal.module.css';
import { CustomModal } from '@/components/CustomModal/CustomModal.js';
import { ReceptionistDashboard } from '@/pages/ReceptionistDashboard/ReceptionistDashboard.tsx';
import { updateReseller } from '@/store/reseller/slice.js';
import { AppRoutes } from 'src/enums/ERoutes.js';
import { CopyOutlined } from '@ant-design/icons';
import FeatureGatingCustomizeModal from '../Reseller_Common_Components/FeatureGatingCustomizeModal.js';

const TrialReceptionist: React.FC = () => {
  const [clients, setClients] = React.useState<any[]>([]);
  const resellerData = useAppSelector(getResellerData);
  const [receptionistCurrentlySelected, setCurrentReceptionist] = React.useState<{ number: string } | null>(null);
  const [deleteReceptionistNoticeModal, setDeleteReceptionistNoticeModal] = React.useState<boolean>(false);
  const [changeMangedReceptionistPasswordModal, setChangeMangedReceptionistPasswordModal] =
    React.useState<boolean>(false);
  const [clearAllLogsWarningModal, setClearAllLogsWarningModal] = React.useState<boolean>(false);
  const [resellerMinutesLimitationModal, setResellerMinutesLimitationModal] = React.useState<boolean>(false);
  const [currentClient, setCurrentClient] = React.useState<any>(null);
  const [previewDashboardModalIsOpen, setPreviewDashboardModalIsOpen] = React.useState<boolean>(false);
  const [isRegistrationModal, setIsRegistrationModalActive] = React.useState<boolean>(false);
  const [embedLinkModal, setEmbedLinkModal] = React.useState<boolean>(false);
  const [successConfigureReceptionistModal, setSuccessConfigureReceptionistModal] = React.useState<boolean>(false);
  const [maxMinutes, setMaxMinutes] = React.useState<number | null>(60); // Default max_minutes value
  const [minutesLimitEnabled, setMinutesLimitEnabled] = React.useState<boolean>(false); // Default enabled value
  const [newPassword, setNewPassword] = React.useState<string>('');

  const { Title, Text } = Typography;

  const dispatch = useAppDispatch();

  const { data } = useQuery({
    queryKey: ['getResellerDashboardLink', resellerData.email_address],
    queryFn: () => ResellerDashboardLink({ email: resellerData.email_address }),
  });

  const previewCurrentClientMutation = useMutation({
    mutationKey: ['previewCurrentClient'],
    mutationFn: loginApi,
    onSuccess(data) {
      if (data.token) {
        dispatch(
          setAccount({
            isAuthenticated: true,
            user: data,
            authToken: data.token,
            currentPage: '',
            activeConfigureTab: '',
          })
        );

        LocalStorageUtility.setLocalData(LocalStorageKeys.AUTH_TOKEN, data.token);
      }
      setPreviewDashboardModalIsOpen(true);
    },
    onError() {
      notification.error({
        message: 'Error previewing client',
      });
    },
  });

  const getTestClientsMutation = useMutation({
    mutationKey: ['fetchResellerTestClients'],
    mutationFn: fetchResellerTestClients,
    onSuccess(data: any) {
      setClients(data.items);
    },
    onError() {},
  });

  const onConvertTrialClient = useMutation({
    mutationKey: ['convertTrialClient'],
    mutationFn: convertTrialReceptionist,
    onSuccess(_, payload) {
      setClients(prev => prev.filter(client => client.number !== payload.subAccountUsername));
      if (resellerData.unreservedReceptionists > 0)
        dispatch(
          updateReseller({
            ...resellerData,
            unreservedReceptionists: resellerData.unreservedReceptionists - 1,
          })
        );

      notification.success({
        message: 'Client converted to full account',
      });
    },
    onError() {
      notification.error({
        message: 'Error converting client',
      });
    },
  });

  const updateMangedReceptionistMinutes = useMutation({
    mutationKey: ['updateMangedReceptionistMinutes'],
    mutationFn: updateManagedReceptionist,
    onSuccess(_, payload) {
      notification.success({
        message: 'Receptionist Minutes updated',
      });

      setClients(prev =>
        prev.map(client => {
          if (client.number === payload.receptionist_username) {
            return {
              ...client,
              max_minutes: maxMinutes,
              minutesLimitEnabled: minutesLimitEnabled,
            };
          }
          return client;
        })
      );

      setResellerMinutesLimitationModal(false);
    },
    onError() {
      notification.error({
        message: 'Error updating receptionist minutes',
      });
    },
  });

  const clearAllLogsMutation = useMutation({
    mutationKey: ['clearAllLogs'],
    mutationFn: cleanDataOfManagedReceptionist,
    onSuccess() {
      notification.success({
        message: `Logs cleared for ${receptionistCurrentlySelected?.number}`,
      });
    },
    onError() {
      notification.error({
        message: 'Error clearing logs',
      });
    },
  });
  const updateMangedReceptionistPassword = useMutation({
    mutationKey: ['updateMangedReceptionistPassword'],
    mutationFn: updateManagedReceptionist,
    onSuccess(_, payload) {
      notification.success({
        message: 'Receptionist password updated',
      });

      setClients(prev =>
        prev.map(client => {
          if (client.number === payload.receptionist_username) {
            return {
              ...client,
              password: newPassword,
            };
          }
          return client;
        })
      );
      setChangeMangedReceptionistPasswordModal(false);
    },
    onError() {
      notification.error({
        message: 'Error updating receptionist password',
      });
    },
  });

  const deleteReceptionistMutation = useMutation({
    mutationKey: ['deleteReceptionist'],
    mutationFn: deleteReceptionist,

    onSuccess(_, payload) {
      setClients(prev => prev.filter(client => client.number !== payload.receptionist_username));

      notification.success({
        message: 'Receptionist deleted',
      });
    },
    onError() {
      notification.error({
        message: 'Error deleting receptionist',
      });
    },
  });

  // Copy the embed link
  const handleCopyIframe = async () => {
    if (data?.link) {
      try {
        const iframeUrl = `${import.meta.env.VITE_APP_WIHTELABEL_URL}${AppRoutes.UNBRANDED_CLIENT_TRIAL}?code=${data.link}`;
        const iframeString = `<iframe src="${iframeUrl}" width="100%" height="100%" frameBorder="0" style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        border: 'none',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        zIndex: 999999,
      }}></iframe>`;

        await navigator.clipboard.writeText(iframeString);
        notification.success({ message: 'iframe copied successfully' });
      } catch {
        notification.error({ message: 'Failed to copy iframe' });
      }
    } else {
      notification.error({ message: 'No link available to copy' });
    }
  };

  React.useEffect(() => {
    if (resellerData.email_address) {
      getTestClientsMutation.mutate(resellerData.email_address);
    }
  }, [resellerData.email_address]);

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.TRIAL_RECEPTIONIST}>
      <FeatureGatingCustomizeModal setClients={data => setClients(data)} clients={clients} />

      {!resellerData || getTestClientsMutation.isPending ? (
        <Loading />
      ) : (
        <div className='!h-full p-6'>
          <div className='flex flex-row justify-between'>
            <h1 className='text-2xl font-semibold mb-4'>Trial Receptionists ({clients.length})</h1>
            <Space direction='horizontal'>
              <Button
                type='default'
                onClick={() => {
                  setEmbedLinkModal(true);
                }}
              >
                <CopyAll />
                Embed iframe
              </Button>
              <Button
                type='default'
                onClick={() => {
                  setIsRegistrationModalActive(true);
                }}
              >
                <Add />
                Trial Account
              </Button>
            </Space>
          </div>
          <Row gutter={[16, 16]}>
            {clients.map(client => (
              <Col key={client.id} xs={24} sm={12} md={8}>
                <ReceptionistCard
                  business_name={client.business_name}
                  number={client.number}
                  password={client.password}
                  minutesLimitEnabled={client.minutesLimitEnabled}
                  max_usage_limit_logs={client.max_usage_limit_logs}
                  loading={previewCurrentClientMutation.isPending || onConvertTrialClient.isPending}
                  featureVisibilitySubClient={client?.featureVisibility || []}
                  featuresSubAccount={client.featuresSubAccount}
                  featureGatingSubClient={client?.featureGating || []}
                  onConvert={number => {
                    onConvertTrialClient.mutate({
                      reseller_email: resellerData.email_address,
                      subAccountUsername: number,
                    });
                  }}
                  trial={true}
                  onDelete={(number: any) => {
                    setCurrentReceptionist({ number });
                    setDeleteReceptionistNoticeModal(true);
                  }}
                  onPreview={(credentials: any) => {
                    previewCurrentClientMutation.mutate(credentials);
                  }}
                  onChangePassword={(number: any) => {
                    setCurrentReceptionist({ number });
                    setChangeMangedReceptionistPasswordModal(true);
                  }}
                  onClearLogs={(number: any) => {
                    setCurrentReceptionist({ number });
                    setClearAllLogsWarningModal(true);
                  }}
                  currentClient={currentClient}
                  setCurrentClient={setCurrentClient}
                  onSetMinutesLimit={(number: any) => {
                    setCurrentReceptionist({ number });
                    setResellerMinutesLimitationModal(true);
                  }}
                />
              </Col>
            ))}
          </Row>
          {isRegistrationModal && (
            <>
              <div
                onClick={() => {
                  setIsRegistrationModalActive(false);
                }}
                className={classes.modalOverlay}
              ></div>
              <div className={`${classes.modal} ${classes.wide}`}>
                <div
                  className={classes.close}
                  onClick={() => {
                    setIsRegistrationModalActive(false);
                  }}
                >
                  &times;
                </div>

                <RegisterPageV3
                  debrand={true}
                  reseller_email={resellerData.email_address}
                  purchase_quotes={resellerData.purchase_quotes}
                  reseller_name={resellerData.reseller_name}
                  test={true}
                  features={resellerData.features}
                  debranded_registration_modal_active_setter={setIsRegistrationModalActive}
                  debrand_modal_instructions_setter={(prop: any) => {
                    getTestClientsMutation.mutate(resellerData.email_address);
                    setSuccessConfigureReceptionistModal(prop);
                  }}
                />
              </div>
            </>
          )}
          {/* All Modals below this point */}
          <CustomModal
            title='Success'
            isModalOpen={successConfigureReceptionistModal}
            confirmAction={() => {
              setSuccessConfigureReceptionistModal(false);
            }}
            cancelAction={() => {
              setSuccessConfigureReceptionistModal(false);
            }}
          >
            <Title level={4}>Congrats, your new Test receptionist has been onboarded successfully!</Title>
          </CustomModal>
          <CustomModal
            title={null}
            isModalOpen={changeMangedReceptionistPasswordModal}
            confirmAction={() => {
              if (receptionistCurrentlySelected && newPassword) {
                updateMangedReceptionistPassword.mutate({
                  receptionist_username: receptionistCurrentlySelected.number,
                  fields: { password: newPassword },
                });
              }
            }}
            cancelAction={() => {
              setChangeMangedReceptionistPasswordModal(false);
            }}
            okText='Change Password'
          >
            <Title level={4}>Change Password for username {receptionistCurrentlySelected?.number}</Title>
            <Input
              placeholder='New Password'
              onChange={e => {
                setNewPassword(e.target.value);
              }}
            />
          </CustomModal>

          <CustomModal
            title='Are you sure?'
            isModalOpen={clearAllLogsWarningModal}
            confirmAction={() => {
              if (receptionistCurrentlySelected) {
                clearAllLogsMutation.mutate({
                  receptionist_username: receptionistCurrentlySelected.number,
                  tableNames: ['voicemails', 'textLogs', 'callLogs'],
                });
              }
              setClearAllLogsWarningModal(false);
            }}
            cancelAction={() => {
              setClearAllLogsWarningModal(false);
            }}
            okText='Yes, Proceed'
          >
            <Title level={4}>
              This action cannot be undone. Are you sure you want to clear all logs for{' '}
              {receptionistCurrentlySelected?.number} receptionist?
            </Title>
          </CustomModal>

          <CustomModal
            title='Minutes Limitation'
            isModalOpen={resellerMinutesLimitationModal}
            confirmAction={() => {
              if (receptionistCurrentlySelected) {
                updateMangedReceptionistMinutes.mutate({
                  receptionist_username: receptionistCurrentlySelected.number,
                  fields: {
                    max_minutes: maxMinutes,
                    usage_notification_emails: [resellerData.email_address],
                    enabled: minutesLimitEnabled,
                  },
                });
              }
            }}
            cancelAction={() => {
              setResellerMinutesLimitationModal(false);
            }}
            okText='Save'
          >
            <Title level={4}>Set Minutes Limitation for {receptionistCurrentlySelected?.number}</Title>

            <div className='mt-4 space-y-4'>
              <div className='flex flex-col'>
                <label className='font-medium'>Maximum Minutes</label>
                <InputNumber
                  min={1}
                  value={maxMinutes}
                  onChange={value => setMaxMinutes(value)}
                  className='w-full mt-2'
                />
              </div>

              <div className='flex flex-row justify-between items-center'>
                <label className='font-medium'>Enable Limitation</label>
                <Switch checked={minutesLimitEnabled} onChange={checked => setMinutesLimitEnabled(checked)} />
              </div>
            </div>
          </CustomModal>

          <CustomModal
            title='Success'
            isModalOpen={successConfigureReceptionistModal}
            confirmAction={() => {
              setSuccessConfigureReceptionistModal(false);
            }}
            cancelAction={() => {
              setSuccessConfigureReceptionistModal(false);
            }}
          >
            <Title level={4}>Congrats, your new receptionist has been onboarded successfully!</Title>
          </CustomModal>

          <CustomModal
            title='Are you sure?'
            isModalOpen={deleteReceptionistNoticeModal}
            confirmAction={() => {
              if (receptionistCurrentlySelected) {
                deleteReceptionistMutation.mutate({
                  receptionist_username: receptionistCurrentlySelected.number,
                  reseller_email: resellerData.email_address,
                });
              }
              setDeleteReceptionistNoticeModal(false);
            }}
            cancelAction={() => {
              setDeleteReceptionistNoticeModal(false);
            }}
            okText='Yes, Proceed'
          >
            <Title level={4}>This action cannot be undone. Are you sure you want to delete this receptionist?</Title>
          </CustomModal>

          <CustomModal
            title={null}
            footer={null}
            isModalOpen={previewDashboardModalIsOpen}
            confirmAction={() => {
              setPreviewDashboardModalIsOpen(false);
            }}
            width={'90%'}
            cancelAction={() => {
              setPreviewDashboardModalIsOpen(false);
            }}
          >
            <ReceptionistDashboard debrand={true} />
          </CustomModal>
          <CustomModal
            title={null}
            isModalOpen={embedLinkModal}
            confirmAction={() => {
              setEmbedLinkModal(false);
              handleCopyIframe();
            }}
            cancelAction={() => {
              setEmbedLinkModal(false);
            }}
          >
            <Title level={4}>Embed Trial iframe</Title>
            <p className='text-sm'>
              Copy the below Iframe code and paste it on one of your website pages to embed the registration form for
              the free trial.
            </p>
            <Typography.Text strong>For Trial Register</Typography.Text>

            <Input
              value={`<iframe src=${import.meta.env.VITE_APP_WIHTELABEL_URL + AppRoutes.UNBRANDED_CLIENT_TRIAL + '?code=' + data?.link} width="100%" height="100%" frameBorder="0"></iframe>`}
              readOnly
              suffix={
                <CopyOutlined
                  className='cursor-pointer text-gray-500 hover:text-gray-700'
                  onClick={() => handleCopyIframe()}
                />
              }
            />
            <Text className='text-md text-red-600'>
              Note: Same Login iframe can be used for both trial and regular users.
            </Text>
          </CustomModal>
        </div>
      )}
    </ResellerDashboard>
  );
};

export default TrialReceptionist;
