import { NoviUser } from '@/pages/Novi/types/NoviUser';
import apiClient from '@/utils/apiClient';

const REGISTER_USER = '/api/novi/register';
const LOGIN_USER = '/api/novi/login';
const UPDATE_LOGO = '/api/novi/users/logo';
const UPDATE_DETAILS = '/api/novi/users/details';
const ACCEPT_TERMS_AND_CONDITIONS = '/api/novi/users/terms';
const ADD_TWILIO_KEYS = '/api/novi/users/twilio';

const NOVI = 'NOVI';

export const registerNoviUser = async (payload: {
  email_address: string;
  password: string;
  logo?: File;
  business_name: string;
}): Promise<{
  success: boolean;
  auth_token: string;
  user: NoviUser;
}> => {
  try {
    const formData = new FormData();
    formData.append('email_address', payload.email_address);
    formData.append('password', payload.password);
    formData.append('business_name', payload.business_name);

    if (payload.logo) {
      formData.append('logo', payload.logo);
    }

    const response = await apiClient.request({
      method: 'POST',
      endPoint: REGISTER_USER,
      body: formData,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const loginNoviUser = async (payload: {
  email_address: string;
  password: string;
}): Promise<{
  success: boolean;
  auth_token: string;
  user: NoviUser;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: LOGIN_USER,
      body: payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateLogo = async (logo: File): Promise<{ message: string; logo: string }> => {
  const formData = new FormData();
  formData.append('logo', logo);

  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: UPDATE_LOGO,
      clientType: NOVI,
      body: formData,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateUserDetails = async (business_name: string): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: UPDATE_DETAILS,
      clientType: NOVI,
      body: { business_name },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const acceptTermsAndConditions = async (): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      clientType: NOVI,
      endPoint: ACCEPT_TERMS_AND_CONDITIONS,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const addTwilioKeys = async (payload: {
  twilio_account_id: string;
  twilio_access_token: string;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'PATCH',
      endPoint: ADD_TWILIO_KEYS,
      clientType: NOVI,
      body: payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
