import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

const EmailRegisterForm = ({ onChangeEmail, classes, setStepState, toast, emailAddress, setEmailAddress, onNext }) => {
  const [emailAddressState, setEmailAddressState] = useState(emailAddress || '');

  const handleEmailChange = event => {
    const value = event.target.value.toLowerCase(); // Convert to lowercase
    console.log('EmailRegisterForm -> value', value);
    setEmailAddressState(value);
    setEmailAddress(value);
    if (onChangeEmail) {
      onChangeEmail(event);
    }
  };

  const handleNextClick = () => {
    if (emailAddress) {
      // Check if email is valid before proceeding
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(emailAddress) || /\s/.test(emailAddress)) {
        toast.error('Please enter a valid email address');
        return;
      }

      if (onNext) {
        onNext();
      } else {
        setStepState(6);
      }
    } else {
      toast.error('Please enter your email address');
    }
  };

  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            Email Address
          </h3>
          <div
            className={classes.subtitle}
            style={{
              textAlign: 'center',
            }}
          >
            Your email address
          </div>

          <input
            type='email'
            value={emailAddressState}
            className={classes.input + ' ' + classes.centered}
            required
            onChange={handleEmailChange}
            placeholder='e.g. john@ai_dma.com'
          />

          <button
            type='button'
            onClick={handleNextClick}
            className={classes.button + ' ' + classes.centered}
            style={{
              marginTop: '2rem',
            }}
          >
            Continue
          </button>
        </Fade>
      </div>
    </>
  );
};

export default EmailRegisterForm;
