import { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Typography, Button, Card, Input, Space, Layout, Row, Col } from 'antd';
import StepCard from './StepCard';
import ctaImage from '@/assets/images/cta-image.png';
import { IntegrationPage } from '@/interfaces/IBlogs';
import { Loading } from '@/components/Loading';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getIntegrations } from '@/api/articles';
import PublicLayout from '@/components/Layout/PublicLayout';
import {
  APP_LANDING_PAGE,
  AppRoutes,
  BOOK_CONSULTATION_LANDING_PAGE,
  PRIVACY_POLICY_LANDING_PAGE,
  TERMS_OF_USE_LANDING_PAGE,
} from 'src/enums/ERoutes';
import './integrationPage.scss';

const { Title, Text } = Typography;

const items = [
  { key: 1, target: '_blank', url: APP_LANDING_PAGE, label: 'Product' },
  { key: 2, target: '_self', url: AppRoutes.RECEPTIONIST_LOGIN, label: 'Login' },
  { key: 3, target: '_self', url: AppRoutes.REGISTER, label: 'Register' },
  { key: 4, target: '_blank', url: BOOK_CONSULTATION_LANDING_PAGE, label: 'Book Consultation' },
  { key: 5, target: '_blank', url: APP_LANDING_PAGE, label: 'Contact' },
  { key: 6, target: '_blank', url: PRIVACY_POLICY_LANDING_PAGE, label: 'Privacy Policy' },
  { key: 7, target: '_blank', url: TERMS_OF_USE_LANDING_PAGE, label: 'Terms of Use' },
];

interface Capability {
  capability: string;
  description: string;
}

const IntegrationHeader = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data: integrationsData, isLoading: loading } = useQuery<IntegrationPage, Error>({
    queryKey: ['integration', slug],
    queryFn: () => getIntegrations({ type: 'integration', slug: slug }),
    enabled: !!slug,
  });

  const integrationType = slug!.includes('aifrontdesk') ? 'inbound' : 'outbound';

  const [productLogo, setProductLogo] = useState(
    'https://blogpostimage.s3.us-west-1.amazonaws.com/appimages/aifrontdesk.png'
  );
  const [integrationLogo, setIntegrationLogo] = useState('');
  const [product, setProduct] = useState('AI Front Desk');
  const [integration, setIntegration] = useState('Slack');
  const [integrationDescription, setIntegrationDescription] = useState('');
  const [productDescription, setProductDescription] = useState(
    'AI Front Desk is an AI-powered receptionist that automates your phone call management.'
  );
  const [step1, setStep1] = useState('A trigger is an event that initiates the automation.');
  const [step2, setStep2] = useState('An action is what takes place after the automation is triggered.');
  const [step3, setStep3] = useState("That's it! You just connected AI Front Desk to Product.");
  const [capabilities, setCapabilities] = useState<Capability[]>([]);

  useEffect(() => {
    if (integrationsData) {
      setProductLogo(integrationsData.productLogo);
      setIntegrationLogo(integrationsData.integrationLogo);
      const formattedProduct = integrationsData.product
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      setProduct(formattedProduct);
      setProductDescription(integrationsData.productDescription);
      setIntegrationDescription(integrationsData.integrationDescription);
      const formattedIntegration = integrationsData.integration
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      setIntegration(formattedIntegration);

      try {
        const stepsObject = JSON.parse(integrationsData.workflow);
        setStep1(stepsObject.step1 || '');
        setStep2(stepsObject.step2 || '');
        setStep3(stepsObject.step3 || '');
      } catch (error) {
        console.error('Error parsing steps:', error);
        setStep1('');
        setStep2('');
        setStep3('');
      }

      try {
        const capabilitiesArray = JSON.parse(integrationsData.capabilities);
        setCapabilities(capabilitiesArray || []);
      } catch (error) {
        console.error('Error parsing capabilities:', error);
        setCapabilities([]);
      }
    }
  }, [integrationsData]);

  if (loading) {
    return (
      <Layout.Content style={{ padding: '64px 0' }}>
        <Loading />
      </Layout.Content>
    );
  }

  return (
    <PublicLayout debrand={false} items={items}>
      <Layout.Content>
        <div className='integration-container'>
          <div className='main-wrapper'>
            <Row gutter={[32, 32]} justify='center' align='top'>
              <Col xs={24} md={12} style={{ maxWidth: '560px' }}>
                <Title level={1} className='integration-title'>
                  {integrationType === 'inbound'
                    ? `How to connect your AI Phone Receptionist to ${integration}`
                    : `How to trigger an AI Phone Call with ${product}`}
                </Title>

                <Button
                  type='primary'
                  size='large'
                  className='start-button'
                  onClick={() => {
                    window.location.href =
                      integrationType === 'inbound'
                        ? 'https://app.myaifrontdesk.com/register'
                        : 'https://www.myaifrontdesk.com/novi';
                  }}
                >
                  Start now for free
                </Button>
              </Col>

              <Col xs={24} md={12} style={{ maxWidth: '480px' }}>
                <Space direction='vertical' size={20}>
                  <Card className='step-card' hoverable>
                    <Space size={24}>
                      {productLogo ? (
                        <img src={productLogo} alt={product} className='step-logo' />
                      ) : (
                        <div className='logo-placeholder' />
                      )}
                      <div>
                        <Text strong className='step-title'>
                          {integrationType === 'inbound' ? '1. AI answers the phone' : '1. An action occurs'}
                        </Text>
                        <Text type='secondary'>
                          {integrationType === 'inbound'
                            ? 'Collects phone call data and talks like a human'
                            : `Trigger unique actions from ${product}`}
                        </Text>
                      </div>
                    </Space>
                  </Card>

                  <div style={{ textAlign: 'center' }}>
                    <DownOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </div>

                  <Card className='step-card' hoverable>
                    <Space size={24}>
                      {integrationLogo ? (
                        <img src={integrationLogo} alt={integration} className='step-logo' />
                      ) : (
                        <div className='logo-placeholder' />
                      )}
                      <div>
                        <Text strong className='step-title'>
                          {integrationType === 'inbound'
                            ? `2. Sends call data to ${integration}`
                            : '2. Make an outbound call'}
                        </Text>
                        <Text type='secondary'>
                          {integrationType === 'inbound'
                            ? `Automate tasks in ${integration} using call data`
                            : `Automatically initiate or schedule an outbound call on ${integration}`}
                        </Text>
                      </div>
                    </Space>
                  </Card>

                  <div style={{ textAlign: 'center' }}>
                    <DownOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '16px' }}>
                    <div className='endless-possibilities-pill'>
                      <Text style={{ color: 'success', fontWeight: 600 }}>Endless possibilities</Text>
                    </div>
                  </div>
                </Space>
              </Col>
            </Row>
          </div>

          <div className='app-details-section'>
            <Title level={2} className='app-details-title'>
              App details
            </Title>

            <Row gutter={{ xs: 8, md: 20 }}>
              <Col xs={24} md={12}>
                <div className='app-logo-container'>
                  <img src={productLogo} alt={product} className='app-logo' />
                  <Text style={{ fontWeight: 600 }}>{product}</Text>
                </div>
                <Text style={{ color: 'text.secondary' }}>{productDescription}</Text>
              </Col>

              <Col xs={24} md={12}>
                <div className='app-logo-container'>
                  <img src={integrationLogo} alt={integration} className='app-logo' />
                  <Text style={{ fontWeight: 600 }}>{integration}</Text>
                </div>
                <Text style={{ color: 'text.secondary' }}>{integrationDescription}</Text>
              </Col>
            </Row>
          </div>

          <StepCard
            integrationType={integrationType}
            product={product}
            integration={integration}
            productLogo={productLogo}
            integrationLogo={integrationLogo}
            step1={step1}
            step2={step2}
            step3={step3}
          />

          <div className='integration-form-section'>
            <Title level={2} className='integration-form-title'>
              Connect your phone calls with {integration} now.
            </Title>

            <div className='form-container'>
              <div className='form-logos'>
                {productLogo && <img src={productLogo} alt={product} className='app-logo' />}
                {integrationLogo && <img src={integrationLogo} alt={integration} className='app-logo' />}
              </div>
              <Input
                placeholder='Describe your integration here'
                className='integration-input'
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    window.location.href =
                      integrationType === 'inbound'
                        ? 'https://app.myaifrontdesk.com/register'
                        : 'https://www.myaifrontdesk.com/novi';
                  }
                }}
              />
              <Button
                type='primary'
                className='connect-button'
                onClick={() => {
                  window.location.href =
                    integrationType === 'inbound'
                      ? 'https://app.myaifrontdesk.com/register'
                      : 'https://www.myaifrontdesk.com/novi';
                }}
              >
                Connect
              </Button>
            </div>
          </div>

          {capabilities.length > 0 && (
            <div className='capabilities-section'>
              <Title level={2} className='capabilities-title'>
                Our Capabilities
              </Title>
              <Text className='capability-subtitle'>
                See what {product} can do with {integration}.
              </Text>

              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 24]}>
                {capabilities.map((capability, index) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={index} style={{ marginBottom: '24px' }}>
                    <Card className='capability-card'>
                      <div className='app-logo-container'>
                        {integrationLogo ? (
                          <img src={integrationLogo} alt={integration} className='app-logo' />
                        ) : (
                          <div className='logo-placeholder' />
                        )}
                        <Text style={{ fontWeight: 600 }}>{capability.capability}</Text>
                      </div>
                      <Text style={{ color: 'text.secondary' }}>{capability.description}</Text>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )}

          <div className='cta-section'>
            <Card className='cta-card'>
              <Row gutter={[48, 48]} align='middle'>
                <Col xs={24} md={12}>
                  <img src={ctaImage} alt='CTA' style={{ maxWidth: '100%', height: 'auto' }} />
                </Col>

                <Col xs={24} md={12}>
                  <Title level={3} style={{ marginBottom: '16px', fontWeight: 600 }}>
                    Try Our AI Receptionist Today
                  </Title>
                  <Text style={{ marginBottom: '32px', display: 'block', color: 'text.secondary' }}>
                    Start your free trial for My AI Front Desk today, it takes minutes to setup!
                  </Text>
                  <Space direction='vertical' size='large' style={{ width: '100%', maxWidth: '300px' }}>
                    <Button
                      onClick={() => {
                        window.location.href =
                          integrationType === 'inbound'
                            ? 'https://app.myaifrontdesk.com/register'
                            : 'https://www.myaifrontdesk.com/novi';
                      }}
                      type='primary'
                      size='large'
                      block
                      className='cta-primary'
                    >
                      TRY FOR FREE
                    </Button>
                    <Button
                      href='https://www.myaifrontdesk.com/white-label'
                      target='_blank'
                      rel='noopener noreferrer'
                      size='large'
                      block
                      className='cta-secondary'
                    >
                      BECOME A RESELLER
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </Layout.Content>
    </PublicLayout>
  );
};

export default IntegrationHeader;
