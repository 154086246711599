import React, { useState } from 'react';
import { Upload, Input, Button, Typography, notification, Space } from 'antd';
import { CloseOutlined, PictureOutlined } from '@ant-design/icons';

import { useMutation } from '@tanstack/react-query';
import { updateLogo, updateUserDetails } from '@/api/novi/user';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInNoviUser } from '@/store/novi/user/selector';
import { setNoviUser } from '@/store/novi/user/slice';

const { Title, Text } = Typography;

const Branding: React.FC = () => {
  const dispatch = useDispatch();
  const noviUser = useAppSelector(getLoggedInNoviUser);

  const [businessName, setBusinessName] = useState<string | ''>(noviUser?.business_name || '');
  const [logo, setLogo] = useState<string | null>(noviUser?.logo || null);

  const { mutate: updateBrandingLogo } = useMutation({
    mutationFn: updateLogo,
    onSuccess: data => {
      dispatch(setNoviUser({ ...noviUser, logo: data.logo }));
      notification.success({
        message: 'Success',
        description: 'Logo uploaded successfully',
        placement: 'bottomRight',
        className: 'compact-notification',
      });
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: error.message,
        placement: 'bottomRight',
        className: 'compact-notification',
      });
      setLogo(noviUser?.logo || null);
    },
  });

  const { mutate: updateUser, isPending } = useMutation({
    mutationFn: updateUserDetails,
    onSuccess: () => {
      dispatch(setNoviUser({ ...noviUser, business_name: businessName }));
      notification.success({
        message: 'Success',
        description: 'Details updated successfully',
        placement: 'bottomRight',
        className: 'compact-notification',
      });
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: error.message,
        placement: 'bottomRight',
        className: 'compact-notification',
      });
      setBusinessName(noviUser?.business_name || '');
    },
  });

  const handleSave = () => {
    updateUser(businessName);
  };

  const beforeUpload = (file: File) => {
    const isImage = file.type.startsWith('image/');
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isImage) {
      notification.error({
        message: 'Invalid File',
        description: 'Please upload an image file',
        placement: 'bottomRight',
        className: 'compact-notification',
      });
      return false;
    }

    if (!isLt2M) {
      notification.error({
        message: 'File Too Large',
        description: 'Image must be smaller than 2MB',
        placement: 'bottomRight',
        className: 'compact-notification',
      });
      return false;
    }

    const previewURL = URL.createObjectURL(file);
    setLogo(previewURL);
    updateBrandingLogo(file);
    return false;
  };

  return (
    <div className='max-w-2xl mx-auto px-4 py-6'>
      <Space direction='vertical' size='middle' className='w-full'>
        <div className='text-center mb-8'>
          <Title level={4} className='text-blue-600 m-0 font-medium'>
            Brand Your Business
          </Title>
        </div>

        <div className='bg-white p-6 rounded-lg shadow-sm'>
          <Space direction='vertical' size='large' className='w-full'>
            <div className='flex justify-center mb-6'>
              <div className='relative'>
                <Upload showUploadList={false} beforeUpload={beforeUpload} className='cursor-pointer'>
                  <div className='w-32 h-32 sm:w-40 sm:h-40 rounded-lg border-2 border-dashed border-gray-200 flex flex-col items-center justify-center bg-gray-50 hover:bg-gray-100 transition-colors duration-200'>
                    {logo ? (
                      <div className='relative w-full h-full'>
                        <img src={logo} alt='Business logo' className='w-full h-full object-cover rounded-lg' />
                        <div className='absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-200 rounded-lg'>
                          <Text className='text-white text-sm'>Change Logo</Text>
                        </div>
                      </div>
                    ) : (
                      <>
                        <PictureOutlined className='text-2xl text-gray-400' />
                        <Text className='text-sm text-gray-500 mt-2'>Upload logo</Text>
                        <Text className='text-xs text-gray-400'>Max 2MB</Text>
                      </>
                    )}
                  </div>
                </Upload>
                {logo && (
                  <Button
                    icon={<CloseOutlined className='text-xs' />}
                    onClick={e => {
                      e.stopPropagation();
                      setLogo(null);
                    }}
                    className='absolute -top-2 -right-2 rounded-full w-6 h-6 p-0 flex items-center justify-center bg-white shadow-sm hover:bg-gray-50 border-gray-200'
                  />
                )}
              </div>
            </div>

            <div className='space-y-1.5'>
              <Text className='text-sm font-medium text-gray-700 block'>Business Name</Text>
              <Input
                size='middle'
                value={businessName}
                onChange={e => setBusinessName(e.target.value)}
                placeholder='Enter your business name'
                className='rounded-md'
              />
            </div>

            <Button
              type='primary'
              size='middle'
              onClick={handleSave}
              loading={isPending}
              className='w-full sm:w-auto mt-4'
            >
              Save Changes
            </Button>
          </Space>
        </div>
      </Space>
    </div>
  );
};

export default Branding;
