import React from 'react';
import { Tabs, List, Tag, Typography, Timeline, Statistic, Row, Col } from 'antd';
import {
  PhoneOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { outboundCallEvent } from '@/pages/Novi/types/Events';
import { Lead } from '@/pages/Novi/types/Leads';

const { Text } = Typography;

interface OutboundAnalyticsDashboardProps {
  lead: Lead;
  events: outboundCallEvent[];
}

interface EventsByType {
  immediate: outboundCallEvent[];
  scheduled: outboundCallEvent[];
  regular: outboundCallEvent[];
  hybrid: outboundCallEvent[];
}

const EventType = {
  CALL_NOW: 'CALL_NOW',
  CALL_LATER: 'CALL_LATER',
  REGULAR: 'REGULAR',
  CALL_NOW_AND_FOLLOW_SCHEDULE: 'CALL_NOW_AND_FOLLOW_SCHEDULE',
};

const getStatusColor = (status: string) => {
  switch (status?.toUpperCase()) {
    case 'SUCCESS':
      return 'green';
    case 'FAILED':
    case 'ERROR':
      return 'red';
    case 'PENDING':
      return 'blue';
    default:
      return 'default';
  }
};

const formatDate = (date: string | number) => {
  return new Date(date).toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const EventHeader: React.FC<{ event: outboundCallEvent }> = ({ event }) => {
  const lastExecution = event.execution_times?.[event.execution_times.length - 1];
  const nextExecution = event.event_execution_time;

  return (
    <div className='mb-4'>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic
            title={<Text className='text-xs'>Event State</Text>}
            value={event.event_state}
            valueStyle={{ color: getStatusColor(event.event_state), fontSize: '14px' }}
            prefix={
              event.event_state === 'SUCCESS' ? (
                <CheckCircleOutlined />
              ) : event.event_state === 'FAILED' ? (
                <CloseCircleOutlined />
              ) : (
                <ClockCircleOutlined />
              )
            }
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={<Text className='text-xs'>Last Execution</Text>}
            value={lastExecution ? formatDate(lastExecution) : 'N/A'}
            valueStyle={{ fontSize: '14px' }}
            prefix={<ClockCircleOutlined />}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={<Text className='text-xs'>Next Execution</Text>}
            value={nextExecution ? formatDate(nextExecution) : 'Not Scheduled'}
            valueStyle={{ fontSize: '14px' }}
            prefix={<CalendarOutlined />}
          />
        </Col>
      </Row>
    </div>
  );
};

const CallItem: React.FC<{ event: outboundCallEvent }> = ({ event }) => {
  const timelineItems =
    event.execution_times?.map((time: string, index: number) => {
      const status = event.state_histories?.[index];

      return {
        color: getStatusColor(status),
        dot:
          status === 'SUCCESS' ? (
            <CheckCircleOutlined />
          ) : status === 'FAILED' ? (
            <CloseCircleOutlined />
          ) : (
            <ClockCircleOutlined />
          ),
        children: (
          <div className='flex items-center justify-between py-1'>
            <Text className='text-xs' strong>
              {formatDate(time)}
            </Text>
            <div>
              <Tag className='text-xs' color={getStatusColor(status)}>
                {status}
              </Tag>
            </div>
          </div>
        ),
      };
    }) || [];

  if (event.event_execution_time) {
    timelineItems.push({
      color: 'blue',
      dot: <ClockCircleOutlined />,
      children: (
        <div className='flex items-center justify-between py-1'>
          <Text type='secondary' className='text-xs'>
            Next scheduled execution
          </Text>
          <Text strong className='text-xs'>
            {formatDate(event.event_execution_time)}
          </Text>
        </div>
      ),
    });
  }

  return (
    <List.Item className='px-4 py-3 hover:bg-gray-50'>
      <div className='space-y-4 w-full'>
        <EventHeader event={event} />

        <div>
          <Text strong className='text-sm'>
            Execution History
          </Text>
          <Timeline items={timelineItems} className='mt-2' />
        </div>

        <div className='grid grid-cols-2 gap-2'>
          <Text type='secondary' className='text-xs'>
            Recording: Not available
          </Text>
          <Text type='secondary' className='text-xs'>
            Transcript: Not available
          </Text>
        </div>

        {event.errors_blob && (
          <div className='mt-2 bg-red-50 p-2 rounded-md'>
            <div className='flex items-start gap-2'>
              <InfoCircleOutlined className='text-red-500 mt-1' />
              <div>
                <Text className='text-xs text-red-700 font-medium'>Error Details</Text>
                <pre className='text-xs text-red-600 mt-1 whitespace-pre-wrap'>
                  {typeof event.errors_blob === 'string'
                    ? event.errors_blob
                    : JSON.stringify(event.errors_blob, null, 2)}
                </pre>
              </div>
            </div>
          </div>
        )}
      </div>
    </List.Item>
  );
};

const OutboundAnalyticsDashboard: React.FC<OutboundAnalyticsDashboardProps> = ({ lead, events }) => {
  const eventsByType = {
    immediate: events.filter(e => e.event_type === EventType.CALL_NOW),
    scheduled: events.filter(e => e.event_type === EventType.CALL_LATER),
    regular: events.filter(e => e.event_type === EventType.REGULAR),
    hybrid: events.filter(e => e.event_type === EventType.CALL_NOW_AND_FOLLOW_SCHEDULE),
  };

  const tabItems = [
    {
      key: 'immediate',
      label: (
        <span className='flex items-center'>
          <PhoneOutlined className='mr-1' /> Immediate
          <Tag className='ml-2 text-xs' color='blue'>
            {eventsByType.immediate.length}
          </Tag>
        </span>
      ),
      children: (
        <List
          size='small'
          dataSource={eventsByType.immediate}
          renderItem={event => <CallItem event={event} />}
          className='mt-2'
        />
      ),
    },
    {
      key: 'scheduled',
      label: (
        <span className='flex items-center'>
          <CalendarOutlined className='mr-1' /> Scheduled
          <Tag className='ml-2 text-xs' color='blue'>
            {eventsByType.scheduled.length}
          </Tag>
        </span>
      ),
      children: (
        <List
          size='small'
          dataSource={eventsByType.scheduled}
          renderItem={event => <CallItem event={event} />}
          className='mt-2'
        />
      ),
    },
    {
      key: 'regular',
      label: (
        <span className='flex items-center'>
          <CalendarOutlined className='mr-1' /> Regular
          <Tag className='ml-2 text-xs' color='blue'>
            {eventsByType.regular.length}
          </Tag>
        </span>
      ),
      children: (
        <List
          size='small'
          dataSource={eventsByType.regular}
          renderItem={event => <CallItem event={event} />}
          className='mt-2'
        />
      ),
    },
    {
      key: 'hybrid',
      label: (
        <span className='flex items-center'>
          <PhoneOutlined className='mr-1' /> Hybrid
          <Tag className='ml-2 text-xs' color='blue'>
            {eventsByType.hybrid.length}
          </Tag>
        </span>
      ),
      children: (
        <List
          size='small'
          dataSource={eventsByType.hybrid}
          renderItem={event => <CallItem event={event} />}
          className='mt-2'
        />
      ),
    },
  ].filter(item => eventsByType[item.key as keyof EventsByType].length > 0);

  return (
    <div className='max-w-3xl mx-auto bg-white rounded-lg shadow-sm'>
      <div className='p-3 border-b'>
        <div className='flex items-center gap-2'>
          <PhoneOutlined className='text-blue-500' />
          <Text className='text-sm font-medium'>{lead.phone_number}</Text>
        </div>
      </div>

      <Tabs items={tabItems} className='px-2' size='small' tabBarStyle={{ marginBottom: 0 }} />
    </div>
  );
};

export default OutboundAnalyticsDashboard;
