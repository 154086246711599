import { useState } from 'react';
import { Card, Input, Button, notification, Space, Typography } from 'antd';
import { TagOutlined, CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import ConfettiExplosion from 'react-confetti-explosion';

const { Text } = Typography;

const CouponRedemption = ({
  updateSuccess,
  applyCouponCode,
}: {
  updateSuccess: () => void;
  applyCouponCode: (coupon: string) => Promise<{
    success: boolean;
    message?: string;
  }>;
}) => {
  const [couponCode, setCouponCode] = useState('');
  const [inputError, setInputError] = useState('');
  const [isExploding, setIsExploding] = useState(false);

  const applyCouponMutation = useMutation({
    mutationKey: ['apply-coupon'],
    mutationFn: applyCouponCode,
    onSuccess: _ => {
      setIsExploding(true);
      setTimeout(() => setIsExploding(false), 2200);

      notification.success({
        message: 'Coupon Applied!',
        icon: <CheckCircleFilled className='text-green-500' />,
      });

      updateSuccess();
      setCouponCode('');
      setInputError('');
    },
    onError: error => {
      setInputError(error.message || 'Invalid coupon code');
      notification.error({
        message: 'Error',
        description: error.message || 'Failed to apply coupon',
      });
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!couponCode.trim()) {
      setInputError('Please enter a coupon code');
      return;
    }
    applyCouponMutation.mutate(couponCode);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCouponCode(value);
    if (inputError) {
      setInputError('');
    }
  };

  return (
    <Card className='shadow-sm relative overflow-hidden'>
      {isExploding && (
        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50'>
          <ConfettiExplosion force={0.8} duration={2200} particleCount={100} width={1600} />
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <Space direction='vertical' className='w-full'>
          <div className='flex items-center gap-2 mb-4'>
            <TagOutlined className='text-blue-500 text-lg' />
            <span className='font-medium text-base'>Have a coupon code?</span>
          </div>

          <div className='flex items-start gap-2 p-3 bg-gray-50 rounded-md mb-3'>
            <InfoCircleOutlined className='text-blue-500 mt-1' />
            <Text type='secondary' className='text-sm'>
              Note: Only one coupon can be active for a subscription at a time.
            </Text>
          </div>

          <div className='flex gap-2'>
            <Input
              value={couponCode}
              onChange={handleInputChange}
              placeholder='Enter coupon code'
              status={inputError ? 'error' : undefined}
              className='flex-1'
              disabled={applyCouponMutation.isPending}
            />
            <Button type='primary' onClick={handleSubmit} loading={applyCouponMutation.isPending}>
              Apply
            </Button>
          </div>

          {inputError && (
            <Text type='danger' className='mt-1'>
              {inputError}
            </Text>
          )}

          {applyCouponMutation.isSuccess && (
            <div className='flex items-center gap-2 text-green-500 mt-2'>
              <CheckCircleFilled />
              <span>Coupon applied successfully!</span>
            </div>
          )}
        </Space>
      </form>
    </Card>
  );
};

export default CouponRedemption;
