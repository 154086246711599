import { useState, useCallback, useEffect } from 'react';
import { Card, ColorPicker, Button, Input, Upload, Space, InputNumber, Alert } from 'antd';
import {
  PhoneOutlined,
  CustomerServiceOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
  // InfoCircleOutlined,
} from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { RECEPTIONIST_NUMBER, RECEPTIONIST_NUMBER_US_RESELLER } from 'src/constants';
import type { Color } from 'antd/es/color-picker';
import type { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import CodeBlock from './CodeBlock';
// import Joyride, { Step } from 'react-joyride';

interface Config {
  From: string;
  To: string;
  Called: string;
  Direction: string;
  websiteCalling: boolean;
  theme: 'gradient' | 'light' | 'dark' | 'solid';
  icon: string;
  customIcon: string;
  size: {
    width: number;
    height: number;
  };
  colors: {
    primary: string;
    secondary: string;
    background: string;
    icon: string;
  };
  position: {
    bottom: number;
    right: number;
  };
}

interface Theme {
  id: Config['theme'];
  preview: string;
}

interface IconOption {
  id: string;
  component: typeof PhoneOutlined;
  preview?: string;
}

const CallWidgetConfigurator: React.FC<{ debrand: boolean }> = ({}) => {
  // const [runTour, setRunTour] = useState(false);
  const loggedInUser = useAppSelector(getLoggedInUser);
  const getInitialCallParams = () => {
    if (loggedInUser.provisionedNumber) {
      return {
        From: loggedInUser.provisionedNumber,
        To: loggedInUser.provisionedNumber,
        Called: loggedInUser.provisionedNumber,
        Direction: 'outbound',
      };
    } else if (loggedInUser.testing_number) {
      const receptionistNumber = loggedInUser.reseller_email ? RECEPTIONIST_NUMBER_US_RESELLER : RECEPTIONIST_NUMBER;

      return {
        From: loggedInUser.testing_number,
        To: receptionistNumber,
        Called: receptionistNumber,
        Direction: 'outbound',
      };
    }

    // Default values if no user configuration is available
    return {
      From: '',
      To: '',
      Called: '',
      Direction: 'outbound',
    };
  };

  const [config, setConfig] = useState<Config>({
    ...getInitialCallParams(),
    websiteCalling: true,
    theme: 'solid',
    icon: 'phone',
    customIcon: '',
    size: {
      width: 64,
      height: 64,
    },
    colors: {
      primary: '#f00',
      secondary: '#7C3AED',
      background: '#FFFFFF',
      icon: '#FFFFFF',
    },
    position: {
      bottom: 40,
      right: 40,
    },
  });

  // Update config when user data changes
  useEffect(() => {
    setConfig(prev => ({
      ...prev,
      ...getInitialCallParams(),
    }));
  }, [loggedInUser]);

  const themes: Theme[] = [
    {
      id: 'gradient',
      preview: 'bg-gradient-to-br from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl',
    },
    {
      id: 'light',
      preview: 'bg-white border-2 border-blue-400 text-blue-600 shadow hover:shadow-lg hover:border-blue-500',
    },
    {
      id: 'dark',
      preview: 'bg-gray-800 border-2 border-blue-400 text-white shadow-lg hover:shadow-xl hover:border-blue-500',
    },
    {
      id: 'solid',
      preview: 'bg-blue-500 text-white shadow-lg hover:shadow-xl hover:bg-blue-600',
    },
  ];

  const icons: IconOption[] = [
    { id: 'phone', component: PhoneOutlined, preview: 'rotate-90' },
    { id: 'headset', component: CustomerServiceOutlined },
    { id: 'support', component: QuestionCircleOutlined },
  ];

  const handleChange = (path: string, value: any) => {
    setConfig(prev => {
      const newConfig = { ...prev };
      const parts = path.split('.');
      let current: any = newConfig;

      for (let i = 0; i < parts.length - 1; i++) {
        current = current[parts[i]];
      }
      current[parts[parts.length - 1]] = value;
      return newConfig;
    });
  };

  const handleIconUpload = useCallback((info: UploadChangeParam<UploadFile>) => {
    const { file } = info;

    if (file.status === 'done' && file.originFileObj) {
      const reader = new FileReader();
      reader.onload = event => {
        const svgContent = event.target?.result as string;
        handleChange('customIcon', svgContent);
        handleChange('icon', 'custom');
      };
      reader.readAsText(file.originFileObj);
    }
  }, []);

  const getButtonStyle = (): React.CSSProperties => {
    switch (config.theme) {
      case 'gradient':
        return {
          background: `linear-gradient(135deg, ${config.colors.primary}, ${config.colors.secondary})`,
        };
      case 'light':
        return {
          background: config.colors.background,
          border: `2px solid ${config.colors.primary}`,
        };
      case 'dark':
        return {
          background: '#1F2937',
          border: `2px solid ${config.colors.primary}`,
        };
      case 'solid':
        return {
          background: config.colors.primary,
        };
      default:
        return {
          background: config.colors.primary,
        };
    }
  };

  const PreviewButton: React.FC = () => {
    const IconComponent = icons.find(i => i.id === config.icon)?.component;
    const buttonStyle = getButtonStyle();

    return (
      <div
        className={
          'cursor-pointer rounded-full shadow-lg transition-transform hover:scale-110 flex items-center justify-center ' +
          icons.find(i => i.id === config.icon)?.preview
        }
        style={{
          width: `${config.size.width}px`,
          height: `${config.size.height}px`,
          ...buttonStyle,
        }}
      >
        {config.icon === 'custom' && config.customIcon ? (
          <div
            dangerouslySetInnerHTML={{ __html: config.customIcon }}
            style={{
              width: `${config.size.width * 0.375}px`,
              height: `${config.size.height * 0.375}px`,
            }}
          />
        ) : (
          IconComponent && (
            <IconComponent
              style={{
                color: config.colors.icon,
                fontSize: `${config.size.width * 0.375}px`,
              }}
            />
          )
        )}
      </div>
    );
  };

  const generateScript = (): string => {
    // Create a sanitized config object that includes call parameters
    const scriptConfig = {
      ...config,
      // Ensure these required call parameters are included
      From: config.From,
      To: config.To,
      Called: config.Called,
      Direction: config.Direction,
      websiteCalling: true,
    };

    return `<script src="${loggedInUser.resellerClient ? 'https://cdn.247aireceptionist.com' : 'https://cdn.myaifrontdesk.com'}/receptionist-embeddable-widget.js"></script>
<script>
document.addEventListener("DOMContentLoaded", function () {
  new CallWidget(${JSON.stringify(scriptConfig, null, 2)});
});
</script>`;
  };

  const handleColorChange = (path: string) => (color: Color) => {
    handleChange(path, color.toHexString());
  };

  // const steps: Step[] = [
  //   {
  //     target: '.theme-section',
  //     content: 'Choose a theme for your call widget. This will determine the overall look of the button.',
  //     placement: 'bottom',
  //   },
  //   {
  //     target: '.icon-section',
  //     content: 'Select an icon or upload your own SVG to customize the button appearance.',
  //     placement: 'bottom',
  //   },
  //   {
  //     target: '.colors-section',
  //     content: "Customize the colors to match your website's branding.",
  //     placement: 'bottom',
  //   },
  //   {
  //     target: '.position-section',
  //     content: 'Adjust where the widget appears on your website.',
  //     placement: 'bottom',
  //   },
  //   {
  //     target: '.size-section',
  //     content: 'Set the size of the call button.',
  //     placement: 'bottom',
  //   },
  //   {
  //     target: '.code-section',
  //     content: "Copy this code and paste it into your website's HTML, just before the closing </body> tag.",
  //     placement: 'top',
  //   },
  // ];

  return (
    <Card title='Call Widget Configuration'>
      {/* <Joyride
        steps={steps}
        run={runTour}
        continuous
        showProgress
        showSkipButton
        styles={{
          options: {
            primaryColor: '#1890ff',
          },
        }}
      /> */}

      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <div className='code-section'>
          <Alert
            message='Installation Instructions'
            description={
              <ol className='list-decimal pl-4'>
                <li>Design your embeddable button</li>
                <li>Copy the code below</li>
                <li>Open your website's HTML file</li>
                <li>
                  Paste the code just before the closing <code>&lt;/body&gt;</code> tag
                </li>
                <li>Save and deploy your changes</li>
              </ol>
            }
            type='success'
            showIcon
            style={{ marginBottom: '16px' }}
          />
          {/* <CodeBlock code={generateScript()} title='Generated Script' /> */}
        </div>
        {/* <Alert
          message='Configuration Guide'
          description={
            <Space>
              <span>New to the Call Widget? Take a quick tour to learn how to configure it.</span>
              <Button type='primary' onClick={() => setRunTour(true)} icon={<InfoCircleOutlined />}>
                Start Tour
              </Button>
            </Space>
          }
          type='info'
          showIcon
        /> */}

        {/* Preview Section */}
        <div className='flex justify-center items-center p-8 bg-gray-50 rounded-lg min-h-[200px]'>
          <PreviewButton />
        </div>

        <Space direction='vertical' size='middle' style={{ width: '100%' }}>
          {/* Enhanced Theme Selection */}
          <div className='theme-section'>
            <div className='font-bold mb-4'>Theme</div>
            <div className='grid grid-cols-2 gap-3 sm:grid-cols-4 max-w-[500px]'>
              {themes.map(theme => (
                <button
                  key={theme.id}
                  onClick={() => handleChange('theme', theme.id)}
                  className={`
                    relative w-full h-12 rounded-lg transition-all duration-200 
                    flex items-center justify-center font-medium text-sm
                    ${theme.preview}
                    ${config.theme === theme.id ? 'ring-2 ring-blue-400 ring-offset-2 scale-105' : ''}
                  `}
                >
                  <span className='relative z-10'>{theme.id.charAt(0).toUpperCase() + theme.id.slice(1)}</span>
                  {config.theme === theme.id && (
                    <div className='absolute top-2 right-2 w-1.5 h-1.5 bg-blue-400 rounded-full' />
                  )}
                </button>
              ))}
            </div>
          </div>

          {/* Icon Selection */}
          <div>
            <div className='font-bold mb-2'>Icon</div>
            <Space>
              {icons.map(({ id, component: Icon, preview }) => (
                <Button
                  key={id}
                  type={config.icon === id ? 'primary' : 'default'}
                  onClick={() => handleChange('icon', id)}
                  icon={<Icon className={preview} />}
                  style={{ width: 48, height: 48 }}
                />
              ))}
              <Upload
                accept='.svg'
                showUploadList={false}
                customRequest={({ onSuccess }: any) => {
                  setTimeout(() => {
                    onSuccess('ok');
                  }, 0);
                }}
                onChange={handleIconUpload}
              >
                <Button type={config.icon === 'custom' ? 'primary' : 'default'} icon={<UploadOutlined />}>
                  Custom SVG
                </Button>
              </Upload>
            </Space>
          </div>

          {/* Colors */}
          <div>
            <div className='font-bold mb-2'>Colors</div>
            <Space direction='vertical'>
              <Space>
                <div className='flex items-center gap-2'>
                  <span>Primary:</span>
                  <ColorPicker value={config.colors.primary} onChange={handleColorChange('colors.primary')} />
                  <Input
                    value={config.colors.primary}
                    onChange={e => handleChange('colors.primary', e.target.value)}
                    style={{ width: 120 }}
                  />
                </div>
              </Space>
              {config.theme === 'gradient' && (
                <Space>
                  <div className='flex items-center gap-2'>
                    <span>Secondary:</span>
                    <ColorPicker value={config.colors.secondary} onChange={handleColorChange('colors.secondary')} />
                    <Input
                      value={config.colors.secondary}
                      onChange={e => handleChange('colors.secondary', e.target.value)}
                      style={{ width: 120 }}
                    />
                  </div>
                </Space>
              )}
              <Space>
                <div className='flex items-center gap-2'>
                  <span>Icon:</span>
                  <ColorPicker value={config.colors.icon} onChange={handleColorChange('colors.icon')} />
                  <Input
                    value={config.colors.icon}
                    onChange={e => handleChange('colors.icon', e.target.value)}
                    style={{ width: 120 }}
                  />
                </div>
              </Space>
            </Space>
          </div>

          {/* Position */}
          <div>
            <div className='font-bold mb-2'>Position (px)</div>
            <Space>
              <div>
                <div>Bottom</div>
                <InputNumber
                  value={config.position.bottom}
                  onChange={value => handleChange('position.bottom', value)}
                  style={{ width: 80 }}
                />
              </div>
              <div>
                <div>Right</div>
                <InputNumber
                  value={config.position.right}
                  onChange={value => handleChange('position.right', value)}
                  style={{ width: 80 }}
                />
              </div>
            </Space>
          </div>

          {/* Size */}
          <div>
            <div className='font-bold mb-2'>Size (px)</div>
            <Space>
              <div>
                <div>Width</div>
                <InputNumber
                  value={config.size.width}
                  onChange={value => handleChange('size.width', value)}
                  style={{ width: 80 }}
                />
              </div>
              <div>
                <div>Height</div>
                <InputNumber
                  value={config.size.height}
                  onChange={value => handleChange('size.height', value)}
                  style={{ width: 80 }}
                />
              </div>
            </Space>
          </div>
        </Space>

        {/* Generated Script */}
        <CodeBlock code={generateScript()} title='Generated Script' />
      </Space>
    </Card>
  );
};

export default CallWidgetConfigurator;
