import { CustomModal } from '../CustomModal';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { Button, Typography, Space } from 'antd';
import { getResellerData } from '@/store/reseller/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';

const { Text, Title } = Typography;

export const ResellerClientSubscriptionUpdateModal = () => {
  const dispatch = useAppDispatch();

  const currentReseller = useAppSelector(getResellerData);

  const handleUpgrade = () => {
    // open currentReseller.email_address in email client
    window.open(`mailto:${currentReseller.email_address}`);
  };

  return (
    <CustomModal
      width={'30%'}
      title={null}
      modalId={MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL}
      onClose={() => dispatch(closeModal())}
      footer={null} // We can customize the footer if needed
    >
      <Space direction='vertical' size='large' style={{ textAlign: 'center', width: '100%' }}>
        <Title level={4}>Premium Feature!!</Title>
        <Text type='secondary'>
          You are not eligible for this feature right now. Please contact your administrator to upgrade your
          subscription to access this and more premium features.
        </Text>
        <Button type='primary' size='large' onClick={() => handleUpgrade()}>
          Contact Administrator
        </Button>
      </Space>
    </CustomModal>
  );
};

export default ResellerClientSubscriptionUpdateModal;
