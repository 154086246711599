import apiClient from '@/utils/apiClient';

const GET_EVENT = '/api/novi/events';

const NOVI = 'NOVI';

export const fetchCampaignEvents = async (email: string) => {
  const response = await apiClient.request({
    method: 'POST',
    endPoint: '/api/campaigns/calls/find',
    body: { campaign_user_email: email },
  });
  const data = await response;
  return data.events;
};

export const fetchCampaignEventsByCampaignId = async (email: string, campaignId: string) => {
  const response = await apiClient.request({
    method: 'POST',
    endPoint: `/api/campaigns/calls/${campaignId}`,
    body: { campaign_user_email: email },
  });
  const data = await response;
  return data;
};

export const fetchTranscript = async (callSid: string, forwardedFrom: string) => {
  const response = await apiClient.request({
    method: 'POST',
    endPoint: `/api/calls/transcript/${callSid}`,
    body: { forwardedFrom },
  });
  const data = await response;
  return data.transcript;
};

export const fetchRecording = async (callSid: string): Promise<Blob> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `/api/calls/recordings/${callSid}`,
    });
    return response;
  } catch (err) {
    console.error('Error fetching recording:', err);
    throw err;
  }
};

export const fetchEvent = async (campaignId: string) => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${GET_EVENT}?campaign_id=${campaignId}`,
      clientType: NOVI,
    });
    const data = await response;
    return data;
  } catch (err) {
    console.error('Error fetching event:', err);
    throw err;
  }
};
