import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { CustomModal } from '../CustomModal';
import { Button, List, notification, Typography, Space, Card, Tag, Divider } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { fetchPendingInvoices } from '@/api/reseller';
import { DollarOutlined, ExclamationCircleOutlined, LinkOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

const ClearPaymentModal = () => {
  const dispatch = useAppDispatch();

  const fetchPendingInvoicesMutation = useMutation({
    mutationKey: ['fetchPendingInvoices'],
    mutationFn: fetchPendingInvoices,
    onError: error => {
      notification.error({
        message: 'Error fetching invoices',
        description: error.message,
      });
    },
  });

  const handleGetInvoices = async () => {
    fetchPendingInvoicesMutation.mutate();
  };

  const handleInvoiceClick = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <CustomModal
      width={'50%'}
      title={
        <Space>
          <ExclamationCircleOutlined style={{ color: '#faad14' }} />
          <span>Clear Payment</span>
        </Space>
      }
      modalId={MODAL_IDS.CLEAR_PAYMENT_MODAL}
      onClose={() => {
        dispatch(closeModal());
      }}
      footer={null}
    >
      <div className='clear-payment-modal'>
        <Card style={{ marginBottom: 16 }}>
          <Title level={5} style={{ color: '#faad14' }}>
            Important Notice
          </Title>
          <Text>
            Your account has outstanding payments that require attention. Features like auto-scaling and the ability to
            add new receptionists has been temporarily been disabled, please settle these pending balances to re-enable
            them.
          </Text>
        </Card>

        {fetchPendingInvoicesMutation?.data?.pendingInvoices?.length === 0 || !fetchPendingInvoicesMutation?.data ? (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Button
              type='primary'
              onClick={handleGetInvoices}
              loading={fetchPendingInvoicesMutation.isPending}
              icon={<DollarOutlined />}
              size='large'
            >
              Get Invoices to Pay
            </Button>
          </div>
        ) : (
          <List
            dataSource={fetchPendingInvoicesMutation?.data?.pendingInvoices}
            renderItem={item => (
              <List.Item>
                <Card hoverable style={{ width: '100%' }}>
                  <Space direction='vertical' size='small' style={{ width: '100%' }}>
                    <Title level={5}>Invoice #{item.id}</Title>
                    <Space>
                      <DollarOutlined />
                      <Text strong>Amount:</Text>
                      <Text>${(item.amount_due / 100).toFixed(2)}</Text>
                    </Space>
                    <Space>
                      <Text strong>Status:</Text>
                      <Tag color={item.paid ? 'green' : 'red'}>{item.paid ? 'Paid' : 'Unpaid'}</Tag>
                    </Space>
                    <Divider style={{ margin: '12px 0' }} />
                    <Button
                      type='primary'
                      icon={<LinkOutlined />}
                      onClick={() => handleInvoiceClick(item.hosted_invoice_url)}
                      style={{ width: '100%' }}
                    >
                      Open Invoice
                    </Button>
                  </Space>
                </Card>
              </List.Item>
            )}
          />
        )}
      </div>
    </CustomModal>
  );
};

export default ClearPaymentModal;
