import { AuthType } from '@/types/TAuth';
import moment from 'moment';
import LocalStorageUtility from './localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { parsePhoneNumber } from 'libphonenumber-js';
import {
  RECEPTIONIST_NUMBER,
  RECEPTIONIST_NUMBER_AU_RESELLER,
  RECEPTIONIST_NUMBER_AUSTRALIA,
  RECEPTIONIST_NUMBER_NL,
  RECEPTIONIST_NUMBER_NL_RESELLER,
  RECEPTIONIST_NUMBER_UK,
  RECEPTIONIST_NUMBER_UK_RESELLER,
  RECEPTIONIST_NUMBER_US_RESELLER,
} from 'src/constants';
import { COUNTRY_CODES } from 'src/enums/ETestingCountry';

export const formatQuestionnaire = (qa?: string) => {
  if (!qa) return [];

  const qaArray = qa
    .trim()
    .split(/(?:Qn|Q|Question|Que|qn|q|due)[\:\-]/i)
    .slice(1)
    .map(qaBlock => {
      const [question, answer] = qaBlock.split(/(?:^|\n)(?:A|An|Answer|Ans|ans|a)[\:\-]/i);

      return {
        question: question.trim(),
        answer: answer ? answer.trim() : '',
      };
    });

  return qaArray;
};

export const formatQAString = (qaPairs: { question: string; answer: string }[]) => {
  if (!qaPairs) return '';
  return qaPairs.map(({ question, answer }) => `Q: ${question}\nA: ${answer}`).join('\n\n');
};

export const renderPSTDate = (utcDate: string, timeZone: string) => {
  const pstDate = moment.utc(utcDate).tz(timeZone);
  return pstDate.format('M/D/YYYY, h:mm:ss A');
};

export const deepEqual = (obj1: { [key: string]: any }, obj2: { [key: string]: any }): boolean => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const ipaToWord = (ipa: string): string => {
  // Define a map of IPA phonemes to their corresponding English word representations
  const phonemeMap: { [key: string]: string } = {
    tʃ: 'ch',
    dʒ: 'j', // Multi-character phonemes first
    ʃ: 'sh',
    ŋ: 'ng',
    θ: 'th',
    ð: 'th',
    ʊ: 'u',
    ʌ: 'u',
    ɑ: 'a',
    æ: 'a',
    ɛ: 'e',
    ɪ: 'i',
    ɔ: 'o',
    ɜː: 'er', // Add 'ɜː' to 'er'
    w: 'w',
    v: 'v',
    z: 'z',
    h: 'h',
    p: 'p',
    b: 'b',
    t: 't',
    d: 'd',
    k: 'k',
    g: 'g',
    m: 'm',
    n: 'n',
    f: 'f',
    s: 's',
    l: 'l',
    r: 'r',
    iː: 'ee',
    uː: 'oo', // Add long vowels
    eɪ: 'ay',
    aɪ: 'ai',
    oʊ: 'o',
    aʊ: 'ow',
    ɔɪ: 'oy',
    ɒ: 'o',
    i: 'i',
    u: 'u',
    ɡ: 'g',
    ʒ: 'zh',
    ː: '', // Additional mappings
    ɾ: 'r',
    x: 'kh', // Additional phonetic mappings
  };

  // Remove IPA markers
  let word = ipa.replace(/\/|\\/g, ''); // Remove slashes
  word = word.replace(/[ˈˌ]/g, ''); // Remove stress markers
  word = word.replace(/:/g, ''); // Remove length markers (ː)
  word = word.replace(/,/g, '-'); // Replace commas with dashes

  // Replace IPA phonemes with their corresponding word representations
  Object.keys(phonemeMap)
    .sort((a, b) => b.length - a.length)
    .forEach(phoneme => {
      word = word.replace(new RegExp(phoneme, 'g'), phonemeMap[phoneme]);
    });

  return word;
};

export const generateRandomColour = () => {
  const hue = Math.floor(Math.random() * 999);
  const saturation = 50 + Math.random() * 10;
  const lightness = 25 + Math.random() * 10;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const parseBooleanField = (field: any, defaultCheck?: boolean): boolean => {
  const isTrueOrFalseString = (value: any) => value === 'true' || value === 'false';

  if (isTrueOrFalseString(field?.S)) {
    return field.S === 'true';
  }
  if (isTrueOrFalseString(field)) {
    return field === 'true';
  }
  if (typeof field?.BOOL === 'boolean') {
    return field.BOOL;
  }
  if (typeof field === 'boolean') {
    return field;
  }

  return defaultCheck === undefined ? false : defaultCheck;
};

// Debounce function to prevent rapid API calls
export const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const getAuthToken = (userType: AuthType) => {
  switch (userType) {
    case 'RECEPTIONIST':
      return LocalStorageUtility.getLocalData(LocalStorageKeys.AUTH_TOKEN);
    case 'RESELLER':
      return LocalStorageUtility.getLocalData(LocalStorageKeys.RESELLER_AUTH_TOKEN);
    case 'NOVI':
      return LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_AUTH_TOKEN);
    case 'NOVI_RECEPTIONIST':
      return LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN);
    default:
      return 'unknown auth type';
  }
};

export const isValidPhone = (phone: string) => {
  // Parse the input number and remove formatting
  const phoneNumber = parsePhoneNumber(phone);

  if (phoneNumber && phoneNumber.isValid()) {
    // Return the standardized E.164 format (e.g., +18011234560)
    return phoneNumber.format('E.164');
  } else {
    return false;
  }
};

export const isValidEmail = (email: string): boolean => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const handlePrefixPlusOnPhone = (value: string) => {
  let phone = value;
  if (!phone.startsWith('+')) {
    phone = '+' + phone;
  }
  return phone;
};

export const isEmpty = (val: any): boolean => {
  return (
    val === undefined ||
    val === null ||
    (typeof val === 'object' && Object.keys(val).length === 0) ||
    (typeof val === 'string' && val.trim().length === 0)
  );
};

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

// Define number mappings
const RECEPTIONIST_NUMBERS = {
  default: {
    standard: RECEPTIONIST_NUMBER,
    reseller: RECEPTIONIST_NUMBER_US_RESELLER,
  },
  [COUNTRY_CODES.US]: {
    standard: RECEPTIONIST_NUMBER,
    reseller: RECEPTIONIST_NUMBER_US_RESELLER,
  },
  [COUNTRY_CODES.AU]: {
    standard: RECEPTIONIST_NUMBER_AUSTRALIA,
    reseller: RECEPTIONIST_NUMBER_AU_RESELLER,
  },
  [COUNTRY_CODES.UK]: {
    standard: RECEPTIONIST_NUMBER_UK,
    reseller: RECEPTIONIST_NUMBER_UK_RESELLER,
  },
  [COUNTRY_CODES.NL]: {
    standard: RECEPTIONIST_NUMBER_NL,
    reseller: RECEPTIONIST_NUMBER_NL_RESELLER,
  },
} as const;

type ResellerFlag = boolean;

/**
 * Gets the appropriate receptionist number based on the country code and reseller status
 * @param phoneNumber - The phone number to check (must include country code)
 * @param isReseller - Whether this is for a reseller account
 * @returns The appropriate receptionist number for the given country and account type
 */
export const getCurrentReceptionistNumber = (phoneNumber: string, isReseller: ResellerFlag = false): string => {
  // Find the matching country code from the phone number
  const countryCode = Object.values(COUNTRY_CODES).find(code => phoneNumber.startsWith(code));

  // Get the appropriate numbers mapping (default if no country code match)
  const numberMapping = countryCode ? RECEPTIONIST_NUMBERS[countryCode] : RECEPTIONIST_NUMBERS.default;

  // Return the appropriate number based on reseller status
  return numberMapping[isReseller ? 'reseller' : 'standard'];
};
