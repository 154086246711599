import { Promo } from '@/components/Promo';
import { BOOK_CALL_CALENDLY_URL, MY_AI_FRONT_DESK_CONTACT_EMAIL } from 'src/constants';

const ReceptionistPromo = () => {
  return (
    <Promo>
      Book a <strong>free set-up call</strong> with our team, we’ll help you go live!{' '}
      <a target='_blank' href={`${BOOK_CALL_CALENDLY_URL}`} className='ml-2' type='primary'>
        Setup Call Now
      </a>
      <a href={`mailto:${MY_AI_FRONT_DESK_CONTACT_EMAIL}`} className='ml-2' type='primary'>
        Email Us
      </a>
    </Promo>
  );
};

export default ReceptionistPromo;
