import useResellerAuth from '@/routes/hooks/useResellerAuth';
import ResellerLoginForm from '../pages/ResellerLogin/ResellerLoginForm';
import { Loading } from '@/components/Loading';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { useQuery } from '@tanstack/react-query';
import { setResellerAcademy } from '@/store/resellerAcademy/slice';
import { fetchResellerAcademy } from '@/api/reseller';
import { useEffect } from 'react';

export const PrivateResellerRoute = () => {
  const { isAuthenticated, isLoading } = useResellerAuth();
  const dispatch = useAppDispatch();

  // fetch reseller Acadmy data
  const { isLoading: resellerAcademyLoading } = useQuery({
    queryKey: ['resellerAcademy'],
    queryFn: async () => {
      let resp = await fetchResellerAcademy();
      if (resp.data) {
        dispatch(setResellerAcademy({ chapters: resp.data, loading: false }));
      }
      return resp;
    },
  });

  useEffect(() => {
    if (resellerAcademyLoading) {
      dispatch(setResellerAcademy({ chapters: [], loading: true }));
    }
  }, [resellerAcademyLoading]);

  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return <Outlet />;
  }

  return <ResellerLoginForm />;
};

export default PrivateResellerRoute;
