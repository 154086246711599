import React from 'react';
import { Alert, Typography, Space, Grid } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const ResellerSubCancelPromo: React.FC = () => {
  const screens = useBreakpoint();
  const currentReseller = useAppSelector(getResellerData);

  if (!currentReseller?.subCancelOn) {
    return null;
  }

  return (
    <Alert
      type='warning'
      showIcon
      icon={<WarningOutlined />}
      closable
      message='Subscription Cancellation Notice'
      description={
        <Space direction='vertical' size={screens.md ? 12 : 8} style={{ width: '100%' }}>
          <div>
            <Text style={{ fontSize: screens.md ? '16px' : '14px' }}>
              Your subscription is scheduled to be canceled on{' '}
              <Text strong>
                {new Date(currentReseller.subCancelOn).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </Text>
            </Text>
          </div>
        </Space>
      }
      style={{
        border: '1px solid #faad14',
        borderRadius: '6px',
        padding: screens.md ? '16px' : '12px',
      }}
    />
  );
};

export default ResellerSubCancelPromo;
