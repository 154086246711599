import React, { useState, ChangeEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { submitComplianceDataFn } from '@/api/reservedNumbers';
import { notification } from 'antd';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';

// Interfaces for type-checking
interface Document {
  fileName: string | null;
  infoToBeVerified: string;
  typeOfDoc: string;
  detailedFields: Record<string, string>;
  addressRequired: boolean;
  file: File | null;
}

interface Field {
  machine_name: string;
  friendly_name: string;
  description: string;
}

interface DocumentRequirement {
  name: string;
  description: string;
  accepted_documents: {
    type: string;
    name: string;
    fields: string[];
  }[];
}

interface TwilioDocsRequirements {
  end_user: {
    detailed_fields: Field[];
    fields: string[];
  }[];
  supporting_document: DocumentRequirement[][];
}

interface NonUSProvisionPromptModalProps {
  onClose: () => void;
  countryCodeNumber: string;
  twilioDocsRequirements: TwilioDocsRequirements | null;
  regulationSid: string;
  setClientDetails: (bundleId: string) => void;
  isReseller?: string;
  resellerEmail?: string;
}

const GetBundleApproveForm: React.FC<NonUSProvisionPromptModalProps> = ({
  onClose,
  countryCodeNumber,
  twilioDocsRequirements,
  regulationSid,
  setClientDetails,
  isReseller = 'false',
  resellerEmail,
}) => {
  const supportingDocuments = twilioDocsRequirements?.supporting_document?.[0] || [];
  const loggedInUser = useAppSelector(getLoggedInUser);
  const username = isReseller == 'true' ? resellerEmail : loggedInUser.number;

  // State hooks
  const [step, setStep] = useState<number>(1); // Current step in the form
  const [formData, setFormData] = useState<Record<string, string>>({}); // Form data for user details
  const [errors, setErrors] = useState<Record<string, string>>({}); // Validation errors
  const [reasonsForNonCompliance, setReasonsForNonCompliance] = useState<string[]>([]); // Reasons for non-compliance
  const [submitLoading, setSubmitLoading] = useState<boolean>(false); // Submission loading state
  const [selectedDocument, setSelectedDocument] = useState<Document[]>(
    supportingDocuments.map(higher => ({
      fileName: higher.description.includes('Document upload is not required') ? null : uuidv4(), // Unique identifier for the document
      infoToBeVerified: higher.name,
      typeOfDoc: higher?.accepted_documents[0]?.type || '',
      detailedFields: higher?.accepted_documents[0]?.fields?.includes('address_sids')
        ? {
            region: '',
            city: '',
            postalCode: '',
            street: '',
            isoCountry: '',
            customerName: username || '',
          }
        : higher?.accepted_documents[0]?.fields?.reduce((acc: Record<string, string>, item: string) => {
            acc[item] = '';
            return acc;
          }, {}),
      addressRequired: higher?.accepted_documents[0]?.fields?.includes('address_sids') || false,
      file: null,
    })) || []
  );

  // Handle moving to the next step in the form
  const handleNextStep = () => {
    const newErrors: Record<string, string> = {};
    if (twilioDocsRequirements && twilioDocsRequirements.end_user) {
      twilioDocsRequirements.end_user[0].fields.forEach(field => {
        if (!formData[field]) {
          newErrors[field] = `${field} is required`;
        }
      });
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Set errors if any
    } else {
      setErrors({});
      setStep(prevStep => prevStep + 1); // Move to the next step
    }
  };

  // Handle moving to the previous step in the form
  const handlePrevStep = () => {
    setStep(prevStep => prevStep - 1);
  };

  // Handle input changes for text and date fields
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle changes for date fields
  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: format(new Date(value), 'yyyy-MM-dd') });
  };

  // Handle file input changes
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      // if files exceed 1MB then show error
      if (files[0].size > 1000000) {
        notification.error({ message: 'File size should not exceed 1MB' });
        return;
      }

      const newSelectedDocument = selectedDocument.map(doc => {
        if (doc.infoToBeVerified === name) {
          return {
            ...doc,
            file: files[0],
          };
        }
        return doc;
      });
      setSelectedDocument(newSelectedDocument);
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      setReasonsForNonCompliance([]);
      setSubmitLoading(true);
      const formPayload = new FormData();

      // Append form data and files to FormData object
      formPayload.append('friendlyName', `${username}`);
      formPayload.append('regulationSid', regulationSid);
      formPayload.append('attributes', JSON.stringify(formData));
      formPayload.append('supportedDocuments', JSON.stringify(selectedDocument));
      formPayload.append('countryCode', countryCodeNumber);
      formPayload.append('isReseller', isReseller);

      selectedDocument.forEach(doc => {
        if (doc.file && doc.fileName) {
          formPayload.append(doc.fileName, doc.file);
        }
      });

      // Send the form data to the server
      const response = await submitComplianceDataFn(formPayload);

      // Handle response from the server
      if (response?.type === 'noncompliant') {
        const reasons = response.data.reasons.flat().map((reason: { friendly_name: string }) => reason.friendly_name);
        setReasonsForNonCompliance(reasons);
      }

      if (response?.type === 'compliant') {
        notification.success({ message: 'Your request has been submitted successfully.' });

        setClientDetails(response.bundleSid);
        onClose(); // Close the modal on successful submission
      }
    } catch (error) {
      console.error('Error sending data to the server:', error);
      const errorMessage = (error as Error).message || 'Failed to submit data';
      notification.error({ message: errorMessage });
    } finally {
      setSubmitLoading(false);
    }
  };

  // Render content based on the current step
  const renderStepContent = () => {
    if (!twilioDocsRequirements || !twilioDocsRequirements.end_user) {
      return <div className='text-red-500'>No requirements found for this country code</div>;
    }

    switch (step) {
      case 1:
        return (
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
            {twilioDocsRequirements.end_user[0].detailed_fields.map(field => (
              <div key={field.machine_name}>
                <label className='block font-medium text-gray-700'>
                  {field.friendly_name.includes('Date') ? `${field.friendly_name}` : field.friendly_name}
                </label>
                {field.friendly_name.includes('Date') ? (
                  <input
                    type='date'
                    name={field.machine_name}
                    value={formData[field.machine_name] || ''}
                    onChange={handleDateChange}
                    className={`mt-1 block w-full p-2 border ${
                      errors[field.machine_name] ? 'border-red-500' : 'border-gray-300'
                    } rounded-md`}
                    placeholder={field.description}
                  />
                ) : (
                  <input
                    type={
                      field.machine_name === 'phone_number'
                        ? 'number'
                        : field.machine_name === 'email'
                          ? 'email'
                          : 'text'
                    }
                    name={field.machine_name}
                    value={formData[field.machine_name] || ''}
                    onChange={handleInputChange}
                    className={`mt-1 block w-full p-2 border ${
                      errors[field.machine_name] ? 'border-red-500' : 'border-gray-300'
                    } rounded-md`}
                    placeholder={field.description}
                  />
                )}
                {errors[field.machine_name] && (
                  <p className='mt-1 text-sm text-red-500'>{errors[field.machine_name]}</p>
                )}
              </div>
            ))}
          </div>
        );
      case 2:
        return (
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
            {twilioDocsRequirements?.supporting_document[0].map(higher => (
              <div key={higher.name}>
                {!higher.description.includes('Document upload is not required') && (
                  <label>Upload Document to verify your: {higher.name}</label>
                )}
                <select
                  value={selectedDocument.find(doc => doc.infoToBeVerified === higher.name)?.typeOfDoc || ''}
                  onChange={e => {
                    const newSelectedDocument = selectedDocument.map(doc => {
                      if (doc.infoToBeVerified === higher.name) {
                        let selectedDoc = higher.accepted_documents.find(doc => doc.type === e.target.value);
                        return {
                          ...doc,
                          typeOfDoc: e.target.value,
                          detailedFields: selectedDoc?.fields.includes('address_sids')
                            ? {
                                region: '',
                                city: '',
                                postalCode: '',
                                street: '',
                                isoCountry: '',
                                customerName: username,
                              }
                            : selectedDoc?.fields.reduce((acc: Record<string, string>, item: string) => {
                                acc[item] = '';
                                return acc;
                              }, {}),
                          addressRequired: selectedDoc?.fields.includes('address_sids') || false,
                        };
                      }
                      return doc;
                    });
                    // @ts-ignore
                    setSelectedDocument(newSelectedDocument);
                  }}
                >
                  {higher.accepted_documents.map(doc => (
                    <option value={doc.type} key={doc.type}>
                      {doc.name}
                    </option>
                  ))}
                </select>

                {!higher.description.includes('Document upload is not required') && (
                  <input
                    type='file'
                    name={higher.name}
                    onChange={handleFileChange}
                    accept='application/pdf, image/*'
                    className='block w-full p-2 mt-1 border border-gray-300 rounded-md'
                    style={{ display: 'block' }}
                  />
                )}

                <div>
                  {Object.keys(
                    selectedDocument.find(doc => doc.infoToBeVerified === higher.name)?.detailedFields || {}
                  ).map(item => {
                    if (item === 'isoCountry') {
                      return (
                        <div key={item}>
                          <label>{item}</label>
                          <Select
                            options={countryList().getData()}
                            value={countryList()
                              .getData()
                              .find(
                                option =>
                                  option.value ===
                                  selectedDocument.find(doc => doc.infoToBeVerified === higher.name)?.detailedFields[
                                    item
                                  ]
                              )}
                            onChange={selectedOption => {
                              const newSelectedDocument = selectedDocument.map(doc => {
                                if (doc.infoToBeVerified === higher.name) {
                                  return {
                                    ...doc,
                                    detailedFields: {
                                      ...doc.detailedFields,
                                      isoCountry: selectedOption?.value || '',
                                    },
                                  };
                                }
                                return doc;
                              });
                              setSelectedDocument(newSelectedDocument);
                            }}
                          />
                        </div>
                      );
                    }
                    return (
                      <div key={item}>
                        <label>{item === 'region' ? 'state / provience' : item}</label>
                        <input
                          type='text'
                          name={item}
                          value={
                            selectedDocument.find(doc => doc.infoToBeVerified === higher.name)?.detailedFields[item] ||
                            ''
                          }
                          disabled={item === 'customerName'}
                          onChange={e => {
                            if (item === 'customerName') return;
                            const newSelectedDocument = selectedDocument.map(doc => {
                              if (doc.infoToBeVerified === higher.name) {
                                return {
                                  ...doc,
                                  detailedFields: {
                                    ...doc.detailedFields,
                                    [item]: e.target.value,
                                  },
                                };
                              }
                              return doc;
                            });
                            setSelectedDocument(newSelectedDocument);
                          }}
                          className='block w-full p-2 mt-1 border border-gray-300 rounded-md'
                          placeholder={item}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className='w-full max-w-4xl  relative overflow-y-auto max-h-[98vh]'>
        <div className='flex items-center justify-between mb-4'>
          <span className='text-lg font-semibold'>
            Standalone Phone Number for <span className='italic font-bold text-gray-500'>{countryCodeNumber}</span>
            <p className='text-sm font-normal text-gray-500'>
              In case of any queries, please reach out to{' '}
              {loggedInUser?.reseller_email ? 'us at' : 'our support team at'}
              <span
                onClick={() =>
                  window.open(`mailto:${loggedInUser?.reseller_email || 'phonenumbers@myaifrontdesk.com'}`)
                }
                className='italic font-bold text-blue-500 cursor-pointer'
              >
                {' '}
                {loggedInUser?.reseller_email || 'phonenumbers@myaifrontdesk.com'}
              </span>
            </p>
            <p className='text-sm font-normal text-gray-500'>
              Please note that the number will be provisioned only after successful compliance verification. Fill the
              below details to proceed.
            </p>
            <br />
            <p className='text-sm text-red-600 mt-[-15px]'>
              <span className='font-semibold'>Note:</span> Please make sure to upload file in PDF format with both sides
              in case of ID proof.
            </p>
            <p className='text-sm text-red-600 mt-[-15px]'>
              Pdf should not exceed 1MB in size. To shrink the size of the pdf, you can use{' '}
              <a
                href='https://www.ilovepdf.com/compress_pdf'
                target='_blank'
                rel='noreferrer'
                className='text-blue-500'
              >
                this link
              </a>
            </p>
          </span>
        </div>

        {reasonsForNonCompliance.length > 0 && (
          <div className='mb-4 text-red-500'>
            <h1>These fields are either invalid or missing:</h1>
            <ul>
              {reasonsForNonCompliance.map((reason, index) => (
                <li key={index}>{reason}</li>
              ))}
            </ul>
          </div>
        )}

        <div className='flex flex-col items-center gap-4'>
          <div className='flex flex-col w-full gap-4'>
            {renderStepContent()} {/* Render content based on current step */}
          </div>
        </div>

        <div className='flex items-center justify-between w-full mt-4'>
          {step > 1 && (
            <button
              onClick={handlePrevStep}
              className='w-1/3 p-3 text-black border-none cursor-pointer bg-lightgreen hover:bg-blue-600 hover:text-white rounded-xl'
            >
              Back
            </button>
          )}
          {step < 2 ? (
            <button
              onClick={handleNextStep}
              className='w-1/3 p-3 ml-auto text-black border-2 shadow-xl cursor-pointer bg-lightgreen bg-orange-400 hover:bg-orange-300 rounded-xl'
            >
              Next
            </button>
          ) : (
            <button
              onClick={() => (submitLoading ? null : handleSubmit())}
              className='w-1/3 p-3 ml-auto text-black border-2 shadow-xl  cursor-pointer bg-lightgreen bg-orange-400 hover:bg-orange-300 rounded-xl'
            >
              {submitLoading ? 'Submitting...' : 'Submit'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GetBundleApproveForm;
