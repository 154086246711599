import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { Button, DatePicker, notification, Select, Space, Spin, Table, TableProps, Tooltip, Alert } from 'antd';
import PlayForWorkOutlinedIcon from '@mui/icons-material/PlayForWorkOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useEffect, useState } from 'react';
import '../CallLogs/CallLogs.scss';
import { CustomModal } from '@/components/CustomModal';
import { TextLogDetails } from './TextLogDetails';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextLog } from '@/interfaces/ILogs';
import { deleteTextLog, getTextLogs, updateUserTimeZone } from '@/api/logs';
import { renderPSTDate } from '@/utils/helper';
import { useDispatch } from 'react-redux';
import { setUserTimeZone } from '@/store/account/slice';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { getProvisionedNumber } from '@/api/reservedNumbers';
import { useLocalTimezone } from 'src/common/hooks/useLocalTimezone';
import { DeleteOutlined } from '@ant-design/icons';
import CopyableText from '@/components/LabelWithdescription/CopyableText';
import { smallScreen } from 'src/enums/EScreenSize';

const timeZoneList = momentTimeZone.tz.names();

const TextLogs = () => {
  const dispatch = useDispatch();
  const loggedInUser = useAppSelector(getLoggedInUser);
  const { RangePicker } = DatePicker;

  const { timeZone, setTimeZone } = useLocalTimezone(loggedInUser?.time_zone);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [originalTextLogs, setOriginalTextLogs] = useState([] as TextLog[]);
  const [textLogs, setTextLogs] = useState<TextLog[]>([]);
  const [selectedTextLog, setSelectedTextLog] = useState<TextLog | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<number | null>(null);
  const [endDate, setEndDate] = useState<number | null>(null);

  const { data, refetch, isPending, isRefetching } = useQuery({
    queryKey: ['getTextLogs'],
    queryFn: getTextLogs,
  });

  const { data: provisionedNumbers, isLoading: isLoadingProvisionedNumber } = useQuery({
    queryKey: ['getProvisionedNumbers'],
    queryFn: () => getProvisionedNumber(),
  });

  const { mutate: deleteTextLogMutation, status: deletingTextLogs } = useMutation({
    mutationKey: ['deleteTextLog'],
    mutationFn: deleteTextLog,
    onSuccess(_, values) {
      setTextLogs(textLogs.filter(log => log.date !== values.textLogDate));
      setOriginalTextLogs(originalTextLogs.filter(log => log.date !== values.textLogDate));
      setSelectedTextLog(null);
      setConfirmDelete(false);
      notification.success({ message: 'Text log deleted successfully' });
    },
  });

  const updateTimeZoneMutation = useMutation({
    mutationKey: ['updateTimeZone'],
    mutationFn: updateUserTimeZone,
    onSuccess(_, values) {
      dispatch(setUserTimeZone({ timeZone: values.timeZone }));
    },
  });

  const handleClick = (index: number) => {
    setSelectedTextLog(textLogs[index]);
    if (index !== undefined && index !== null) {
      setShowModal(true);
    }
  };
  const handleConfirm = () => {
    setSelectedTextLog(null);
    setShowModal(false);
  };
  const handleCancel = () => {
    setSelectedTextLog(null);
    setShowModal(false);
  };

  const handleTimeZoneChange = (value: string) => {
    setTimeZone(value);
    updateTimeZoneMutation.mutate({ timeZone: value });
  };

  const handleDeleteTextLog = (index: number) => {
    setSelectedTextLog(textLogs[index]);
    setConfirmDelete(true);
  };

  const handleCancelDeleteModal = () => {
    setSelectedTextLog(null);
    setConfirmDelete(false);
  };

  const confimDelteTextLog = () => {
    deleteTextLogMutation({ textLogDate: selectedTextLog?.date ?? '' });
  };

  const handleDateRangeChange = (_: any, dateStrings: [string, string]) => {
    const [start, end] = dateStrings;
    setStartDate(start ? moment(start).valueOf() : null);
    setEndDate(end ? moment(end).valueOf() : null);
  };

  const handleTextLogsDownload = () => {
    const headingRow = 'Date,To,Text,Text Type\n';
    const csv = textLogs
      .map(log => {
        return `${renderPSTDate(log.date, timeZone)},${log.to},${log.text},${log.textType}`;
      })
      .join('\n');
    const csvData = headingRow + csv;
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `text_logs_${moment().format('YYYY-MM-DD')}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const columns: TableProps<TextLog>['columns'] = [
    {
      key: 'Date',
      title: 'Dates',
      render: (_, record) => <CopyableText text={renderPSTDate(record.date, timeZone)} />,
    },
    {
      key: 'to',
      title: 'To',
      dataIndex: 'to',
      render: (_, record) => <CopyableText text={record.to} />,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, __, index) => (
        <Space size='small'>
          <Button type='default' onClick={() => handleClick(index)}>
            View
          </Button>
          <Tooltip title='Delete'>
            <Button type='text' danger icon={<DeleteOutlined />} onClick={() => handleDeleteTextLog(index)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (data?.textLogs) {
      setOriginalTextLogs(data.textLogs);
      setTextLogs(data.textLogs);
    }
  }, [data?.textLogs]);

  useEffect(() => {
    if (startDate || endDate) {
      const filteredLogs = originalTextLogs.filter(log => {
        const logDate = moment(log.date).valueOf();
        if (startDate && endDate) {
          return logDate >= startDate && logDate <= endDate;
        } else if (startDate) {
          return logDate >= startDate;
        } else if (endDate) {
          return logDate <= endDate;
        }
        return true;
      });

      setTextLogs(filteredLogs);
    } else {
      setTextLogs(originalTextLogs);
    }
  }, [startDate, endDate, originalTextLogs]);

  const baseClass = 'call-logs';
  return (
    <div className={baseClass}>
      <div className={`${baseClass}_header`}>
        <h1>Text Logs</h1>
        <Button
          type='primary'
          icon={<PlayForWorkOutlinedIcon />}
          onClick={handleTextLogsDownload}
          disabled={isLoadingProvisionedNumber || !provisionedNumbers?.provisioned_number}
        >
          {!smallScreen && 'Download Logs'}
        </Button>
        <Button
          type='primary'
          icon={<RefreshOutlinedIcon />}
          onClick={() => refetch()}
          loading={isPending || isRefetching}
        >
          {!smallScreen && 'Refresh Logs'}
        </Button>
      </div>
      <div className={`${baseClass}_input-container`}>
        <RangePicker placement='bottomRight' onChange={handleDateRangeChange} />
        <Select
          value={timeZone}
          onChange={handleTimeZoneChange}
          showSearch
          placeholder='Select a time zone'
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={timeZoneList.map(zone => ({ label: zone, value: zone }))}
        />
      </div>
      <Alert className='overview-info' closable description='Access and review your Text Logs in minutes! 👇' />
      <Spin spinning={isPending}>
        <div className={`${baseClass}_table-container`}>
          <Table columns={columns} dataSource={textLogs} rowKey='date' />
        </div>
      </Spin>
      <CustomModal
        title={<strong>Text Log Details</strong>}
        children={<TextLogDetails textLog={selectedTextLog as TextLog} />}
        isModalOpen={showModal}
        confirmAction={handleConfirm}
        cancelAction={handleCancel}
      />
      <CustomModal
        title={<>Are you sure you want to delete this text log?</>}
        children={<>This action cannot be undone.</>}
        isModalOpen={confirmDelete}
        confirmAction={confimDelteTextLog}
        cancelAction={handleCancelDeleteModal}
        footer={[
          <Button key='cancel' onClick={handleCancelDeleteModal}>
            Cancel
          </Button>,
          <Button
            key='confirm'
            type='primary'
            onClick={confimDelteTextLog}
            danger
            loading={deletingTextLogs === 'pending'}
          >
            Delete
          </Button>,
        ]}
      />
    </div>
  );
};

export default TextLogs;
