import React, { useEffect, useState } from 'react';
import './IntakeFormComponent.scss';
import { CallLog } from '@/interfaces/ILogs';
interface IntakeForm {
  [key: string]: string;
}
interface ConvertedData {
  intakeResponses: IntakeForm[];
  transcript: string;
  date: string;
  from: string;
  responses: string[];
}
interface Props {
  sortedData: CallLog[];
}
const IntakeFormComponent: React.FC<Props> = ({ sortedData }) => {
  const [convertedData, setConvertedData] = useState<{ questions: string; answers: ConvertedData[] }[]>([]);
  const [convertedDataMp, setConvertedDataMp] = useState<{ [key: string]: ConvertedData[] }>({});
  const [fullConversation, setFullConversation] = useState<string>('');
  const [selectedForm, setSelectedForm] = useState<string>('');
  const [callSummary, setCallSummary] = useState('');
  console.log(callSummary);
  const isValidIntakeForms = (intakeForms: any): intakeForms is IntakeForm[] => {
    if (!Array.isArray(intakeForms)) {
      return false;
    }
    for (const form of intakeForms) {
      if (typeof form !== 'object' || Array.isArray(form) || form === null) {
        return false;
      }
      const keys = Object.keys(form);
      const values = Object.values(form);
      if (keys.length === 0 || values.length === 0) {
        return false;
      }
      for (const value of values) {
        if (typeof value !== 'string') {
          return false;
        }
      }
    }
    return true;
  };
  useEffect(() => {
    if (!sortedData) {
      return;
    }
    const convertedData0 = sortedData
      .map(el => {
        if (!el.intakeResponses) return null;
        // if length is 0, return null
        if (el.intakeResponses.length === 0) return null;
        let intakeResponses: IntakeForm[];
        try {
          intakeResponses = JSON.parse(el.intakeResponses) as IntakeForm[];
        } catch (err) {
          console.log(err);
          return null;
        }
        return {
          intakeResponses,
          transcript: el.transcript,
          date: el.date,
          from: el.from,
        };
      })
      .filter((el): el is ConvertedData => el !== null)
      .filter(el => {
        const intakeDate = new Date(el.date);
        const cutoffDate = new Date('May 18, 2024');
        return intakeDate > cutoffDate;
      })
      .filter(el => isValidIntakeForms(el.intakeResponses));
    const convertedDataMp: { [key: string]: ConvertedData[] } = {};
    convertedData0.forEach(el => {
      if (el?.intakeResponses && Array.isArray(el.intakeResponses)) {
        el.intakeResponses.forEach(form => {
          const key = Object.keys(form).join('~~~');
          const responses = Object.values(form);
          if (convertedDataMp[key]) {
            convertedDataMp[key].push({
              ...el,
              responses,
              intakeResponses: [form],
            });
          } else {
            convertedDataMp[key] = [
              {
                ...el,
                responses,
                intakeResponses: [form],
              },
            ];
          }
        });
      }
    });
    const convertedData1 = Object.entries(convertedDataMp).map(([key, value]) => ({
      questions: key,
      answers: value,
    }));
    setConvertedDataMp(convertedDataMp);
    setConvertedData(convertedData1);
  }, [sortedData]);
  const renderTranscript = (text: string) => {
    const endingString = "user@ [Silence]\nassistant@ Sorry, I didn't hear anything. Can you say that again?";
    if (text?.endsWith(endingString)) {
      text = text.slice(0, text.length - endingString.length) + 'user@ [User Hung Up]';
    }
    const formattedText = text
      ?.replace(/user@/g, '<br /><span style="font-weight: bold;">Caller:</span>')
      ?.replace(/assistant@/g, '<br /><span style="font-weight: bold;">Assistant:</span>')
      ?.replace(/@/g, ':');
    if (formattedText?.endsWith('<br /><span style="font-weight: bold;">Assistant:</span> ')) {
      return (
        <div
          className='max-h-[calc(100vh-80px)] overflow-y-auto px-10'
          dangerouslySetInnerHTML={{
            __html: formattedText + ' [User Hung up]',
          }}
        ></div>
      );
    }
    if (formattedText?.endsWith('<br /><span style="font-weight: bold;">Caller:</span> ')) {
      return (
        <div
          className='max-h-[calc(100vh-80px)] overflow-y-auto px-10'
          dangerouslySetInnerHTML={{
            __html: formattedText + ' [User Hung up]',
          }}
        ></div>
      );
    }
    return (
      <div
        className='max-h-[calc(100vh-80px)] overflow-y-auto px-10'
        dangerouslySetInnerHTML={{ __html: formattedText }}
      ></div>
    );
  };

  return (
    <>
      {fullConversation && <div onClick={() => setFullConversation('')} className='modalOverlay'></div>}
      <div
        className={`modal !py-4 !px-0 !max-h-[calc(100vh-80px)] ${fullConversation ? 'active' : ''} [&_span]:!text-[16px] [&_span]:!justify-start [&_span]:![line-height:_normal] [&_span]:!mt-2`}
      >
        <div className='close' onClick={() => setFullConversation('')}>
          &times;
        </div>
        {/* <span className="text-lg font-semibold italic mb-4">Summary</span>
        <p>{callSummary || 'NA'}</p> */}
        {renderTranscript(fullConversation)}
      </div>
      <div style={{ width: '100%' }}>
        {selectedForm ? (
          <div className='relative'>
            <div className='card bg-white' style={{ width: '100%', maxWidth: '1200px', margin: '3rem auto' }}>
              <div
                style={{
                  marginBottom: '1.75rem',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  gap: '.5rem',
                  cursor: 'pointer',
                }}
                onClick={() => setSelectedForm('')}
              >
                <span style={{ fontSize: '1.5rem', transform: 'translateY(-2px)' }}>&lt;</span>
                <div>Go Back</div>
              </div>
              <table style={{ margin: 0, paddingLeft: '1.5rem', width: '100%' }}>
                {/* ... (keep the table content) */}
                <thead>
                  <tr>
                    {selectedForm.split('~~~')?.map((el, idx) => (
                      <th key={idx} style={{ border: 0 }}>
                        {el}
                      </th>
                    ))}
                    <th style={{ border: 0 }}>Date</th>
                    <th style={{ border: 0 }}>From</th>
                    <th style={{ border: 0 }}>Full transcript</th>
                  </tr>
                </thead>
                <tbody>
                  {convertedDataMp[selectedForm].map((el2, idx2) => {
                    console.log(el2);
                    return (
                      <tr key={'mapped-answers-' + idx2}>
                        {(el2 as any)?.responses?.map((el3: any, idx3: any) => (
                          <td key={'mapped-answers-' + idx2 + '-' + idx3} style={{ border: 0 }}>
                            {el3}
                          </td>
                        ))}
                        <td style={{ border: 0 }}>{el2.date}</td>
                        <td style={{ border: 0 }}>{el2.from}</td>
                        <td style={{ border: 0 }}>
                          <button
                            style={{
                              background: 'rgb(0, 123, 255)',
                              border: 'transparent',
                              color: 'white',
                              borderRadius: '5px',
                              padding: '.5rem 1rem',
                              fontWeight: 'bold',
                              margin: '.5rem 0',
                              fontSize: '.75rem',
                            }}
                            onClick={() => {
                              setCallSummary((el2 as any)?.callSummary as any);
                              setFullConversation(el2.transcript);
                            }}
                          >
                            View full conversation
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <>
            {convertedData.map((el, idx) => {
              const mappableQuestions = el?.questions.split('~~~');
              return (
                <div
                  key={idx}
                  className='bg-white card relative'
                  style={{ width: '100%', maxWidth: '500px', margin: '2rem auto 0' }}
                >
                  <div>
                    <div>
                      <strong>Form {idx + 1}.</strong>
                    </div>
                    <div>
                      <strong>Questions</strong>:
                    </div>
                    <ul>
                      {mappableQuestions.map((el2, idx2) => (
                        <li key={'mapped-question-' + idx + '-' + idx2}>{el2}</li>
                      ))}
                    </ul>
                    <button
                      style={{
                        background: 'rgb(0, 123, 255)',
                        border: 'transparent',
                        color: 'white',
                        borderRadius: '5px',
                        padding: '.75rem 1.5rem',
                        fontWeight: 'bold',
                        marginBottom: '1rem',
                        fontSize: '.75rem',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      onClick={() => setSelectedForm(el?.questions)}
                    >
                      See what users are responding to these questions
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};
export default IntakeFormComponent;
