import React, { useState } from 'react';
import { Badge, Button, Card, Checkbox, Tooltip } from 'antd';
import { ChatBubbleOutline, DiamondOutlined, MailOutline, WorkspacePremiumOutlined } from '@mui/icons-material';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { PlusOutlined } from '@ant-design/icons';
import PreviewEmail from './PreviewEmail';
import PreviewSms from './PreviewSms';
import AddEmails from './AddEmails';
import AddPhones from './AddPhones';
import { Show } from '@/interfaces/INotifications';
import LearnMore from '@/components/Buttons/LearnMore';
import { getResellerData } from '@/store/reseller/selector';
import PreviewButton from './PreviewButton';
import { NotifKey } from 'src/enums/ENotifKey';

interface NotificationCategory {
  heading: string;
  label: string;
  description: string;
  cardDescription: string;
  email: boolean;
  sms: boolean;
  voicemail: boolean;
  emails: string[];
  phones: string[];
  notifKey: string;
  saveEmails: (val: string[]) => void;
  savePhones: (val: string[]) => void;
  saveCheck: (method: 'email' | 'sms' | 'voicemail') => void;
  show: Show | null;
  onShowChange?: (newShow: Show) => void;
  tutorialId: string;
}

const NotificationCard: React.FC<NotificationCategory> = ({
  heading,
  label,
  description,
  cardDescription,
  email,
  sms,
  voicemail,
  emails,
  phones,
  notifKey,
  saveEmails,
  savePhones,
  saveCheck,
  show,
  onShowChange,
  tutorialId,
}) => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const [categories, setCategories] = useState({
    heading,
    label,
    description,
    cardDescription,
    email,
    sms,
    voicemail,
    emails,
    phones,
  });
  const [emailAddClicked, setEmailAddClicked] = useState(false);
  const [phoneAddClicked, setPhoneAddClicked] = useState(false);
  const [previewEmail, setPreviewEmail] = useState(false);
  const [previewSms, setPreviewSms] = useState(false);
  const currentReseller = useAppSelector(getResellerData);

  const handleCheckboxChange = (method: 'email' | 'sms' | 'voicemail') => {
    const props = { ...categories, [method]: !categories[method] };
    setCategories(props);
    saveCheck(method);
  };

  return (
    <Card className='w-full mx-auto bg-white rounded-lg shadow-lg'>
      <div>
        <div className='flex flex-col mb-4'>
          <div className='flex justify-between items-center'>
            <div className='flex items-center space-x-4'>
              <h3 className='text-lg font-semibold'>{categories.heading}</h3>
              <LearnMore tutorialId={tutorialId} />
            </div>
            <div className='flex items-center space-x-4'>
              <PreviewButton
                title={notifKey === NotifKey.DirectCall ? 'Customize Email Notification' : 'Preview Email Notification'}
                setPreviewEmail={setPreviewEmail}
                children={<MailOutline fontSize='small' />}
              />
              <PreviewButton
                title={
                  notifKey === NotifKey.DirectCall ? 'Customize Text SMS Notification' : 'Preview Text SMS Notification'
                }
                setPreviewEmail={setPreviewSms}
                children={<ChatBubbleOutline fontSize='small' />}
              />
            </div>
          </div>
          <span>{categories.cardDescription}</span>
        </div>

        <div className={`flex flex-wrap justify-between items-center mb-2`}>
          <div>
            <h4 className='font-medium'>{categories.label}</h4>
            <p className='text-sm text-gray-500'>{categories.description}</p>
          </div>

          <div className='flex space-x-8'>
            <div className='flex items-center space-x-1'>
              <Tooltip title='Included from Basic plan'>
                <WorkspacePremiumOutlined fontSize='small' className='text-blue-500 cursor-pointer' />
              </Tooltip>
              <label>Email</label>
              <Checkbox
                checked={categories.email}
                onChange={() => handleCheckboxChange('email')}
                disabled={loggedInUser?.resellerClient ? false : loggedInUser.subscriptionStatus !== 'active'}
              />
            </div>

            <div className='flex items-center space-x-1'>
              <Tooltip title='Included from Pro plan'>
                <DiamondOutlined fontSize='small' className='text-yellow-500 cursor-pointer' />
              </Tooltip>
              <label>SMS</label>
              <Checkbox
                checked={categories.sms}
                onChange={() => handleCheckboxChange('sms')}
                disabled={
                  loggedInUser?.resellerClient
                    ? false
                    : loggedInUser.subscriptionStatus !== 'active' ||
                      loggedInUser.subscriptionType?.toLocaleLowerCase() !== 'pro'
                }
              />
            </div>

            <div className='flex items-center space-x-1'>
              <Tooltip title='Included from Pro plan'>
                <DiamondOutlined fontSize='small' className='text-yellow-500 cursor-pointer' />
              </Tooltip>
              <label>Voicemail</label>
              <Badge count={'Upcoming'} className='badge-info' />
              <Checkbox
                checked={categories.voicemail}
                onChange={() => handleCheckboxChange('voicemail')}
                disabled={
                  true
                  //  loggedInUser.subscriptionStatus !== 'active' || loggedInUser.subscriptionType?.toLocaleLowerCase() !== 'pro'
                }
              />
            </div>
          </div>
        </div>
        {categories.email && (
          <div className='flex flex-col'>
            <span className='font-semibold italic text-xs'>Emails to be notified:</span>
            <div className='flex flex-wrap gap-2 items-center'>
              {categories.emails.map((item: string, index: number) => {
                return <Badge key={index} count={item} className='badge-disabled' />;
              })}
              <Button type='link' icon={<PlusOutlined />} onClick={() => setEmailAddClicked(!emailAddClicked)}>
                Add Email
              </Button>
            </div>
          </div>
        )}
        {categories.sms && (
          <div className='flex flex-col mt-2'>
            <span className='font-semibold italic text-xs'>Phones to be notified:</span>
            <div className='flex flex-wrap gap-2 items-center'>
              {categories.phones.map((item: string, index: number) => {
                return (
                  <Badge
                    key={index}
                    className='badge-disabled font-medium text-xs py-1 px-2 rounded-lg hover:bg-blue-100'
                  >
                    {item}
                  </Badge>
                );
              })}
              <Button type='link' icon={<PlusOutlined />} onClick={() => setPhoneAddClicked(!phoneAddClicked)}>
                Add Phone
              </Button>
            </div>
          </div>
        )}
        <PreviewEmail
          open={previewEmail}
          setOpen={setPreviewEmail}
          disabled={loggedInUser?.resellerClient ? false : loggedInUser.subscriptionStatus !== 'active'}
          notifKey={notifKey}
          show={show}
          onShowChange={onShowChange}
          resellerName={currentReseller?.reseller_name}
          resellerLogo={currentReseller?.logoUrl}
          resellerClient={loggedInUser?.resellerClient}
        />
        <PreviewSms
          open={previewSms}
          setOpen={setPreviewSms}
          disabled={
            loggedInUser?.resellerClient
              ? false
              : loggedInUser.subscriptionStatus !== 'active' ||
                loggedInUser.subscriptionType?.toLocaleLowerCase() !== 'pro'
          }
          notifKey={notifKey}
          show={show}
          onShowChange={onShowChange}
        />
        <AddEmails
          open={emailAddClicked}
          setOpen={val => setEmailAddClicked(val as boolean)}
          emails={emails}
          saveEmails={saveEmails}
        />
        <AddPhones
          open={phoneAddClicked}
          setOpen={val => setPhoneAddClicked(val as boolean)}
          phones={phones}
          savePhones={savePhones}
        />
      </div>
    </Card>
  );
};

export default NotificationCard;
