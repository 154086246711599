import { EmptyBoardProps } from '@/interfaces/IEmptyBoardProps';
import React, { useState } from 'react';
import AddEditGroupModal from '../Modals/AddEditGroupModal';
import { FaRobot, FaPhoneAlt, FaChartLine, FaDollarSign } from 'react-icons/fa';
import { Modal } from 'antd';

const EmptyBoard: React.FC<EmptyBoardProps> = ({ username, type }) => {
  const [isBoardModalOpen, setIsBoardModalOpen] = useState<boolean>(false);

  return (
    <div className='bg-white min-h-screen w-full flex flex-col items-center justify-start p-4 sm:p-8 pt-8 sm:pt-16'>
      <h1 className='text-2xl sm:text-3xl font-bold mb-4 text-gray-800 text-center'>⚡️ Welcome to the AI CRM</h1>
      <p className='text-lg sm:text-xl text-gray-600 mb-8 text-center max-w-2xl px-4'>
        Experience the first fully automated CRM for small businesses Your AI Receptionist intelligently groups leads
        based on call data.
      </p>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 mb-8 sm:mb-12 w-full max-w-4xl'>
        <FeatureCard
          icon={<FaRobot className='text-3xl sm:text-4xl mb-2 sm:mb-4 text-blue-500' />}
          title='AI-Powered Call Answering'
          description='Our AI captures call data, eliminating the need for phone answering staff'
        />
        <FeatureCard
          icon={<FaPhoneAlt className='text-3xl sm:text-4xl mb-2 sm:mb-4 text-green-500' />}
          title='Automated Lead Management'
          description='Every conversation is automatically tracked and managed in your CRM'
        />
        <FeatureCard
          icon={<FaChartLine className='text-3xl sm:text-4xl mb-2 sm:mb-4 text-purple-500' />}
          title='Intelligent Lifecycle Marketing'
          description='Automate stage tracking for follow-ups and promotions without additional staff'
        />
        <FeatureCard
          icon={<FaDollarSign className='text-3xl sm:text-4xl mb-2 sm:mb-4 text-yellow-500' />}
          title='Enterprise-Grade'
          description='Get powerful Enterprise-Grade CRM features for free. Yes, this is included.'
        />
      </div>
      <button
        onClick={() => setIsBoardModalOpen(true)}
        className='bg-blue-600 text-white font-bold py-3 px-8 rounded-md text-lg hover:bg-blue-700 transition-colors duration-300 w-full sm:w-auto'
      >
        {type === 'edit' ? 'Create Your First Group' : 'Get Started'}
      </button>
      <Modal
        title={type === 'edit' ? 'Create Your First Group' : 'Get Started'}
        open={isBoardModalOpen}
        onCancel={() => setIsBoardModalOpen(false)}
        width={800}
        style={{ top: 20 }}
        footer={null}
      >
        <AddEditGroupModal
          type={type}
          setIsBoardModalOpen={setIsBoardModalOpen}
          username={username as string}
          isOpen={isBoardModalOpen}
        />
      </Modal>
    </div>
  );
};

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({
  icon,
  title,
  description,
}) => (
  <div className='border border-gray-200 rounded-lg p-4 sm:p-6 text-left w-full shadow-sm hover:shadow-md transition-shadow duration-300'>
    {icon}
    <h3 className='text-lg sm:text-xl font-semibold mb-2 text-gray-800'>{title}</h3>
    <p className='text-sm text-gray-600'>{description}</p>
  </div>
);

export default EmptyBoard;
