import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { ResellerAcademyInitialState } from '@/types/TResellerAcademy';
import { VideoContent } from '@/interfaces/IResellerAcademy';

const initialState: ResellerAcademyInitialState = {
  chapters: [],
  loading: false,
  error: '',
};

export const slice: Slice<
  ResellerAcademyInitialState,
  {
    setResellerAcademy: (
      state: ResellerAcademyInitialState,
      action: PayloadAction<ResellerAcademyInitialState>
    ) => void;
    updateResellerAcademyVideoBySlug: (
      state: ResellerAcademyInitialState,
      action: PayloadAction<{
        slug: string;
        chapterId: number;
        video: VideoContent;
      }>
    ) => void;
  },
  'resellerAcademy'
> = createSlice({
  name: 'resellerAcademy',
  initialState,
  reducers: {
    setResellerAcademy: (state: ResellerAcademyInitialState, action: PayloadAction<ResellerAcademyInitialState>) => {
      state.chapters = action.payload.chapters;
      state.loading = action.payload.loading;
    },

    updateResellerAcademyVideoBySlug: (
      state: ResellerAcademyInitialState,
      action: PayloadAction<{
        slug: string;
        chapterId: number;
        video: VideoContent;
      }>
    ) => {
      const { chapters } = state;
      const { slug, chapterId, video } = action.payload;
      const chapterIndex = chapters.findIndex(c => c.id === chapterId);
      if (chapterIndex === -1) return;
      const videoIndex = chapters[chapterIndex].videos.findIndex(v => v.slug === slug);
      if (videoIndex === -1) return;
      chapters[chapterIndex].videos[videoIndex] = video;
    },
  },
});

export const { setResellerAcademy, updateResellerAcademyVideoBySlug } = slice.actions;

export default slice.reducer;
