import apiClient from '@/utils/apiClient';

export const updateCampaignVariables = async (
  campaign_id: string,
  variables: any
): Promise<{
  message: string;
  variables: any;
}> => {
  if (!campaign_id) {
    return { message: 'Campaign ID is required', variables: [] };
  }
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: `/api/campaigns/${campaign_id}/updateVariables`,
      body: { variables },
    });
    return response.data;
  } catch (error) {
    throw new Error('Error updating campaign variables: ' + (error as Error).message);
  }
};

export const cancelCampaignAPI = async (id: string, campaign_id: string): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `/api/campaigns/cancel`,
      body: { id, campaign_id },
    });

    return response.data;
  } catch (error) {
    throw new Error('Error cancelling campaign: ' + (error as Error).message);
  }
};

export const callNow = async (payload: {
  lead: any;
  campaign_id: string;
  campaign_user_email: string;
  timeZone: string;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `/api/campaigns/call/immediate`,
      body: payload,
    });

    return response.data;
  } catch (error) {
    throw new Error('Error immediate campaign: ' + (error as Error).message);
  }
};
