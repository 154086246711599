import { Col, Typography } from 'antd';
import './IconCard.scss';

const { Title, Paragraph } = Typography;

interface IconCardProps {
  onClick?: (e: any) => void;
  title: string;
  description: string;
  icon?: React.ReactNode;
  extraClass?: string;
}

const IconCard = ({ onClick, title, description, icon, extraClass }: IconCardProps) => {
  return (
    <Col
      className={`${extraClass} flex flex-col m-2 justify-center text-center items-center space-y-4 border-dotted border p-6 cursor-pointer rounded-2xl hover:shadow-lg icon-card`}
      onClick={onClick}
      span={10}
    >
      {icon && <>{icon}</>}
      <Title level={4}>{title}</Title>
      <Paragraph className='opacity-50'>{description}</Paragraph>
    </Col>
  );
};

export default IconCard;
