import { useState, useRef, useEffect } from 'react';
import { Heatmap } from '@ant-design/plots';
import { Button, Typography } from 'antd';
import moment from 'moment';
import { AnalyticsResponse, DayLog, HourLog, YearMonthLog } from '@/types/TAnalytics';

type Selection = {
  year: string | null;
  month: string | null;
  day: string | null;
};

type Props = {
  analyticsData: AnalyticsResponse;
};

// Helper functions
const generateCompleteMonthData = (year: string) => {
  return Array.from({ length: 12 }, (_, i) => ({
    year,
    month: i + 1,
    monthName: moment().month(i).format('MMM'),
    value: 0,
  }));
};

const generateCompleteDayData = (year: string, month: string) => {
  const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
  return Array.from({ length: daysInMonth }, (_, i) => ({
    year,
    month,
    day: i + 1,
    value: 0,
  }));
};

const generateCompleteHourData = (year: string, month: string, day: string) => {
  const hours = Array.from({ length: 24 }, (_, i) => ({
    year,
    month,
    day,
    hour: i,
    value: 0,
    hourLabel: `${i.toString().padStart(2, '0')}:00`,
  }));

  return hours.sort((a, b) => a.hour - b.hour);
};

// Main Component
export const AllCallLogs = ({ analyticsData }: Props) => {
  const [selection, setSelection] = useState<Selection>({
    year: null,
    month: null,
    day: null,
  });

  // Add refs to track current state
  const currentSelectionRef = useRef(selection);
  const [viewLevel, setViewLevel] = useState<'yearMonth' | 'monthDay' | 'dayHour'>('yearMonth');
  const viewLevelRef = useRef<'yearMonth' | 'monthDay' | 'dayHour'>('yearMonth');

  // Update refs when selection changes
  useEffect(() => {
    currentSelectionRef.current = selection;
    if (selection.day) {
      viewLevelRef.current = 'dayHour';
      setViewLevel('dayHour');
    } else if (selection.month) {
      viewLevelRef.current = 'monthDay';
      setViewLevel('monthDay');
    } else {
      viewLevelRef.current = 'yearMonth';
      setViewLevel('yearMonth');
    }
  }, [selection]);

  const handleCellClick = (data: any) => {
    if (viewLevelRef.current === 'yearMonth') {
      const newSelection = {
        year: data.data.year,
        month: data.data.month.toString().padStart(2, '0'),
        day: null,
      };
      setSelection(newSelection);
    } else if (viewLevelRef.current === 'monthDay') {
      const newSelection = {
        ...currentSelectionRef.current,
        day: data.data.day.toString().padStart(2, '0'),
      };
      setSelection(newSelection);
    }
  };

  const handleBack = () => {
    if (viewLevelRef.current === 'dayHour') {
      setSelection(prev => ({
        ...prev,
        day: null,
      }));
    } else if (viewLevelRef.current === 'monthDay') {
      setSelection(_ => ({
        year: null,
        month: null,
        day: null,
      }));
    }
  };

  const getHeatmapData = () => {
    if (!analyticsData?.yearMonthLogs) {
      return [];
    }
    const { year, month, day } = selection;

    if (!year) {
      const years = Array.from(new Set(analyticsData.yearMonthLogs.map(log => log.year)));
      const completeData = years
        .flatMap(year => generateCompleteMonthData(year))
        .sort((a, b) => {
          const yearDiff = parseInt(b.year) - parseInt(a.year);
          if (yearDiff !== 0) return yearDiff;
          return a.month - b.month;
        });

      analyticsData.yearMonthLogs.forEach(log => {
        const index = completeData.findIndex(item => item.year === log.year && item.month === log.month);
        if (index !== -1) {
          completeData[index].value = log.value;
        }
      });

      return completeData;
    }

    if (!day) {
      const key = `${year}-${month}`;
      const monthData = analyticsData.monthDayLogs[key] || [];
      const completeData = generateCompleteDayData(year, month as string);

      monthData.forEach(log => {
        const index = completeData.findIndex(item => item.day === log.day);
        if (index !== -1) {
          completeData[index].value = log.value;
        }
      });

      completeData.sort((a, b) => a.day - b.day);
      return completeData;
    }

    const key = `${year}-${month}-${day}`;
    const hourData = analyticsData.dayHourLogs[key] || [];
    const completeData = generateCompleteHourData(year, month as string, day);

    hourData.forEach(log => {
      const index = completeData.findIndex(item => item.hour === log.hour);
      if (index !== -1) {
        completeData[index].value = log.value;
      }
    });

    return completeData;
  };

  const getConfig = () => {
    const data = getHeatmapData();
    const { year, day } = selection;

    const baseConfig = {
      data,
      colorField: 'value',
      mark: 'cell',
      style: { inset: 5 },
      scale: {
        color: {
          range: ['#86abe0', '#242f85'],
          domain: [0, Math.max(...data.map(d => d.value))],
        },
      },
      onReady: ({ chart }: any) => {
        chart.on('element:click', (e: any) => {
          handleCellClick(e.data);
        });
      },
    };

    if (!year) {
      return {
        ...baseConfig,
        xField: 'monthName',
        yField: 'year',
        tooltip: {
          title: (row: YearMonthLog) =>
            moment()
              .month(row.month - 1)
              .format('MMMM YYYY'),
          items: [
            {
              name: 'Total Calls',
              channel: 'color',
            },
          ],
        },
      };
    }

    if (!day) {
      return {
        ...baseConfig,
        xField: 'day',
        yField: 'month',
        tooltip: {
          title: (row: DayLog) =>
            moment()
              .month(parseInt(row.month) - 1)
              .date(row.day)
              .format('MMMM D, YYYY'),
          items: [
            {
              name: 'Total Calls',
              channel: 'color',
            },
          ],
        },
      };
    }

    return {
      ...baseConfig,
      xField: 'hourLabel',
      yField: 'day',
      meta: {
        hourLabel: { type: 'cat' },
      },
      tooltip: {
        title: (row: HourLog) =>
          moment()
            .month(parseInt(row.month) - 1)
            .date(parseInt(row.day))
            .hour(row.hour)
            .format('MMMM D, YYYY h A'),
        items: [
          {
            name: 'Total Calls',
            channel: 'color',
          },
        ],
      },
    };
  };

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <Typography.Title level={5}>
          {selection.year === null && 'Year-Month View'}
          {selection.month !== null && selection.day === null && 'Month-Day View'}
          {selection.day !== null && 'Day-Hour View'}
        </Typography.Title>
        <Typography.Text type='secondary'>Click on a cell to view more detailed information.</Typography.Text>{' '}
      </div>

      {viewLevel !== 'yearMonth' && (
        <Button onClick={handleBack} style={{ marginBottom: '16px' }}>
          Back
        </Button>
      )}

      <Heatmap {...getConfig()} />
    </div>
  );
};
