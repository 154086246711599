import React from 'react';
import { Alert, Button, Form, Input, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setNoviUser } from '@/store/novi/user/slice';
import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { loginNoviUser } from '@/api/novi/user';

const { Title, Text } = Typography;

export const CampaignLogin: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const mutation = useMutation({
    mutationKey: ['campaignLoginApi'],
    mutationFn: loginNoviUser,
    onSuccess: data => {
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_AUTH_TOKEN, data.auth_token);
      dispatch(setNoviUser({ ...data.user }));
      navigate('/novi-portal/');
    },
  });

  return (
    <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <Form form={form} layout='vertical' onFinish={values => mutation.mutate(values)} className='space-y-6'>
            <div className='text-center mb-8'>
              <Title level={2} className='mb-2'>
                Welcome Back
              </Title>
              <Text className='text-gray-500'>Log in to your campaign portal</Text>
            </div>

            <Form.Item
              label='Email Address'
              name='email_address'
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input size='large' placeholder='Enter your email' />
            </Form.Item>

            <Form.Item
              label='Password'
              name='password'
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password size='large' placeholder='Enter your password' />
            </Form.Item>

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                size='large'
                loading={mutation.isPending}
                className='w-full h-12'
              >
                Submit
              </Button>
            </Form.Item>

            {mutation.isError && (
              <Alert message={mutation.error?.message || 'An error occurred'} type='error' showIcon className='mb-4' />
            )}

            <div className='flex flex-col items-center space-y-4 mt-6 text-sm'>
              <Text className='text-gray-600'>
                Don't have an account?{' '}
                <Link to='/novi-portal/register' className='text-blue-600 hover:text-blue-500'>
                  Register Now
                </Link>
              </Text>
              {/* <Link to="/forgot-password" className="text-blue-600 hover:text-blue-500">
                Forgot Password?
              </Link> */}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
