import React, { useEffect, useState } from 'react';
import { Image, notification, Popconfirm } from 'antd';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { UploadOutlined } from '@mui/icons-material';
import { CloseCircleOutlined } from '@ant-design/icons';

interface ImageUploadProps {
  file: File;
  onImageChange?: (file: File | null) => void;
  presignedUrl: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ file, onImageChange, presignedUrl }) => {
  const [previewImageAvatar, setPreviewImageAvatar] = useState(presignedUrl);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [showCloseIconAvatar, setShowCloseIconAvatar] = useState(false);

  const handleAvatarImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const imageFile = event.target.files && event.target.files[0];
    if (imageFile) {
      // handle file extension
      const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
      if (!allowedFileTypes.includes(imageFile.type)) {
        notification.error({
          message: 'Invalid file type. Please choose a .jpeg, .jpg or .png file.',
        });
        return;
      }

      // handle file size
      const fileSizeLimitInBytes = 1024 * 1024; // 1 MB
      if (imageFile.size > fileSizeLimitInBytes) {
        notification.error({
          message: 'File size exceeds the limit of 1 MB. Please choose a smaller file.',
        });
        return;
      }

      // process/read file
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => {
        const base64Image = reader.result as string;
        setPreviewImageAvatar(base64Image);
        console.log('Setting preview image:', base64Image);
      };
      reader.onerror = error => {
        console.error('FileReader error:', error);
      };

      // save file
      setAvatarFile(imageFile);
      onImageChange?.(imageFile); // Notify parent component
    }
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result as string;
        setPreviewImageAvatar(base64Image);
        console.log('Setting preview image:', base64Image);
      };
    }
  }, [file]);

  const handleDropAvatar = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setAvatarFile(droppedFile);
    onImageChange?.(droppedFile); // Notify parent component
  };

  const handleClearImage = () => {
    setPreviewImageAvatar('');
    setAvatarFile(null);
    onImageChange?.(null); // Notify parent component
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div className='border-b'>
      <LabelWithDescription
        customClassName='mt-2'
        label='Attach Image (Optional)'
        description='Please attach a JPEG/JPG/PNG file not more than 1 MB'
      />
      <div
        onDrop={handleDropAvatar}
        onDragOver={handleDragOver}
        className='sm:w-full rounded-lg px-10 py-6 border-dashed bg-white border-2 border-borderColor'
      >
        <label
          htmlFor='dropzone-file-avatar'
          className='flex flex-col items-center justify-center gap-4 cursor-pointer'
        >
          {!avatarFile && (
            <div className='flex'>
              <div className='rounded-full p-3 bg-grayLight'>
                <UploadOutlined />
              </div>
              <div className='px-2'>
                <p className='text-lg font-semibold'>Upload Image</p>
                <p className='text-md font-semibold text-gray'>{'Must be JPEG/JPG/PNG image (Max. 1 MB)'}</p>
              </div>
            </div>
          )}
          <input
            id='dropzone-file-avatar'
            type='file'
            accept='.png, .jpg, .jpeg'
            onChange={handleAvatarImageChange}
            multiple={false}
            style={{ display: 'none' }}
          />
        </label>
        {previewImageAvatar && (
          <>
            <div className='relative'>
              <Image
                src={previewImageAvatar}
                className='z-10 w-full h-full'
                alt='Avatar IMG'
                onMouseOut={() => setShowCloseIconAvatar(true)}
                onMouseOver={() => setShowCloseIconAvatar(false)}
              />
              {showCloseIconAvatar && (
                <Popconfirm
                  title='Remove Image'
                  description='Are you sure, you want to delete this image?'
                  okText='Yes'
                  okButtonProps={{ className: 'bg-blue' }}
                  cancelText='No'
                  onConfirm={handleClearImage}
                >
                  <div className='absolute top-0 right-0'>
                    <CloseCircleOutlined size={20} className='cursor-pointer z-20' />
                  </div>
                </Popconfirm>
              )}
            </div>
            <p className='text-lg text-textSecondary'>{file?.name}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
