import { ArrowLeftOutlined, CheckCircleFilled, FilePdfOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Empty, List, Spin, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'src/enums/ERoutes';
import ResellerDashboard from '../../ResellerDashboard/ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import './ChapterDetail.scss';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getChapterById, getResellerAcademy } from '@/store/resellerAcademy/selector';

const ChapterDetail = () => {
  const { Text, Title } = Typography;
  const { chapterId } = useParams();
  const navigate = useNavigate();
  const chapter = useAppSelector(getChapterById(Number(chapterId)));
  const { loading } = useAppSelector(getResellerAcademy);

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.ACADEMY} showHeader={false}>
      <Spin spinning={loading}>
        <div className='chapter-detail'>
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(AppRoutes.RESELLER_ACADEMY)}
            className='chapter-detail__back-button'
          >
            Back to Chapters
          </Button>

          {!chapter && !loading ? (
            <Empty description='Chapter not found' className='chapter-detail__empty'>
              <Button type='primary' onClick={() => navigate(AppRoutes.RESELLER_ACADEMY)}>
                Return to Academy
              </Button>
            </Empty>
          ) : (
            <>
              <Title level={2}>{chapter?.title}</Title>
              <Text className='chapter-detail__description'>{chapter?.description}</Text>

              <List
                className='chapter-detail__list'
                itemLayout='horizontal'
                dataSource={chapter?.videos}
                renderItem={video => (
                  <List.Item
                    className='chapter-detail__list-item'
                    onClick={() =>
                      navigate(
                        AppRoutes.RESELLER_ACADEMY_VIDEO.replace(':chapterId', chapter?.id?.toString() || '').replace(
                          ':videoSlug',
                          video?.slug?.toString() || ''
                        )
                      )
                    }
                  >
                    <List.Item.Meta
                      avatar={
                        video.isCompleted ? (
                          <CheckCircleFilled className='chapter-detail__icon chapter-detail__icon--completed' />
                        ) : (
                          <PlayCircleOutlined className='chapter-detail__icon' />
                        )
                      }
                      title={video.title}
                      description={
                        <div className='chapter-detail__video-meta'>
                          <Text className='chapter-detail__video-description'>{video.description}</Text>
                          <Text className='chapter-detail__video-duration'>
                            {(video.duration / 60).toPrecision(2)} mins
                          </Text>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
              {chapter?.pdfs && chapter.pdfs.length > 0 && (
                <>
                  <Divider className='chapter-detail__divider' />
                  <Title level={3}>Additional Reading Materials</Title>
                  <List
                    className='chapter-detail__list chapter-detail__pdf-list'
                    itemLayout='horizontal'
                    dataSource={chapter.pdfs}
                    renderItem={pdf => (
                      <List.Item
                        className='chapter-detail__list-item'
                        onClick={() =>
                          navigate(
                            AppRoutes.RESELLER_ACADEMY_PDF.replace(':chapterId', chapter?.id?.toString() || '').replace(
                              ':pdfSlug',
                              pdf?.slug?.toString() || ''
                            )
                          )
                        }
                      >
                        <List.Item.Meta
                          avatar={<FilePdfOutlined className='chapter-detail__icon chapter-detail__icon--pdf' />}
                          title={pdf.title}
                          // description={
                          //   <div className='chapter-detail__pdf-meta'>
                          //     <Text className='chapter-detail__pdf-description'>{pdf.description}</Text>
                          //     {pdf.size && <Text className='chapter-detail__pdf-size'>{pdf.size}</Text>}
                          //   </div>
                          // }
                        />
                      </List.Item>
                    )}
                  />
                </>
              )}
            </>
          )}
        </div>
      </Spin>
    </ResellerDashboard>
  );
};

export default ChapterDetail;
