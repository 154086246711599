import React, { useEffect } from 'react';
import {
  cleanDataOfManagedReceptionist,
  createCheckoutSessionNewResellers,
  decrementUnreservedReceptionist,
  deleteReceptionist,
  ResellerDashboardLink,
  updateManagedReceptionist,
} from '@/api/reseller';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Input,
  InputNumber,
  notification,
  Switch,
  Card,
  Row,
  Col,
  Typography,
  Space,
  Tooltip,
  Select,
} from 'antd';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CopyOutlineIcon from '@mui/icons-material/FileCopyOutlined';

// @ts-ignore
import RegisterPageV3 from '../../../v1/RegisterPageV3.jsx';
import classes from '../../../v1/Whitelabel/ResellerPortal.module.css';
import { CustomModal } from '@/components/CustomModal';
import { ReceptionistDashboard } from '@/pages/ReceptionistDashboard/ReceptionistDashboard.tsx';
import { createReceptionistClient, loginApi } from '@/api/user';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setAccount } from '@/store/account/slice';
import { Loading } from '@/components/Loading';
import ReceptionistCard from '@/Reseller_Common_Components/ReceptionistCard.tsx';
import { updateReseller, updateResellerClients } from '@/store/reseller/slice.js';
import { CopyAll, ShoppingCartCheckout } from '@mui/icons-material';
import ResellerDashboard from '../ResellerDashboard.tsx';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems.ts';
import LocalStorageUtility from '@/utils/localStorage.ts';
import { LocalStorageKeys } from 'src/enums/ELocalStorage.ts';
import { useGetResellerClients } from 'src/Hooks/resellerHooks.ts';
import { getTwilioBundleStatus } from '@/api/reservedNumbers.ts';
import { AppRoutes } from 'src/enums/ERoutes.ts';
import { CopyOutlined } from '@ant-design/icons';
import FeatureGatingCustomizeModal from '../Reseller_Common_Components/FeatureGatingCustomizeModal.tsx';

const { Title } = Typography;

export default function MyReceptionist() {
  const resellerData = useAppSelector(getResellerData);

  const dispatch = useAppDispatch();

  const [clients, setClients] = React.useState<any[]>([]);
  const [isRegistrationModal, setIsRegistrationModalActive] = React.useState<boolean>(false);
  const [successConfigureReceptionistModal, setSuccessConfigureReceptionistModal] = React.useState<boolean>(false);
  const [deleteReceptionistNoticeModal, setDeleteReceptionistNoticeModal] = React.useState<boolean>(false);
  const [clearAllLogsWarningModal, setClearAllLogsWarningModal] = React.useState<boolean>(false);
  const [receptionistCurrentlySelected, setCurrentReceptionist] = React.useState<{ number: string } | null>(null);
  const [changeMangedReceptionistPasswordModal, setChangeMangedReceptionistPasswordModal] =
    React.useState<boolean>(false);
  const [resellerMinutesLimitationModal, setResellerMinutesLimitationModal] = React.useState<boolean>(false);
  const [purchaseSeatsModal, setPurchaseSeatsModal] = React.useState<boolean>(false);
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [maxMinutes, setMaxMinutes] = React.useState<number | null>(60); // Default max_minutes value
  const [minutesLimitEnabled, setMinutesLimitEnabled] = React.useState<boolean>(false); // Default enabled value
  const [removeSubscriptionModal, setRemoveSubscriptiontNoticeModal] = React.useState<boolean>(false);
  const [seatsBuyed, setSeatsBuyed] = React.useState<number | null>(1);
  const [copyReceptionistModal, setCopyReceptionistModal] = React.useState<boolean>(false);
  const [copiedReceptiontCreds, setCopiedReceptiontCreds] = React.useState({
    username: '',
    password: '',
  });

  const [previewDashboardModalIsOpen, setPreviewDashboardModalIsOpen] = React.useState<boolean>(false);
  const [currentClient, setCurrentClient] = React.useState<string | null>(null);
  const [embedLinkModal, setEmbedLinkModal] = React.useState<boolean>(false);

  const getClientsMutation = useGetResellerClients({
    shouldFetchReservedNumbers: true,
    onSuccess: data => {
      setClients(data.items);
      if (!resellerData.totalReceptionists) {
        setSeatsBuyed(5);
      }
    },
  });
  const { data: linkData } = useQuery({
    queryKey: ['getResellerDashboardLink', resellerData.email_address],
    queryFn: () => ResellerDashboardLink({ email: resellerData.email_address }),
  });
  const handleCopyIframe = async (route: string) => {
    if (linkData?.link) {
      try {
        const iframeUrl = `${import.meta.env.VITE_APP_WIHTELABEL_URL}${route}?code=${linkData.link}`;
        const iframeString = `<iframe src="${iframeUrl}" width="100%" height="100%" frameBorder="0" style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        border: 'none',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        zIndex: 999999,
      }}></iframe>`;

        await navigator.clipboard.writeText(iframeString);
        notification.success({ message: 'iframe copied successfully' });
      } catch {
        notification.error({ message: 'Failed to copy iframe' });
      }
    } else {
      notification.error({ message: 'No link available to copy' });
    }
  };

  const decrementUnreservedReceptionistMutation = useMutation({
    mutationKey: ['decrementUnreservedReceptionist'],
    mutationFn: decrementUnreservedReceptionist,
    onSuccess() {
      dispatch(
        updateReseller({
          ...resellerData,
          unreservedReceptionists: resellerData.unreservedReceptionists - 1,
          totalReceptionists: resellerData.totalReceptionists - 1,
        })
      );
      setRemoveSubscriptiontNoticeModal(false);

      notification.success({
        message: 'Receptionist deleted',
      });
    },
    onError() {
      setRemoveSubscriptiontNoticeModal(false);

      notification.error({
        message: 'Error deleting receptionist',
      });
    },
  });

  const deleteReceptionistMutation = useMutation({
    mutationKey: ['deleteReceptionist'],
    mutationFn: deleteReceptionist,

    onSuccess(_, payload) {
      setClients(prev => {
        const newState = prev.filter(client => client.number !== payload.receptionist_username);
        dispatch(updateResellerClients(newState));
        return newState;
      });
      dispatch(
        updateReseller({
          ...resellerData,
          unreservedReceptionists: resellerData.unreservedReceptionists + 1,
        })
      );
      notification.success({
        message: 'Receptionist deleted',
      });
    },
    onError() {
      notification.error({
        message: 'Error deleting receptionist',
      });
    },
  });

  const previewCurrentClientMutation = useMutation({
    mutationKey: ['previewCurrentClient'],
    mutationFn: loginApi,
    onSuccess(data) {
      if (data.token) {
        dispatch(
          setAccount({
            isAuthenticated: true,
            user: data,
            authToken: data.token,
            currentPage: '',
            activeConfigureTab: '',
          })
        );

        LocalStorageUtility.setLocalData(LocalStorageKeys.AUTH_TOKEN, data.token);
      }
      setPreviewDashboardModalIsOpen(true);
    },
    onError() {
      notification.error({
        message: 'Error previewing client',
      });
    },
  });

  const updateMangedReceptionistPassword = useMutation({
    mutationKey: ['updateMangedReceptionistPassword'],
    mutationFn: updateManagedReceptionist,
    onSuccess(_, payload) {
      notification.success({
        message: 'Receptionist password updated',
      });

      setClients(prev => {
        const newState = prev.map(client => {
          if (client.number === payload.receptionist_username) {
            return {
              ...client,
              password: newPassword,
            };
          }
          return client;
        });
        dispatch(updateResellerClients(newState));
        return newState;
      });
      setChangeMangedReceptionistPasswordModal(false);
    },
    onError() {
      notification.error({
        message: 'Error updating receptionist password',
      });
    },
  });

  const updateMangedReceptionistMinutes = useMutation({
    mutationKey: ['updateMangedReceptionistMinutes'],
    mutationFn: updateManagedReceptionist,
    onSuccess(_, payload) {
      notification.success({
        message: 'Receptionist Minutes updated',
      });

      setClients(prev => {
        const newState = prev.map(client => {
          if (client.number === payload.receptionist_username) {
            return {
              ...client,
              max_minutes: maxMinutes,
              minutesLimitEnabled: minutesLimitEnabled,
            };
          }
          return client;
        });
        dispatch(updateResellerClients(newState));
        return newState;
      });

      setResellerMinutesLimitationModal(false);
    },
    onError() {
      notification.error({
        message: 'Error updating receptionist minutes',
      });
    },
  });

  const clearAllLogsMutation = useMutation({
    mutationKey: ['clearAllLogs'],
    mutationFn: cleanDataOfManagedReceptionist,
    onSuccess() {
      notification.success({
        message: `Logs cleared for ${receptionistCurrentlySelected?.number}`,
      });
    },
    onError() {
      notification.error({
        message: 'Error clearing logs',
      });
    },
  });

  const createCheckoutSessionNewResellersMutation = useMutation({
    mutationKey: ['createCheckoutSessionNewResellers'],
    mutationFn: createCheckoutSessionNewResellers,
    onSuccess(data: any) {
      setPurchaseSeatsModal(false);
      if (data?.redirectPaymentURL) {
        // open this on same page
        window.location.href = data?.redirectPaymentURL;

        notification.success({
          message: 'Payment page opened',
        });

        // @ts-ignore
        window.gtag('event', 'resellerPurchaseClicked', {
          event_category: 'EditDemo.js',
          event_label: 'success',
        });
      } else if (data.quantity) {
        dispatch(
          updateReseller({
            ...resellerData,
            totalReceptionists: resellerData.totalReceptionists + data.quantity,
            unreservedReceptionists: resellerData.unreservedReceptionists + data.quantity,
          })
        );
        notification.success({
          message: `Seats Added to your subscription ${data.quantity}`,
        });
      }
    },
    onError(error) {
      const errorMessage = error?.message || 'Error creating checkout session';
      notification.error({
        message: errorMessage,
      });
      // @ts-ignore
      window.gtag('event', 'couldntPurchase', {
        event_category: 'EditDemo.js',
        event_label: 'error',
      });
    },
  });

  const createCopiedReceptionistMutation = useMutation({
    mutationKey: ['createCopiedReceptionist'],
    mutationFn: createReceptionistClient,
    onSuccess(data) {
      if (!data.success) {
        notification.error({
          message: 'Error copying receptionist',
        });
        return;
      }
      setCopyReceptionistModal(false);
      notification.success({
        message: 'Receptionist copied',
      });
      dispatch(
        updateReseller({
          ...resellerData,
          unreservedReceptionists: resellerData.unreservedReceptionists - 1,
        })
      );
      getClientsMutation.mutate(resellerData.email_address);
    },
    onError(err) {
      notification.error({
        message: err.message || 'Error copying receptionist',
      });
    },
  });

  const handleCopyReceptionist = () => {
    const currentClientObject = clients.find(client => client.number === receptionistCurrentlySelected?.number);
    if (currentClientObject) {
      // Create a new object without the number field
      const { number, ...clientWithoutNumber } = currentClientObject;
      const mappedObject = {
        email: copiedReceptiontCreds.username,
        businessName: clientWithoutNumber.business_name,
        testingNumber: clientWithoutNumber.testing_number,
        systemPhrase: clientWithoutNumber.system_phrase,
        variables: JSON.parse(clientWithoutNumber.variables || '{}'),
        companyInfo: clientWithoutNumber.business_information,
        password: copiedReceptiontCreds.password,
        referralCode: clientWithoutNumber.referral,
        findOutAboutUs: clientWithoutNumber.find_out_about_us,
        sampleQA: clientWithoutNumber.sample_qa,
        selectedLanguages: JSON.parse(clientWithoutNumber.selected_languages || '[]'),
        languageGreetings: JSON.parse(clientWithoutNumber.language_greetings || '{}'),
        workflows: JSON.parse(clientWithoutNumber.workflows || '{}'),
        callWorkflows: JSON.parse(clientWithoutNumber.callWorkflows || '{}'),
        businessType: clientWithoutNumber.businessType,
        reseller_email: clientWithoutNumber.reseller_email,
        username: copiedReceptiontCreds.username,
        voiceSelected: clientWithoutNumber.voice_selected,
        english_voice_selected: clientWithoutNumber.english_voice_selected,
        unreservedReceptionists: resellerData.unreservedReceptionists,
      };

      createCopiedReceptionistMutation.mutate(mappedObject);
    }
  };
  React.useEffect(() => {
    if (resellerData.email_address) {
      getClientsMutation.mutate(resellerData.email_address);
    }
    // @ts-ignore

    window.gtag('event', 'WhitelabelReceptionistDirectoryView', {
      event_category: 'Pageview',
    });
  }, [resellerData.email_address]);

  // getTwilioBundleStatus get call using useQuery with bundleId as a parameter

  const { data } = useQuery({
    queryKey: ['getTwilioBundleStatus', resellerData?.twilioBundleId],
    queryFn: () => getTwilioBundleStatus(resellerData?.twilioBundleId as string, true),
    enabled: !!resellerData?.twilioBundleId,
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    retry: 3,
  });

  useEffect(() => {
    if (data) dispatch(updateReseller({ twilioBundleStatus: data.status }));
  }, [data]);

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.MY_RECEPTIONISTS}>
      <FeatureGatingCustomizeModal setClients={data => setClients(data)} clients={clients} />

      {!resellerData || getClientsMutation.isPending ? (
        <Loading />
      ) : (
        <div className='!h-full p-6'>
          {/* Reserved Receptionists */}
          <div className='flex flex-row justify-between'>
            <h1 className='text-2xl font-semibold mb-4'>Reserved Receptionists ({clients.length})</h1>
            <Space direction='horizontal'>
              <Button type='default' onClick={() => setEmbedLinkModal(true)}>
                <CopyAll />
                Embed Iframe
              </Button>
              <Button type='default' onClick={() => setPurchaseSeatsModal(true)}>
                {/* Add some shopping cart */}
                <ShoppingCartCheckout />
                Purchase More Seats
              </Button>
            </Space>
          </div>
          <Row gutter={[16, 16]}>
            {clients.map(client => (
              <Col key={client.id} xs={24} sm={12} md={8}>
                <ReceptionistCard
                  business_name={client.business_name}
                  number={client.number}
                  password={client.password}
                  minutesLimitEnabled={client.minutesLimitEnabled}
                  max_usage_limit_logs={client.max_usage_limit_logs}
                  loading={previewCurrentClientMutation.isPending}
                  featureVisibilitySubClient={client.featureVisibility}
                  featureGatingSubClient={client.featureGating}
                  reservedNumber={client.reservedNumber}
                  featuresSubAccount={client.featuresSubAccount}
                  onDelete={(number: any) => {
                    setCurrentReceptionist({ number });
                    setDeleteReceptionistNoticeModal(true);
                  }}
                  onPreview={(credentials: any) => {
                    previewCurrentClientMutation.mutate(credentials);
                  }}
                  onChangePassword={(number: any) => {
                    setCurrentReceptionist({ number });
                    setChangeMangedReceptionistPasswordModal(true);
                  }}
                  onClearLogs={(number: any) => {
                    setCurrentReceptionist({ number });
                    setClearAllLogsWarningModal(true);
                  }}
                  currentClient={currentClient}
                  setCurrentClient={setCurrentClient}
                  onSetMinutesLimit={(number: any) => {
                    setCurrentReceptionist({ number });
                    setResellerMinutesLimitationModal(true);
                  }}
                />
              </Col>
            ))}
          </Row>

          {/* Unreserved Receptionists */}
          <h1 className='text-2xl font-semibold my-4'>
            Unreserved Receptionists ({resellerData?.unreservedReceptionists || 0})
          </h1>
          <Row gutter={[16, 16]}>
            {Array.from({ length: resellerData?.unreservedReceptionists }).map((_, index) => (
              <Col key={index} xs={24} sm={12} md={8}>
                <Card
                  title={`Unreserved Receptionist #${index + 1}`}
                  className='hover:shadow-lg transition-shadow duration-300'
                  extra={
                    <Space direction='horizontal'>
                      <button
                        onClick={() => {
                          setCopyReceptionistModal(true);
                        }}
                        className='text-gray-400 hover:text-red-500 transition-colors'
                        aria-label='copy existing receptionist'
                      >
                        <Tooltip title='copy existing receptionist' key='copy'>
                          <CopyOutlineIcon sx={{ fontSize: 20 }} />
                        </Tooltip>
                      </button>
                      <button
                        onClick={() => {
                          setRemoveSubscriptiontNoticeModal(true);
                        }}
                        className='text-gray-400 hover:text-red-500 transition-colors'
                        aria-label='Delete receptionist'
                      >
                        <Tooltip title='delete unreserved receptionist' key='copy'>
                          <DeleteOutlineIcon sx={{ fontSize: 20 }} />
                        </Tooltip>
                      </button>
                    </Space>
                  }
                >
                  <Button
                    onClick={() => {
                      setIsRegistrationModalActive(true);
                    }}
                    type='primary'
                    block
                  >
                    Reserve Receptionist
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>

          {/* All Modals below this point */}
          <CustomModal
            title='Success'
            isModalOpen={successConfigureReceptionistModal}
            confirmAction={() => {
              getClientsMutation.mutate(resellerData.email_address);

              setSuccessConfigureReceptionistModal(false);
            }}
            cancelAction={() => {
              getClientsMutation.mutate(resellerData.email_address);
              setSuccessConfigureReceptionistModal(false);
            }}
          >
            <Title level={4}>Congrats, your new receptionist has been onboarded successfully!</Title>
          </CustomModal>

          <CustomModal
            title='Are you sure?'
            isModalOpen={deleteReceptionistNoticeModal}
            confirmAction={() => {
              if (receptionistCurrentlySelected) {
                deleteReceptionistMutation.mutate({
                  receptionist_username: receptionistCurrentlySelected.number,
                  reseller_email: resellerData.email_address,
                });
              }
              setDeleteReceptionistNoticeModal(false);
            }}
            cancelAction={() => {
              setDeleteReceptionistNoticeModal(false);
            }}
            okText='Yes, Proceed'
          >
            <Title level={4}>This action cannot be undone. Are you sure you want to delete this receptionist?</Title>
          </CustomModal>

          <CustomModal
            title={null}
            footer={null}
            isModalOpen={previewDashboardModalIsOpen}
            confirmAction={() => {
              setPreviewDashboardModalIsOpen(false);
            }}
            width={'90%'}
            cancelAction={() => {
              setPreviewDashboardModalIsOpen(false);
            }}
          >
            <ReceptionistDashboard debrand={true} />
          </CustomModal>

          <CustomModal
            title={null}
            isModalOpen={changeMangedReceptionistPasswordModal}
            confirmAction={() => {
              if (receptionistCurrentlySelected && newPassword) {
                updateMangedReceptionistPassword.mutate({
                  receptionist_username: receptionistCurrentlySelected.number,
                  fields: { password: newPassword },
                });
              }
            }}
            cancelAction={() => {
              setChangeMangedReceptionistPasswordModal(false);
            }}
            okText='Change Password'
          >
            <Title level={4}>Change Password for username {receptionistCurrentlySelected?.number}</Title>
            <Input
              placeholder='New Password'
              onChange={e => {
                setNewPassword(e.target.value);
              }}
            />
          </CustomModal>

          <CustomModal
            title='Are you sure?'
            isModalOpen={clearAllLogsWarningModal}
            confirmAction={() => {
              if (receptionistCurrentlySelected) {
                clearAllLogsMutation.mutate({
                  receptionist_username: receptionistCurrentlySelected.number,
                  tableNames: ['voicemails', 'textLogs', 'callLogs'],
                });
              }
              setClearAllLogsWarningModal(false);
            }}
            cancelAction={() => {
              setClearAllLogsWarningModal(false);
            }}
            okText='Yes, Proceed'
          >
            <Title level={4}>
              This action cannot be undone. Are you sure you want to clear all logs for{' '}
              {receptionistCurrentlySelected?.number} receptionist?
            </Title>
          </CustomModal>

          <CustomModal
            title='Minutes Limitation'
            isModalOpen={resellerMinutesLimitationModal}
            confirmAction={() => {
              if (receptionistCurrentlySelected) {
                updateMangedReceptionistMinutes.mutate({
                  receptionist_username: receptionistCurrentlySelected.number,
                  fields: {
                    max_minutes: maxMinutes,
                    usage_notification_emails: [resellerData.email_address],
                    enabled: minutesLimitEnabled,
                  },
                });
              }
            }}
            cancelAction={() => {
              setResellerMinutesLimitationModal(false);
            }}
            okText='Save'
          >
            <Title level={4}>Set Minutes Limitation for {receptionistCurrentlySelected?.number}</Title>

            <div className='mt-4 space-y-4'>
              <div className='flex flex-col'>
                <label className='font-medium'>Maximum Minutes</label>
                <InputNumber
                  min={1}
                  value={maxMinutes}
                  onChange={value => setMaxMinutes(value)}
                  className='w-full mt-2'
                />
              </div>

              <div className='flex flex-row justify-between items-center'>
                <label className='font-medium'>Enable Limitation</label>
                <Switch checked={minutesLimitEnabled} onChange={checked => setMinutesLimitEnabled(checked)} />
              </div>
            </div>
          </CustomModal>

          <CustomModal
            title='Purchase More Seats'
            isModalOpen={purchaseSeatsModal}
            confirmAction={() => {
              // Purchase more seats
              if (seatsBuyed) {
                createCheckoutSessionNewResellersMutation.mutate({
                  resellerEmail: resellerData.email_address,
                  quantity: seatsBuyed,
                });
              }
            }}
            cancelAction={() => {
              setPurchaseSeatsModal(false);
            }}
            okText='Purchase'
            loading={createCheckoutSessionNewResellersMutation.isPending}
          >
            <Title level={4}>Buy More seats for your reseller dashboard</Title>

            <Typography.Text>
              You currently have {resellerData.totalReceptionists || 'no'} seats. How many more would you like to
              purchase?{' '}
              {resellerData.totalReceptionists ? '' : 'Note: for newcomers if they are new they must buy  5 seats '}
            </Typography.Text>

            <div className='mt-4 space-y-4'>
              <div className='flex flex-col'>
                <label className='font-medium'>Number of Seats</label>
                <InputNumber
                  min={resellerData?.totalReceptionists ? 1 : 5}
                  value={seatsBuyed}
                  className='w-full mt-2'
                  onChange={value => setSeatsBuyed(value)}
                />
              </div>
            </div>
          </CustomModal>

          <CustomModal
            title='Are you sure?'
            isModalOpen={removeSubscriptionModal}
            confirmAction={() => {
              if (resellerData.totalReceptionists <= (resellerData.minimumReceptionistsAllowed ?? 5)) {
                notification.error({
                  message: `You must retain at least ${resellerData.minimumReceptionistsAllowed || 5} seats`,
                });
                return;
              }
              decrementUnreservedReceptionistMutation.mutate({ reseller_email: resellerData.email_address });
            }}
            loading={decrementUnreservedReceptionistMutation.isPending}
            cancelAction={() => {
              setRemoveSubscriptiontNoticeModal(false);
            }}
            okText='Yes, Proceed'
          >
            <Title level={4}>This action cannot be undone. Are you sure you want to delete this receptionist?</Title>
          </CustomModal>
          <CustomModal
            title='Copy Existing Receptionist'
            isModalOpen={copyReceptionistModal}
            confirmAction={handleCopyReceptionist}
            cancelAction={() => {
              setCopyReceptionistModal(false);
              setCopiedReceptiontCreds({ username: '', password: '' });
            }}
            okText='Copy Receptionist'
            loading={createCopiedReceptionistMutation.isPending}
          >
            <div className='space-y-4'>
              <Title level={4}>Select a receptionist to copy and provide new credentials</Title>

              <div className='flex flex-col space-y-2'>
                <label className='font-medium'>Select Receptionist to Copy</label>
                <Select
                  placeholder='Select a receptionist'
                  className='w-full'
                  onChange={value => {
                    setCurrentReceptionist({ number: value });
                  }}
                  value={receptionistCurrentlySelected?.number}
                >
                  {clients.map(client => (
                    <Select.Option key={client.number} value={client.number}>
                      {client.business_name} ({client.number})
                    </Select.Option>
                  ))}
                </Select>
              </div>

              {receptionistCurrentlySelected && (
                <div className='space-y-4 mt-4'>
                  <div className='flex flex-col space-y-2'>
                    <label className='font-medium'>New Username</label>
                    <Input
                      placeholder='Enter new username'
                      value={copiedReceptiontCreds.username}
                      onChange={e =>
                        setCopiedReceptiontCreds(prev => ({
                          ...prev,
                          username: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className='flex flex-col space-y-2'>
                    <label className='font-medium'>New Password</label>
                    <Input.Password
                      placeholder='Enter new password'
                      value={copiedReceptiontCreds.password}
                      onChange={e =>
                        setCopiedReceptiontCreds(prev => ({
                          ...prev,
                          password: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </CustomModal>
          <CustomModal
            title={null}
            isModalOpen={embedLinkModal}
            confirmAction={() => {
              setEmbedLinkModal(false);
            }}
            cancelAction={() => {
              setEmbedLinkModal(false);
            }}
          >
            <Title level={4}>Embed iframes for login/register</Title>
            <p className='text-sm'>Copy the below iframe code and paste it in your website to embed login/regiter.</p>

            <Typography.Text strong>For Login</Typography.Text>

            <Input
              value={`<iframe src=${import.meta.env.VITE_APP_WIHTELABEL_URL + AppRoutes.UNBRANDED_DASHBOARD + '?code=' + linkData?.link} width="100%" height="100%" frameBorder="0"></iframe>`}
              readOnly
              suffix={
                <CopyOutlined
                  className='cursor-pointer text-gray-500 hover:text-gray-700'
                  onClick={() => handleCopyIframe(AppRoutes.UNBRANDED_DASHBOARD)}
                />
              }
            />
            <Typography.Text strong>For Register</Typography.Text>
            <Input
              value={`<iframe src=${import.meta.env.VITE_APP_WIHTELABEL_URL + AppRoutes.GENERIC_DOMAIN_REGISTER + '?code=' + linkData?.link} width="100%" height="100%" frameBorder="0"></iframe>`}
              readOnly
              suffix={
                <CopyOutlined
                  className='cursor-pointer text-gray-500 hover:text-gray-700'
                  onClick={() => handleCopyIframe(AppRoutes.GENERIC_DOMAIN_REGISTER)}
                />
              }
            />
          </CustomModal>

          {isRegistrationModal && (
            <>
              <div
                onClick={() => {
                  setIsRegistrationModalActive(false);
                }}
                className={classes.modalOverlay}
              ></div>
              <div className={`${classes.modal} ${classes.wide}`}>
                <div
                  className={classes.close}
                  onClick={() => {
                    setIsRegistrationModalActive(false);
                  }}
                >
                  &times;
                </div>

                <RegisterPageV3
                  debrand={true}
                  reseller_email={resellerData.email_address}
                  purchase_quotes={resellerData.purchase_quotes}
                  reseller_name={resellerData.reseller_name}
                  debranded_registration_modal_active_setter={setIsRegistrationModalActive}
                  features={resellerData.features}
                  debrand_modal_instructions_setter={(prop: any) => {
                    setSuccessConfigureReceptionistModal(prop);
                    dispatch(
                      updateReseller({
                        ...resellerData,
                        unreservedReceptionists: resellerData.unreservedReceptionists - 1,
                      })
                    );
                  }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </ResellerDashboard>
  );
}
