import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, Form, Modal, Tooltip } from 'antd';
import DOMPurify from 'dompurify';
import { generateNotificationSMSHtml } from '@/utils/emailSMSHelper';
import { DiamondOutlined } from '@mui/icons-material';
import { NotifKey } from 'src/enums/ENotifKey';
import { Show } from '@/interfaces/INotifications';
import { OverViewCardForm } from '../../Cards/OverviewCardForm';

interface props {
  open: boolean;
  setOpen: (val: boolean) => void;
  disabled: boolean;
  notifKey: string;
  show: Show | null;
  onShowChange?: (newShow: Show) => void;
}

const PreviewSms: React.FC<props> = ({ open, setOpen, disabled, notifKey, show: parentShow, onShowChange }) => {
  const [form] = Form.useForm();

  const [localShow, setLocalShow] = useState<Show | null>(parentShow);
  const [smsPart, setSmsPart] = useState<string>('');

  useEffect(() => {
    if (parentShow) {
      setLocalShow(parentShow);
      form.setFieldsValue({ show: parentShow });
    }
  }, [parentShow, form]);

  useEffect(() => {
    const smsHtml = generateNotificationSMSHtml({
      showCallSummary: notifKey === NotifKey.DirectCall ? localShow?.callSummaryText : false,
      showCallInfoLink: notifKey === NotifKey.DirectCall ? localShow?.callInformationLink : false,
      notifKey,
    });
    setSmsPart(smsHtml);
  }, [localShow]);

  const onLocalCheck = (key: keyof Show) => {
    if (localShow) {
      const newShow = {
        ...localShow,
        [key]: !localShow[key],
      };
      setLocalShow(newShow);
      form.setFieldsValue({ show: newShow });
    }
  };

  const sanitizeAndFormatText = (text: string) => {
    const sanitized = DOMPurify.sanitize(text);
    return sanitized;
  };

  const handleSubmit = async () => {
    if (onShowChange && localShow) {
      onShowChange(localShow);
    }
    setOpen(false);
  };

  const FormItems = () => (
    <div className='flex flex-col'>
      {notifKey === NotifKey.DirectCall && (
        <>
          <div className='flex items-center gap-4'>
            <span className='mr-4'>Include:</span>
            <span>
              <Form.Item name={['show', 'callSummaryText']} valuePropName='checked' noStyle>
                <Checkbox onChange={() => onLocalCheck('callSummaryText')} disabled={disabled}>
                  Call Summary
                </Checkbox>
              </Form.Item>
            </span>
            <span>
              <Form.Item name={['show', 'callInformationLink']} valuePropName='checked' noStyle>
                <Checkbox onChange={() => onLocalCheck('callInformationLink')} disabled={disabled}>
                  Call Info Link
                </Checkbox>
              </Form.Item>
            </span>
          </div>
          <Divider />
        </>
      )}
      <div className='max-h-[60vh] overflow-y-auto'>
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeAndFormatText(smsPart),
          }}
        ></span>
      </div>
    </div>
  );

  return (
    <Modal
      title={
        <div className='flex items-center gap-1'>
          <span className='text-lg'>
            {notifKey === NotifKey.DirectCall ? 'Customize Text SMS Notification' : 'Preview Text SMS Notification'}
          </span>
          <Tooltip title='Included from Pro plan'>
            <DiamondOutlined fontSize='medium' className='text-yellow-500 cursor-pointer' />
          </Tooltip>
        </div>
      }
      open={open}
      onCancel={() => setOpen(false)}
      width={700}
      footer={null}
    >
      <OverViewCardForm
        form={form}
        initialValues={{
          show: parentShow,
        }}
        formItems={<FormItems />}
        handleSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default PreviewSms;
