import { useState } from 'react';
import { Input, Button, Typography, Divider, Tooltip, Space, Modal, Carousel, notification } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, QuestionCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { addTwilioKeys } from '@/api/novi/user';
import { useDispatch } from 'react-redux';
import { setNoviUser } from '@/store/novi/user/slice';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInNoviUser } from '@/store/novi/user/selector';

const { Title, Text } = Typography;

const TwilioSetup: React.FC = () => {
  const dispatch = useDispatch();
  const noviUser = useAppSelector(getLoggedInNoviUser);

  const [twilioAccountId, setTwilioAccountId] = useState<string | ''>(noviUser.twilio_account_id || '');
  const [twilioAccessToken, setTwilioAccessToken] = useState<string | ''>(noviUser.twilio_access_token || '');
  const [showTwilioAccountId, setShowTwilioAccountId] = useState<boolean>(false);
  const [showTwilioAccessToken, setShowTwilioAccessToken] = useState<boolean>(false);
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);

  const [isTwilioConfigured, setIsTwilioConfigured] = useState<boolean>(
    !!noviUser.twilio_account_id && !!noviUser.twilio_access_token
  );

  const { mutate: updateTwilioConfig, isPending } = useMutation({
    mutationFn: addTwilioKeys,
    onSuccess: () => {
      dispatch(
        setNoviUser({
          ...noviUser,
          twilio_account_id: twilioAccountId,
          twilio_access_token: twilioAccessToken,
        })
      );
      setIsTwilioConfigured(true);
      notification.success({
        message: 'Success',
        description: 'Twilio details validated and saved successfully',
        placement: 'bottomRight',
        className: 'compact-notification',
      });
    },
    onError: (error: Error) => {
      notification.error({
        message: 'Error',
        description: error.message,
        placement: 'bottomRight',
        className: 'compact-notification',
      });
    },
  });

  const handleSave = () => {
    updateTwilioConfig({ twilio_access_token: twilioAccessToken, twilio_account_id: twilioAccountId });
  };

  const handleVideoModal = () => {
    setShowVideoModal(!showVideoModal);
  };

  const maskToken = (token: string) => {
    if (!token) return '';
    return '*'.repeat(token.length);
  };

  return (
    <div className='max-w-6xl mx-auto px-4 py-6'>
      <Space direction='vertical' size='middle' className='w-full'>
        <div className='text-center mb-8'>
          <Title level={4} className='text-blue-600 m-0 font-medium'>
            Twilio Setup
          </Title>
        </div>

        <div className='bg-white p-6 rounded-lg shadow-sm'>
          <Space direction='vertical' size='large' className='w-full'>
            <div>
              <Title level={5} className='text-blue-600 m-0 mb-2'>
                Why Twilio Integration?
              </Title>
              <Text className='text-sm text-gray-600'>
                Our platform leverages Twilio, a leading communication platform, to enable features like SMS messaging,
                voice calls, and more.
              </Text>
              <Button
                type='link'
                icon={<PlayCircleOutlined className='text-sm' />}
                onClick={handleVideoModal}
                className='text-sm p-0 h-auto mt-2 text-blue-600 hover:text-blue-800'
              >
                Watch Video Overview
              </Button>
            </div>

            <Divider className='my-4' />

            <div>
              <Space direction='vertical' size='middle' className='w-full'>
                <div className='flex items-center gap-2'>
                  <Title level={5} className='text-blue-600 m-0'>
                    Twilio Account Details
                  </Title>
                  <Tooltip title='Twilio is a communication platform that allows you to send SMS messages, make phone calls, and more.'>
                    <QuestionCircleOutlined className='text-gray-400 text-sm' />
                  </Tooltip>
                </div>

                <div className='grid gap-4'>
                  {isTwilioConfigured ? (
                    <>
                      <div>
                        <Text className='text-sm font-medium text-gray-700 block mb-1'>Twilio Account ID</Text>
                        <Input size='middle' value={maskToken(twilioAccountId)} disabled className='rounded-md' />
                      </div>
                      <div>
                        <Text className='text-sm font-medium text-gray-700 block mb-1'>Twilio Access Token</Text>
                        <Input size='middle' value={maskToken(twilioAccessToken)} disabled className='rounded-md' />
                      </div>
                      <Button
                        type='default'
                        size='middle'
                        onClick={() => {
                          setIsTwilioConfigured(false);
                          setTwilioAccountId('');
                          setTwilioAccessToken('');
                        }}
                        className='w-full sm:w-auto mt-4'
                      >
                        Reconfigure Twilio
                      </Button>
                    </>
                  ) : (
                    <>
                      <div>
                        <Text className='text-sm font-medium text-gray-700 block mb-1'>Twilio Account ID</Text>
                        <Input.Password
                          size='middle'
                          value={twilioAccountId}
                          onChange={e => setTwilioAccountId(e.target.value)}
                          placeholder='Enter your Twilio Account ID'
                          iconRender={visible =>
                            visible ? (
                              <EyeOutlined className='text-gray-400' />
                            ) : (
                              <EyeInvisibleOutlined className='text-gray-400' />
                            )
                          }
                          visibilityToggle={{
                            visible: showTwilioAccountId,
                            onVisibleChange: setShowTwilioAccountId,
                          }}
                          className='rounded-md'
                        />
                      </div>

                      <div>
                        <Text className='text-sm font-medium text-gray-700 block mb-1'>Twilio Access Token</Text>
                        <Input.Password
                          size='middle'
                          value={twilioAccessToken}
                          onChange={e => setTwilioAccessToken(e.target.value)}
                          placeholder='Enter your Twilio Access Token'
                          iconRender={visible =>
                            visible ? (
                              <EyeOutlined className='text-gray-400' />
                            ) : (
                              <EyeInvisibleOutlined className='text-gray-400' />
                            )
                          }
                          visibilityToggle={{
                            visible: showTwilioAccessToken,
                            onVisibleChange: setShowTwilioAccessToken,
                          }}
                          className='rounded-md'
                        />
                      </div>

                      <Button
                        type='primary'
                        size='middle'
                        loading={isPending}
                        onClick={handleSave}
                        className='w-full sm:w-auto mt-4'
                      >
                        Save Changes
                      </Button>
                    </>
                  )}
                </div>
              </Space>
            </div>
          </Space>
        </div>

        <Modal
          open={showVideoModal}
          onCancel={handleVideoModal}
          footer={null}
          width={720}
          className='video-modal rounded-lg overflow-hidden'
          closeIcon={<span className='text-white'>×</span>}
        >
          <div className='p-4'>
            <Title level={5} className='text-center text-blue-600 m-0 mb-4'>
              Twilio Integration Overview
            </Title>
            <div className='rounded-lg overflow-hidden'>
              <Carousel autoplay>
                <iframe
                  width='100%'
                  height='360'
                  src='https://www.loom.com/embed/ae3b7257107243e39bc1792b5e2c83c0'
                  allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </Carousel>
            </div>
          </div>
        </Modal>
      </Space>
    </div>
  );
};

export default TwilioSetup;
