import { Card, Typography, Progress, Row, Col, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BookOpen, Clock, ChevronRight } from 'lucide-react';
import { AppRoutes } from 'src/enums/ERoutes';
import ResellerDashboard from '../ResellerDashboard/ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerAcademy } from '@/store/resellerAcademy/selector';
import './ResellerAcademy.scss';

const { Title, Text } = Typography;

const ResellerAcademy = () => {
  const navigate = useNavigate();
  const { chapters, loading } = useAppSelector(getResellerAcademy);

  const getChapterProgress = (chapterId: number): number => {
    const chapter = chapters?.find(c => c.id === chapterId);
    if (!chapter) return 0;
    const completedVideos = chapter.videos.filter(v => v.isCompleted).length;
    return Math.round((completedVideos / chapter.videos.length) * 100);
  };

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.ACADEMY} showHeader={false}>
      <Spin spinning={loading}>
        <div className='reseller-academy'>
          <div className='reseller-academy__container'>
            <div className='reseller-academy__header'>
              <Title level={2} className='reseller-academy__title'>
                Reseller Academy
              </Title>
              <Text className='reseller-academy__subtitle'>
                Master the art of reselling with our comprehensive training program
              </Text>
            </div>

            <Row gutter={[24, 24]}>
              {chapters?.map(chapter => (
                <Col xs={24} md={12} lg={8} key={chapter.id}>
                  <Card
                    hoverable
                    className='reseller-academy__card'
                    onClick={() =>
                      navigate(AppRoutes.RESELLER_ACADEMY_CHAPTER.replace(':chapterId', chapter.id?.toString()))
                    }
                  >
                    <div className='reseller-academy__card-content'>
                      <div className='reseller-academy__card-header'>
                        <Text className='reseller-academy__chapter-number'>Chapter {chapter.id}</Text>
                        <ChevronRight className='reseller-academy__chevron' />
                      </div>

                      <Progress
                        percent={getChapterProgress(chapter.id)}
                        strokeColor={{
                          '0%': '#1890ff',
                          '100%': '#52c41a',
                        }}
                        strokeWidth={8}
                        className='reseller-academy__progress'
                      />

                      <Title level={4} className='reseller-academy__card-title'>
                        {chapter.title}
                      </Title>

                      <Text className='reseller-academy__card-description'>{chapter.description}</Text>

                      <div className='reseller-academy__card-footer'>
                        <div className='reseller-academy__stat'>
                          <BookOpen className='reseller-academy__icon' />
                          <span>{chapter.videos.length} lessons</span>
                        </div>
                        <div className='reseller-academy__stat'>
                          <Clock className='reseller-academy__icon' />
                          <span>{chapter.totalDuration} mins</span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Spin>
    </ResellerDashboard>
  );
};

export default ResellerAcademy;
