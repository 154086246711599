import { useState } from 'react';
import { Layout, Menu, Drawer, Typography, Button, Avatar, Space } from 'antd';
import type { MenuProps } from 'antd';
import {
  AppstoreOutlined,
  CalendarOutlined,
  RobotOutlined,
  TeamOutlined,
  MenuOutlined,
  DashboardOutlined,
  ExperimentOutlined,
  MessageOutlined,
  PhoneOutlined,
  FileTextOutlined,
  ApiOutlined,
  BranchesOutlined,
} from '@ant-design/icons';
import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';
import { NoviUser } from '@/pages/Novi/types/NoviUser';
import { BarChartOutlined, VoiceChatOutlined } from '@mui/icons-material';

const { Sider } = Layout;
const { Title } = Typography;

interface SiderMenuProps {
  currentPage: ENoviDashboardSider | string;
  handleMenuSelect: (key: ENoviDashboardSider) => void;
  selectedCampaignId: string | null;
  noviUser: NoviUser;
  isMobile: boolean;
}

const SiderMenu: React.FC<SiderMenuProps> = ({
  currentPage,
  handleMenuSelect,
  selectedCampaignId,
  noviUser,
  isMobile,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const handleDrawerClose = () => setIsDrawerOpen(false);

  const baseMenuItems = !selectedCampaignId
    ? [
        {
          key: ENoviDashboardSider.MY_CAMPAIGNS,
          icon: <AppstoreOutlined />,
          label: 'My Campaigns',
        },
        {
          key: ENoviDashboardSider.BRANDING,
          icon: <BranchesOutlined />,
          label: 'Branding',
        },
        {
          key: ENoviDashboardSider.BYOT,
          icon: <ApiOutlined />,
          label: 'Bring Your Twilio',
        },
      ]
    : [];

  const campaignMenuItems: MenuProps['items'] = selectedCampaignId
    ? [
        {
          type: 'group',
          label: 'Campaign Management',
          children: [
            {
              key: ENoviDashboardSider.LEADS,
              icon: <TeamOutlined />,
              label: 'Leads',
            },
            {
              key: ENoviDashboardSider.SCHEDULE,
              icon: <CalendarOutlined />,
              label: 'Schedule',
            },
          ],
        },
        {
          type: 'group',
          label: 'AI & Automation',
          children: [
            {
              key: ENoviDashboardSider.CONFIGURE,
              icon: <RobotOutlined />,
              label: 'AI Configuration',
            },
            {
              key: ENoviDashboardSider.VOICE_LIBRARY,
              icon: <ExperimentOutlined />,
              label: 'Voice Studio',
            },
            {
              key: ENoviDashboardSider.INTAKE_FORMS,
              icon: <FileTextOutlined />,
              label: 'Smart Forms',
            },
            {
              key: ENoviDashboardSider.TEXTING_PLATFORM,
              icon: <MessageOutlined />,
              label: 'Message Center',
            },
          ],
        },
        {
          type: 'group',
          label: 'Logs & Analytics',
          children: [
            {
              key: ENoviDashboardSider.ANALYTICS,
              icon: <BarChartOutlined />,
              label: 'Analytics',
            },
            {
              key: ENoviDashboardSider.CALL_LOGS,
              icon: <PhoneOutlined />,
              label: 'Call Analytics',
            },
            {
              key: ENoviDashboardSider.TEXT_LOGS,
              icon: <DashboardOutlined />,
              label: 'Message Insights',
            },
            {
              key: ENoviDashboardSider.VOICE_MAIL_LOGS,
              icon: <VoiceChatOutlined />,
              label: 'Voicemail Logs',
            },
          ],
        },
      ]
    : [];

  const getMenuItems = (): MenuProps['items'] => [...campaignMenuItems, ...baseMenuItems];

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    handleMenuSelect(key as ENoviDashboardSider);
    if (isMobile) {
      handleDrawerClose();
    }
  };

  const MenuHeader = ({ collapsed }: { collapsed: boolean }) => (
    <div className='p-4 border-b border-gray-100'>
      <Space direction='vertical' size='small' style={{ width: '100%' }}>
        <Space align='center' style={{ width: '100%', justifyContent: 'space-between' }}>
          <Avatar
            src={noviUser.logo}
            size={48}
            style={{
              padding: 4,
              backgroundColor: '#f5f5f5',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            }}
          />
        </Space>

        {!collapsed && (
          <Title level={5} style={{ margin: 0 }}>
            {noviUser.business_name || 'Welcome Back'}
          </Title>
        )}
      </Space>
    </div>
  );

  const sidebarContent = (
    <>
      <MenuHeader collapsed={collapsed} />
      <Menu
        mode='inline'
        selectedKeys={[currentPage]}
        onClick={handleMenuClick}
        items={getMenuItems()}
        style={{
          border: 'none',
          padding: '8px',
        }}
        theme='light'
      />
    </>
  );

  return isMobile ? (
    <>
      <Button
        type='text'
        icon={<MenuOutlined />}
        onClick={() => setIsDrawerOpen(true)}
        style={{
          position: 'fixed',
          top: '16px',
          left: '16px',
          zIndex: 1000,
        }}
      />
      <Drawer
        title={null}
        placement='left'
        onClose={handleDrawerClose}
        open={isDrawerOpen}
        width={280}
        styles={{
          body: {
            padding: 0,
            backgroundColor: '#fff',
          },
          mask: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        }}
        closable={false}
      >
        {sidebarContent}
      </Drawer>
    </>
  ) : (
    <Sider
      theme='light'
      width={220}
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={{
        height: '100vh',
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#fff',
        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
      }}
    >
      {sidebarContent}
    </Sider>
  );
};

export default SiderMenu;
